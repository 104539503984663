/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN, StorageService } from '@cubicNx/libs/utils';

import { StorageType } from '@cubicNx/libs/utils';
import { UserLogin } from '../../types/api-types';
import { AgencyConfig } from '../../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class UserCacheService {
	constructor(
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private storageService: StorageService
	) {}

	/**
	 * sets the current user into the cache
	 *
	 * @param currentUser - the logged in user details
	 */
	public setCurrentUser = (currentUser: UserLogin): void => {
		this.storageService.set(this.getCurrentUserKey(), currentUser, StorageType.session);
	};

	/**
	 * gets the current user from the cache
	 *
	 * @returns the logged in user details
	 */
	public getCurrentUser = (): UserLogin => {
		return this.storageService.get(this.getCurrentUserKey(), StorageType.session);
	};

	/**
	 * clears the current user from the cache
	 */
	public clearCurrentUser = (): void => {
		this.storageService.set(this.getCurrentUserKey(), null, StorageType.session);
	};

	/**
	 * gets the current user key from the config service
	 *
	 * @returns the current user key from the config service
	 */
	private getCurrentUserKey = (): string => {
		return this.config.getCurrentUserKey();
	};
}
