import { Injectable } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';

/**
 * Service responsible for i18n translations.
 */
@Injectable({
	providedIn: 'root',
})
export class TranslationService {
	private english: string = 'en';
	private spanish: string = 'es';
	private french: string = 'fr';
	private langs: string[] = [this.english, this.french, this.spanish];

	constructor(private translateService: TranslateService) {}

	/**
	 * Performs translation related initialization
	 *
	 * initializes to a supplied language, otherwise it will default to the browser language
	 * if it is one of our configured languages, otherwise default to english
	 *
	 * @param language - a language (if set to default to)
	 */
	public async init(language: string = null): Promise<void> {
		this.translateService.addLangs(this.langs);

		if (language) {
			await this.setLanguage(language);
		} else {
			const browserlang: string = this.translateService.getBrowserLang();

			if (browserlang.includes(this.english)) {
				await this.setLanguage(this.english);
			} else if (browserlang.includes(this.spanish)) {
				await this.setLanguage(this.spanish);
			} else if (browserlang.includes(this.french)) {
				await this.setLanguage(this.french);
			} else {
				await this.setLanguage(this.english);
			}
		}
	}

	/**
	 * sets the language to use for all translations
	 *
	 * @param language - the language to use for translations
	 */
	public setLanguage = async (language: string): Promise<void> => {
		this.translateService.setDefaultLang(language);

		await this.translateService.use(language).toPromise();
	};

	/**
	 * gets the language currently being used by the tranlsation service
	 *
	 * @returns language being used for tranlsation purposes
	 */
	public getLanguage = (): string => {
		return this.translateService.getDefaultLang();
	};

	/**
	 * will return a translated version of the supplied text
	 *
	 * replace any dashes/hyphens with underscores - typically hardcoded translations will already have underscores
	 * but some translation keys are contructed from back end data which may have hyphens
	 *
	 * @param translateKey - key of the text to be translated
	 * @returns Language specific variant
	 */
	public getTranslation = (translateKey: string): string => {
		let translatedKey: string = '';

		translateKey = translateKey.replace(/ |-/g, '_').toUpperCase();

		this.translateService.get([translateKey]).subscribe((translations: { [x: string]: string }) => {
			translatedKey = translations[translateKey];

			return translatedKey;
		});

		return translatedKey;
	};
}
