/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';
import { AgenciesAdminModalService } from '../../services/agencies-admin-modal.service';
import { StopsDataService } from '../../../../../support-features/stops/services/stops-data.service';
import { PredictionsDataService } from '../../../../predictions/services/predictions-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { RouteConfig, Stop } from '../../../../predictions/types/api-types';
import { ResultContent, ViewPanelSource } from '@cubicNx/libs/utils';
import { HeadwaySettings } from './../../../../../support-features/agencies/types/types';

import { AgencyDetail, TerminalDepartureRouteStopConfig } from '../../../../../support-features/agencies/types/api-types';
import { defaultHeadwayAdherenceSettings } from '../../../../../support-features/agencies/types/types';

@Component({
	selector: 'agency-admin-view-settings',
	templateUrl: './agency-admin-view-settings.component.html',
	styleUrls: ['./agency-admin-view-settings.component.scss'],
})
export class AgencyAdminViewSettingsComponent extends TranslateBaseComponent implements OnInit, OnChanges {
	@Input() agencyDetail: AgencyDetail = null;
	@Input() authorityId: string = null;
	@Input() agencyId: string = null;

	@Output() agencyUpdated: EventEmitter<void> = new EventEmitter<void>();
	@Output() settingsLoaded: EventEmitter<void> = new EventEmitter<void>();

	public settingsViewTableOptions: ViewPanelSource;
	public adherenceViewTableOptions: ViewPanelSource;
	public headwayViewTableOptions: ViewPanelSource;
	public ridershipViewTableOptions: ViewPanelSource;
	public legacyNextbusOptions: ViewPanelSource;
	public terminalDepartureOptions: ViewPanelSource;
	public terminalDepartureExceptionOptions: ViewPanelSource;

	public routeConfigs: Map<string, RouteConfig> = new Map<string, RouteConfig>();
	public routeNames: Map<string, string> = new Map<string, string>();
	public stopNames: Map<string, string> = new Map<string, string>();

	constructor(
		private agenciesAdminModalService: AgenciesAdminModalService,
		private predictionsDataService: PredictionsDataService,
		private stopsDataService: StopsDataService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the agency details settings view - second tab on the agency details
	 */
	public async ngOnInit(): Promise<void> {
		await this.initializeRouteConfigs();

		this.settingsViewTableOptions = {
			title: 'T_AGENCY.SETTINGS_TEXT',
			rowDefs: [
				{
					displayName: 'T_AGENCY.SETTINGS.LATITUDE',
					name: 'latitude',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.LONGITUDE',
					name: 'longitude',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.AGENCY_LANG',
					name: 'agency_lang',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.UNIT_OF_MEASURE',
					name: 'unit_of_measure',
					convert: (value): string => value.charAt(0).toUpperCase() + value.slice(1),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.DATE_FORMAT',
					name: 'date_format',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.TIME_FORMAT',
					name: 'time_format',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.AGENCY_TIMEZONE',
					name: 'agency_timezone',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.AUTOMATIC_LOGOUT_TIMER',
					name: 'logout_timer',
					convert: (value): string => this.logoutSecondsToTime(value),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.SIDE_OF_TRAVEL',
					name: 'side_of_travel',
					convert: this.convertSideOfTravel,
				},
				{
					displayName: 'T_AGENCY.SETTINGS.IMMINENT_DEPARTURE_TRAVEL',
					name: 'imminent_departure',
					convert: (value): string => this.setDefaultImminentDeparture(value, 120),
				},
			],
		};

		this.adherenceViewTableOptions = {
			title: 'T_AGENCY.ADHERENCE',
			rowDefs: [
				{
					displayName: 'T_AGENCY.SETTINGS.ADHERENCE_SETTING_EARLY_MIN_SEC',
					name: 'adherence_setting_early_min_sec',
					convert: (value): string => this.setDefaultAdherenceValues(value, 300),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_EARLY_SEC',
					name: 'adherence_setting_very_early_sec',
					convert: (value): string => this.setDefaultAdherenceValues(value, 600),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.ADHERENCE_SETTING_LATE_MIN_SEC',
					name: 'adherence_setting_late_min_sec',
					convert: (value): string => this.setDefaultAdherenceValues(value, 600),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.ADHERENCE_SETTING_VERY_LATE_SEC',
					name: 'adherence_setting_very_late_sec',
					convert: (value): string => this.setDefaultAdherenceValues(value, 1200),
				},
				{
					displayName: 'T_AGENCY.SETTINGS.STALE_SEC',
					name: 'stale_sec',
					convert: (value): string => this.setDefaultAdherenceValues(value, 600),
				},
			],
		};

		this.headwayViewTableOptions = {
			title: 'T_AGENCY.HEADWAY.TITLE',
			rowDefs: [
				{
					displayName: 'T_AGENCY.HEADWAY.CLOSE',
					name: 'headway_adherence_close',
					convert: this.convertHeadwayAdherence('headway_adherence_close'),
				},
				{
					displayName: 'T_AGENCY.HEADWAY.VERY_CLOSE',
					name: 'headway_adherence_very_close',
					convert: this.convertHeadwayAdherence('headway_adherence_very_close'),
				},
				{
					displayName: 'T_AGENCY.HEADWAY.DISTANT',
					name: 'headway_adherence_distant',
					convert: this.convertHeadwayAdherence('headway_adherence_distant'),
				},
				{
					displayName: 'T_AGENCY.HEADWAY.VERY_DISTANT',
					name: 'headway_adherence_very_distant',
					convert: this.convertHeadwayAdherence('headway_adherence_very_distant'),
				},
			],
		};

		this.ridershipViewTableOptions = {
			title: 'T_AGENCY.RIDERSHIP.TITLE',
			rowDefs: [
				{
					displayName: 'T_AGENCY.RIDERSHIP.EMPTY',
					name: 'ridership_threshold_empty',
					convert: (value): string =>
						this.formatRidershipThreshold(value, '<=', this.getTranslation('T_AGENCY.RIDERSHIP.SEATED')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.MANY_SEATS',
					name: 'ridership_threshold_many',
					convert: (value): string =>
						this.formatRidershipThreshold(value, '<=', this.getTranslation('T_AGENCY.RIDERSHIP.SEATED')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.FEW_SEATS',
					name: 'ridership_threshold_few_standing',
					convert: (value): string =>
						this.formatRidershipThreshold(value, '<=', this.getTranslation('T_AGENCY.RIDERSHIP.SEATED')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.STANDING',
					name: 'ridership_threshold_few_standing',
					convert: (value): string => this.formatRidershipThreshold(value, '>', this.getTranslation('T_AGENCY.RIDERSHIP.SEATED')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.CRUSHED',
					name: 'ridership_threshold_crushed',
					convert: (value): string => this.formatRidershipThreshold(value, '>=', this.getTranslation('T_AGENCY.RIDERSHIP.TOTAL')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.FULL',
					name: 'ridership_threshold_full',
					convert: (value): string => this.formatRidershipThreshold(value, '>=', this.getTranslation('T_AGENCY.RIDERSHIP.TOTAL')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.NOT_ACCEPTING',
					name: 'ridership_threshold_not_accepting',
					convert: (value): string => this.formatRidershipThreshold(value, '>=', this.getTranslation('T_AGENCY.RIDERSHIP.TOTAL')),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.AUTO_RESET_UNASSIGNED',
					name: 'ridership_auto_reset_unassigned_secs',
					convert: (value): string => this.formatAutoResetValue(value),
				},
				{
					displayName: 'T_AGENCY.RIDERSHIP.AUTO_RESET_LOOP_DWELL',
					name: 'ridership_auto_reset_loop_dwell_secs',
					convert: (value): string => this.formatAutoResetValue(value),
				},
			],
		};

		this.legacyNextbusOptions = {
			title: 'T_AGENCY.SETTINGS.LEGACY_NEXTBUS_SETTINGS',
			rowDefs: [
				{
					displayName: 'T_AGENCY.SETTINGS.DEPARTING_WORD',
					name: 'departing_word',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.ARRIVING_WORD',
					name: 'arriving_word',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.DEFAULT_ROUTE_ID',
					name: 'default_route_id',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.SMS_PREFIX',
					name: 'sms_prefix',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.CAN_SELECT_BY_STOP_CODE',
					name: 'can_select_by_stop_code',
					convert: this.convertCanSelectByStopCode,
				},
				{
					displayName: 'T_AGENCY.SETTINGS.STOP_CODE_LENGTH',
					name: 'stop_code_length',
				},
				{
					displayName: 'T_AGENCY.SETTINGS.HAS_SIGNS',
					name: 'hassigns',
				},
			],
		};

		this.terminalDepartureOptions = {
			title: 'T_AGENCY.TERMINAL_DEPARTURE.TITLE',
			rowDefs: [
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS',
					name: 'terminal_departure_arrival_stop_radius',
					convert: (value): string => this.formatMeters(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.APPLY_SCHED_LAYOVERS',
					name: 'terminal_departure_apply_sched_layovers',
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MIN_LAYOVER',
					name: 'terminal_departure_min_layover_seconds',
					convert: (value): string => this.toMinuteAndSeconds(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.EXTEND_ALL',
					name: 'terminal_departure_extend_all',
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MAX_DEPARTURE',
					name: 'terminal_departure_max_seconds',
					convert: (value): string => this.toMinuteAndSeconds(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_AT_UPCOMING_STOP',
					name: 'terminal_departure_detect_departure_at_upcoming_stop',
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_STOP_SEQUENCE',
					name: 'terminal_departure_detect_departure_stop_sequence',
				},
			],
			dynamicSubTitle: this.createTerminalDepartureExceptionSubTitle,
			rowSubDefs: [
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DEFAULT_DEADHEADING',
					name: 'terminal_departure_default_deadhead_seconds',
					convert: (value): string => this.toMinuteAndSeconds(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MIN_DIST_NAV_SERVICE',
					name: 'terminal_departure_min_dist_nav_service',
					convert: (value): string => this.formatMeters(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.START_TIME_NAV_SERVICE',
					name: 'terminal_departure_start_nav_service_throttle',
					convert: (value): string => this.hoursAndMinutesConversion(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.END_TIME_NAV_SERVICE',
					name: 'terminal_departure_end_nav_service_throttle',
					convert: (value): string => this.hoursAndMinutesConversion(value),
				},
				{
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.CALL_FREQUENCY_NAV_SERVICE',
					name: 'terminal_departure_nav_service_call_frequency',
					convert: (value): string => this.toMinuteAndSeconds(value),
				},
			],
		};
	}

	/**
	 * populates the terminal departure exceptions part of the agency settings view
	 */
	public terminalDepartureException = (): void => {
		for (const exception of this.agencyDetail.terminal_departure_exceptions) {
			this.terminalDepartureExceptionOptions = {
				dynamicTitle: this.createTerminalDepartureExceptionTitle,
				rowDefs: [],
				dynamicSubTitle: this.createTerminalDepartureExceptionSubTitle,
				rowSubDefs: [],
			};

			if (exception.arrival_stop_radius !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.ARRIVAL_STOP_RADIUS',
					name: 'arrival_stop_radius',
					convert: (value) => this.formatMeters(value),
				});
			}

			if (exception.apply_sched_layovers !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.APPLY_SCHED_LAYOVERS',
					name: 'apply_sched_layovers',
				});
			}

			if (exception.min_layover_seconds !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MIN_LAYOVER',
					name: 'min_layover_seconds',
					convert: (value) => this.toMinuteAndSeconds(value),
				});
			}

			if (exception.max_extension_seconds !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MAX_DEPARTURE',
					name: 'max_extension_seconds',
					convert: (value) => this.toMinuteAndSeconds(value),
				});
			}

			if (exception.detect_departure_at_upcoming_stop !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_AT_UPCOMING_STOP',
					name: 'detect_departure_at_upcoming_stop',
				});
			}

			if (exception.detect_departure_stop_sequence !== null) {
				this.terminalDepartureExceptionOptions.rowDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DETECT_DEPARTURE_STOP_SEQUENCE',
					name: 'detect_departure_stop_sequence',
				});
			}

			if (exception.default_deadhead_seconds !== null) {
				this.terminalDepartureExceptionOptions.rowSubDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.DEFAULT_DEADHEADING',
					name: 'default_deadhead_seconds',
					convert: (value) => this.toMinuteAndSeconds(value),
				});
			}

			if (exception.min_dist_nav_service !== null) {
				this.terminalDepartureExceptionOptions.rowSubDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.MIN_DIST_NAV_SERVICE',
					name: 'min_dist_nav_service',
					convert: (value) => this.formatMeters(value),
				});
			}

			if (exception.start_nav_service_throttle !== null) {
				this.terminalDepartureExceptionOptions.rowSubDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.START_TIME_NAV_SERVICE',
					name: 'start_nav_service_throttle',
					convert: (value) => this.hoursAndMinutesConversion(value),
				});
			}

			if (exception.end_nav_service_throttle !== null) {
				this.terminalDepartureExceptionOptions.rowSubDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.END_TIME_NAV_SERVICE',
					name: 'end_nav_service_throttle',
					convert: (value) => this.hoursAndMinutesConversion(value),
				});
			}

			if (exception.nav_service_call_frequency !== null) {
				this.terminalDepartureExceptionOptions.rowSubDefs.push({
					displayName: 'T_AGENCY.TERMINAL_DEPARTURE.CALL_FREQUENCY_NAV_SERVICE',
					name: 'nav_service_call_frequency',
					convert: (value) => this.toMinuteAndSeconds(value),
				});
			}

			exception.exceptionOptions = this.terminalDepartureExceptionOptions;
		}
	};

	/**
	 * when settings view inputs are changed (agency, authority), this method initalises terminal departure and route information
	 * for the input agency
	 */
	public async ngOnChanges(): Promise<void> {
		await this.initializeRouteConfigs();
		this.terminalDepartureException();
	}

	/**
	 * presents the edit aganecy setting dialog
	 */
	public editSettings = (): void => {
		this.agenciesAdminModalService.editSettings(this.authorityId, this.agencyId).subscribe((updated: boolean) => {
			if (updated) {
				this.agencyUpdated.emit();
			}
		});
	};

	/**
	 * converts the supplied number of seconds to a formatted version of hours and minutes past midnight - 12:00 PM for example
	 *
	 * @param secs - number of seconds
	 * @returns formatted hours and minutes
	 */
	public hoursAndMinutesConversion = (secs: number): string => {
		const minutesConversion: number = Math.floor(secs / 60) % 60;
		let minutes: string = minutesConversion.toString();

		if (minutes.length < 2) {
			minutes = '0' + minutes;
		}

		const hours: number = Math.floor(secs / 3600);

		if (hours === 12) {
			return '12:' + minutes + ' ' + 'PM';
		} else if (hours === 0) {
			return '12:' + minutes + ' ' + 'AM';
		}

		return hours > 12 ? hours - 12 + ':' + minutes + ' ' + 'PM' : hours + ':' + minutes + ' ' + 'AM';
	};

	/**
	 * sets up the terminal exception route and stop details
	 */
	private initializeRouteConfigs = async (): Promise<void> => {
		this.routeNames.set('', this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ANY_ROUTE'));
		this.stopNames.set('allDep', this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ALL_STATIONS_DEPARTURE'));
		this.stopNames.set('allArr', this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.ALL_STATIONS_ARRIVAL'));

		for (const exception of this.agencyDetail.terminal_departure_exceptions) {
			if (!this.routeNames.has(exception.route)) {
				const result: ResultContent = await this.predictionsDataService.getRouteConfig(this.authorityId, exception.route);

				if (result.success) {
					this.routeConfigs.set(exception.route, result.resultData);
					this.routeNames.set(exception.route, result.resultData.title);
				}
			}

			if (!this.stopNames.has(exception.stop)) {
				if (this.routeConfigs.has(exception.route)) {
					const stop: Stop = this.routeConfigs.get(exception.route).stops.find((stop) => stop.id === exception.stop);

					const name: string = stop ? stop.name : exception.stop;

					this.stopNames.set(exception.stop, name);
				} else {
					const result: ResultContent = await this.stopsDataService.getStop(this.authorityId, this.agencyId, exception.stop);

					if (result.success) {
						this.stopNames.set(exception.stop, result.resultData.stop_name);
					}
				}
			}
		}

		this.settingsLoaded.emit();
	};

	/**
	 * converts the can select by stop code value
	 *
	 * @param value - the can select by stop code value
	 * @returns the formatted can select by stop code value
	 */
	private convertCanSelectByStopCode = (value: string): string => {
		const text: string = value ? 'T_AGENCY.ENABLED' : 'T_AGENCY.DISABLED';

		return this.getTranslation(text);
	};

	/**
	 * converts the side of travel value
	 *
	 * @param value - the side of travel value
	 * @returns the formatted side of travel value
	 */
	private convertSideOfTravel = (value: string): string => {
		const text: string = value.toLowerCase() === 'right' ? 'T_AGENCY.SETTINGS.RIGHT' : 'T_AGENCY.SETTINGS.LEFT';

		return this.getTranslation(text);
	};

	/**
	 * converts the supplied imminent departure details to minutes and seconds
	 *
	 * @param value - imminent departure value
	 * @param defaultValue - default imminent departure value
	 * @returns the formatted hours minutes matching the supplied imminent departure value
	 */
	private setDefaultImminentDeparture = (value: any, defaultValue: any): string => {
		return value !== null ? this.toMinuteAndSeconds(value) : this.toMinuteAndSeconds(defaultValue);
	};

	/**
	 * returns the formatted hours and minutes for the supplied number of logout seconds
	 *
	 * @param value - number of seconds
	 * @returns formatted hours and seconds
	 */
	private logoutSecondsToTime = (value: any): string => {
		return this.toHour(value) + ' ' + this.toMinute(value % 3600);
	};

	/**
	 * converts the supplied number of seconds to a formatted version of minutes and seconds
	 *
	 * @param secs - number of seconds
	 * @returns formatted minutes and seconds
	 */
	private toMinuteAndSeconds = (secs: any): string => {
		let result: string = '';

		const sec: string = this.getTranslation('T_AGENCY.SETTINGS.SECONDS');
		const min: string = this.getTranslation('T_AGENCY.SETTINGS.MINUTES');

		if (secs < 60) {
			result = parseInt(secs) + ' ' + sec;
		}

		if (secs >= 60) {
			const minutes: number = Math.floor(parseInt(secs) / 60);

			result = minutes + ' ' + min;
			const seconds: number = Math.floor(parseInt(secs) % 60);

			if (seconds > 0) {
				result = minutes + ' ' + min + ' ' + seconds + ' ' + sec;
			}
		}

		return result;
	};

	/**
	 * converts the supplied number of seconds to hours equivalent
	 *
	 * @param secs - number of seconds
	 * @returns number of hours within supplied seconds
	 */
	private toHour = (secs: any): string => {
		let result: string = '';

		const hours: number = Math.floor(parseInt(secs) / 3600);

		if (hours > 0) {
			result = hours + ' ' + this.getTranslation(hours > 1 ? 'T_CORE.HOURS' : 'T_CORE.HOUR');
		}

		return result;
	};

	/**
	 * converts the supplied number of seconds to minutes equivalent
	 *
	 * @param secs - number of seconds
	 * @returns number of minutes within supplied seconds
	 */
	private toMinute = (secs: any): string => {
		let result: string = '';

		const minutes: number = Math.floor(parseInt(secs) / 60);

		if (minutes > 0) {
			result = minutes + ' ' + this.getTranslation(minutes > 1 ? 'T_CORE.MINUTES' : 'T_CORE.MINUTE');
		}

		return result;
	};

	/**
	 * converts supplied adherence value to minutes
	 *
	 * @param value - adherence value
	 * @param defaultValue - default adherence value
	 * @returns adherence value in minutes
	 */
	private setDefaultAdherenceValues = (value: any, defaultValue: any): string => {
		return value !== null ? this.toMinute(value) : this.toMinute(defaultValue);
	};

	/**
	 * converts headway adherence to a percentage
	 *
	 * @param field - headway adherence value
	 * @returns headway adherence as a percentage
	 */
	private convertHeadwayAdherence = (field: string): any => {
		return (value: any): string => {
			if (typeof value === 'string') {
				value = parseFloat(value);
			}

			if (!value) {
				value = defaultHeadwayAdherenceSettings[field as keyof HeadwaySettings];
			}

			return Math.round(value * 100) + '%';
		};
	};

	/**
	 * converts supplied ridership threshold to a formatted percentage value
	 *
	 * @param value - ridership value
	 * @param prefix - prefix value
	 * @param suffix - suffix value
	 * @returns formatted ridership value
	 */
	private formatRidershipThreshold = (value: any, prefix: string, suffix: string): string => {
		if (isNaN(parseFloat(value))) {
			return this.getTranslation('T_AGENCY.RIDERSHIP.UNDEFINED');
		}

		return prefix + ' ' + Math.round(value * 100) + '% ' + suffix;
	};

	/**
	 * converts auto reset value to minutes and seconds
	 *
	 * @param value - auto reset value
	 * @returns auto reset value in minutes and seconds
	 */
	private formatAutoResetValue = (value: string): string => {
		if (isNaN(parseInt(value))) {
			return this.getTranslation('T_AGENCY.RIDERSHIP.UNDEFINED');
		}

		return this.toMinuteAndSeconds(value);
	};

	/**
	 * converts meters to formatted form - eg 7 meters
	 *
	 * @param value - unformatted meters value
	 * @returns formatted meters value
	 */
	private formatMeters = (value: string): string => {
		return value + ' ' + this.getTranslation('T_DEPOT.METERS');
	};

	/**
	 * retrieves route name from list of route names matching supplied value
	 *
	 * @param value - value to index route name
	 * @returns route name
	 */
	private getRouteName = (value: string): string => {
		return this.routeNames.get(value);
	};

	/**
	 * retrieves stop name from list of stop names matching supplied value
	 *
	 * @param value - value to index stop name
	 * @returns stop name
	 */
	private getStopName = (value: string): string => {
		return this.stopNames.get(value);
	};

	/**
	 * creates a terminal departure exception title
	 *
	 * @param value - terminal departure exception title value
	 * @returns formatted terminal departure exception title
	 */
	private createTerminalDepartureExceptionTitle = (value: TerminalDepartureRouteStopConfig): string => {
		return (
			this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.EXCEPTION_TITLE') +
			' - ' +
			this.getTranslation('T_AGENCY.ROUTE') +
			' : ' +
			this.getRouteName(value.route) +
			' , ' +
			this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.TERMINAL') +
			' : ' +
			this.getStopName(value.stop)
		);
	};

	/**
	 * creates a terminal departure exception sub title
	 *
	 * @param value - terminal departure exception title value
	 * @returns formatted terminal departure exception sub title
	 */
	private createTerminalDepartureExceptionSubTitle = (value: TerminalDepartureRouteStopConfig): string => {
		if (
			value.default_deadhead_seconds ||
			value.min_dist_nav_service ||
			value.start_nav_service_throttle ||
			value.end_nav_service_throttle ||
			value.nav_service_call_frequency
		) {
			return this.getTranslation('T_AGENCY.TERMINAL_DEPARTURE.NAVIGATION_SERVICE');
		} else {
			return null;
		}
	};
}
