import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { AgenciesModule } from '../../support-features/agencies/agencies.module';
import { BlocksModule } from '../../support-features/blocks/blocks.module';
import { RoutesModule } from '../../support-features/routes/routes.module';
import { StopsModule } from '../../support-features/stops/stops.module';
import { VehiclesModule } from '../../support-features/vehicles/vehicles.module';
import { AgencyPortalUtilsModule } from '../../utils/agency-portal-utils.module';

import { RouteDetailsComponent } from './left-menu/routes/details/route-details.component';
import { MapBannersComponent } from './banners/map-banners.component';
import { MapHomeComponent } from './home/map-home.component';
import { BlockDetailsComponent } from './left-menu/blocks/details/block-details.component';
import { BlocksListComponent } from './left-menu/blocks/list/blocks-list.component';
import { MapNavigationComponent } from './left-menu/navigation/map-navigation.component';
import { MapNavigationMenuComponent } from './left-menu/navigation/menu/map-navigation-menu.component';
import { RoutesListComponent } from './left-menu/routes/list/routes-list.component';
import { StopDetailsComponent } from './left-menu/stops/details/stop-details.component';
import { StopsListComponent } from './left-menu/stops/list/stops-list.component';
import { VehicleDetailsComponent } from './left-menu/vehicles/details/vehicle-details.component';
import { VehiclesListComponent } from './left-menu/vehicles/list/vehicles-list.component';
import { VehicleReassignBlocksComponent } from './left-menu/vehicles/reassign/blocks/vehicle-reassign-blocks.component';
import { VehicleReassignComponent } from './left-menu/vehicles/reassign/vehicle-reassign.component';
import { MapViewsListComponent } from './left-menu/views/list/map-views-list.component';
import { ConfirmMapViewSaveComponent } from './left-menu/views/save/confirm-map-view-save.component';
import { MapOptionsComponent } from './options/map-options.component';
import { MapSettingsComponent } from './options/settings/map-settings.component';
import { MapReplayComponent } from './replay/map-replay.component';
import { ReplayTimeFrameSelectComponent } from './replay/time-frame-select/replay-time-frame-select.component';
import { LadderComponent } from './views/ladder-view/ladder.component';
import { LadderVerticalComponent } from './views/ladder-view/vertical/ladder-vertical.component';
import { MapComponent } from './views/map-view/map.component';

import { MapViewApiService } from './left-menu/views/services/map-view-api.service';
import { MapViewDataService } from './left-menu/views/services/map-view-data.service';
import { MapViewEventsService } from './left-menu/views/services/map-view-events.service';
import { MapViewModalService } from './left-menu/views/services/map-view-modal.service';
import { MapViewService } from './left-menu/views/services/map-view.service';
import { ReplayApiService } from './replay/services/replay-api.service';
import { ReplayDataService } from './replay/services/replay-data.service';
import { MapActiveEntityService } from './services/map-active-entity.service';
import { MapBaseService } from './services/map-base.service';
import { MapDepotService } from './services/map-depot.service';
import { MapEventsService } from './services/map-events.service';
import { MapHistoryService } from './services/map-history.service';
import { MapLocationService } from './services/map-location.service';
import { MapModalService } from './services/map-modal.service';
import { MapNavigationService } from './services/map-navigation.service';
import { MapOptionsService } from './services/map-options.service';
import { MapPollingService } from './services/map-polling.service';
import { MapReplayService } from './services/map-replay.service';
import { MapRoutesService } from './services/map-routes.service';
import { MapStateService } from './services/map-state.service';
import { MapStopsService } from './services/map-stops.service';
import { MapTimeService } from './services/map-time.service';
import { MapUpdateService } from './services/map-update.service';
import { MapUtilsService } from './services/map-utils.service';
import { MapVehicleTrackService } from './services/map-vehicle-track.service';
import { MapVehicleTrailService } from './services/map-vehicle-trail.service';
import { MapVehiclesService } from './services/map-vehicles.service';
import { MapMarkerUtilsService } from './services/markers/map-marker-utils.service';
import { MapRoutesMarkerService } from './services/markers/map-routes-marker.service';
import { MapStopsClusterTooltipMarkerService } from './services/markers/map-stops-cluster-tooltip-marker.service';
import { MapStopsMarkerService } from './services/markers/map-stops-marker.service';
import { MapStopsTooltipMarkerService } from './services/markers/map-stops-tooltip-marker.service';
import { MapVehiclesLabelMarkerService } from './services/markers/map-vehicles-label-marker.service';
import { MapVehiclesMarkerService } from './services/markers/map-vehicles-marker.service';
import { MapVehiclesTooltipMarkerService } from './services/markers/map-vehicles-tooltip-marker.service';
import { MapVehiclesTrailMarkerService } from './services/markers/map-vehicles-trail-marker.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		AgenciesModule,
		RoutesModule,
		BlocksModule,
		VehiclesModule,
		StopsModule,
		AgencyPortalUtilsModule,
	],
	declarations: [
		MapHomeComponent,
		MapBannersComponent,
		MapReplayComponent,
		ReplayTimeFrameSelectComponent,
		MapSettingsComponent,
		MapOptionsComponent,
		ConfirmMapViewSaveComponent,
		BlockDetailsComponent,
		BlocksListComponent,
		MapNavigationComponent,
		MapNavigationMenuComponent,
		RoutesListComponent,
		RouteDetailsComponent,
		StopsListComponent,
		StopDetailsComponent,
		VehiclesListComponent,
		VehicleDetailsComponent,
		VehicleReassignComponent,
		VehicleReassignBlocksComponent,
		MapViewsListComponent,
		LadderVerticalComponent,
		LadderComponent,
		MapComponent,
	],
	providers: [
		MapDepotService,
		MapEventsService,
		MapLocationService,
		MapNavigationService,
		MapOptionsService,
		MapPollingService,
		MapRoutesService,
		MapStateService,
		MapStopsService,
		MapUtilsService,
		MapVehicleTrackService,
		MapVehicleTrailService,
		MapVehiclesService,
		MapHistoryService,
		MapTimeService,
		MapReplayService,
		ReplayDataService,
		ReplayApiService,
		MapModalService,
		MapBaseService,
		MapViewService,
		MapViewApiService,
		MapViewDataService,
		MapViewModalService,
		MapViewEventsService,
		MapUpdateService,
		MapActiveEntityService,
		MapMarkerUtilsService,
		MapRoutesMarkerService,
		MapStopsClusterTooltipMarkerService,
		MapStopsMarkerService,
		MapStopsTooltipMarkerService,
		MapVehiclesLabelMarkerService,
		MapVehiclesMarkerService,
		MapVehiclesTooltipMarkerService,
		MapVehiclesTrailMarkerService,
	],
	exports: [],
})
export class MapModule {}
