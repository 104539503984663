import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { LoggerService } from '../logging/logger.service';
import { Config, CONFIG_TOKEN } from '../../config/types';

/**
 * Service responsible for sending HTTP requests.
 */
@Injectable({
	providedIn: 'root',
})
export class HttpService {
	private readonly baseURL: string = '';

	/**
	 * Initialise the HTTP Service,
	 *
	 * @param httpClient - The underlying HTTP client.
	 * @param config - The Configuration.
	 * @param loggerService - The Logger Service.
	 */
	constructor(
		private httpClient: HttpClient,
		@Inject(CONFIG_TOKEN) private config: Config,
		private loggerService: LoggerService
	) {
		this.baseURL = this.config.getApiUrl();
	}

	/**
	 * Performs the HTTP GET request with the supplied URL.
	 *
	 * @param url - The URL to GET data from.
	 * @param params - The parameters to use during the fetch of the resource data.
	 * @param reponseType - Any specific content type ( Default 'application/json').
	 * @param timeout - The timeout vlaue for a response.
	 * @returns The promised retrieved HTTP response content.
	 */
	public get = async (url: string, params: any = null, reponseType: string = null, timeout: number = null): Promise<any> => {
		try {
			const response: any = await this.httpClient
				.get(this.baseURL + url, this.headerOptions(params, reponseType, timeout) as any)
				.toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP GET call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP POST request with the supplied data and URL.
	 *
	 * @param url - The URL to POST data to.
	 * @param data - The optional POSTed payload.
	 * @param params - The parameters to use during the post of the resource data.
	 * @param timeout - The timeout vlaue for a response.
	 * @returns The promised retrieved HTTP response content.
	 */
	public post = async (url: string, data: any = null, params: any = null, timeout: number = null): Promise<any> => {
		try {
			const response: any = await this.httpClient
				.post(this.baseURL + url, data, this.headerOptions(params, null, timeout) as any)
				.toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP POST call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP PUT request with the supplied data and URL.
	 *
	 * @param url - The URL to PUT data to.
	 * @param data - The optional PUTted payload.
	 * @param timeout - The timeout vlaue for a response.
	 * @returns The promised retrieved HTTP response content.
	 */
	public put = async (url: string, data: any = null, timeout: number = null): Promise<any> => {
		try {
			const response: any = await this.httpClient
				.put(this.baseURL + url, data, this.headerOptions(null, null, timeout) as any)
				.toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP PUT call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Performs the HTTP DELETE request with the supplied data and URL.
	 *
	 * @param url - The URL to DELETE data from.
	 * @param timeout - The timeout vlaue for a response.
	 * @returns The promised retrieved HTTP response content.
	 */
	public delete = async (url: string, timeout: number = null): Promise<any> => {
		try {
			const response: any = await this.httpClient
				.delete(this.baseURL + url, this.headerOptions(null, null, timeout) as any)
				.toPromise();

			return response.body;
		} catch (error) {
			this.loggerService.logFatal('Error returned from HTTP DELETE call - URL: ' + this.baseURL + url, error);
			throw error;
		}
	};

	/**
	 * Create the headers for the HTTP request.
	 *
	 * @param parameters - Optional parameters.
	 * @param respType - Optional specific response type header value, default to JSON.
	 * @param timeout - Optional timeout value.
	 * @returns The Headers Options object.
	 */
	private headerOptions = (parameters: any = null, respType: string = null, timeout: number = null): any => {
		let headers: HttpHeaders = new HttpHeaders();

		if (timeout) {
			headers = headers.set('Accept', 'application/json').set('timeout', timeout.toString());
		} else {
			headers = headers.set('Accept', 'application/json');
		}

		if (parameters) {
			const params: any = {};

			for (const property in parameters) {
				if (typeof parameters[property] === 'object') {
					params[property] = JSON.stringify(parameters[property]);
				} else {
					params[property] = parameters[property];
				}
			}

			return {
				headers,
				params,
				withCredentials: false,
				observe: 'response' as const,
				responseType: respType ? respType : 'json',
			};
		} else {
			return {
				headers,
				withCredentials: false,
				observe: 'response' as const,
				responseType: respType ? respType : 'json',
			};
		}
	};
}
