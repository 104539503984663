/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';

import { MatTabChangeEvent } from '@angular/material/tabs';
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';

import { Subscription } from 'rxjs';

import { CONFIG_TOKEN, TimeFormatType, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TimeHelpers } from '@cubicNx/libs/utils';

import { MapVehicleTrailService } from '../../../services/map-vehicle-trail.service';
import { MapVehicleTrackService } from '../../../services/map-vehicle-track.service';
import { StateService } from '@cubicNx/libs/utils';
import { MapLocationService } from '../../../services/map-location.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapEventsService } from '../../../services/map-events.service';
import { CurrentUserPermissionService } from '../../../../../support-features/login/services/current-user/current-user-permission.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { VehicleEventsDataService } from '../../../../vehicle-events/services/vehicle-events-data.service';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { MapActiveEntityService } from '../../../services/map-active-entity.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { MapOptionsService } from '../../../services/map-options.service';
import { MapReplayService } from '../../../services/map-replay.service';

import { PermissionRequest, PermissionRequestActionType } from '../../../../../support-features/login/types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';
import { RouteExtended } from '../../../../../support-features/routes/types/api-types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { VehicleEvent, VehicleEventDetails } from '../../../../vehicle-events/types/api-types';
import { CategoryFilterOptions } from '../../../../vehicle-events/types/types';
import { AgencyConfig } from '../../../../../config/types/types';

import { MapLocation, MapModeType, MapVehicle, ModeType, VehicleDetailsActiveTab } from '../../../types/types';
import { EntityType } from '../../../../../utils/components/breadcrumbs/types/types';
import { Vehicle, VehicleStop } from '../../../../../support-features/vehicles/types/api-types';

import {
	NextStopList,
	NextStopListItem,
	VehicleEventList,
	VehicleEventListItem,
	VehicleSpeedDisplay,
	VehicleStatusDisplayType,
} from '../../../../../support-features/vehicles/types/types';

import { ColumnType, Columns, IconType, PageRequestInfo, SortDirection, SortRequestInfo } from '@cubicNx/libs/utils';

import moment, { Moment } from 'moment';

@Component({
	selector: 'vehicle-details',
	templateUrl: './vehicle-details.component.html',
	styleUrls: ['./vehicle-details.component.scss'],
	providers: [{ provide: MAT_CHECKBOX_DEFAULT_OPTIONS, useValue: { clickAction: 'noop' } }],
})
export class VehicleDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy, OnChanges {
	@Input() vehicleId: string = null;
	@Input() activeTab: VehicleDetailsActiveTab = VehicleDetailsActiveTab.summary;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('expandedDetailTemplate', { static: true, read: TemplateRef }) template: TemplateRef<any>;

	public readonly emDash: string = '—';

	// Make accessible in html
	public vehicleStatusDisplayType: typeof VehicleStatusDisplayType = VehicleStatusDisplayType;

	public vehicle: Vehicle = null;

	public vehicleLoaded: boolean = false;
	public vehicleEventsLoaded: boolean = false;
	public nextStopsLoaded: boolean = false;

	public vehicleStatus: VehicleStatusDisplayType = VehicleStatusDisplayType.none;
	public vehicleStatusDisplay: string = null;
	public vehicleRouteDisplay: RoutePillData = null;
	public headingDegDisplay: number = null;
	public speedoDegDisplay: VehicleSpeedDisplay = null;
	public vehicleDirectionDisplay: string = null;
	public vehicleLocationDisplay: string = null;
	public vehicleBlockIdDisplay: string = null;
	public lastAVLReceivedDisplay: string = null;
	public lastAVLReceivedAgoDisplay: string = null;
	public lastGPSReceivedDisplay: string = null;
	public lastGPSReceivedAgoDisplay: string = null;
	public showExpandedSection: boolean = false;

	public vehicleReassignButtonTooltip: string = null;
	public hasVehicleReassignPermission: boolean = true;

	public showNextStops: boolean = false;
	public showVehicleEvents: boolean = false;
	public stopsListName: string = 'vehicle-stops-list';
	public eventsListName: string = 'vehicle-events-list';
	public stopListColumns: Columns = [];
	public eventsListColumns: Columns = [];
	public nextStops: NextStopList = [];
	public vehicleEvents: VehicleEventList = [];
	public listLoadingIndicator: boolean = true;
	public stopsListSortings: SortRequestInfo = { sort: 'predictedTimeUntilStop', sortDir: SortDirection.asc };
	public eventsListSortings: SortRequestInfo = { sort: 'createdAt', sortDir: SortDirection.asc };

	private readonly eventsListPageNum: number = 1;
	private readonly eventsListPageSize: number = 100;

	private readonly mphToDegFactor: number = 3.475;
	private readonly kphToDegFactor: number = 2.317;

	private readonly stopCodeColumnName: string = 'stopCode';
	private readonly stopDescriptionColumnName: string = 'stopDescription';
	private readonly stopTimeUntilColumnName: string = 'predictedTimeUntilStop';
	private readonly eventCategoryColumnName: string = 'category';
	private readonly eventTitleColumnName: string = 'type';
	private readonly eventCreatedAtColumnName: string = 'createdAt';

	private mapNavigateRefresh$Subscription: Subscription = null;
	private refreshPoll$Subscription: Subscription = null;

	private initialized: boolean = false;
	private lastVehiclePollSuccess: boolean = false;
	private authorityId: string = null;
	private agencyId: string = null;
	private lastReceivedTimer: any = null;
	private agencyTimezone: string = null;
	private route: RouteExtended = null;
	private nextStopRelativeTimeInterval: any = null;

	private stopsListCacheContainer: any = {};
	private eventsListCacheContainer: any = {};
	private stopsCacheFields: string[] = ['stopsListSortings'];
	private eventsCacheFields: string[] = ['eventsListSortings'];

	constructor(
		public mapVehicleTrailService: MapVehicleTrailService,
		public mapVehicleTrackService: MapVehicleTrackService,
		private stateService: StateService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private mapLocationService: MapLocationService,
		private mapEventsService: MapEventsService,
		private mapHistoryService: MapHistoryService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesService: MapVehiclesService,
		private mapActiveEntityService: MapActiveEntityService,
		private vehicleEventsDataService: VehicleEventsDataService,
		private agenciesDataService: AgenciesDataService,
		private vehiclesDataService: VehiclesDataService,
		private colorUtilityService: ColorUtilityService,
		private routesDataService: RoutesDataService,
		private mapNavigationService: MapNavigationService,
		private mapOptionsService: MapOptionsService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private mapReplayService: MapReplayService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the vehicle detaiils page
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.loadCache();

		this.loadVehicleReassignState();

		this.buildListColumns();

		this.initLastReceivedTimer();

		this.init();
	}

	/**
	 * handle any changes to the component input values. If a new route id is set - we need
	 * to reinitialize for that route
	 *
	 * @param changes - the details of the change of our inputs
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.vehicleId && !changes.vehicleId.firstChange) {
			if (changes.vehicleId.previousValue !== changes.vehicleId.currentValue) {
				this.init();
			}
		}

		if (changes.activeTab && !changes.activeTab.firstChange) {
			if (changes.activeTab.previousValue !== changes.activeTab.currentValue) {
				this.setTab(changes.activeTab.currentValue);
			}
		}
	}

	/* user interactions for html */

	/**
	 * handle the user toggle of the vehicle
	 */
	public toggleRenderVehicle = async (): Promise<void> => {
		// user can click on mat-checkboxes despite being disabled so just ignore click
		if (this.vehicleDisplayed()) {
			this.mapVehiclesService.removeVehicle(this.vehicleId);
		} else {
			this.mapVehiclesService.addVehicle(this.vehicle);
			this.mapVehiclesService.zoomToVehicle(this.vehicle);
			this.mapActiveEntityService.setActiveEntity(this.vehicleId, EntityType.vehicle);
		}
	};

	/**
	 * handle the user toggle of the route
	 */
	public toggleRenderRoute = (): void => {
		if (this.routeDisplayed()) {
			if (this.vehicle.current_state.route?.route_id) {
				this.mapRoutesService.removeRoute(this.vehicle.current_state.route.route_id);
			}
		} else {
			this.addRoute();
		}
	};

	/**
	 * toggle to show the expanded section
	 */
	public toggleExpandedSection = (): void => {
		this.showExpandedSection = !this.showExpandedSection;
	};

	/**
	 * return to the users previous history location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * zoom to the vehicle on the map. If the vehicle is displayed already, zoom to it, otherwise
	 * the route will be toggled on (and the route zoomed to as part of that process)
	 */
	public zoomTo = (): void => {
		if (this.vehicleDisplayed()) {
			this.zoomToVehicle();
		} else {
			// toggle on the vehicle - it will zoom to location once it's added
			this.toggleRenderVehicle();
		}
	};

	/**
	 * determine if zoom should be enabled (enabled if vehicle displayed but disabled when in ladder mode)
	 *
	 * @returns whether the zoom should be enabled
	 */
	public zoomEnabled = (): boolean => {
		return !this.isLadderMode() && this.showVehicleEnabled() && this.vehicleDisplayed();
	};

	/**
	 * determine if the show vehicle should be enabled (if in ladder mode it must have a route and be assigned)
	 * @returns a flag indicating if show vehicle (button) should be enabled
	 */
	public showVehicleEnabled = (): boolean => {
		return !this.isLadderMode() || (this.hasRoute() && !this.isUnassigned());
	};

	/**
	 * determine if the show route should be enabled (if vehicle has a route but not if in ladder mode and unsassigned)
	 * @returns a flag indicating if show vehicle (button) should be enabled
	 */
	public showRouteEnabled = (): boolean => {
		return this.hasRoute() && this.route !== null && !(this.isLadderMode() && this.isUnassigned());
	};

	/**
	 * determine if vehicle tracking should be available (only when vehicle displayed and not in ladder mode)
	 * @returns if vehicle tracking should be enabled
	 */
	public vehicleTrackTrailEnabled = (): boolean => {
		return !this.isLadderMode() && this.vehicleDisplayed();
	};

	/**
	 * handle the track vehcile checkbox click - set this vehicle to be the one tracked
	 */
	public changeTrackVehicle = async (): Promise<void> => {
		if (this.vehicleDisplayed()) {
			this.mapVehicleTrackService.toggleTrackedVehicle(this.vehicle);
		}
	};

	/**
	 * handle the track vehcile checkbox click - set this vehicle to be the one tracked
	 */
	public changeShowingVehicleTrail = async (): Promise<void> => {
		if (this.vehicleDisplayed()) {
			this.mapVehicleTrailService.toggleTrailedVehicle(this.vehicle);
		}
	};

	/**
	 * navigate to the vehicle reassign page
	 */
	public showVehicleReAssign = (): void => {
		this.mapNavigationService.navigateToVehicleReassignDetails(
			this.vehicle.vehicle_id,
			this.vehicle.current_state.route?.route_id,
			this.vehicle.current_state.block_id
		);
	};

	/**
	 * handle the tab changed action from the user
	 * @param tab - the tab event containing the tab index
	 */
	public tabChanged = async (tab: MatTabChangeEvent): Promise<void> => {
		// This approach ensures that the tab index is updated in the parent forcing the ngChanges and ultimately the setTab being called.
		// If we set the tab internally the parent isnt aware of the change and future change detections of the tab wont be picked up.

		let tabIndexType: VehicleDetailsActiveTab = VehicleDetailsActiveTab.summary;

		switch (tab.index) {
			case 0:
				tabIndexType = VehicleDetailsActiveTab.summary;
				break;
			case 1:
				tabIndexType = VehicleDetailsActiveTab.events;
				break;
		}

		await this.mapNavigationService.navigateToVehicleDetails(this.authorityId, this.vehicle.vehicle_id, tabIndexType, true);
	};

	/**
	 * handle the user action of clicking on the block id and navigate to the block details
	 */
	public viewBlockDetails = async (): Promise<void> => {
		if (this.vehicleBlockIdDisplay && this.mapOptionsService.getMapMode() === MapModeType.live) {
			await this.mapNavigationService.navigateToBlockDetails(this.authorityId, this.vehicleBlockIdDisplay);
		}
	};

	/**
	 * handle the user action of clicking on the route and navigate to the route details
	 *
	 * @param routeId - the route to navigate to
	 */
	public navigateToRouteDetails = async (routeId: string): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(this.authorityId, routeId);
	};

	/* end of user interactions for html */

	/* helper methods for html */

	/**
	 * determine if the vehicle has an associated route
	 * @returns the route id or null
	 */
	public hasRoute = (): string => {
		return this.vehicle.current_state.route?.route_id;
	};

	/**
	 * determine if the vehicle is unassigned
	 * @returns true if the vehicle is unassigned
	 */
	public isUnassigned = (): boolean => {
		const vehicleStatusDisplayType: VehicleStatusDisplayType = this.mapVehiclesService.getVehicleCurrentStatusType(
			this.vehicle.current_state.veh_state,
			this.vehicle.current_state.predictability,
			this.vehicle.current_state.route?.route_id
		);

		if (vehicleStatusDisplayType === VehicleStatusDisplayType.unassigned) {
			return true;
		}

		return false;
	};

	/**
	 * determine if the ridership value is valid
	 *
	 * @param passengerCount - the vehicle passenger count
	 * @param ridershipEnum - the current ridership enum
	 * @returns true if the passenger count and ridership value are valid
	 */
	public ridershipIsValid = (passengerCount: number, ridershipEnum: number): boolean => {
		return this.passengerCountValid(passengerCount) && this.ridershipEnumValid(ridershipEnum);
	};

	/**
	 * determine if the passenger count is valid
	 *
	 * @param passengerCount - the vehicle passenger count
	 * @returns true if the passenger count is valid
	 */
	public passengerCountValid = (passengerCount: number): number | boolean => {
		return passengerCount || passengerCount === 0;
	};

	/**
	 * determine if the ridership enum value is valid
	 *
	 * @param ridershipEnum - the current ridership enum
	 * @returns true if the ridership enum value is valid
	 */
	public ridershipEnumValid = (ridershipEnum: number): boolean => {
		return (ridershipEnum || ridershipEnum === 0) && ridershipEnum !== -1;
	};

	/**
	 * determine if the ridership value is valid in replay mode
	 *
	 * @param passengerCount - the vehicle passenger count
	 * @returns true if the passenger count is valid and the map is in replay mode
	 */
	public replayRidershipIsValid = (passengerCount: number): boolean => {
		return (
			this.mapOptionsService.getMapMode() === MapModeType.replay && this.passengerCountValid(passengerCount) && !this.isUnassigned()
		);
	};

	/**
	 * get the ridership percentage rounded to the nearest number
	 * @param ridershipPercentage - the ridership percentage
	 * @returns the rounded ridership percentage
	 */
	public getRidershipPercent = (ridershipPercentage: number): number => {
		return Math.round(ridershipPercentage * 100);
	};

	/**
	 * determine if the map is in ladder mode
	 * @returns true if the map is in ladder mode
	 */
	public isLadderMode = (): boolean => {
		return this.mapOptionsService.getMode() === ModeType.ladder;
	};

	/**
	 * determine if the vehicle is displayed on the map
	 * @returns true if the vehicle is displayed on the map
	 */
	public vehicleDisplayed = (): boolean => {
		const isVehicleRendered: boolean = this.showVehicleEnabled() && this.mapVehiclesService.vehicleDisplayed(this.vehicle.vehicle_id);

		return isVehicleRendered;
	};

	/**
	 * determine if the route is displayed on the map
	 * @returns true if the route is displayed on the map
	 */
	public routeDisplayed = (): boolean => {
		return (
			this.vehicle &&
			this.vehicle.current_state.route &&
			this.mapRoutesService.routeDisplayed(this.vehicle.current_state.route.route_id)
		);
	};

	/* end of helper methods for html */

	/**
	 * handle the sort request triggered from our datatable for the stops list
	 * @param sortInfo - the details about the sort (sort field/direction)
	 */
	public handleSortStopsList = (sortInfo: PageRequestInfo): void => {
		this.stopsListSortings.sort = sortInfo.sort;
		this.stopsListSortings.sortDir = sortInfo.sortDir;

		this.cacheStopsListSortInfo();

		this.nextStops = this.sortStopsList(this.nextStops);
	};

	/**
	 * handle the sort request triggered from our datatable for the events list
	 * @param sortInfo - the details about the sort (sort field/direction)
	 */
	public handleSortEventsList = (sortInfo: PageRequestInfo): void => {
		this.eventsListSortings.sort = sortInfo.sort;
		this.eventsListSortings.sortDir = sortInfo.sortDir;

		this.cacheEventsListPageAndSortInfo();

		this.vehicleEvents = this.sortEventsList(this.vehicleEvents);
	};

	/**
	 * determine if replay mode is active
	 * @returns return true if replay mode is active
	 */
	public replayModeActive = (): boolean => {
		return this.mapOptionsService.getMapMode() === MapModeType.replay;
	};

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		if (this.lastReceivedTimer) {
			clearInterval(this.lastReceivedTimer);
		}

		if (this.nextStopRelativeTimeInterval) {
			clearInterval(this.nextStopRelativeTimeInterval);
		}

		this.mapActiveEntityService.setActiveEntity(null, EntityType.vehicle);

		this.unsubscribe();
	}

	/**
	 * handle any initialization actions.
	 *
	 * load the vehicle and associated stop and event data
	 */
	private init = async (): Promise<void> => {
		this.enableRefresh.emit(false);

		this.resetVehicleDetails();

		// perform in init so we pick up potential new agency from ngOnChanges i.e when a vehicle for a different agency is selected
		this.loadAgencyDetails();

		this.loadVehicleReassignState();

		await this.loadVehicle();

		if (this.vehicle) {
			// show loading during init only - not during poll of data
			this.listLoadingIndicator = true;

			this.determineVehicleDetails();
			this.determineNextStops();

			this.vehicleLoaded = true;

			await this.loadVehicleEvents();

			this.listLoadingIndicator = false;

			if (this.vehicleDisplayed()) {
				this.mapActiveEntityService.setActiveEntity(this.vehicleId, EntityType.vehicle);

				this.zoomToVehicle();
			}

			this.setTab(this.activeTab);

			this.initialized = true;
		} else {
			this.mapNavigationService.navigateToVehicleList(this.authorityId);
		}

		this.enableRefresh.emit(true);
	};

	/**
	 * load translations for the page
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_REASSIGN',
			'T_CORE.NO_PERMISSION',
			'T_MAP.MAP_TIME_UNTIL_STOP',
			'T_MAP.MAP_PREDICTED_ARRIVAL',
			'T_MAP.MAP_VIEW_ZOOM_TO',
			'T_MAP.MAP_CODE',
			'T_MAP.MAP_DESCRIPTION',
			'T_CORE.NO_NEXT_STOPS_INFO',
			'T_MAP.MAP_EVENTS',
			'T_MAP.MAP_EVENT_CATEGORY',
			'T_MAP.MAP_EVENT_TYPE',
			'T_MAP.MAP_EVENT_CREATED_AT',
			'T_MAP.MAP_NO_EVENTS',
			'T_MAP.MAP_AGO',
		]);
	};

	/**
	 * load the cache for the page (sort info for the stops/events lists)
	 */
	private loadCache = (): void => {
		const stopsCacheContainer: any = this.stateService.mapLoadAcrossSessions(
			this.stopsListName,
			this.stopsListCacheContainer,
			this.stopsCacheFields
		);

		if (stopsCacheContainer.stopsSortInfo) {
			this.stopsListSortings = stopsCacheContainer['stopsListSortings'];
		}

		const eventsCacheContainer: any = this.stateService.mapLoadAcrossSessions(
			this.eventsListName,
			this.eventsListCacheContainer,
			this.eventsCacheFields
		);

		if (eventsCacheContainer.eventsSortInfo) {
			this.eventsListSortings = eventsCacheContainer['eventsListSortings'];
		}
	};

	/**
	 * cache for sort details selected by the user (for the stops list)
	 */
	private cacheStopsListSortInfo = (): void => {
		this.stopsListCacheContainer['stopsListSortings'] = this.stopsListSortings;
		this.stateService.mapPersistAcrossSessions(this.stopsListName, this.stopsListCacheContainer, this.stopsCacheFields);
	};

	/**
	 * cache for sort details selected by the user (for the events list)
	 */
	private cacheEventsListPageAndSortInfo = (): void => {
		this.eventsListCacheContainer['eventsListSortings'] = this.eventsListSortings;
		this.stateService.mapPersistAcrossSessions(this.eventsListName, this.eventsListCacheContainer, this.eventsCacheFields);
	};

	/**
	 * load the vehicle reassign state to set permissions and tooltips accordingly
	 */
	private loadVehicleReassignState(): void {
		const permissionRequest: PermissionRequest = {
			authorityId: this.authorityId,
			agencyId: this.agencyId,
		};

		this.hasVehicleReassignPermission = this.currentUserPermissionService.hasPermissionTo(
			PermissionRequestActionType.vehicleReassign,
			permissionRequest
		);

		this.vehicleReassignButtonTooltip = this.hasVehicleReassignPermission
			? this.translations['T_MAP.MAP_REASSIGN']
			: this.translations['T_CORE.NO_PERMISSION'];
	}

	/**
	 * get the arrival time header (dynamic based on whether the map is set to clock or relative time)
	 * @returns the correct column header
	 */
	private getArrivalTimeHeader = (): string => {
		let header: string = this.translations['T_MAP.MAP_TIME_UNTIL_STOP'];

		if (this.mapOptionsService.getTimeFormat() === TimeFormatType.clockTime) {
			header = this.translations['T_MAP.MAP_PREDICTED_ARRIVAL'];
		}

		return header;
	};

	/**
	 * load the current agency details
	 */
	private loadAgencyDetails = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;

		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
	};

	/**
	 * initializes a timer to update the last received info evry second
	 */
	private initLastReceivedTimer = (): void => {
		// start a timer that fires every second to update the gps/avl received time 'ago' value
		this.lastReceivedTimer = setInterval(() => {
			if (this.vehicle) {
				this.lastAVLReceivedDisplay = this.getRecievedTime(this.vehicle.current_state.received_timestamp);
				this.lastAVLReceivedAgoDisplay = this.getRecievedTimeAgo(this.vehicle.current_state.received_timestamp);
				this.lastGPSReceivedDisplay = this.getRecievedTime(this.vehicle.current_state.date);
				this.lastGPSReceivedAgoDisplay = this.getRecievedTimeAgo(this.vehicle.current_state.date);
			}
		}, 1000);
	};

	/**
	 * load the vehicle data
	 */
	private loadVehicle = async (): Promise<void> => {
		const result: ResultContent = await this.vehiclesDataService.getVehicle(this.authorityId, this.vehicleId);

		if (result.success) {
			const vehicle: Vehicle = result.resultData;

			this.vehicle = vehicle;

			// determine route every time - it's possible the assigned route can change (i.e via vehicle
			// reassign/unassign or by schedule means)
			// note: get the route up front so the user doesn't have to wait if they decide to show the route
			await this.determineRoute();
		}

		this.lastVehiclePollSuccess = result.success;
	};

	/**
	 * determine the route for the vehicle
	 */
	private determineRoute = async (): Promise<void> => {
		if (this.vehicle.current_state.route?.route_id) {
			if (this.route === null || this.route.route_id !== this.vehicle.current_state.route?.route_id) {
				const response: ResultContent = await this.routesDataService.getRoute(
					this.authorityId,
					this.agencyId,
					this.vehicle.current_state.route.route_id
				);

				if (response.success) {
					this.route = response.resultData;
				}
			}
		} else {
			this.route = null;
		}
	};

	/**
	 * reset the vehicle details
	 */
	private resetVehicleDetails = (): void => {
		this.vehicle = null;
		this.nextStops = [];
		this.vehicleEvents = [];

		this.showNextStops = false;
		this.showVehicleEvents = false;

		this.initialized = false;
		this.vehicleLoaded = false;
		this.nextStopsLoaded = false;
		this.vehicleEventsLoaded = false;

		this.vehicleStatus = VehicleStatusDisplayType.none;
		this.vehicleStatusDisplay = null;
		this.vehicleRouteDisplay = null;
		this.headingDegDisplay = null;
		this.speedoDegDisplay = null;
		this.vehicleDirectionDisplay = null;
		this.vehicleBlockIdDisplay = null;
		this.lastAVLReceivedDisplay = null;
		this.lastAVLReceivedAgoDisplay = null;
		this.lastGPSReceivedDisplay = null;
		this.lastGPSReceivedAgoDisplay = null;
	};

	/**
	 * zoom to the vehicle location on the map
	 */
	private zoomToVehicle = (): void => {
		// get the vehicle position from the map (rather than the one just loaded in this page which).
		// whilst the page stays in sync with the map using the shared polling, the initial request
		// can mean the position of the vehicle is more up to date than the vehicle which will cacth up
		// on next poll.  It just means the zoom will go to where the vehicle is currently on the map, rather
		// than where it's due to be (shortly on the next poll)
		const mapVehicle: MapVehicle = this.mapVehiclesService.getVehicle(this.vehicle.vehicle_id);

		const mapLocation: MapLocation = {
			lat: mapVehicle.lat,
			lon: mapVehicle.lon,
			zoom: this.config.getMaxZoomLevel(),
			offsetAdjust: true,
			// ensure we don't clear current tracked vehicle on restart
			clearTrackedVehicle: this.mapVehicleTrackService.getTrackedVehicleId() !== this.vehicleId,
		};

		this.mapLocationService.setLocation(mapLocation);
	};

	/**
	 * determine various vehicle display details for the view template
	 */
	private determineVehicleDetails = (): void => {
		// Determine various values for the display
		this.determineDirectionDisplay();
		this.determineLocationDisplay();
		this.determineBlockDisplay();
		this.determineVehicleHeadingDisplay();
		this.determineVehicleSpeedDegDisplay();
		this.determineVehicleStatusDisplay();
		this.determineRouteDisplay();
	};

	/**
	 * determine the vehicle direction display for the view template
	 */
	private determineDirectionDisplay = (): void => {
		this.vehicleDirectionDisplay = this.vehicle.current_state.direction;
	};

	/**
	 * determine the vehicle location display for the view template
	 */
	private determineLocationDisplay = (): void => {
		this.vehicleLocationDisplay =
			this.vehicle.current_state.lat && this.vehicle.current_state.lon
				? this.vehicle.current_state.lat + ',' + this.vehicle.current_state.lon
				: null;
	};

	/**
	 * determine the vehicle block display for the view template
	 */
	private determineBlockDisplay = (): void => {
		this.vehicleBlockIdDisplay = this.vehicle.current_state.block_id ? this.vehicle.current_state.block_id : null;

		if (this.vehicle.current_state.predictability === 'NO_ROUTE') {
			this.vehicleBlockIdDisplay = null;
		}
	};

	/**
	 * determine the vehicle heading display for the view template
	 */
	private determineVehicleHeadingDisplay = (): void => {
		this.headingDegDisplay = 0;

		if (this.vehicle.current_state.heading) {
			this.headingDegDisplay = this.vehicle.current_state.heading;
		}
	};

	/**
	 * determine the vehicle speed display (unit based on agency setting i.e mph/kph) for the view template
	 */
	private determineVehicleSpeedDegDisplay = (): void => {
		let speedUnits: string = 'KM/H';
		let curSpeedKph: number = 0;

		const beginAngle: number = 221;

		const measurementSystem: string = this.agenciesDataService.getAgencyMeasurementSystem(this.authorityId, this.agencyId);

		if (measurementSystem === 'imperial') {
			speedUnits = 'MPH';
		}

		if (this.vehicle.current_state.speed) {
			curSpeedKph = this.vehicle.current_state.speed;
		}

		let deg: number = beginAngle + curSpeedKph * this.kphToDegFactor;

		if (speedUnits === 'MPH') {
			curSpeedKph = Math.ceil(0.621371 * curSpeedKph);
			deg = beginAngle + curSpeedKph * this.mphToDegFactor;
		}

		this.speedoDegDisplay = {
			speed: curSpeedKph.toFixed(0),
			units: speedUnits,
			deg,
		};
	};

	/**
	 * determine the vehicle status display for the view template
	 */
	private determineVehicleStatusDisplay = (): void => {
		this.vehicleStatus = this.mapVehiclesService.getVehicleCurrentStatusType(
			this.vehicle.current_state.veh_state,
			this.vehicle.current_state.predictability,
			this.vehicle.current_state.route?.route_id
		);

		this.vehicleStatusDisplay = this.mapVehiclesService.getVehicleStatusDisplayText(this.vehicleStatus);
	};

	/**
	 * determine the vehicle route display for the view template
	 */
	private determineRouteDisplay = (): void => {
		this.vehicleRouteDisplay = null;

		// Vehicle is not stale and has route in current state
		if (this.vehicle.current_state.route && this.vehicle.current_state.route.route_id) {
			this.vehicleRouteDisplay = {
				routeId: this.vehicle.current_state.route.route_id,
				routeShortName: this.vehicle.current_state.route.route_name,
				routeLongName: this.vehicle.current_state.route.route_name,
				routeColor: this.colorUtilityService.getColor(this.vehicle.current_state.route.route_color),
				routeTextColor: this.colorUtilityService.getColor(this.vehicle.current_state.route.route_text_color),
			};
		}
	};

	/**
	 * get (and format) the last recieved time for the display
	 * @param receivedTime - the recieved time
	 * @returns the formatted recieved time in HH:mm:ss
	 */
	private getRecievedTime = (receivedTime: number): string => {
		if (receivedTime) {
			const date: Moment = moment.unix(receivedTime / 1000);

			return date.tz(this.agencyTimezone).format('HH:mm:ss');
		}

		return null;
	};

	/**
	 * get (and format) the last recieved time (ago) for the display i.e display as '2m 28s' or '2m 28s ago'
	 * @param receivedTime - the recieved time
	 * @returns the formatted recieved time in HH:mm:ss
	 */
	private getRecievedTimeAgo = (receivedTime: number): string => {
		if (receivedTime) {
			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				const replayTime: number = this.mapReplayService.getCurrentReplayTime();
				const seconds: number = TimeHelpers.getSecondsFromReplayTime(receivedTime, replayTime);

				return TimeHelpers.getRelativeTimeFormatting(seconds, this.translations['T_MAP.MAP_AGO']);
			} else {
				const seconds: number = TimeHelpers.getSeconds(receivedTime);

				return TimeHelpers.getRelativeTimeFormatting(seconds, this.translations['T_MAP.MAP_AGO']);
			}
		}

		return null;
	};

	/* start of load next stops and related calls */

	/**
	 * sort the stops list sorted by stop code (client side sorting)
	 *
	 * @param nextStops - the stops list to sort
	 * @returns the stops list sorted by stop code
	 */
	private sortByStopCode = (nextStops: NextStopList): NextStopList => {
		return nextStops.sort((aItem: NextStopListItem, bItem: NextStopListItem) => aItem.stopCode.localeCompare(bItem.stopCode));
	};

	/**
	 * sort the stops list sorted by description (client side sorting)
	 *
	 * @param nextStops - the stops list to sort
	 * @returns the stops list sorted by description
	 */
	private sortByStopDescription = (nextStops: NextStopList): NextStopList => {
		return nextStops.sort((aItem: NextStopListItem, bItem: NextStopListItem) =>
			aItem.stopDescription.localeCompare(bItem.stopDescription)
		);
	};

	/**
	 * sort the stops list sorted by until time (client side sorting)
	 *
	 * @param nextStops - the stops list to sort
	 * @returns the stops list sorted by until time
	 */
	private sortByStopUntilTime = (nextStops: NextStopList): NextStopList => {
		return nextStops.sort((aItem: NextStopListItem, bItem: NextStopListItem) => aItem.rawPredictedArrival - bItem.rawPredictedArrival);
	};

	/**
	 * main entry point for the stops list sort
	 *
	 * @param nextStops - the stops list to sort
	 * @returns the sorted stops list
	 */
	private sortStopsList = (nextStops: NextStopList): NextStopList => {
		switch (this.stopsListSortings.sort) {
			case this.stopCodeColumnName: {
				nextStops = this.sortByStopCode(nextStops);
				break;
			}
			case this.stopDescriptionColumnName: {
				nextStops = this.sortByStopDescription(nextStops);
				break;
			}
			case this.stopTimeUntilColumnName: {
				nextStops = this.sortByStopUntilTime(nextStops);
				break;
			}
		}

		if (this.stopsListSortings.sortDir === SortDirection.desc) {
			nextStops = nextStops.reverse();
		}

		return nextStops;
	};

	/**
	 * sort the events list sorted by event category (client side sorting)
	 *
	 * @param vehicleEvents - the events list to sort
	 * @returns the events list sorted by category
	 */
	private sortByEventCategory = (vehicleEvents: VehicleEventList): VehicleEventList => {
		return vehicleEvents.sort((aItem: VehicleEventListItem, bItem: VehicleEventListItem) =>
			aItem.categoryRaw.localeCompare(bItem.categoryRaw)
		);
	};

	/**
	 * sort the events list sorted by event title (client side sorting)
	 *
	 * @param vehicleEvents - the events list to sort
	 * @returns the events list sorted by title
	 */
	private sortByEventTitle = (vehicleEvents: VehicleEventList): VehicleEventList => {
		return vehicleEvents.sort((aItem: VehicleEventListItem, bItem: VehicleEventListItem) => aItem.type.localeCompare(bItem.type));
	};

	/**
	 * sort the events list sorted by created-at (client side sorting)
	 *
	 * @param vehicleEvents - the events list to sort
	 * @returns the events list sorted by created-at
	 */
	private sortByEventCreatedAt = (vehicleEvents: VehicleEventList): VehicleEventList => {
		return vehicleEvents.sort((aItem: VehicleEventListItem, bItem: VehicleEventListItem) =>
			aItem.createdAtRaw.isAfter(bItem.createdAtRaw) ? 1 : bItem.createdAtRaw.isAfter(aItem.createdAtRaw) ? -1 : 0
		);
	};

	/**
	 * main entry point for the events list sort
	 *
	 * @param vehicleEvents - the events list to sort
	 * @returns the sorted events list
	 */
	private sortEventsList = (vehicleEvents: VehicleEventList): VehicleEventList => {
		switch (this.eventsListSortings.sort) {
			case this.eventCategoryColumnName: {
				vehicleEvents = this.sortByEventCategory(vehicleEvents);
				break;
			}
			case this.eventTitleColumnName: {
				vehicleEvents = this.sortByEventTitle(vehicleEvents);
				break;
			}
			case this.eventCreatedAtColumnName: {
				vehicleEvents = this.sortByEventCreatedAt(vehicleEvents);
				break;
			}
		}

		if (this.eventsListSortings.sortDir === SortDirection.desc) {
			vehicleEvents = vehicleEvents.reverse();
		}

		return vehicleEvents;
	};

	/**
	 * determine and the next stops list by extracting the data from the vehicle object
	 *
	 * set an inteval to update the predicted time until stop (when in relative time mode)
	 */
	private determineNextStops = (): void => {
		const slashRegex: RegExp = new RegExp('/(\\w)\\/(\\w)/', 'g');

		if (this.vehicle.current_state.next_stops) {
			// wait until after the awaited call - we don't want the view to show 'no data' as the table updates
			const nextStops: NextStopList = [];

			this.vehicle.current_state.next_stops.forEach((stop: VehicleStop) => {
				const nextStop: NextStopListItem = {
					stopCode: stop.stop_code,
					stopDescription: stop.stop_name ? stop.stop_name.replace(slashRegex, '$1 / $2') : this.emDash,
					rawPredictedArrival: stop.predictedArrival,
					predictedTimeUntilStop: this.getTimeUntilStop(stop.predictedArrival),
				};

				nextStops.push(nextStop);
			});

			this.nextStops = this.sortStopsList(nextStops);

			this.nextStopsLoaded = true;
		}

		this.nextStopRelativeTimeInterval = setInterval(() => {
			if (this.mapOptionsService.getTimeFormat() === TimeFormatType.relativeTime) {
				this.nextStops.forEach((nextStop) => {
					nextStop.predictedTimeUntilStop = this.getTimeUntilStop(nextStop.rawPredictedArrival);
				});

				this.nextStops = [...this.nextStops];
			}
		}, 1000);
	};

	/**
	 * calculate the predicted arrival time based on the chosen time format (clock/relative time)
	 *
	 * @param predictedArrival - the arrival time
	 * @returns the formmatted arrival time
	 */
	private getTimeUntilStop = (predictedArrival: number): string => {
		let timeUntilStop: string = null;

		if (predictedArrival) {
			const agencyPredictionTime: Moment = moment(predictedArrival).tz(this.agencyTimezone);

			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				const replayTime: number = this.mapReplayService.getCurrentReplayTime();

				timeUntilStop = TimeHelpers.getTimeByUserSelectedFormat(
					agencyPredictionTime,
					this.mapOptionsService.getTimeFormat(),
					this.agencyTimezone,
					this.translations['T_MAP.MAP_AGO'],
					replayTime
				);
			} else {
				timeUntilStop = TimeHelpers.getTimeByUserSelectedFormat(
					agencyPredictionTime,
					this.mapOptionsService.getTimeFormat(),
					this.agencyTimezone,
					this.translations['T_MAP.MAP_AGO']
				);
			}
		}

		return timeUntilStop;
	};

	/* end of load next stops and related calls*/

	/* start of load vehicle event related calls*/

	/**
	 * load the vehicle events for this vehicle and format the data for the list
	 */
	private loadVehicleEvents = async (): Promise<void> => {
		const categoryFilterOptions: CategoryFilterOptions = {
			alarm: true,
			alert: true,
			event: true,
		};

		const response: ResultContent = await this.vehicleEventsDataService.getVehicleEvents(
			this.authorityId,
			null,
			[this.vehicleId],
			this.eventsListPageNum,
			this.eventsListPageSize,
			null,
			null,
			categoryFilterOptions,
			null,
			null,
			null
		);

		// wait until after the awaited call - we don't want the view to show 'no data' as the table updates
		const vehicleEvents: VehicleEventList = [];

		if (response.success) {
			const vehicleEventDetails: VehicleEventDetails = response.resultData;

			vehicleEventDetails.rows.forEach((vehicleEvent: VehicleEvent) => {
				const vehicleEventListItem: VehicleEventListItem = {
					category: this.getCategory(vehicleEvent.category),
					categoryRaw: vehicleEvent.category,
					type: vehicleEvent.type,
					createdAt: this.formatVehicleEventDate(vehicleEvent.created_at),
					createdAtRaw: moment(vehicleEvent.created_at).tz(this.agencyTimezone),
				};

				vehicleEvents.push(vehicleEventListItem);
			});
		}

		this.vehicleEvents = this.sortEventsList(vehicleEvents);

		this.vehicleEventsLoaded = true;
	};

	/**
	 * get the appropriate style class for the category
	 * @param category - the event category
	 * @returns the appropriate style class for the category
	 */
	private getCategory = (category: string): IconType => {
		let iconClass: string = 'nb-icons ';

		const iconType: string = '';

		switch (category.toUpperCase()) {
			case 'ALARM':
				iconClass += 'nb-danger-solid';
				break;
			case 'ALERT':
				iconClass += 'nb-warning-solid';
				break;
			case 'EVENT':
				iconClass += 'nb-information-solid';
				break;
			default:
				iconClass += 'nb-danger-solid';
				break;
		}

		return {
			iconName: category,
			iconClass,
			iconType,
		};
	};

	/**
	 * format the vehicle event datetime for the event list created at columb
	 * @param date - the event date
	 * @returns the formatted event date in 'MMM D, Y [at] hh:mm A' format
	 */
	private formatVehicleEventDate = (date: string): string => {
		let formattedDate: string = '';

		if (date) {
			const dateValue: Moment = moment(date).tz(this.agencyTimezone);

			if (dateValue) {
				formattedDate = dateValue.format('MMM D, Y [at] hh:mm A');
			}
		}

		return formattedDate;
	};

	/* end of load vehicle event related calls*/

	/**
	 * add the route to the map
	 */
	private addRoute = async (): Promise<void> => {
		if (this.vehicle.current_state.route?.route_id) {
			this.mapRoutesService.addRoute(this.route);

			// add this vehicle as the active entity Id (we are relying on the route to actually load the vehicle)
			this.mapActiveEntityService.setActiveEntity(this.vehicleId, EntityType.vehicle);
		}
	};

	/**
	 * build the vehicles list columns for our the stops data table and the events data table
	 */
	private buildListColumns = (): void => {
		this.stopListColumns = [
			{
				name: this.stopCodeColumnName,
				displayName: this.translations['T_MAP.MAP_CODE'],
				columnType: ColumnType.text,
				width: 110,
			},
			{
				name: this.stopDescriptionColumnName,
				displayName: this.translations['T_MAP.MAP_DESCRIPTION'],
				columnType: ColumnType.text,
				width: 230,
			},
			{
				name: this.stopTimeUntilColumnName,
				displayName: '',
				displayNameDynamic: this.getArrivalTimeHeader,
				columnType: ColumnType.text,
				width: 150,
			},
		];

		this.eventsListColumns = [
			{
				name: this.eventCategoryColumnName,
				displayName: this.translations['T_MAP.MAP_EVENT_CATEGORY'],
				columnType: ColumnType.icon,
				width: 120,
			},
			{
				name: this.eventTitleColumnName,
				displayName: this.translations['T_MAP.MAP_EVENT_TYPE'],
				columnType: ColumnType.text,
				width: 165,
			},
			{
				name: this.eventCreatedAtColumnName,
				displayName: this.translations['T_MAP.MAP_EVENT_CREATED_AT'],
				columnType: ColumnType.text,
				width: 165,
			},
		];
	};

	/**
	 * set the tab control based on the passed in tab index
	 * @param tabIndex - the tab index
	 */
	private setTab = (tabIndex: VehicleDetailsActiveTab): void => {
		this.activeTab = tabIndex;

		switch (this.activeTab) {
			case VehicleDetailsActiveTab.summary: {
				this.showVehicleEvents = false;
				this.showNextStops = true;
				break;
			}
			case VehicleDetailsActiveTab.events: {
				this.showNextStops = false;
				this.showVehicleEvents = true;
				break;
			}
			default: {
				this.showVehicleEvents = false;
				this.showNextStops = true;
				break;
			}
		}
	};

	/**
	 * handle the refresh of the page - triggered from our poll subscription
	 */
	private handleRefreshVehicleDetails = async (): Promise<void> => {
		// make sure we are initialized so we don't attempt a refresh at the same time
		if (this.initialized) {
			// essentially stop updates if the vehicle isn't returned the last time.
			// this handles an edge case where the vehicle isn't returned and we keep polling with the error
			if (this.lastVehiclePollSuccess) {
				// it is essentially telling the parent navigation component to disable the refresh while the list is loading.
				this.enableRefresh.emit(false);

				await this.loadVehicle();

				this.determineVehicleDetails();
				this.determineNextStops();
				await this.loadVehicleEvents();

				this.enableRefresh.emit(true);
			}
		}
	};

	/**
	 * handle any subsbriptions.
	 *
	 * refresh - the click of the refresh icon contained within the parent
	 *
	 * poll - the regular poll update triggered by the polling service
	 */
	private setSubscriptions = (): void => {
		this.mapNavigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.init();
		});

		this.refreshPoll$Subscription = this.mapEventsService.pollRefresh.subscribe(async () => {
			await this.handleRefreshVehicleDetails();
		});
	};

	/**
	 * unsubscribe from our subscriptions for clean up
	 */
	private unsubscribe = (): void => {
		this.mapNavigateRefresh$Subscription?.unsubscribe();
		this.refreshPoll$Subscription?.unsubscribe();
	};
}
