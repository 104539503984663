import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MarkdownModule } from 'ngx-markdown';

import { BlocksModule } from '../../support-features/blocks/blocks.module';
import { DepotsModule } from '../../support-features/depots/depots.module';
import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';

import { HeadwayPerformanceSummaryComponent } from './charts/headway-performance-summary/headway-performance-summary.component';
import { OccupancyChartComponent } from './charts/occupancy-chart/occupancy-chart.component';
import { PerformanceLineChartComponent } from './charts/performance-line-chart/performance-line-chart.component';
import { DashboardAddWidgetModalComponent } from './dashboard-add-widget-modal/dashboard-add-widget-modal.component';
import { DashboardFormatModalComponent } from './dashboard-format-modal/dashboard-format-modal.component';
import { DashboardMainComponent } from './dashboard-main/dashboard-main.component';
import { DashboardTitleComponent } from './dashboard-title/dashboard-title.component';
import { DashboardToolbarComponent } from './dashboard-toolbar/dashboard-toolbar.component';
import { DashboardWidgetsViewComponent } from './dashboard-widgets-view/dashboard-widgets-view.component';
import { StructureSelectComponent } from './structure-select/structure-select.component';
import { StructureComponent } from './structure/structure.component';
import { WidgetContainerComponent } from './widget-container/widget-container.component';
import { WidgetEditModalComponent } from './widget-edit-modal/widget-edit-modal.component';
import { WidgetHeaderComponent } from './widget-header/widget-header.component';
import { BlockRowComponent } from './widgets/current-blocks/block-row/block-row.component';
import { BlockTooltipComponent } from './widgets/current-blocks/block-tooltip/block-tooltip.component';
import { CurrentBlocksEditComponent } from './widgets/current-blocks/current-blocks-edit/current-blocks-edit.component';
import { CurrentBlocksListComponent } from './widgets/current-blocks/current-blocks-list/current-blocks-list.component';
import { CurrentSectionComponent } from './widgets/current-blocks/current-section/current-section.component';
import { ExternalLinksEditComponent } from './widgets/external-links/external-links-edit/external-links-edit.component';
import { ExternalLinksViewComponent } from './widgets/external-links/external-links-view/external-links-view.component';
import { HeadwayPerformanceEditComponent } from './widgets/headway-performance/headway-performance-edit/headway-performance-edit.component';
import { HeadwayPerformanceComponent } from './widgets/headway-performance/headway-performance/headway-performance.component';
import { OccupancyEditComponent } from './widgets/occupancy/occupancy-edit/occupancy-edit.component';
import { OccupancyListComponent } from './widgets/occupancy/occupancy-list/occupancy-list.component';
import { OccupancyTooltipComponent } from './widgets/occupancy/occupancy-tooltip/occupancy-tooltip.component';
import { OccupancyComponent } from './widgets/occupancy/occupancy/occupancy.component';
import { PerformanceEditComponent } from './widgets/performance/performance-edit/performance-edit.component';
import { PerformanceComponent } from './widgets/performance/performance/performance.component';
import { SchedulePerformanceComponent } from './widgets/schedule-performance/schedule-performance/schedule-performance.component';
import { TrafficEditComponent } from './widgets/traffic/traffic-edit/traffic-edit.component';
import { TrafficComponent } from './widgets/traffic/traffic/traffic.component';
import { VehicleEventDetailsWidgetComponent } from './widgets/vehicle-events/details/vehicle-event-details-widget.component';
import { VehicleEventSettingsWidgetComponent } from './widgets/vehicle-events/settings/vehicle-event-settings-widget.component';
import { WeatherEditComponent } from './widgets/weather/weather-edit/weather-edit.component';
import { WeatherComponent } from './widgets/weather/weather/weather.component';

import { SchedulePerformanceDonutChartComponent } from './charts/schedule-performance-donut-chart/schedule-performance-donut-chart.component';

import { DeadheadingVehiclesEditComponent } from './widgets/deadheading-vehicles/deadheading-vehicles-edit/deadheading-vehicles-edit.component';

import { DeadheadingVehiclesViewComponent } from './widgets/deadheading-vehicles/deadheading-vehicles-view/deadheading-vehicles-view.component';

import { SchedulePerformanceEditComponent } from './widgets/schedule-performance/schedule-performance-edit/schedule-performance-edit.component';

import { RoutesModule } from '../../support-features/routes/routes.module';
import { VehicleEventsModule } from '../vehicle-events/vehicle-events.module';
import { VehiclesModule } from '../../support-features/vehicles/vehicles.module';
import { RoutingModule } from '../../routing/routing.module';

import { WidgetDirective } from './widget-container/widget.directive';
import { WidgetEditModalDirective } from './widget-edit-modal/widget-edit-modal.directive';

import { ComponentInjectionService } from './services/component-injection.service';
import { DashboardConfigService } from './services/dashboard-config.service';
import { WidgetEventsService } from './services/widget-events.service';
import { TrafficService } from './widgets/traffic/services/traffic.service';
import { WeatherApiService } from './widgets/weather/services/weather-api.service';
import { WeatherDataService } from './widgets/weather/services/weather-data.service';
import { AdherenceColorService } from '../reports/interactive-viewer/report-viewer-list/list-reports/adherence/ahderence-color.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [
		WidgetDirective,
		WidgetEditModalDirective,
		BlockRowComponent,
		BlockTooltipComponent,
		CurrentBlocksEditComponent,
		CurrentBlocksListComponent,
		CurrentSectionComponent,
		DeadheadingVehiclesEditComponent,
		DeadheadingVehiclesViewComponent,
		ExternalLinksEditComponent,
		ExternalLinksViewComponent,
		HeadwayPerformanceComponent,
		HeadwayPerformanceEditComponent,
		OccupancyComponent,
		OccupancyEditComponent,
		OccupancyListComponent,
		OccupancyTooltipComponent,
		PerformanceComponent,
		PerformanceEditComponent,
		SchedulePerformanceComponent,
		SchedulePerformanceEditComponent,
		TrafficComponent,
		TrafficEditComponent,
		VehicleEventDetailsWidgetComponent,
		VehicleEventSettingsWidgetComponent,
		WeatherComponent,
		WeatherEditComponent,
		HeadwayPerformanceSummaryComponent,
		OccupancyChartComponent,
		PerformanceLineChartComponent,
		SchedulePerformanceDonutChartComponent,
		DashboardAddWidgetModalComponent,
		DashboardFormatModalComponent,
		DashboardMainComponent,
		DashboardToolbarComponent,
		DashboardWidgetsViewComponent,
		StructureComponent,
		StructureSelectComponent,
		WidgetContainerComponent,
		WidgetHeaderComponent,
		DashboardTitleComponent,
		WidgetEditModalComponent,
	],
	exports: [],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		DragDropModule,
		NotificationModule,
		NgxDatatableModule,
		MarkdownModule.forRoot(),
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		VehicleEventsModule,
		DepotsModule,
		RoutesModule,
		BlocksModule,
		VehiclesModule,
		RoutingModule,
	],
	providers: [
		ComponentInjectionService,
		DashboardConfigService,
		WidgetEventsService,
		TrafficService,
		WeatherApiService,
		WeatherDataService,
		AdherenceColorService,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class DashboardModule {}
