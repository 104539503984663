/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';
import { MapLocationService } from './map-location.service';

import { Stop, Stops } from '../../../support-features/stops/types/api-types';
import { MapLocation, MapStop, MapStops } from '../types/types';
import { CONFIG_TOKEN } from '@cubicNx/libs/utils';
import { AgencyConfig } from '../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapStopsService {
	constructor(
		private mapStateService: MapStateService,
		private mapLocationService: MapLocationService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig
	) {}

	/**
	 * add a stop to the map state
	 *
	 * check if the stop exists. This scenario should only be possible when trying to add
	 * a stop that has been added as part of a route where the active filters have determined that
	 * it should be hidden (i.e show hidden stops is turned off)
	 *
	 * @param stop - the stop to add
	 */
	public addStop = (stop: Stop): void => {
		if (this.stopExists(stop.stop_code)) {
			const existingStop: MapStop = this.getStop(stop.stop_code);

			// set the hidden state to false so the stop shows again - we always want to show a stop
			// that the user is manually adding
			existingStop.hidden = false;

			this.mapStateService.reloadStops();
		} else {
			// stop not part of our stops list - just add it
			const mapStop: MapStop = this.getMapStopFromStop(stop);

			this.mapStateService.addStop(mapStop);
		}
	};

	/**
	 * add a stops to the map state
	 *
	 * @param stops - the stops to add
	 */
	public addStops = (stops: MapStops): void => {
		this.mapStateService.addStops(stops);
	};

	/**
	 * remove a stop from the map state
	 *
	 * @param stopCode - the stop (codes) to remove
	 */
	public removeStop = (stopCode: string): void => {
		this.mapStateService.removeStop(stopCode);
	};

	/**
	 * remove stops that belong to a particular route from the map state
	 *
	 * @param stopCodes - the stop (codes) to remove
	 */
	public removeRouteStops = (stopCodes: Array<string>): void => {
		this.mapStateService.removeStops(stopCodes);
	};

	/**
	 * get a stop from the map state using the stop code
	 *
	 * @param stopCode - the stop code;
	 * @returns the stop
	 */
	public getStop = (stopCode: string): MapStop => {
		return this.mapStateService.getStop(stopCode);
	};

	/**
	 * get a stop from the map state using the stop id
	 *
	 * @param stopId - the stop id;
	 * @returns the stop
	 */
	public getStopCodeFromId = (stopId: string): string => {
		// Stop code is used throughout to key our stops so we require a lookup method using a stop id
		// for instance s where a stop code isnt available
		const stops: MapStops = this.getStops();

		return Object.keys(stops).find((stopCodeKey: string) => stops[stopCodeKey].stopId === stopId);
	};

	/**
	 * get stops from the map state
	 *
	 * @returns the stops
	 */
	public getStops = (): MapStops => {
		return this.mapStateService.getStops();
	};

	/**
	 * determine if a stop exists on the map
	 *
	 * @param stopCode - the stop code to check
	 * @returns true if the stop exists on the map
	 */
	public stopExists = (stopCode: string): boolean => {
		return this.getStop(stopCode) !== null;
	};

	/**
	 * determine if the stop is displayed on the map (differs from stopExists) as this version
	 * will also rule out hidden stops
	 *
	 * @param stopCode - the stop code to check
	 * @returns true if the stop exists on the map and is not hidden
	 */
	public stopDisplayed = (stopCode: string): boolean => {
		const stop: MapStop = this.getStop(stopCode);

		return stop !== null && stop.hidden === false;
	};

	/**
	 * clear all stops
	 */
	public clearStops = (): void => {
		this.mapStateService.clearStops();
	};

	/**
	 * zoop to a stop
	 *
	 * @param stop - the stop to zoom to
	 */
	public zoomToStop = (stop: Stop): void => {
		const location: MapLocation = {
			lat: stop.stop_lat,
			lon: stop.stop_lon,
			zoom: this.config.getMaxZoomLevel(),
			offsetAdjust: true,
			clearTrackedVehicle: true,
		};

		this.mapLocationService.setLocation(location);
	};

	/**
	 * get stops that are part of a route in map stop format
	 *
	 * @param routeStops - the route stops to retrieve
	 * @returns the route stops
	 */
	public getRouteStops = (routeStops: Stops): MapStops => {
		const stops: MapStops = {};

		routeStops.forEach((stop: Stop) => {
			const mapStop: MapStop = this.getMapStopFromStop(stop);

			stops[stop.stop_code] = mapStop;
		});

		if (Object.keys(stops).length > 0) {
			this.determineRouteStopsHiddenStatus(stops, this.getShowHiddenStops());
		}

		return stops;
	};

	/**
	 * set the map state show hidden stops on/off
	 *
	 * @param showHiddenStops - true when the map state is set to show hidden stops
	 */
	public setShowHiddenStops = (showHiddenStops: boolean): void => {
		// re-evaluate hidden status for all stops on a route when we change the setting
		this.determineRouteStopsHiddenStatus(this.getStopsOnRoute(), showHiddenStops);

		this.mapStateService.setShowHiddenStops(showHiddenStops);
	};

	/**
	 * get the map state show hidden stops on/off
	 *
	 * @returns - true when the map state is set to show hidden stops
	 */
	public getShowHiddenStops = (): boolean => {
		return this.mapStateService.getShowHiddenStops();
	};

	/**
	 * init saved view stops
	 */
	public initViewStops = (): void => {
		this.mapStateService.initViewStops();
	};

	/**
	 * determime the hidden status of a stop based on its hiddenRaw value (value set from nextbus API)
	 * and whether we have set to show thise stops
	 *
	 * @param stops - the stops to determine the hidden status
	 * @param showHiddenStops - whether the setting is on/off to show hiddenb stops
	 */
	private determineRouteStopsHiddenStatus = (stops: MapStops, showHiddenStops: boolean): void => {
		for (const stopCode in stops) {
			const stop: MapStop = stops[stopCode];

			// check it exists - just because it's part of a route doesn't mean we haven't removed it (manually)
			if (stop) {
				stop.hidden = stop.hiddenRaw && !showHiddenStops;
			}
		}
	};

	/**
	 * get all stops that were added due to being on a route
	 *
	 * @returns all stops that were added due to being on a route
	 */
	private getStopsOnRoute = (): MapStops => {
		return this.mapStateService.getStopsOnRoute();
	};

	/**
	 * convert a stop returned from the nextbus API in to the format and properties needed by our map state
	 *
	 * @param stop - the origianal full stop object
	 * @returns - the map stop with everything the map needs to render this stop
	 */
	private getMapStopFromStop = (stop: Stop): MapStop => {
		return {
			stopId: stop.stop_id,
			stopCode: stop.stop_code,
			stopName: stop.stop_name,
			stopLat: stop.stop_lat,
			stopLon: stop.stop_lon,
			hiddenRaw: stop.hidden,
			hidden: false,
		};
	};
}
