/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { AsyncTimeout, CONFIG_TOKEN } from '@cubicNx/libs/utils';

import { MapStateService } from './map-state.service';
import { MapEventsService } from './map-events.service';
import { ReplayDataService } from '../replay/services/replay-data.service';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ReplayInitComplete } from '../replay/types/types';
import { InitReplayResponse, InitStatusResponse, ReplayStatus } from '../replay/types/api-types';

import { AgencyConfig } from '../../../config/types/types';
import { ResultContent } from '@cubicNx/libs/utils';

import { Moment } from 'moment';
import moment from 'moment';

@Injectable({
	providedIn: 'root',
})
export class MapReplayService {
	private authorityId: string = null;
	private replayId: string = null;
	private replayInitExpiryTime: Moment = null;

	private replayInitTimeoutPeriod: number = 300;
	private replayInitPollInterval: number = 1000;
	private replayInitPollTimer: any = null;

	private replayPollInterval: number = 10000;

	private selectedDay: Moment = null;
	private sliderTimeRange: number[] = [];

	constructor(
		private notificationService: NotificationService,
		private translationService: TranslationService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private mapStateService: MapStateService,
		private mapEventsService: MapEventsService,
		private replayDataService: ReplayDataService
	) {
		this.replayInitPollInterval = this.config.getMapReplayInitPollPeriod();
		this.replayInitTimeoutPeriod = this.config.getMapReplayInitTimeoutPeriod();
	}

	/**
	 * initialize the map replay. Send a request to initialize the replay then poll for the initialization completing
	 *
	 * @param authorityId - the current authority id
	 * @param startTime - the replay start time
	 * @param endTime - the replay end time
	 * @param includePredictions - wether or not to include predictions in the replay data
	 */
	public init = async (authorityId: string, startTime: Moment, endTime: Moment, includePredictions: boolean): Promise<void> => {
		this.authorityId = authorityId;

		const initResultContent: ResultContent = await this.replayDataService.init(authorityId, startTime, endTime, includePredictions);

		if (initResultContent.success) {
			const initReplayResultData: InitReplayResponse = initResultContent.resultData;

			if (initReplayResultData.success) {
				this.replayId = initReplayResultData.replayId;
				this.setCurrentReplayId(this.replayId);

				// Get a moment in time for now so that we can time out the initialization process if needs be
				this.replayInitExpiryTime = moment().add(this.replayInitTimeoutPeriod, 'seconds');

				// If the map replay component has been destroyed (the clear emthod is called to destroy this components timer).
				// But as this is a service, it needs to recreate the timer if the map replay component  is ever recreated and an
				// init attempt made.
				if (!this.replayInitPollTimer) {
					this.replayInitPollTimer = new AsyncTimeout();
				}

				this.startReplayInitPollTimer(this.replayInitPollInterval * 1000);
			} else {
				this.handleInitFailure();
			}
		} else {
			this.handleInitFailure();
		}
	};

	/**
	 * clear the replay init poll timer
	 */
	public clear = (): void => {
		if (this.replayInitPollTimer) {
			this.replayInitPollTimer.cancel();
			this.replayInitPollTimer = null;
		}
	};

	/**
	 * get the replay poll interval
	 *
	 * @returns the replay poll interval
	 */
	public getReplayPollInterval = (): number => {
		return this.replayPollInterval;
	};

	/**
	 * set the replay poll interval in seconds (we use the config lookup to poll more often when the replaying at faster speeds)
	 *
	 * @param playbackSpeed - the playback speed to lookup the appropriate config value
	 */
	public setReplayPollInterval = (playbackSpeed: number): void => {
		this.replayPollInterval = this.config.getMapRefreshRate();

		switch (playbackSpeed) {
			case 1:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed1RefreshRate();
				break;
			case 2:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed2RefreshRate();
				break;
			case 5:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed5RefreshRate();
				break;
			case 10:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed10RefreshRate();
				break;
			case 20:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed20RefreshRate();
				break;
			case 60:
				this.replayPollInterval = this.config.getMapReplayPlaybackSpeed60RefreshRate();
				break;
		}

		this.replayPollInterval *= 1000;
	};

	/**
	 * get the map state value of whether we are showing the replay control
	 *
	 * @returns true if we are showing the replay control
	 */
	public getShowReplayControl = (): boolean => {
		return this.mapStateService.getShowReplayControl();
	};

	/**
	 * set the map state to show/hide the replay control
	 *
	 * @param showReplayControl - true if we are showing the replay control
	 */
	public setShowReplayControl = (showReplayControl: boolean): void => {
		this.mapStateService.setShowReplayControl(showReplayControl);
	};

	/**
	 * get the map state value for the current replay time
	 *
	 * @returns the current replay time
	 */
	public getCurrentReplayTime = (): number => {
		return this.mapStateService.getCurrentReplayTime();
	};

	/**
	 * set the map state replay time
	 *
	 * @param currentReplayTime - the map state replay time
	 */
	public setCurrentReplayTime = (currentReplayTime: number): void => {
		this.mapStateService.setCurrentReplayTime(currentReplayTime);
	};

	/**
	 * get the map state value for the current replay id
	 *
	 * @returns the current replay id
	 */
	public getCurrentReplayId = (): string => {
		return this.mapStateService.getCurrentReplayId();
	};

	/**
	 * set the map state replay id
	 *
	 * @param currentReplayId - the map state replay id
	 */
	public setCurrentReplayId = (currentReplayId: string): void => {
		this.mapStateService.setCurrentReplayId(currentReplayId);
	};

	/**
	 * cache the selected day
	 * @param selectedDay - the selected day
	 */
	public setSelectedDay = (selectedDay: Moment): void => {
		this.selectedDay = selectedDay;
	};

	/**
	 * get the cache selected day
	 * @returns the selected day
	 */
	public getSelectedDay = (): Moment => {
		return this.selectedDay;
	};

	/**
	 * cache the selected slider time range
	 * @param sliderTimeRange - the slider time range
	 */
	public setSliderTimeRange = (sliderTimeRange: number[]): void => {
		this.sliderTimeRange = sliderTimeRange;
	};

	/**
	 * get the cached slider time range
	 * @returns the cached slider time range
	 */
	public getSliderTimeRange = (): number[] => {
		return this.sliderTimeRange;
	};

	/**
	 * handle initialization failure (reported from the nextbus API)
	 */
	private handleInitFailure = (): void => {
		this.handleInitComplete(false);

		this.notificationService.notifyError(this.translationService.getTranslation('T_MAP.MAP_REPLAY_INIT_FAILURE'));
	};

	/**
	 * handle initialization complete (reported from the nextbus API) and publish the event for recievers to commence the replay process
	 *
	 * @param success - the success state
	 */
	private handleInitComplete = (success: boolean): void => {
		const initComplete: ReplayInitComplete = {
			success,
		};

		this.mapEventsService.publishReplayInitComplete(initComplete);
	};

	/**
	 * start the replay init polling timer
	 *
	 * @param interval - the interval to poll at
	 */
	private startReplayInitPollTimer = (interval: number): void => {
		// Check we havent expired the replay initialization time
		if (moment().isBefore(this.replayInitExpiryTime)) {
			this.replayInitPollTimer.start(interval).then(async () => {
				await this.pollReplayInitStateCheck();
			});
		} else {
			this.handleInitFailure();
		}
	};

	/**
	 * check the current initialization state
	 */
	private pollReplayInitStateCheck = async (): Promise<void> => {
		const statusCheckResultContent: ResultContent = await this.replayDataService.getInitStatus(this.authorityId, this.replayId);

		if (statusCheckResultContent.success) {
			const statusCheckResultData: InitStatusResponse = statusCheckResultContent.resultData;

			if (statusCheckResultData.replayDataStatus === ReplayStatus.ready) {
				this.notificationService.notifySuccess(this.translationService.getTranslation('T_MAP.MAP_REPLAY_INIT_SUCCESS'));
				this.handleInitComplete(true);
			} else if (
				statusCheckResultData.replayDataStatus === ReplayStatus.complete ||
				statusCheckResultData.replayDataStatus === ReplayStatus.aborted ||
				statusCheckResultData.replayDataStatus === ReplayStatus.expired
			) {
				this.handleInitFailure();
			} else {
				this.startReplayInitPollTimer(this.replayInitPollInterval * 1000);
			}
		} else {
			this.startReplayInitPollTimer(this.replayInitPollInterval * 1000);
		}
	};
}
