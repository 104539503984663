/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Observable, Subscription } from 'rxjs';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { VehicleEventsDataService } from '../services/vehicle-events-data.service';
import { VehicleEventsUtilService } from '../services/vehicle-events-util.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { DefaultPagination, Pagination, PaginationSettings } from '@cubicNx/libs/utils';
import { VehicleEvent, VehicleEventDetails, VehicleEvents } from '../types/api-types';
import { VehicleEventsSettings } from '../types/types';
import { AgencyConfig } from '../../../config/types/types';
import { VehicleSummaries } from '../../../support-features/vehicles/types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { StateService } from '@cubicNx/libs/utils';

import moment from 'moment';

@Component({
	selector: 'vehicle-event-details',
	templateUrl: './vehicle-event-details.component.html',
	styleUrls: ['./vehicle-event-details.component.scss'],
})
export class VehicleEventDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() listName: string = null;
	@Input() settings: VehicleEventsSettings;
	@Input() loaded: boolean = false;
	@Input() hasResults: boolean = false;
	@Input() success: boolean = false;

	public heightStyle: { 'max-height': string };
	public vehicleEvents: VehicleEvents = [];

	public pagination: Pagination = { ...DefaultPagination };

	public paginationSettings: PaginationSettings = null;

	public timezone: string = null;

	private endTimeToRequest: number = null;

	private vehicles: VehicleSummaries;

	private resizeObservable$: Observable<Event> = null;
	private resizeSubscription$: Subscription = null;

	private maxHeight: number = 714;

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['pageSize'];

	constructor(
		private vehiclesDataService: VehiclesDataService,
		private vehicleEventsUtilService: VehicleEventsUtilService,
		private vehicleEventsDataService: VehicleEventsDataService,
		private agenciesDataService: AgenciesDataService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private stateService: StateService,
		translationService: TranslationService
	) {
		super(translationService);

		this.handleResize();
	}

	/**
	 * performs initialization tasks for the vehicle event details component
	 */
	public async ngOnInit(): Promise<void> {
		await this.init();
	}

	/**
	 * initialization in a public callable method for parents (header/widget versions)
	 */
	public init = async (): Promise<void> => {
		this.paginationSettings = {
			pageSize: this.config.getDefaultPageSize(),
			maxPageSize: this.config.getPaginationMaxSize(),
		};

		// overwirte with cache if there is anything saved
		this.loadCache();

		this.timezone = this.agenciesDataService.getAgencyTimezone(
			this.settings.selectedAgency.authority_id,
			this.settings.selectedAgency.agency_id
		);
	};

	/**
	 * when page number changes, get vehicle events up until the last requested time so we don't get new
	 * data in our response
	 */
	public pageChanged = async (): Promise<void> => {
		await this.getVehicleEvents(this.endTimeToRequest);
	};

	/**
	 * when page size changes, get vehicle events up until the last requested time so we don't get new
	 * data in our response
	 */
	public setPageSize = async (): Promise<void> => {
		await this.getVehicleEvents(this.endTimeToRequest);
	};

	/**
	 * resets paging to beginning of events list
	 */
	public resetCurrentPage = async (): Promise<void> => {
		this.pagination.currentPage = 1;
	};

	/**
	 * retrieves vehicle events from the API from the time supplied
	 *
	 * @param endTimeToRequest - the end time to request data from
	 */
	public getVehicleEvents = async (endTimeToRequest: number = null): Promise<void> => {
		this.cachePageInfo(this.paginationSettings.pageSize);

		let vehicleIds: string[] = [];
		let routeIds: string[] = [];

		this.loaded = false;

		this.pagination.totalItems = 0;

		// check we have vehicles before our request rather than load vehicles during init. Otherwise there
		// is a scenario where the parent can request vehicle events after the vehicles have been requested but
		// before they have been returned (essentially the parent is getting in and requesting during await of load vehicles).
		// Requesting them here guarantees they will always be available
		if (!this.vehicles) {
			await this.loadVehicles();
		}

		if (
			(this.settings.includedEventTypeIds.length !== 0 && this.settings.categoryFilterOptions.alarm) ||
			this.settings.categoryFilterOptions.alert ||
			this.settings.categoryFilterOptions.event
		) {
			if (this.vehicles) {
				vehicleIds = this.vehicleEventsUtilService.getFilteredVehicles(this.settings, this.vehicles);
			}

			routeIds = this.vehicleEventsUtilService.getFilteredRoutes(this.settings);

			let sort: string = null;
			let sortDir: string = null;

			if (this.settings.sortBy === 'Date') {
				sort = 'created_at';
				sortDir = 'DESC';
			} else {
				sort = 'category,created_at';
				sortDir = 'ASC,DESC';
			}

			// either we have selected all vehicles or we must have a valid route list of vehicle list following our filters
			// if not then the request wouldn't return any data so don't bother
			if (this.settings.vehicleFilterSelection.value === 'all' || vehicleIds.length > 0 || routeIds.length > 0) {
				const response: ResultContent = await this.vehicleEventsDataService.getVehicleEvents(
					this.settings.selectedAgency.authority_id,
					this.settings.includedEventTypeIds,
					vehicleIds,
					this.pagination.currentPage,
					this.paginationSettings.pageSize,
					null,
					endTimeToRequest,
					this.settings.categoryFilterOptions,
					sort,
					sortDir,
					routeIds
				);

				// default values after our await
				this.vehicleEvents = [];
				this.hasResults = false;
				this.success = false;

				if (response.success) {
					const events: VehicleEventDetails = response.resultData;

					this.pagination.totalItems = events.count;

					if (events.count > 0) {
						this.hasResults = true;
					}

					this.vehicleEvents = events.rows;

					if (this.vehicleEvents.length > 0) {
						// Make a note of the end time for future requests for paging. This ensures if requesting
						// data due to a page change it doesn't trigger updated requests causing confusion for the reader.
						if (!endTimeToRequest) {
							// not a paging request this time - make a note of the latest time in case next request is (a paging request)
							this.endTimeToRequest = 0;

							// get the latest vehicle event - this would be the first record if ordered by date, but not if ordered
							// by category - workout regardless
							this.vehicleEvents.forEach((vehicleEvent: VehicleEvent) => {
								const createdAt: number = moment.utc(vehicleEvent.created_at).valueOf() / 1000;

								if (createdAt > this.endTimeToRequest) {
									this.endTimeToRequest = createdAt;
								}
							});
						}
					}

					this.success = true;
				}
			} else {
				this.vehicleEvents = [];
				this.hasResults = false;
				this.success = true;
			}
		} else {
			this.vehicleEvents = [];
			this.hasResults = false;
			this.success = true;
		}

		this.loaded = true;

		// force change detection to trigger for pagination control after we have updated the totalItems property
		this.pagination = { ...this.pagination };
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.resizeSubscription$?.unsubscribe();
	}

	/**
	 * retrieves vehicle from the nextbus API and remembers them
	 */
	private loadVehicles = async (): Promise<void> => {
		if (!this.vehicles && this.settings.selectedAgency?.authority_id) {
			const vehiclesResponse: ResultContent = await this.vehiclesDataService.getVehicles(this.settings.selectedAgency.authority_id);

			if (vehiclesResponse.success) {
				this.vehicles = vehiclesResponse.resultData as VehicleSummaries;
			}
		}
	};

	/**
	 * deals with resizing of the view
	 */
	private handleResize = (): void => {
		this.heightStyle = { 'max-height': this.maxHeight + 'px' };

		this.resizeObservable$ = fromEvent(window, 'resize');

		this.resizeSubscription$ = this.resizeObservable$.subscribe(() => {
			this.maxHeight = window.innerHeight - 300;
			this.heightStyle = { 'max-height': this.maxHeight + 'px' };
		});
	};

	/**
	 * retrieves last page information for the list
	 */
	private loadCache = (): void => {
		if (this.listName) {
			const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

			if (cacheContainer.pageSize) {
				this.paginationSettings.pageSize = cacheContainer['pageSize'];
			}
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageSize - page size
	 */
	private cachePageInfo = (pageSize: number): void => {
		if (this.listName) {
			// just cache the page size. The page num needs to be reset each time. A persisted page number wouldn't
			// give any value across sessions as the data changes frequently
			this.listCacheContainer['pageSize'] = pageSize;
			this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
		}
	};
}
