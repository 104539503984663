import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NotificationService } from '../../notification/notification.service';
import { TranslationService } from '../../translation/translation.service';
import { LoggerService } from '../../logging/logger.service';

// Intercept any errors
@Injectable({
	providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
	constructor(
		private logger: LoggerService,
		private translationService: TranslationService,
		private notificationService: NotificationService
	) {}

	/**
	 * intercept http requests to handle errors
	 *
	 * @param req - the http request
	 * @param next - the http handler
	 * @returns the http event
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(req).pipe(
			map(
				(res) =>
					// No error - return response
					res
			),
			catchError((error: HttpErrorResponse) => {
				// Deal with server side errors
				if (error.error instanceof ErrorEvent) {
					this.logger.logDebug('This is client side error', error.error.message);
				} else {
					// If we get a 401 error at any time except when logging in, it's probably because the JWT expired. Send
					// them back to the login screen.
					if (error.status === 401 && !req.url.endsWith('/login')) {
						this.logger.logWarn('Not authorised and not attempting to log in, so redirecting to login page');
						document.location.replace(document.location.pathname + '#/login');
					} else if (error.status === 504) {
						const errorText: string = this.translationService.getTranslation('T_CORE.GATEWAY_TIMEOUT');

						this.logger.logDebug(errorText);
					}
				}

				return throwError(error);
			})
		);
	}
}
