/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { MapVehiclesMarkerService } from '../../../services/markers/map-vehicles-marker.service';
import { MapStopsTooltipMarkerService } from '../../../services/markers/map-stops-tooltip-marker.service';
import { MapVehiclesTooltipMarkerService } from '../../../services/markers/map-vehicles-tooltip-marker.service';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapEventsService } from '../../../services/map-events.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { StopsDataService } from '../../../../../support-features/stops/services/stops-data.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapActiveEntityService } from '../../../services/map-active-entity.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { MapStopsService } from '../../../services/map-stops.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { StopLabelRouteDisplay, StopLabelRoutesDisplay } from '../../../../../support-features/stops/types/types';
import { VehiclePosition } from '../../../../../support-features/vehicles/types/api-types';
import { RoutePillData } from '@cubicNx/libs/utils';
import { ResultContent } from '@cubicNx/libs/utils';
import { StopInfo } from '../../../../../support-features/stops/types/api-types';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { Agency } from '../../../../../support-features/agencies/types/api-types';

import {
	DisplayPriorityType,
	EventExtended,
	HTMLElementExtended,
	MapRoute,
	MapStop,
	MapUpdateEvent,
	MapUpdateType,
	MapVehicle,
	ModeType,
	RouteAssociatedStop,
	RouteAssociatedVehicle,
	VehicleDetailsActiveTab,
} from '../../../types/types';

import {
	DirectionStop,
	DirectionStops,
	NormalizedStopsForRoute,
	TripPattern,
} from '../../../../../support-features/routes/types/api-types';

import {
	LadderStop,
	LadderStopsInRoute,
	LadderStops,
	LadderVehicle,
	MatchedStopInTrip,
	StopDirection,
	LadderStopInRoute,
} from '../types/types';

import { EntityType } from '../../../../../utils/components/breadcrumbs/types/types';

import { AgencyConfig } from '../../../../../config/types/types';

import moment from 'moment';

import { DivIcon } from 'leaflet';

declare let $: any;

@Component({
	selector: 'ladder-vertical',
	templateUrl: './ladder-vertical.component.html',
	styleUrls: ['./ladder-vertical.component.scss'],
})
export class LadderVerticalComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	@Input() route: MapRoute = null;

	// make accessible in html
	public displayPriorityType: typeof DisplayPriorityType = DisplayPriorityType;

	public loading: boolean = true;
	public routePill: RoutePillData = null;
	public directionCount: number = 0;
	public inboundStops: LadderStops = [];
	public outboundStops: LadderStops = [];
	public direction1Vehicles: LadderVehicle[] = [];
	public direction2Vehicles: LadderVehicle[] = [];
	public downDeadGrayBoxVehicles: LadderVehicle[] = [];
	public upDeadGrayBoxVehicles: LadderVehicle[] = [];
	public heightForStops: number = 0;

	public inboundStopsDisplay: LadderStops = [];
	public outboundStopsDisplay: LadderStops = [];

	private readonly ladderAllStopsVisibleThreshold: number = 15;
	private readonly ladderMinimumPercentStopsVisible: number = 0.8;
	private readonly ladderLineHeightIncrementThreshold: number = 10;
	private readonly ladderLineHeightSmallIncrement: number = 2;
	private readonly ladderLineHeightLargeIncrement: number = 3;
	private readonly ladderVehicleCenteredOnStopPosition: number = 8;
	private readonly pageBuffer: number = 212;
	private readonly labelCloseDelay: number = 300;

	private mapUpdate$Subscription: Subscription = null;

	private selectedAgency: SelectedAgency = null;

	private resizing: boolean = false;
	private stopLabelShowing: boolean = false;
	private vehicleIdLabelShowing: string = null;
	private sideOfTravel: string = 'Right';
	private imminentDeparture: number = 0;
	private normalizedStops: NormalizedStopsForRoute = null;
	private vehicles: MapVehicle[] = [];

	private inboundHeightLength: number = 0;
	private outboundHeightLength: number = 0;
	private stopLabelPosition: number = 0;
	private stopTooltipRouteIds: string[] = [];
	private stopTooltipStopCode: string = null;
	private vehicleTooltipVehicleId: string = null;
	private stopTooltipUpdateTimer: any = null;

	constructor(
		public mapOptionsService: MapOptionsService,
		private agenciesDataService: AgenciesDataService,
		private mapEventsService: MapEventsService,
		private loggerService: LoggerService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private routesDataService: RoutesDataService,
		private stopsDataService: StopsDataService,
		private mapVehiclesService: MapVehiclesService,
		private colorUtilityService: ColorUtilityService,
		private mapNavigationService: MapNavigationService,
		private mapActiveEntityService: MapActiveEntityService,
		private mapStopsService: MapStopsService,
		private mapRoutesService: MapRoutesService,
		private mapVehiclesMarkerService: MapVehiclesMarkerService,
		private mapVehiclesTooltipMarkerService: MapVehiclesTooltipMarkerService,
		private mapStopsTooltipMarkerService: MapStopsTooltipMarkerService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handles the ladder resize function
	 */
	@HostListener('window:resize', ['$event'])
	public onResize = (): void => {
		this.handleLadderRezise();
	};

	/**
	 * performs initialization tasks for the ladder vertical component
	 *
	 * sets subscriptions, loads stops and vehicles for the route
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		this.routePill = this.determineRoutePillData();

		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		const agency: Agency = this.agenciesDataService.getAgenciesByAuthority(this.selectedAgency.authority_id)[0];

		this.sideOfTravel = agency.side_of_travel || 'Right';
		this.imminentDeparture = agency.imminent_departure;

		await this.loadStops();

		// sometimes we load stops quick enough for the map update event to fire with the vehicles for the route but if it's
		// slow then when we receive the update with the new vehicles, it can't process them as we don't have the normalized stops available
		this.processVehicles();

		this.loading = false;
	}

	/**
	 * navigates to the route details view for the route
	 */
	public openRouteDetails = async (): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, this.route.routeId);
	};

	/**
	 * navigates to the stop details view for the supplied stop
	 *
	 * @param stop - the stop details
	 */
	public openStopDetails = async (stop: LadderStop): Promise<void> => {
		// we should never have a stop code containing an _ar as the stop code should typically reference the sister stop
		// from the _ar paring
		// if we still have the _ar it's because the sister stop could not be found and we have rogue config
		if (!stop.stopCode.includes('_ar')) {
			await this.mapNavigationService.navigateToStopDetails(this.selectedAgency.authority_id, stop.stopCode);
		}
	};

	/**
	 * navigates to the vehicle details view for the supplied vehicle
	 *
	 * @param vehicle - the vehicle details
	 */
	public openVehDetails = async (vehicle: LadderVehicle): Promise<void> => {
		await this.mapNavigationService.navigateToVehicleDetails(
			this.selectedAgency.authority_id,
			vehicle.vehicleId,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * creates a stop label for stop mouse over
	 *
	 * @param stop - the stop details
	 * @returns a label that is rendered when the user mouses over a stop on the ladder
	 */
	public formatStopLabel = (stop: LadderStop): string => {
		const ladderMaximumStopLabelLength: number = this.config.getLadderMaximumStopLabelLength();

		const showStopsLabel: boolean = this.mapOptionsService.getShowLadderStopLabels();
		const showStopCode: boolean = this.mapOptionsService.getShowLadderStopLabelCode();
		const showStopName: boolean = this.mapOptionsService.getShowLadderStopLabelName();

		let stopLabel: string = '';

		if (showStopsLabel) {
			stopLabel = showStopCode ? stop.stopCode : '';
			stopLabel = showStopName ? stopLabel + ' ' + stop.description : stopLabel;
			stopLabel =
				stopLabel.trim().length > ladderMaximumStopLabelLength
					? stopLabel.trim().substr(0, ladderMaximumStopLabelLength) + '...'
					: stopLabel.trim();
		}

		return stopLabel;
	};

	/**
	 * deals with user hovering into a stop label tooltip
	 *
	 * check if label is already set/showing and re-set if need be. This assists in the workaround where
	 * events mouseout events fire even though we are still hovering over the tooltip
	 */
	public stopTooltipHoverIn = (): void => {
		this.stopLabelShowing = true;
	};

	/**
	 * deals with user hovering out of a stop label tooltip
	 *
	 * closes stop label
	 */
	public stopTooltipHoverOut = (): void => {
		// mark the label as cleared
		this.stopLabelShowing = false;

		// don't close instantly, the user may re-enter the tooltip
		setTimeout(() => {
			// make sure the stop label hasn't been put back in (by the mouseenter event) then remove
			if (!this.stopLabelShowing) {
				this.removeStopTooltip();
			}
		}, this.labelCloseDelay);
	};

	/**
	 * deals with the user hovering into a stop on the ladder
	 *
	 * @param event - the hover in event data
	 * @param stop - the stop being hovered over
	 * @param key - the key (inbound/outbound)
	 * @param side - the side
	 */
	public stopHoverIn = async (event: MouseEvent, stop: LadderStop, key: string, side: string): Promise<void> => {
		this.stopLabelPosition = event.clientY - 177;

		// prepare a MapStop type as that is the type the stop labels expect. We just need the values to satisfy what the labels require
		const mapStop: MapStop = {
			hiddenRaw: false,
			hidden: true,
			stopLat: null,
			stopLon: null,
			stopName: stop.description,
			stopId: stop.stopId,
			stopCode: stop.stopCode || stop.stopId, // sometimes there isn't a stop code returned?
		};

		if (mapStop.stopId) {
			// set the initial tooltip - it only has enough detail to show the loading spinner
			const stopLabel: DivIcon = this.mapStopsTooltipMarkerService.getIcon(mapStop, null, null, null);

			// check if label is already set/showing and re-set if need be. This assists in the workaround where
			// mouseleave events fire even though we are still hovering over the label
			this.stopLabelShowing = true;

			if (key === 'inbound') {
				document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML = stopLabel.options.html as string;

				if (this.directionCount > 1) {
					document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML = '';
				}
			} else {
				document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML = stopLabel.options.html as string;
				document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML = '';
			}

			this.positionStopTooltip(side, true);

			const authorityId: string = this.selectedAgency.authority_id;
			const agencyId: string = this.selectedAgency.agency_id;
			const stopCode: string = stop.stopCode;
			const stopId: string = stop.stopId;

			let result: ResultContent = null;

			// we should never have a stop code containing an _ar as the stop code should typically reference the sister stop
			// from the _ar paring
			// if we still have the _ar it's because the sister stop could not be found and we have rogue config
			// In this situation we should use the get stop info by stop id rather than by the typical get info by stop code
			if (!stop.stopCode.includes('_ar')) {
				result = await this.stopsDataService.getStopInfoByCode(authorityId, agencyId, stopCode, stopId);
			} else {
				result = await this.stopsDataService.getStopInfoById(authorityId, agencyId, stopId);
			}

			if (result.success) {
				const stopInfo: StopInfo = result.resultData;

				let stopIcon: DivIcon = this.mapStopsTooltipMarkerService.getIcon(
					mapStop,
					stopInfo.routes,
					stopInfo.predictions,
					stopInfo.lastPassing
				);

				if (key === 'inbound') {
					if (document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML !== '') {
						document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML = stopIcon.options.html as string;
					}
				} else {
					if (document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML !== '') {
						document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML = stopIcon.options.html as string;
					}
				}

				// add listeners to handle our click handlers for links in our html
				const stopLabelRoutesDisplay: StopLabelRoutesDisplay = this.mapStopsTooltipMarkerService.getLivePredictions(
					stopInfo.routes,
					stopInfo.predictions,
					stopInfo.lastPassing
				);

				this.addStopClickListeners(stopCode, stopLabelRoutesDisplay);

				this.positionStopTooltip(side, false);

				clearInterval(this.stopTooltipUpdateTimer);

				// update the tooltip (as the data counts down every second)
				// seems inefficent - is there a better way to handle this?
				this.stopTooltipUpdateTimer = setInterval(() => {
					this.removeStopClickListeners();

					stopIcon = this.mapStopsTooltipMarkerService.getIcon(
						mapStop,
						stopInfo.routes,
						stopInfo.predictions,
						stopInfo.lastPassing
					);

					if (key === 'inbound') {
						if (document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML !== '') {
							document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML = stopIcon.options.html as string;
						}
					} else {
						if (document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML !== '') {
							document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML = stopIcon.options.html as string;
						}
					}

					this.stopTooltipStopCode = stopCode;
					this.stopTooltipRouteIds = [];

					stopLabelRoutesDisplay.forEach((route: StopLabelRouteDisplay) => {
						this.stopTooltipRouteIds.push(route.routeId);
					});

					this.addStopClickListeners(stopCode, stopLabelRoutesDisplay);

					this.positionStopTooltip(side, false);
				}, 1000);
			}
		}
	};

	/**
	 * deals with the user hovering out of a stop on the ladder
	 */
	public stopHoverOut = async (): Promise<void> => {
		// mark the label as cleared
		this.stopLabelShowing = false;

		// don't close instantly, we need to give the use time to enter the tooltip itself (if they do we will stay open)
		setTimeout(() => {
			// make sure the stop label hasn't been put back in (by the mouseenter event) then remove
			if (!this.stopLabelShowing) {
				clearInterval(this.stopTooltipUpdateTimer);

				// clean up our click listers
				this.removeStopClickListeners();

				if (document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML) {
					document.getElementById('inbound-stopLabel' + this.route.routeId).innerHTML = '';
				}

				if (this.directionCount > 1) {
					if (document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML) {
						document.getElementById('outbound-stopLabel' + this.route.routeId).innerHTML = '';
					}
				}
			}
		}, this.labelCloseDelay);
	};

	/**
	 * retrieves the stop styling
	 *
	 * @param stopCode - the stop code
	 * @returns the stop styling
	 */
	public getStopStyle = (stopCode: string): string => {
		let stopStyle: string = '';

		if (this.isActiveEntity(stopCode)) {
			stopStyle += 'nb-stop-icon-shadow-active ';
		}

		if (this.mapOptionsService.getDisplayPriority() === DisplayPriorityType.stops) {
			stopStyle += 'verticalStopIconTop';
		} else {
			stopStyle += 'verticalStopIconBottom';
		}

		return stopStyle;
	};

	/**
	 * retrieves the stop label styling
	 *
	 * @returns the stop label styling
	 */
	public getStopLabelStyle = (): any => {
		const stopLabelPos: any = {
			position: 'absolute',
			top: this.stopLabelPosition + 'px',
		};

		return stopLabelPos;
	};

	/**
	 * deals with user hovering into a vehicle on the ladder
	 *
	 * @param vehicle - the vehicle details
	 */
	public vehicleLabelHoverIn = (vehicle: LadderVehicle): void => {
		this.vehicleTooltipVehicleId = vehicle.vehicleId;

		this.clearOtherTooltips(true, vehicle.vehicleId);

		this.vehicleIdLabelShowing = vehicle.vehicleId;

		this.positionVehicleTooltip(vehicle);
	};

	/**
	 * deals with user hovering out of a vehicle on the ladder
	 * @param vehicle - the vehicle
	 */
	public vehicleLabelHoverOut = (vehicle: LadderVehicle): void => {
		this.vehicleIdLabelShowing = null;

		setTimeout(() => {
			// if hoverflag is still off (i.e we havent entered the tooltip)
			if (this.vehicleIdLabelShowing !== vehicle.vehicleId) {
				this.removeVehicleClickListeners();

				this.removeVehicleTooltip(vehicle);
			}
		}, this.labelCloseDelay);
	};

	/**
	 * Gets the style values required for the vehicle icon on the ladder.
	 *
	 * @param vehicle - the vehicle object containing the vehicle data for styling.
	 * @returns a style object.
	 */
	public getVehicleStyle = (vehicle: LadderVehicle): any => ({
		cursor: 'pointer',
		position: 'absolute',
		height: '17px',
		width: '9px',
		'z-index': 1,
		left: '0px',
		top: vehicle.ladderPosition + 'px',
		'-webkit-transform': 'rotate(' + vehicle.angle + 'deg)',
		'-ms-transform': 'rotate(' + vehicle.angle + 'deg)',
		transform: 'rotate(' + vehicle.angle + 'deg)',
		transition: this.resizing ? null : '1.5s ease-in-out',
	});

	/**
	 * Gets the style values required for the vehicle labels on the ladder.
	 *
	 * @param vehicle - the vehicle object containing the vehicle data for vehicle label styling.
	 * @param side - which side of the vehicle the label should extend from (depends on which column the vehicle is being rendered
	 * @returns a style object.
	 */
	public getVehicleLabelStyle = (vehicle: LadderVehicle, side: string): any => {
		const vehicleLabelPos: any = {
			position: 'absolute',
			top: vehicle.ladderPosition + 'px',
			'z-index': 2,
			transition: this.resizing ? null : '1.5s ease-in-out',
		};

		if (side === 'right') {
			vehicleLabelPos['left'] = '15px';
		} else {
			vehicleLabelPos['right'] = '10px';
		}

		return vehicleLabelPos;
	};

	/**
	 * general clean up activities such as removing subscriptions, clearing timers when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
		clearInterval(this.stopTooltipUpdateTimer);
	}

	/**
	 * determines whether the supplied stop is an active entity
	 *
	 * @param stopCode - the stop code
	 * @returns true if the supplied stop is an active entity
	 */
	public isActiveEntity = (stopCode: string): boolean => {
		return this.mapActiveEntityService.isActiveEntity(stopCode, EntityType.stop);
	};

	/**
	 * createa a route pill instance from underlying route data
	 *
	 * @returns route pill
	 */
	private determineRoutePillData = (): RoutePillData => {
		return {
			routeShortName: this.route.routeShortName,
			routeLongName: this.route.routeLongName,
			routeId: this.route.routeId,
			routeColor: this.colorUtilityService.getColor(this.route.routeColor),
			routeTextColor: this.colorUtilityService.getColor(this.route.routeTextColor),
		};
	};

	/**
	 * retrieves all of the normalized stops for the route
	 */
	private loadStops = async (): Promise<void> => {
		const response: ResultContent = await this.routesDataService.getRouteNormalizedStops(this.route);

		if (response.success) {
			const normalizedStops: NormalizedStopsForRoute = response.resultData;

			this.normalizedStops = normalizedStops;

			this.determineStopDirectionCount(normalizedStops);

			this.determineStops(normalizedStops);
		}
	};

	/**
	 * loads the supplied stops into inbound and outbound lists for rendering on the ladder for the specific route
	 *
	 * @param normalizedStops - the normalized stops
	 */
	private determineStops = (normalizedStops: NormalizedStopsForRoute): void => {
		this.inboundStops = [];
		this.outboundStops = [];

		this.determineAvailableHeight();

		if (normalizedStops.direction1Stops && normalizedStops.direction1Stops.length > 0) {
			this.inboundStops = this.determineDirectionStops(normalizedStops.direction1Stops);

			this.inboundHeightLength = this.determineTotalLineLength(this.inboundStops);

			this.determineShowStopLabels(this.inboundStops, StopDirection.inbound);
		}

		if (normalizedStops.direction2Stops && normalizedStops.direction2Stops.length > 0) {
			this.outboundStops = this.determineDirectionStops(normalizedStops.direction2Stops);

			this.outboundHeightLength = this.determineTotalLineLength(this.outboundStops);

			this.determineShowStopLabels(this.outboundStops, StopDirection.outbound);
		}

		this.determineDisplayStops();

		this.loading = false;
	};

	/**
	 * calculates the available height for rendering the stops
	 */
	private determineAvailableHeight = (): void => {
		const pageHeight: number = $('#page-wrapper').outerHeight();

		this.heightForStops = pageHeight - this.pageBuffer;
	};

	/**
	 * creates ladder direction stops from the supplied stops data
	 *
	 * @param stops - the stops
	 * @returns ladder direction stops
	 */
	private determineDirectionStops = (stops: DirectionStops): LadderStops => {
		const ladderStops: LadderStops = [];

		const filteredDirectionStops: DirectionStops = this.filterHiddenStops(stops);

		const totalStops: number = filteredDirectionStops.length;

		const averageLineHeight: number = this.determineAverageLineHeight(totalStops);
		const remainingHeight: number = this.determineRemainingHeight(totalStops, averageLineHeight);

		filteredDirectionStops.forEach((stop: DirectionStop, index: number) => {
			const ladderStop: LadderStop = this.determineStop(
				stop,
				index,
				filteredDirectionStops.length,
				averageLineHeight,
				remainingHeight
			);

			ladderStops.push(ladderStop);
		});

		return ladderStops;
	};

	/**
	 * filters out any hidden stops from the supplied stops data
	 *
	 * @param stops - the stops
	 * @returns stops with hidden stops removed
	 */
	private filterHiddenStops = (stops: DirectionStops): DirectionStops => {
		let filteredStops: DirectionStops = [];

		if (this.mapStopsService.getShowHiddenStops()) {
			filteredStops = stops;
		} else {
			stops.forEach((stop: DirectionStop) => {
				if (!stop.hiddenStop) {
					filteredStops.push(stop);
				}
			});
		}

		return filteredStops;
	};

	/**
	 * caclulates the average line height between stops
	 *
	 * line height in between stops, ignore line for last stop
	 *
	 * @param totalStops - the total number of stops
	 * @returns average line height between stops
	 */
	private determineAverageLineHeight = (totalStops: number): number =>
		totalStops === 0 ? this.heightForStops : Math.floor(this.heightForStops / (totalStops - 1));

	/**
	 * calculates the remaining height for the stops
	 *
	 * @param totalStops - the total number of stops
	 * @param averageLineHeight - the average line height
	 * @returns remaining height
	 */
	private determineRemainingHeight = (totalStops: number, averageLineHeight: number): number =>
		this.heightForStops - averageLineHeight * (totalStops - 1);

	/**
	 * determines the show stops labels
	 *
	 * @param stops - the stops data
	 * @param stopDirection - the stop direction
	 * @returns the stops on the ladder
	 */
	private determineShowStopLabels = (stops: LadderStops, stopDirection: StopDirection): LadderStops => {
		if (stops.length > 0) {
			// if stop line height is above 15 pixels then its ladder view can show all the stops
			const isLineHeightEnough: boolean = stops[0].lineHeight > this.ladderAllStopsVisibleThreshold;

			const tripPatternExtremeIndices: string[] = this.getFirstStopsFromTripPatterns(stopDirection);

			const visibleIndices: number[] = [];
			const timepointTypeIndices: number[] = [];

			stops.forEach((stop: LadderStop, index: number) => {
				if (isLineHeightEnough) {
					// show all labels
					stop.showLabel = true;
					visibleIndices.push(index);
				} else {
					// identify Timepoint type stops

					// make sure first and last stops are not hidden
					if (index === 0 || index === stops.length - 1) {
						stop.showLabel = true;
						visibleIndices.push(index);
					} else {
						// identify all the timepoints (avoid hiding stops that are first stops in trip patterns)
						const isTimepoint: boolean = stop.jobCondition !== null && stop.jobCondition.indexOf('Timepoint') !== -1;
						const isTripPatternIndex: boolean = tripPatternExtremeIndices.includes(stop.stopTag);

						stop.showLabel = isTimepoint || isTripPatternIndex;

						if (stop.showLabel) {
							visibleIndices.push(index);
							timepointTypeIndices.push(index);
						}
					}
				}
			});

			// check if enough stops are visible after all timepoints are identified.
			let enoughStopsAreVisible: boolean = visibleIndices.length > stops.length * this.ladderMinimumPercentStopsVisible;

			if (!isLineHeightEnough && !enoughStopsAreVisible) {
				stops.forEach((stop: LadderStop, index: number) => {
					if (!visibleIndices.includes(index)) {
						// ensure scheduled points are visible
						const isSchedPoint: boolean = stop.jobCondition !== null && stop.jobCondition.indexOf('SchedPoint') !== -1;

						stop.showLabel = isSchedPoint;

						if (stop.showLabel) {
							visibleIndices.push(index);
						}
					}
				});
			}

			enoughStopsAreVisible = visibleIndices.length > stops.length * this.ladderMinimumPercentStopsVisible;

			// if below flag is true then show more stop labels to fill the empty area on ladder
			// else if flag is false hide some label to have better look and feel. But avoid hiding timepoints,schedpoints
			const showFewMoreLabels: boolean = !isLineHeightEnough && !enoughStopsAreVisible;

			let incrementBy: number =
				stops[0].lineHeight > this.ladderLineHeightIncrementThreshold
					? this.ladderLineHeightSmallIncrement
					: this.ladderLineHeightLargeIncrement;

			incrementBy = showFewMoreLabels || incrementBy === 2 ? incrementBy : incrementBy - 1;

			for (let i: number = incrementBy - 1; !isLineHeightEnough && i < stops.length - 1; i = i + incrementBy) {
				stops[i].showLabel = timepointTypeIndices.includes(i) || showFewMoreLabels;
			}
		}

		return stops;
	};

	/**
	 * gets the first stops from trip patterns within the normalized stops data
	 *
	 * @param stopDirection - the stop direction
	 * @returns first stops from trip patterns
	 */
	private getFirstStopsFromTripPatterns = (stopDirection: StopDirection): string[] => {
		const stopsAtExtremeIndices: string[] = [];

		this.normalizedStops.tripPatterns.forEach((tripPattern: TripPattern) => {
			if (
				tripPattern.stops &&
				tripPattern.stops.length > 0 &&
				tripPattern.stops[0].direction === (stopDirection === StopDirection.inbound ? 'Inbound' : 'Outbound')
			) {
				stopsAtExtremeIndices.push(tripPattern.stops[0].stopTag);
				stopsAtExtremeIndices.push(tripPattern.stops[tripPattern.stops.length - 1].stopTag);
			}
		});

		return stopsAtExtremeIndices;
	};

	/**
	 * dtermines the stops for display purposes
	 */
	private determineDisplayStops = (): void => {
		this.inboundStopsDisplay = ObjectHelpers.deepCopy(this.inboundStops);
		this.outboundStopsDisplay = ObjectHelpers.deepCopy(this.outboundStops);

		this.determineStopCodeDisplay(this.inboundStopsDisplay);
		this.determineStopCodeDisplay(this.outboundStopsDisplay);

		// set side of travel
		if (this.sideOfTravel === 'Left' || this.directionCount === 1) {
			const leftTravelUpStops: LadderStops = [];

			for (let i: number = this.inboundStopsDisplay.length - 1; i >= 0; i--) {
				leftTravelUpStops.push(this.inboundStopsDisplay[i]);
			}

			this.inboundStopsDisplay = leftTravelUpStops;
		}

		if (this.sideOfTravel === 'Right') {
			const rightTravelUpStops: LadderStops = [];

			for (let j: number = this.outboundStopsDisplay.length - 1; j >= 0; j--) {
				rightTravelUpStops.push(this.outboundStopsDisplay[j]);
			}

			this.outboundStopsDisplay = rightTravelUpStops;
		}
	};

	/**
	 * determines the stop codes to be displayed on the ladder
	 *
	 * strip out the _ar to force user clicks to refrence the equivalent 'sister' stop from the _ar paring
	 * for cases when API returns unknown - just fall back to the stop tag (shouldnt happen unless config is misconfigured)
	 *
	 * @param ladderStops - the ladder stops
	 */
	private determineStopCodeDisplay = (ladderStops: LadderStops): void => {
		ladderStops.forEach((ladderStop: LadderStop) => {
			if (ladderStop.stopCode) {
				if (ladderStop.stopCode.includes('_ar')) {
					if (ladderStop.stopCode.includes('unknown')) {
						ladderStop.stopCode = ladderStop.stopTag;
					} else {
						ladderStop.stopCode = ladderStop.stopCode.replace('_ar', '');
					}
				}
			}
		});
	};

	/**
	 * creates a stop for the ladder from the supplied data
	 *
	 * @param stop - the stop
	 * @param stopIndex - the stop index
	 * @param totalStops - the total number of stops
	 * @param averageLineHeight - the average line height between stops
	 * @param remainingHeight - the remianing height
	 * @returns a stop for the ladder
	 */
	private determineStop = (
		stop: DirectionStop,
		stopIndex: number,
		totalStops: number,
		averageLineHeight: number,
		remainingHeight: number
	): LadderStop => {
		// Our stopTag contains the stop id
		const stopCode: string = this.determineStopCode(stop.stopTag);

		const lineHeight: number = this.determineStopLineLength(stopIndex, totalStops, averageLineHeight, remainingHeight);

		const ladderStop: LadderStop = {
			description: stop.description,
			stopTag: stop.stopTag,
			direction: stop.direction,
			jobCondition: stop.jobCondition,
			position: stop.position,
			showLabel: false,
			stopId: stop.stopTag,
			stopCode,
			lineHeight,
		};

		return ladderStop;
	};

	/**
	 * determines the stop code for the supplied stop id
	 *
	 * @param stopId - the stop id
	 * @returns the equivalent stop code
	 */
	private determineStopCode = (stopId: string): string => {
		return this.mapStopsService.getStopCodeFromId(stopId);
	};

	/**
	 * determines the stop line length
	 *
	 * @param stopIndex - the stop index
	 * @param totalStops - the total number fo stops
	 * @param averageLineHeight - the average line height
	 * @param remainingHeight - the remaining height
	 * @returns stop line length
	 */
	private determineStopLineLength = (
		stopIndex: number,
		totalStops: number,
		averageLineHeight: number,
		remainingHeight: number
	): number => {
		return averageLineHeight + (totalStops - stopIndex <= remainingHeight ? 1 : 0);
	};

	/**
	 * determines the total line length
	 *
	 * @param stops - the stops
	 * @returns the total line length
	 */
	private determineTotalLineLength = (stops: LadderStops): number => {
		let total: number = 0;

		stops.forEach((stop: LadderStop) => {
			total += stop.lineHeight;
		});

		return total;
	};

	/**
	 * Set up the subscriptions to the map events service.
	 */
	private setSubscriptions = (): void => {
		this.mapUpdate$Subscription = this.mapEventsService.mapUpdate.subscribe((event) => {
			this.handleMapUpdate(event);
		});
	};

	/**
	 * when a map update is published, this may require re-rendering of the ladder details
	 *
	 * @param update  - the map update details
	 */
	private handleMapUpdate = (update: MapUpdateEvent): void => {
		// include try catch - shouldn't be needed but if an exception otherwise occurs it breaks the subscription and nothing works until
		// the user refreshes
		try {
			switch (update.mapUpdateType) {
				case MapUpdateType.activeEntityUpdate:
				case MapUpdateType.refreshVehicleLabels:
					// force a refresh so the correct vehicle is highlighted (or hightlight cleared)
					this.renderVehicles(this.vehicles);
					break;
				case MapUpdateType.removeVehicles:
				case MapUpdateType.removeVehicle:
				case MapUpdateType.addVehicle:
				case MapUpdateType.addVehicles:
				case MapUpdateType.updateVehicles:
				case MapUpdateType.reloadVehicles:
					this.processVehicles();
					break;
				case MapUpdateType.reloadStops:
					this.determineStops(this.normalizedStops);
					break;
			}
		} catch (exception) {
			this.loggerService.logError('Failed to process map update', exception);
		}
	};

	/**
	 * checks vehicles on the route to ensure that they should still be present on the ladder
	 */
	private processVehicles = (): void => {
		const route: MapRoute = this.mapRoutesService.getRoute(this.route.routeId);

		if (route) {
			if (this.vehicles.length > 0) {
				this.vehicles.forEach((existingVehicle: MapVehicle) => {
					if (!route.vehicles.find((vehicle: RouteAssociatedVehicle) => vehicle.vehicleId === existingVehicle.vehicleId)) {
						// vehicle has been removed from the route - remove it
						this.removeExistingVehicle(existingVehicle.vehicleId);
					}
				});
			}

			this.vehicles = [];

			if (this.normalizedStops) {
				if (route.vehicles) {
					route.vehicles.forEach((routeVehicle: RouteAssociatedVehicle) => {
						const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(routeVehicle.vehicleId);

						// just because the vehicle is part of the route - doesn't mean it hasn't been removed so check
						if (vehicle) {
							this.vehicles.push(vehicle);
						} else {
							this.removeExistingVehicle(routeVehicle.vehicleId);
						}
					});
				}

				this.renderVehicles(this.vehicles);
			}
		}
	};

	/**
	 * handles ladder resizing
	 */
	private handleLadderRezise = (): void => {
		this.resizing = true;

		this.clearDeadVehicles();

		// allow the resize to finish before refreshing stops/vehicle
		setTimeout(() => {
			this.determineAvailableHeight();
			this.reloadStops();
			this.renderVehicles(this.vehicles);

			// give the vehicles a change to render before turning the flag off - ensure the vehicles dont animate when resizing
			setTimeout(() => {
				this.resizing = false;
			});
		}, 1500);
	};

	/**
	 * reloads the stops on the ladder
	 */
	private reloadStops = (): void => {
		const totalInboundStops: number = this.inboundStops.length;

		const inboundAverageLineHeight: number = this.determineAverageLineHeight(totalInboundStops);
		const inboundRemainingHeight: number = this.determineRemainingHeight(totalInboundStops, inboundAverageLineHeight);

		this.inboundStops.forEach((stop: LadderStop, index: number) => {
			stop.lineHeight = this.determineStopLineLength(index, totalInboundStops, inboundAverageLineHeight, inboundRemainingHeight);
		});

		this.inboundHeightLength = this.determineTotalLineLength(this.inboundStops);

		this.determineShowStopLabels(this.inboundStops, StopDirection.inbound);

		if (this.directionCount > 1) {
			const totalOutboundStops: number = this.outboundStops.length;

			const outboundAverageLineHeight: number = this.determineAverageLineHeight(totalOutboundStops);
			const outboundRemainingHeight: number = this.determineRemainingHeight(totalOutboundStops, outboundAverageLineHeight);

			this.outboundStops.forEach((stop: LadderStop, index: number) => {
				stop.lineHeight = this.determineStopLineLength(
					index,
					totalOutboundStops,
					outboundAverageLineHeight,
					outboundRemainingHeight
				);
			});

			this.outboundHeightLength = this.determineTotalLineLength(this.outboundStops);
			this.determineShowStopLabels(this.outboundStops, StopDirection.outbound);
		}

		this.determineDisplayStops();
	};

	/**
	 * calculates the stops count in all directions
	 *
	 * @param normalizedStops - the normalized stops
	 */
	private determineStopDirectionCount = (normalizedStops: NormalizedStopsForRoute): void => {
		this.directionCount = 0;

		if (normalizedStops.direction1Stops && normalizedStops.direction1Stops.length > 0) {
			this.directionCount++;
		}

		if (normalizedStops.direction2Stops && normalizedStops.direction2Stops.length > 0) {
			this.directionCount++;
		}
	};

	/**
	 * Unsubscribes from service subscriptions.
	 */
	private unsubscribe = (): void => {
		this.mapUpdate$Subscription.unsubscribe();
	};

	/**
	 * clears dead vehicles
	 */
	private clearDeadVehicles = (): void => {
		this.downDeadGrayBoxVehicles = [];
		this.upDeadGrayBoxVehicles = [];
	};

	/**
	 * renders the supplied vehicle on the ladder
	 *
	 * @param vehicle - the vehicle
	 */
	private renderVehicle = (vehicle: MapVehicle): void => {
		if (vehicle.vehiclePosition?.currentStopTag) {
			const currentStopTag: string = vehicle.vehiclePosition.currentStopTag;

			let prvStopOfMatchedInTrip: MatchedStopInTrip = {
				pathTag: null,
				direction: null,
				stopTag: '<empty>',
				position: 0,
				stopDistance: 0,
				isStopFoundOnNextPath: false,
				countOfDuplicateMatch: 0,
			};

			let matchedStopInTrip: MatchedStopInTrip = null;
			let nextStopInTrip: DirectionStop = null;

			if (this.normalizedStops) {
				this.normalizedStops.tripPatterns.forEach((tripPattern: TripPattern) => {
					let countOfDuplicateMatch: number = 0;
					let prvMatchedPathTag: string = null;

					if (tripPattern.stops) {
						tripPattern.stops.forEach((stop: DirectionStop, stopIndex: number) => {
							if (!matchedStopInTrip) {
								const stopDis: number = stop.position - prvStopOfMatchedInTrip.position;

								if (stopDis > 0) {
									stop.stopDistance = stopDis;
								} else {
									stop.stopDistance = stop.position;
								}

								if (stop.stopTag === currentStopTag) {
									if (
										stop.stopTag === prvStopOfMatchedInTrip.stopTag &&
										stop.direction === prvStopOfMatchedInTrip.direction
									) {
										// Ignore if previous and current stops is same
										this.loggerService.logDebug(
											'Stop matched, but ignored due to matching with previous stop',
											vehicle
										);
									} else {
										countOfDuplicateMatch++;
										this.loggerService.logDebug(
											'Stop matched @ ' + stop.direction + ' , ' + tripPattern.tripPatternTag + ' , ' + stopIndex
										);
										this.loggerService.logDebug(
											'Count of matched stops in TripPattern with vehicle :' + countOfDuplicateMatch
										);
									}
								}

								if (stop.stopTag === currentStopTag && stop.direction !== prvStopOfMatchedInTrip.direction) {
									this.loggerService.logDebug(
										'Stops matched count is reset to zero by searching in another direction',
										vehicle
									);
									countOfDuplicateMatch = 1;
								}

								if (stop.pathTag === vehicle.vehiclePosition.pathTag) {
									if (stop.stopTag === currentStopTag) {
										this.loggerService.logDebug('Found stop matching with vehicle pathTag:' + vehicle);

										matchedStopInTrip = {
											stopDistance: stop.stopDistance,
											direction: stop.direction,
											position: stop.position,
											stopTag: stop.stopTag,
											pathTag: stop.pathTag,
											isStopFoundOnNextPath: false,
											countOfDuplicateMatch: 0,
										};
									} else if (prvStopOfMatchedInTrip.stopTag === currentStopTag) {
										this.loggerService.logDebug('Found stop matching with privous stop in TP and pathTag:' + vehicle);

										matchedStopInTrip = this.getMatchedStopInTrip(stop, prvStopOfMatchedInTrip.stopTag);
									} else {
										prvMatchedPathTag = stop.pathTag;
									}
								} else if (prvMatchedPathTag === vehicle.vehiclePosition.pathTag && stop.stopTag === currentStopTag) {
									this.loggerService.logDebug('Found stop matching with vehicle next pathTag:' + vehicle);

									matchedStopInTrip = this.getMatchedStopInTrip(stop, prvStopOfMatchedInTrip.stopTag);
								} else if (
									vehicle.vehiclePosition.pathTag.indexOf(prvStopOfMatchedInTrip.stopTag) !== -1 &&
									vehicle.vehiclePosition.pathTag.indexOf(stop.stopTag) !== -1
								) {
									if (stop.stopTag === currentStopTag) {
										matchedStopInTrip = this.getMatchedStopInTrip(stop, prvStopOfMatchedInTrip.stopTag);
									} else if (prvStopOfMatchedInTrip.stopTag === currentStopTag) {
										matchedStopInTrip = this.getMatchedStopInTrip(stop, prvStopOfMatchedInTrip.stopTag);
									}
								}

								if (matchedStopInTrip !== null) {
									matchedStopInTrip.countOfDuplicateMatch = countOfDuplicateMatch;

									tripPattern.stops.forEach((tripStop) => {
										if (tripStop.stopTag !== stop.stopTag) {
											tripStop.stopDistance = tripStop.position - stop.position;
											nextStopInTrip = tripStop;

											this.loggerService.logDebug(
												'Next stop:' + tripStop.stopTag + ' , Stop Distance:' + tripStop.stopDistance
											);
										} else {
											this.loggerService.logDebug('Found next stop is duplicate of current stop:' + tripStop.stopTag);
										}
									});
								} else {
									prvStopOfMatchedInTrip = {
										stopDistance: stop.stopDistance,
										direction: stop.direction,
										position: stop.position,
										stopTag: stop.stopTag,
										pathTag: stop.pathTag,
										isStopFoundOnNextPath: false,
										countOfDuplicateMatch: 0,
									};
								}
							}
						});
					}
				});
			}

			if (matchedStopInTrip !== null) {
				//this.LoggerService.logError("Ignored vehicle for not matching in any Trip Pattern", vehicle);

				let isVehicleInInbound: boolean = false;

				if (this.inboundStops.length > 0) {
					if (matchedStopInTrip.direction === this.inboundStops[0].direction) {
						isVehicleInInbound = true;
					}
				}

				if (isVehicleInInbound) {
					this.renderVehiclePosition(
						vehicle,
						this.inboundStops,
						prvStopOfMatchedInTrip,
						matchedStopInTrip,
						nextStopInTrip,
						StopDirection.inbound
					);
				} else {
					this.renderVehiclePosition(
						vehicle,
						this.outboundStops,
						prvStopOfMatchedInTrip,
						matchedStopInTrip,
						nextStopInTrip,
						StopDirection.outbound
					);
				}
			}
		}
	};

	/**
	 * creates a matched stop in trip object from the stops list
	 *
	 * @param stop - the stop
	 * @param stopTag - the stop tag
	 * @returns the matched stop on the trip
	 */
	private getMatchedStopInTrip = (stop: DirectionStop, stopTag: string): MatchedStopInTrip => {
		const ladderStopInRoute: LadderStopsInRoute = this.findStopInRoute(stop, stopTag);

		if (ladderStopInRoute.stopFound) {
			return {
				stopDistance: this.getDisBetweenPaths(ladderStopInRoute),
				direction: stop.direction,
				position: stop.position,
				stopTag: stop.stopTag,
				pathTag: stop.pathTag,
				isStopFoundOnNextPath: true,
				countOfDuplicateMatch: 0,
			};
		} else {
			return null;
		}
	};

	/**
	 * finds the stop in the route and then matches the previous stop on the trip
	 *
	 * @param stop - the stop
	 * @param prvStopTag - the previous stop tag
	 * @returns stops and previous stop for the supplied stop
	 */
	private findStopInRoute = (stop: DirectionStop, prvStopTag: string): LadderStopsInRoute => {
		const matchedStopTag: string = stop.stopTag;

		let prvStopInRoute: LadderStopInRoute = null;
		let matchedStopInRoute: LadderStopInRoute = null;

		this.route.stops.forEach((routeStop: RouteAssociatedStop) => {
			if (routeStop.stopId === prvStopTag) {
				const prevStop: MapStop = this.mapStopsService.getStop(routeStop.stopCode);

				prvStopInRoute = {
					stopId: routeStop.stopId,
					lat: prevStop.stopLat,
					lon: prevStop.stopLon,
				};
			}

			if (routeStop.stopId === matchedStopTag) {
				const matchedStop: MapStop = this.mapStopsService.getStop(routeStop.stopCode);

				matchedStopInRoute = {
					stopId: routeStop.stopId,
					lat: matchedStop.stopLat,
					lon: matchedStop.stopLon,
				};
			}
		});

		if (prvStopInRoute && matchedStopInRoute) {
			return {
				stopFound: true,
				prvStopInRoute,
				matchedStopInRoute,
			};
		} else {
			return {
				stopFound: false,
				prvStopInRoute: null,
				matchedStopInRoute: null,
			};
		}
	};

	/**
	 * determines the difference between the supplied stop and the previous stop
	 *
	 * @param ladderStopInRoute - the stop in the route on the ladder
	 * @returns the difference between the stop and its previous stop
	 */
	private getDisBetweenPaths = (ladderStopInRoute: LadderStopsInRoute): number => {
		const lat1: number = ladderStopInRoute.prvStopInRoute.lat;
		const lon1: number = ladderStopInRoute.prvStopInRoute.lon;

		const lat2: number = ladderStopInRoute.matchedStopInRoute.lat;
		const lon2: number = ladderStopInRoute.matchedStopInRoute.lon;

		const a: number = (lat1 * Math.PI) / 180;
		const b: number = (lat2 * Math.PI) / 180;
		const c: number = ((lon2 - lon1) * Math.PI) / 180;
		const R: number = 6371e3;

		const distance: number = Math.acos(Math.sin(a) * Math.sin(b) + Math.cos(a) * Math.cos(b) * Math.cos(c)) * R;

		this.loggerService.logDebug('Distance of matched stop in next path:' + distance);

		return distance;
	};

	/**
	 * renders the supplied vehicles on the ladder
	 *
	 * @param vehicles - the vehicles
	 */
	private renderVehicles = async (vehicles: MapVehicle[]): Promise<void> => {
		this.clearDeadVehicles();

		if (vehicles) {
			vehicles.forEach((vehicle: MapVehicle) => {
				const atDepot: boolean = vehicle.vehState === 'at_depot';

				if (!atDepot) {
					this.renderVehicle(vehicle);
				} else {
					this.removeExistingVehicle(vehicle.vehicleId);
				}
			});
		}
	};

	/**
	 * renders the vehicle position
	 *
	 * @param vehicle - the vehicle
	 * @param directionStops - the direction stops
	 * @param prvStopOfMatchedInTrip - the previous stop in the trip
	 * @param matchedStopInTrip - the matched stop in the trip
	 * @param nextStopInTp - the next stop in the trip
	 * @param stopDirection - the stop direction
	 */
	private renderVehiclePosition = (
		vehicle: MapVehicle,
		directionStops: LadderStops,
		prvStopOfMatchedInTrip: MatchedStopInTrip,
		matchedStopInTrip: MatchedStopInTrip,
		nextStopInTp: DirectionStop,
		stopDirection: StopDirection
	): void => {
		let vehicleOnLadder: boolean = false;
		let totalPixCount: number = 0;
		let stopPixelInTp: number = 0;
		let vehiclePositionInPx: number = 0;
		let duplicateStopMatchToConsider: number = 0;
		let lastMatchedLineHeight: number = 0;
		let matchedInDirection: boolean = false;
		let positionFound: boolean = false;

		for (let i: number = 0; i < directionStops.length; i++) {
			if (!positionFound) {
				let ignoreStop: boolean = false;

				totalPixCount += directionStops[i].lineHeight;

				if (stopPixelInTp > 0 && directionStops[i].stopTag !== matchedStopInTrip.stopTag) {
					this.loggerService.logDebug('Found stop which is in another TripPattern ' + directionStops[i].stopTag);
					stopPixelInTp += directionStops[i].lineHeight;
				}

				if (directionStops[i].stopTag === prvStopOfMatchedInTrip.stopTag) {
					stopPixelInTp = 0;
					stopPixelInTp += directionStops[i].lineHeight;
					this.loggerService.logDebug('Found previous stop of vehicle current stop ' + directionStops[i].stopTag);
				}

				let stopMatched: boolean = false;

				if (directionStops[i].stopTag === matchedStopInTrip.stopTag) {
					this.loggerService.logDebug('Found first stop matching in trip pattern, stop:' + directionStops[i].stopTag);
					stopMatched = true;
					duplicateStopMatchToConsider++;
				}

				if (matchedStopInTrip.countOfDuplicateMatch !== duplicateStopMatchToConsider) {
					if (stopMatched) {
						this.loggerService.logDebug('Ignored matched stop, vehicle not at this stop');
						stopPixelInTp = 0;
					}

					ignoreStop = true;
				}

				if (!ignoreStop) {
					if (directionStops[i].stopTag === matchedStopInTrip.stopTag) {
						this.loggerService.logDebug('Total pixel count upto current stop:' + totalPixCount);
						this.loggerService.logDebug('Space between stops in pixel:' + stopPixelInTp);
						this.loggerService.logDebug(
							'Stop position:' + directionStops[i].position + ' , vehicle postion:' + vehicle.vehiclePosition.position
						);

						let distanceFromBusToStop: number = directionStops[i].position - vehicle.vehiclePosition.position;

						if (matchedStopInTrip.isStopFoundOnNextPath) {
							this.loggerService.logDebug('Executing next path special case');

							distanceFromBusToStop =
								matchedStopInTrip.stopDistance + prvStopOfMatchedInTrip.position - vehicle.vehiclePosition.position;

							this.loggerService.logDebug('Next path matching distance from bus to stop:' + distanceFromBusToStop);

							if (
								distanceFromBusToStop < 0 ||
								(vehicle.vehiclePosition.atCurrentStop &&
									vehicle.vehiclePosition.currentStopTag === directionStops[i].stopTag)
							) {
								distanceFromBusToStop = 0;
							}
						}

						this.loggerService.logDebug('Distance from bus to stop:' + distanceFromBusToStop);

						if (distanceFromBusToStop < 0 && vehicle.vehiclePosition.atCurrentStop) {
							this.loggerService.logDebug('Vehicle @ current stop:' + vehicle.vehiclePosition.atCurrentStop);
							distanceFromBusToStop = 0;
						}

						if (distanceFromBusToStop < 0 && !nextStopInTp) {
							this.loggerService.logDebug('Ignored vehicle due to vehicle crossed ending stop');

							return;
						}

						if (distanceFromBusToStop < 0 && nextStopInTp && matchedStopInTrip.pathTag !== nextStopInTp.pathTag) {
							this.loggerService.logDebug('Vehicle moved on next path');
							distanceFromBusToStop = 0;
						}

						let ignore: boolean = false;

						if (distanceFromBusToStop < 0) {
							this.loggerService.logDebug('Finding vehicle position with next stop');

							prvStopOfMatchedInTrip = matchedStopInTrip;

							matchedStopInTrip = {
								stopDistance: nextStopInTp.stopDistance,
								direction: nextStopInTp.direction,
								position: nextStopInTp.position,
								stopTag: nextStopInTp.stopTag,
								pathTag: nextStopInTp.pathTag,
								isStopFoundOnNextPath: false,
								countOfDuplicateMatch: 0,
							};

							matchedStopInTrip.countOfDuplicateMatch += 1;
							stopPixelInTp = directionStops[i].lineHeight;

							ignore = true;
						}

						if (!ignore) {
							this.loggerService.logDebug('Distance from bus to stop:' + distanceFromBusToStop);
							let percBusToStop: number = Math.floor((distanceFromBusToStop * 100) / matchedStopInTrip.stopDistance);

							if (isNaN(percBusToStop)) {
								percBusToStop = 0;
							}

							this.loggerService.logDebug('Percentage from bus to stop:' + percBusToStop);

							let pixelInBusToStop: number = Math.floor((percBusToStop / 100) * stopPixelInTp);

							if (i === 0 && directionStops[i].stopTag === vehicle.vehiclePosition.currentStopTag) {
								if (vehicle.vehiclePosition.atCurrentStop) {
									pixelInBusToStop = totalPixCount;
								} else if (vehicle.vehiclePosition.position < 10) {
									pixelInBusToStop = totalPixCount;
								}
							}

							this.loggerService.logDebug('Calculated pixels from bus to stop:' + pixelInBusToStop);

							vehiclePositionInPx = totalPixCount - pixelInBusToStop;
							matchedInDirection = true;

							lastMatchedLineHeight = directionStops[i].lineHeight;

							this.loggerService.logDebug('Vehicle position in ladder @ ' + vehiclePositionInPx + ' in pixels');

							positionFound = true;
						}
					}
				}
			}
		}

		if (matchedInDirection) {
			if (!vehicle.hidden) {
				const vehicleDeadState: boolean = this.getVehicleDeadState(vehicle.vehState, vehicle.vehiclePosition);

				const ladderVehicle: LadderVehicle = {
					vehicleId: vehicle.vehicleId,
					routeId: vehicle.routeId,
					ladderPosition: 0,
					icon: this.mapVehiclesMarkerService.getIcon(vehicle, ModeType.ladder).options.html as string,
					angle: 0,
					departure: 0,
					vehLabelHtml: this.mapVehiclesTooltipMarkerService.getIcon(vehicle).options.html as string,
					stopDirection,
					hoverFlag: false,
				};

				if (vehicleDeadState) {
					const vehPrediction: number = vehicle.vehiclePosition?.predictionTime ? vehicle.vehiclePosition.predictionTime : 0;
					const timeUntilStop: number = this.getSecondsUntil(vehPrediction);

					ladderVehicle.departure = timeUntilStop;
				}

				const isInboundLeftTravel: boolean = this.sideOfTravel === 'Left' || this.directionCount === 1;

				if (isInboundLeftTravel && stopDirection === StopDirection.inbound) {
					if (totalPixCount === lastMatchedLineHeight) {
						if (vehiclePositionInPx < this.ladderVehicleCenteredOnStopPosition) {
							vehiclePositionInPx = this.ladderVehicleCenteredOnStopPosition;
						}

						vehiclePositionInPx = vehiclePositionInPx + lastMatchedLineHeight;
					}

					ladderVehicle.ladderPosition = this.inboundHeightLength - vehiclePositionInPx;

					if (vehicleDeadState) {
						this.downDeadGrayBoxVehicles.push(ladderVehicle);
					} else {
						vehicleOnLadder = true;

						const existingVehicleIndex: number = this.direction1Vehicles.findIndex(
							(v: LadderVehicle) => v.vehicleId === ladderVehicle.vehicleId
						);

						if (existingVehicleIndex !== -1) {
							// update vehicle - we need to update individual properties to maintain existing object so vehicle
							// animation works
							this.updateVehicle(this.direction1Vehicles[existingVehicleIndex], ladderVehicle);
						} else {
							this.direction1Vehicles.push(ladderVehicle);
						}
					}
				} else if (stopDirection === StopDirection.inbound) {
					ladderVehicle.angle = 180;
					ladderVehicle.ladderPosition = vehiclePositionInPx <= 17 ? vehiclePositionInPx : vehiclePositionInPx - 17;
					ladderVehicle.ladderPosition = ladderVehicle.ladderPosition - lastMatchedLineHeight;

					if (ladderVehicle.ladderPosition < 0) {
						ladderVehicle.ladderPosition = -this.ladderVehicleCenteredOnStopPosition;
					}

					if (vehicleDeadState) {
						this.upDeadGrayBoxVehicles.push(ladderVehicle);
					} else {
						vehicleOnLadder = true;

						const existingVehicleIndex: number = this.direction1Vehicles.findIndex(
							(v: LadderVehicle) => v.vehicleId === ladderVehicle.vehicleId
						);

						if (existingVehicleIndex !== -1) {
							// update vehicle - we need to update individual properties to maintain existing object so vehicle
							// animation works
							this.updateVehicle(this.direction1Vehicles[existingVehicleIndex], ladderVehicle);
						} else {
							this.direction1Vehicles.push(ladderVehicle);
						}
					}
				}

				if (!isInboundLeftTravel && stopDirection === StopDirection.outbound) {
					if (totalPixCount === lastMatchedLineHeight) {
						if (vehiclePositionInPx < this.ladderVehicleCenteredOnStopPosition) {
							vehiclePositionInPx = this.ladderVehicleCenteredOnStopPosition;
						}

						vehiclePositionInPx = vehiclePositionInPx + lastMatchedLineHeight;
					}

					ladderVehicle.ladderPosition = this.outboundHeightLength - vehiclePositionInPx;

					if (vehicleDeadState) {
						this.downDeadGrayBoxVehicles.push(ladderVehicle);
					} else {
						vehicleOnLadder = true;

						const existingVehicleIndex: number = this.direction2Vehicles.findIndex(
							(v: LadderVehicle) => v.vehicleId === ladderVehicle.vehicleId
						);

						if (existingVehicleIndex !== -1) {
							// update vehicle - we need to update individual properties to maintain existing object so vehicle
							// animation works
							this.updateVehicle(this.direction2Vehicles[existingVehicleIndex], ladderVehicle);
						} else {
							this.direction2Vehicles.push(ladderVehicle);
						}
					}
				} else if (stopDirection === StopDirection.outbound) {
					ladderVehicle.angle = 180;
					ladderVehicle.ladderPosition = vehiclePositionInPx <= 17 ? vehiclePositionInPx : vehiclePositionInPx - 17;
					ladderVehicle.ladderPosition = ladderVehicle.ladderPosition - lastMatchedLineHeight;

					if (ladderVehicle.ladderPosition < 0) {
						ladderVehicle.ladderPosition = -this.ladderVehicleCenteredOnStopPosition;
					}

					if (vehicleDeadState) {
						this.upDeadGrayBoxVehicles.push(ladderVehicle);
					} else {
						vehicleOnLadder = true;

						const existingVehicleIndex: number = this.direction2Vehicles.findIndex(
							(v: LadderVehicle) => v.vehicleId === ladderVehicle.vehicleId
						);

						if (existingVehicleIndex !== -1) {
							// update vehicle - we need to update individual properties to maintain existing object so vehicle
							// animation works
							this.updateVehicle(this.direction2Vehicles[existingVehicleIndex], ladderVehicle);
						} else {
							this.direction2Vehicles.push(ladderVehicle);
						}
					}
				}
			}
		} else {
			this.loggerService.logDebug(
				stopDirection === StopDirection.inbound ? 'Ignored vehicle not found in Inbound' : 'Ignored vehicle not found in Outbound'
			);
		}

		// handle any vehicles that need to be removed - if vehicle hasn't been added/updated this polling period - make sure it is removed
		if (!vehicleOnLadder) {
			this.removeExistingVehicle(vehicle.vehicleId);
		}

		this.upDeadGrayBoxVehicles.sort((a, b) => a.departure - b.departure);
		this.downDeadGrayBoxVehicles.sort((a, b) => a.departure - b.departure);
	};

	/**
	 * updates the vehicle details with the supplied update
	 *
	 * @param vehicle - the vehicle
	 * @param updatedVehicle - the updated vehicle
	 */
	private updateVehicle = (vehicle: LadderVehicle, updatedVehicle: LadderVehicle): void => {
		vehicle.ladderPosition = updatedVehicle.ladderPosition;
		vehicle.icon = updatedVehicle.icon;
		vehicle.angle = updatedVehicle.angle;
		vehicle.departure = updatedVehicle.departure;
		vehicle.vehLabelHtml = updatedVehicle.vehLabelHtml;
		vehicle.stopDirection = updatedVehicle.stopDirection;
		vehicle.hoverFlag = updatedVehicle.hoverFlag;
	};

	/**
	 * removes a vehicle from the ladder
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeExistingVehicle = (vehicleId: string): void => {
		const existingDirection1VehicleIndex: number = this.direction1Vehicles.findIndex(
			(existingVehicle: LadderVehicle) => existingVehicle.vehicleId === vehicleId
		);

		if (existingDirection1VehicleIndex !== -1) {
			this.direction1Vehicles.splice(existingDirection1VehicleIndex, 1);
		}

		const existingDirection2VehicleIndex: number = this.direction2Vehicles.findIndex(
			(existingVehicle: LadderVehicle) => existingVehicle.vehicleId === vehicleId
		);

		if (existingDirection2VehicleIndex !== -1) {
			this.direction2Vehicles.splice(existingDirection2VehicleIndex, 1);
		}
	};

	/**
	 * retrieves the vehicle dead state
	 *
	 * @param vehicleState - the vehicle state
	 * @param vehiclePosition - the vehicle position
	 * @returns the vehicle dead state
	 */
	private getVehicleDeadState = (vehicleState: string, vehiclePosition: VehiclePosition): boolean => {
		let vehDeadState: boolean = false;

		if (vehicleState === 'driver on break') {
			if (vehiclePosition) {
				const vehPrediction: number = vehiclePosition?.predictionTime ? vehiclePosition.predictionTime : 0;
				const timeUntilStop: number = this.getSecondsUntil(vehPrediction);

				if (timeUntilStop > this.imminentDeparture) {
					vehDeadState = true;
				}
			}
		}

		return vehDeadState;
	};

	/**
	 * utility to determine the number of seconds until the supplied timestamp
	 *
	 * @param timestamp - the timestamp
	 * @returns the number of seconds until the supplied timestamp
	 */
	private getSecondsUntil = (timestamp: number): number => {
		timestamp = Math.floor(timestamp / 1000);

		const value: string = moment().format('x');

		const currentSeconds: number = Math.floor(parseInt(value, 10) / 1000);

		return timestamp - currentSeconds;
	};

	/**
	 * clears down other tooltips for the supplied vehicle
	 *
	 * @param isVehicle - true if it is a vehicle
	 * @param entityId - the entity id
	 */
	private clearOtherTooltips = (isVehicle: boolean, entityId: string): void => {
		this.removeStopTooltip();

		this.direction1Vehicles.forEach((veh: LadderVehicle) => {
			if (!isVehicle || veh.vehicleId !== entityId) {
				this.removeVehicleTooltip(veh);
			}
		});

		this.direction2Vehicles.forEach((veh: LadderVehicle) => {
			if (!isVehicle || veh.vehicleId !== entityId) {
				this.removeVehicleTooltip(veh);
			}
		});

		this.downDeadGrayBoxVehicles.forEach((veh: LadderVehicle) => {
			this.removeVehicleTooltip(veh);
		});

		this.upDeadGrayBoxVehicles.forEach((veh: LadderVehicle) => {
			this.removeVehicleTooltip(veh);
		});
	};

	/**
	 * removes the stop tooltip
	 */
	private removeStopTooltip = (): void => {
		// clean up our click listeners
		this.removeStopClickListeners();

		const inboundStopLabel: HTMLElement = document.getElementById('inbound-stopLabel' + this.route.routeId);

		if (inboundStopLabel.innerHTML) {
			inboundStopLabel.innerHTML = '';
		}

		if (this.directionCount > 1) {
			const outboundStopLabel: HTMLElement = document.getElementById('outbound-stopLabel' + this.route.routeId);

			if (outboundStopLabel.innerHTML) {
				outboundStopLabel.innerHTML = '';
			}
		}
	};

	/**
	 * removes the vehicle tooltip
	 *
	 * @param vehicle - the vehicle
	 */
	private removeVehicleTooltip = (vehicle: LadderVehicle): void => {
		const vehicleDivElement: HTMLElement = document.getElementById('v_' + vehicle.vehicleId);

		if (vehicleDivElement) {
			vehicle.hoverFlag = false;

			const labelParent: any = vehicleDivElement.children[1];

			const toolTip: HTMLElement = labelParent.querySelector('.vehicle-map-labels');
			const labelSummary: HTMLElement = labelParent.querySelector('.label-summary');
			const labelDetailsSummary: HTMLElement = labelParent.querySelector('.label-details');

			if (labelSummary) {
				labelSummary.style.display = 'flex';
			}

			if (labelDetailsSummary) {
				labelDetailsSummary.style.display = 'none';
			}

			if (labelParent) {
				labelParent.style.zIndex = '2';
			}

			if (toolTip) {
				toolTip.style.position = '';
			}
		}
	};

	/**
	 * renders the vehicle tooltip in the appropriate position
	 *
	 * @param vehicle - the vehicle
	 */
	private positionVehicleTooltip = (vehicle: LadderVehicle): void => {
		if (!vehicle.hoverFlag) {
			const ladderColumnRect: DOMRect = document.getElementById('ladderColumn').getBoundingClientRect();
			const mainLadderContentRect: DOMRect = document.getElementById('mainLadderContent').getBoundingClientRect();

			const vehicleDivElement: HTMLElement = document.getElementById('v_' + vehicle.vehicleId);

			if (vehicleDivElement) {
				vehicle.hoverFlag = true;

				this.addVehicleClickListeners();

				const labelParent: any = vehicleDivElement.children[1];
				const labelSummary: HTMLElement = labelParent.querySelector('.label-summary');
				const labelDetails: HTMLElement = labelParent.querySelector('.label-details');
				const tooltip: HTMLElement = labelParent.querySelector('.vehicle-map-labels');

				if (labelSummary) {
					labelSummary.style.display = 'none';
				}

				if (labelDetails) {
					labelDetails.style.display = 'block';
				}

				if (tooltip) {
					const tooltipRect: DOMRect = tooltip.getBoundingClientRect();

					let tipX: number = 0;
					let tipY: number = 0;

					const tipBottom: number = tooltipRect.bottom + tooltipRect.height - 100;

					// if out on the bottom, align the bottom
					if (tipBottom > ladderColumnRect.bottom) {
						tooltip.style.position = 'absolute';

						tipY = -(tooltipRect.height * 0.75);

						tooltip.style.top = tipY + 'px';

						if (vehicle.stopDirection === StopDirection.inbound) {
							tipX = -tooltipRect.width;
							tooltip.style.left = tipX + 'px';
						}
					}

					// if the tooltip goes off the screen then show to the left instead. Note this causes a conflict and
					// quirky behaviour when labels are on - so just show to the right as normal (vehicle labels themselves
					// will cause extra horozontal space)
					if (!this.mapOptionsService.vehicleLabelsAreShowing() && tooltipRect.right > mainLadderContentRect.right) {
						tooltip.style.position = 'absolute';
						tooltip.style.right = '22px';
					}

					labelParent.style.zIndex = '9001';
				}
			}
		}
	};

	/**
	 * renders the stop tooltip in the appropriate position
	 *
	 * @param side - the side
	 * @param resetTopPosition - the reset top position
	 */
	private positionStopTooltip = (side: string, resetTopPosition: boolean): void => {
		const ladderColumnRect: DOMRect = document.getElementById('ladderColumn').getBoundingClientRect();
		const mainLadderContentRect: DOMRect = document.getElementById('mainLadderContent').getBoundingClientRect();

		const tooltip: HTMLElement = document.getElementById('stop-label');

		if (tooltip) {
			const tooltipRect: DOMRect = tooltip.getBoundingClientRect();

			if (tooltipRect) {
				const tipBottom: number = tooltipRect.bottom;

				tooltip.style.position = 'absolute';

				// go to right side if on the right (unles sthere isnt enough room)
				if (side === 'right' && tooltipRect.right < mainLadderContentRect.right) {
					tooltip.style.left = '15px';
				} else {
					// positions to the left when using 'right' style
					tooltip.style.right = '5px';
				}

				tooltip.style.top = '0px';

				if (!resetTopPosition) {
					if (tipBottom > ladderColumnRect.bottom) {
						const tipY: number = -tooltipRect.height;

						tooltip.style.top = tipY + 'px';
					}
				}

				tooltip.style.zIndex = '9001';
			}
		}
	};

	/**
	 * add all vehicle click listeners for the vehicle tooltips
	 */
	private addVehicleClickListeners = (): void => {
		if (this.vehicleTooltipVehicleId) {
			this.addVehicleIdListeners(this.vehicleTooltipVehicleId);
			this.addBlockIdClickListener(this.vehicleTooltipVehicleId);
			this.addVehicleRouteIdClickListener(this.vehicleTooltipVehicleId);
		}
	};

	/**
	 * remove all vehicle click listeners for the vehicle tooltips
	 */
	private removeVehicleClickListeners = (): void => {
		if (this.vehicleTooltipVehicleId) {
			this.removeVehicleIdClickListeners(this.vehicleTooltipVehicleId);
			this.removeBlockIdClickListener(this.vehicleTooltipVehicleId);
			this.removeVehicleRouteIdClickListener(this.vehicleTooltipVehicleId);
			this.vehicleTooltipVehicleId = null;
		}
	};

	/**
	 * add vehicle id click listeners for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			// setup click listeners for vehicle id clicks (we will typically have the 1 vehicle id but could be more
			// when we have linked vehicles)
			vehicle.vehicleIds.forEach((id: string) => {
				this.addVehicleIdClickListener(id);
			});
		}
	};

	/**
	 * add vehicle id click listener for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElementExtended = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.vehicleId = vehicleId;
			vehicleIdElement.addEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * remove vehicle id click listeners for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			vehicle.vehicleIds.forEach((id: string) => {
				this.removeVehicleIdClickListener(id);
			});
		}
	};

	/**
	 * remove vehicle id click listener  for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElement = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.removeEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * handle vehicle id click  for the vehicle tooltips - navigate to the vehicle details page
	 *
	 * @param evt - the vehicle id click event including the vehicle id
	 */
	private onVehicleIdClick = async (evt: EventExtended): Promise<void> => {
		const vehicleId: string = evt.currentTarget.vehicleId;

		await this.mapNavigationService.navigateToVehicleDetails(
			this.selectedAgency.authority_id,
			vehicleId,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * add block id click listener  for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addBlockIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const blockIdElement: HTMLElementExtended = document.getElementById('blockId' + vehicle.blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.blockId = vehicle.blockId;
			blockIdElement.addEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * remove block click listener for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeBlockIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const blockIdElement: HTMLElement = document.getElementById('blockId' + vehicle.blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.removeEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * handle block id click - navigate to the block details page
	 *
	 * @param evt - the block id click event including the block id
	 */
	private onBlockIdClick = async (evt: EventExtended): Promise<void> => {
		const blockId: string = evt.currentTarget.blockId;

		if (blockId && blockId !== '-' && blockId !== '—') {
			await this.mapNavigationService.navigateToBlockDetails(this.selectedAgency.authority_id, blockId);
		}
	};

	/**
	 * add route id click listener for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleRouteIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const routeIdElement: HTMLElementExtended = document.getElementById('vehicleRouteId' + vehicleId + '_' + vehicle.routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.vehicleRouteId = vehicle.routeId;
			routeIdElement.addEventListener('click', this.onVehicleRouteIdClick);
		}
	};

	/**
	 * remove route id click listener for the vehicle tooltips
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleRouteIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const routeIdElement: HTMLElementExtended = document.getElementById('vehicleRouteId' + vehicleId + '_' + vehicle.routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.vehicleRouteId = null;
			routeIdElement.removeEventListener('click', this.onVehicleRouteIdClick);
		}
	};

	/**
	 * handle vehicle route click for the vehicle tooltips - navigate to the route details page
	 *
	 * @param evt - the route click event including the route id
	 */
	private onVehicleRouteIdClick = async (evt: EventExtended): Promise<void> => {
		const routeId: string = evt.currentTarget.vehicleRouteId;

		await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, routeId);
	};

	/**
	 * add the click listeners to handle our click handlers for links in our stop tooltips
	 *
	 * @param stopCode - the stop code
	 * @param stopLabelRoutesDisplay - the list of routes for our stop
	 */
	private addStopClickListeners = (stopCode: string, stopLabelRoutesDisplay: StopLabelRoutesDisplay): void => {
		this.stopTooltipStopCode = stopCode;
		this.stopTooltipRouteIds = [];

		stopLabelRoutesDisplay.forEach((route: StopLabelRouteDisplay) => {
			this.stopTooltipRouteIds.push(route.routeId);
		});

		this.addStopCodeClickListener();
		this.addStopRouteClickListeners();
	};

	/**
	 * remove all click listeners for the stop tooltip
	 */
	private removeStopClickListeners = (): void => {
		this.removeStopCodeClickListener();
		this.removeStopRouteClickListeners();

		this.stopTooltipStopCode = null;
		this.stopTooltipRouteIds = [];
	};

	/**
	 * add stop code click listeners for the stop tooltip
	 */
	private addStopCodeClickListener = (): void => {
		if (this.stopTooltipStopCode) {
			const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + this.stopTooltipStopCode + 'Click');

			if (stopCodeElement) {
				stopCodeElement.stopCode = this.stopTooltipStopCode;
				stopCodeElement.addEventListener('click', this.onStopCodeClick);
			}
		}
	};

	/**
	 * remove stop code click listeners for the stop tooltip
	 */
	private removeStopCodeClickListener = (): void => {
		const stopCodeElement: HTMLElementExtended = document.getElementById('stopCode' + this.stopTooltipStopCode + 'Click');

		if (stopCodeElement) {
			stopCodeElement.stopCode = null;
			stopCodeElement.removeEventListener('click', this.onStopCodeClick);
		}
	};

	/**
	 * add route click listeners for the stop tooltip
	 */
	private addStopRouteClickListeners = (): void => {
		if (this.stopTooltipRouteIds) {
			this.stopTooltipRouteIds.forEach((routeId: string) => {
				this.addStopRouteIdClickListener(routeId);
			});
		}
	};

	/**
	 * add the route id click listener for the stop tooltip
	 * @param routeId - the route id
	 */
	private addStopRouteIdClickListener = (routeId: string): void => {
		const routeIdElement: HTMLElementExtended = document.getElementById('stopRouteId' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.stopRouteId = routeId;
			routeIdElement.addEventListener('click', this.onStopRouteIdClick);
		}
	};

	/**
	 * handle the stop code click for the stop tooltip - navigate to stop details
	 *
	 * @param evt - the stop code click event including the stop code
	 */
	private onStopCodeClick = async (evt: EventExtended): Promise<void> => {
		const stopCode: string = evt.currentTarget.stopCode;

		await this.mapNavigationService.navigateToStopDetails(this.selectedAgency.authority_id, stopCode);
	};

	/**
	 * remove route click listeners for the stop tooltip
	 */
	private removeStopRouteClickListeners = (): void => {
		if (this.stopTooltipRouteIds.length > 0) {
			this.stopTooltipRouteIds.forEach((routeId: string) => {
				this.removeStopRouteIdClickListener(routeId);
			});
		}
	};

	/**
	 * remove route id click listener for the stop tooltip
	 *
	 * @param routeId - the route id
	 */
	private removeStopRouteIdClickListener = (routeId: string): void => {
		const routeIdElement: HTMLElementExtended = document.getElementById('stopRouteId' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.stopRouteId = null;
			routeIdElement.removeEventListener('click', this.onStopRouteIdClick);
		}
	};

	/**
	 * handle the route id click for the stop tooltip
	 *
	 * @param evt - the click event
	 */
	private onStopRouteIdClick = async (evt: EventExtended): Promise<void> => {
		const routeId: string = evt.currentTarget.stopRouteId;

		await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, routeId);
	};
}
