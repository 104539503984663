import { NgModule } from '@angular/core';
import { HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { MaterialModule } from './material.module';
import { NotificationModule } from './notification.module';

import { NotAuthorizedComponent } from './components/authorized/not-authorized.component';
import { ConfirmModalComponent } from './components/confirm/confirm-modal.component';
import { DataTableComponent } from './components/data-table/data-table.component';
import { ImageViewComponent } from './components/image-view/image-view.component';
import { InputValidationComponent } from './components/input-validation/input-validation.component';
import { LabelComponent } from './components/label/field-label.component';
import { LoadingOverlayComponent } from './components/loading-overlay/loading-overlay.component';
import { MultiSelectComponent } from './components/multi-select/multi-select.component';
import { PaginationToolbarComponent } from './components/pagination-toolbar/pagination-toolbar.component';
import { PanelHeaderComponent } from './components/panel-header/panel-header.component';
import { RoutePillComponent } from './components/route-pill/route-pill.component';
import { searchFilterComponent } from './components/search-filter/search-filter.component';
import { SliderComponent } from './components/slider/slider.component';
import { TimeSelectorComponent } from './components/time-selector/time-selector.component';
import { TranslateBaseComponent } from './components/translate-base/translate-base.component';
import { ViewPanelComponent } from './components/view-panel/view-panel.component';

import { ClickOutsideDirective } from './directives/click-outside/click-outside.directive';
import { HttpInterceptorProviders } from './services/http/interceptors/index';
import { ImageApiService } from './components/image-view/service/image-api.service';
import { ImageDataService } from './components/image-view/service/image-data.service';
import { ColorUtilityService } from './services/color/color-utility.service';
import { ExporterService } from './services/export/exporter.service';
import { HttpService } from './services/http/http.service';
import { AuthenticationInterceptor } from './services/http/interceptors/authentication-interceptor';
import { ErrorInterceptor } from './services/http/interceptors/error-interceptor';
import { LoggingInterceptor } from './services/http/interceptors/logging-interceptor';
import { LoggerService } from './services/logging/logger.service';
import { NotificationService } from './services/notification/notification.service';
import { StorageService } from './services/storage/storage.service';
import { TokenService } from './services/token/token.service';
import { TranslationService } from './services/translation/translation.service';
import { uuid4 } from './services/uuid/uuid-service';
import { ValidatorService } from './services/validator/validator.service';
import { StateService } from './services/state/state-service';
import { BrowserDetectionService } from './services/browser/browser-detection.service';
import { DataStore } from './store/data-store';

import { FormatField } from './pipes/format-field/format-field.pipe';
import { HeadwaySecondsToHoursMinutesPipe, SecondsToHoursMinutesPipe } from './pipes/format-time/format-time.pipe';
import { JoinByIndividualFields, JoinPipe } from './pipes/join/join.pipe';
import { KeysPipe } from './pipes/keys/keys.pipe';
import { RidershipImagePipe } from './pipes/ridership-image/ridership-image.pipe';
import { SafeHtmlPipe } from './pipes/safe-html/safe-html.pipe';
import { TimeAgoPipe } from './pipes/time-ago/time-ago.pipe';
import { NvD3Component } from './components/nvd3/nvd3';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	declarations: [
		TimeAgoPipe,
		JoinPipe,
		SecondsToHoursMinutesPipe,
		HeadwaySecondsToHoursMinutesPipe,
		JoinByIndividualFields,
		FormatField,
		KeysPipe,
		RidershipImagePipe,
		SafeHtmlPipe,
		ClickOutsideDirective,
		PanelHeaderComponent,
		searchFilterComponent,
		DataTableComponent,
		NotAuthorizedComponent,
		ConfirmModalComponent,
		InputValidationComponent,
		LoadingOverlayComponent,
		ViewPanelComponent,
		LabelComponent,
		MultiSelectComponent,
		SliderComponent,
		TimeSelectorComponent,
		ImageViewComponent,
		RoutePillComponent,
		PaginationToolbarComponent,
		NvD3Component,
	],
	exports: [
		TimeAgoPipe,
		JoinPipe,
		SecondsToHoursMinutesPipe,
		HeadwaySecondsToHoursMinutesPipe,
		JoinByIndividualFields,
		FormatField,
		KeysPipe,
		RidershipImagePipe,
		SafeHtmlPipe,
		ClickOutsideDirective,
		PanelHeaderComponent,
		searchFilterComponent,
		DataTableComponent,
		NotAuthorizedComponent,
		ConfirmModalComponent,
		InputValidationComponent,
		LoadingOverlayComponent,
		ViewPanelComponent,
		LabelComponent,
		MultiSelectComponent,
		SliderComponent,
		TimeSelectorComponent,
		ImageViewComponent,
		RoutePillComponent,
		PaginationToolbarComponent,
		NvD3Component,
	],
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		NgxDatatableModule,
		TypeaheadModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
	],
	providers: [
		HttpInterceptorProviders,
		DataStore,
		uuid4,
		AuthenticationInterceptor,
		ErrorInterceptor,
		HttpService,
		LoggingInterceptor,
		LoggerService,
		NotificationService,
		StorageService,
		TokenService,
		TranslationService,
		ExporterService,
		ImageDataService,
		ImageApiService,
		ColorUtilityService,
		ValidatorService,
		StateService,
		BrowserDetectionService,
		TranslateBaseComponent,
		provideHttpClient(withInterceptorsFromDi()),
	],
})
export class UtilsModule {}
