/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapOptionsService } from '../../services/map-options.service';
import { MapEventsService } from '../../services/map-events.service';
import { MapBaseService } from '../../services/map-base.service';
import { MapLocationService } from '../../services/map-location.service';
import { AgenciesEventsService } from '../../../../support-features/agencies/services/agencies-events.service';
import { MapStopsService } from '../../services/map-stops.service';
import { MapRoutesService } from '../../services/map-routes.service';
import { MapStopsMarkerService } from '../../services/markers/map-stops-marker.service';
import { MapRoutesMarkerService } from '../../services/markers/map-routes-marker.service';
import { MapVehiclesMarkerService } from '../../services/markers/map-vehicles-marker.service';
import { MapPollingService } from '../../services/map-polling.service';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { CurrentUserUtilService } from '../../../../support-features/login/services/current-user/current-user-utils.service';
import { TranslationService } from '@cubicNx/libs/utils';

import {
	MapLocation,
	MapRoute,
	MapRoutesPathItem,
	MapRoutesPathItems,
	MapStop,
	MapUpdateEvent,
	MapUpdateType,
	ModeType,
} from '../../types/types';

import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';

import { AgencyConfig } from '../../../../config/types/types';

import 'mapbox-gl';
import 'mapbox-gl-leaflet';
import L from 'leaflet';

@Component({
	selector: 'map',
	templateUrl: './map.component.html',
	styleUrls: ['./map.component.scss'],
})
export class MapComponent extends TranslateBaseComponent implements OnInit, AfterViewInit, OnDestroy {
	@ViewChild('map')
	private mapContainer: ElementRef<HTMLElement>;

	public mapInitialized: boolean = false;
	public mapWidth: number = 0;
	public mapHeight: number = 0;

	private readonly creditsLeaflet: string = '<a href="https://leafletjs.com/">Leaflet</a>';
	private readonly creditsMapbox: string = '<a href="https://www.mapbox.com/about/maps/">Maps &copy; Mapbox</a>';
	private readonly detailsWidth: number = 445;
	private readonly fullNavBarWidth: number = 70;
	private readonly miniNavBarWidth: number = 35;
	private readonly headerOffset: number = 68;
	private readonly navbarOffset: number = 70;

	private agenciesSelectionChange$Subscription: Subscription = null;
	private mapUpdate$Subscription: Subscription = null;
	private mapZoomIn$Subscription: Subscription = null;
	private mapZoomOut$Subscription: Subscription = null;
	private selectedAgency: SelectedAgency = null;

	private map: L.Map = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private currentUserUtilService: CurrentUserUtilService,
		private mapOptionsService: MapOptionsService,
		private mapPollingService: MapPollingService,
		private mapEventsService: MapEventsService,
		private mapBaseService: MapBaseService,
		private mapLocationService: MapLocationService,
		private mapStopsService: MapStopsService,
		private mapRoutesService: MapRoutesService,
		private mapStopsMarkerService: MapStopsMarkerService,
		private mapVehiclesMarkerService: MapVehiclesMarkerService,
		private mapRoutesMarkerService: MapRoutesMarkerService,
		private loggerService: LoggerService,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * handle the resize of the map
	 */
	@HostListener('window:resize', ['$event'])
	public onResize(): void {
		this.determineMapSize();
	}

	/**
	 * handle the initialization of the map
	 */
	public async ngOnInit(): Promise<void> {
		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		this.determineMapSize();

		this.setSubscriptions();
	}

	/**
	 * set up various map initialization once the component has been rendered
	 *
	 * set up the main map instance
	 *
	 * setup up the attribution Control
	 *
	 * initialize our markers/icons on the map
	 *
	 * set the initial location
	 *
	 * start the map polling
	 */
	public ngAfterViewInit(): void {
		this.map = L.map(this.mapContainer.nativeElement, {
			maxZoom: this.config.getMaxZoomLevel(),
			minZoom: this.config.getMinZoomLevel(),
			zoomControl: false,
			scrollWheelZoom: true,
			preferCanvas: true,
		});

		this.mapBaseService.init(this.map);

		this.setupAttributionControl();
		this.setupListeners();

		this.mapRoutesMarkerService.init(this.map);
		this.mapStopsMarkerService.init(this.map, this.selectedAgency);
		this.mapVehiclesMarkerService.init(this.map, this.selectedAgency);

		this.mapLocationService.setLocation(this.mapLocationService.getLocation());

		// init routes and stops. Note: we don't init existing vehicles as they could be out of date if the data is old
		// vehicles will be picked up during poll mechanismn
		this.initRoutes();
		this.initStops();

		this.mapPollingService.initPolling();

		this.mapInitialized = true;

		this.changeDetectorRef.detectChanges();
	}

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * determine the map size from the available space
	 */
	private determineMapSize = (): void => {
		let mapHeight: number = window.innerHeight;
		let mapWidth: number = window.innerWidth;

		if (!this.mapOptionsService.getFullScreen()) {
			mapHeight -= this.headerOffset;
			mapWidth -= this.navbarOffset;
		}

		// something odd occurs when dragging in mapbox vector map if the size isn't a multiple of 5
		mapWidth = Math.ceil(mapWidth / 5) * 5;
		mapHeight = Math.ceil(mapHeight / 5) * 5;

		this.mapWidth = mapWidth;
		this.mapHeight = mapHeight;
	};

	/**
	 * set up subscriptions for the page
	 *
	 * agency selection change - handle the user changing agency
	 *
	 * zoom in - handle the zoom in event
	 *
	 * zoom out - handle the zoom out event
	 *
	 * map update - handle the map update event
	 */
	private setSubscriptions = (): void => {
		this.agenciesSelectionChange$Subscription = this.agenciesEventsService.agenciesSelectionChange.subscribe(() => {
			this.handleAgenciesSelectionChange();
		});

		this.mapZoomIn$Subscription = this.mapEventsService.zoomIn.subscribe(() => {
			this.handleZoomIn();
		});

		this.mapZoomOut$Subscription = this.mapEventsService.zoomOut.subscribe(() => {
			this.handleZoomOut();
		});

		this.mapUpdate$Subscription = this.mapEventsService.mapUpdate.subscribe((event) => {
			this.handleMapUpdate(event);
		});
	};

	/**
	 * set up the attrobution control with leaflet and mapbox credentials
	 */
	private setupAttributionControl = (): void => {
		this.map.attributionControl
			.setPrefix(this.creditsLeaflet) // override the default prefix which sets a Ukranian flag
			.addAttribution(this.creditsMapbox);
	};

	/**
	 * set up map listeners for zoom, dragging and moving
	 */
	private setupListeners = (): void => {
		this.map.on('zoomend', () => {
			this.handleZoomEnd();
		});

		this.map.on('dragend', () => {
			this.handleDragEnd();
		});

		this.map.on('moveend', () => {
			this.handleMoveEnd();
		});
	};

	/**
	 * handle the user zoom
	 */
	private handleZoomEnd = (): void => {
		if (this.map) {
			this.mapLocationService.setLocationZoom(this.map.getZoom());
		}

		this.refreshAllIcons();
	};

	/**
	 * handle the user drag
	 */
	private handleDragEnd = (): void => {
		// store latest map location when map is moved by dragging
		this.updateMapLocation();
	};

	/**
	 * handle the user moving the map
	 */
	private handleMoveEnd = (): void => {
		// sometimes when we zoom to a route the vehicles are all rendered in an offset position - reload
		if (this.mapInitialized) {
			this.mapVehiclesMarkerService.reloadVehicles();
		}
	};

	/**
	 * initialize routes that were persisted from the previous instance
	 */
	private initRoutes = (): void => {
		for (const routeId in this.mapRoutesService.getRoutes()) {
			this.addRoute(routeId);
		}
	};

	/**
	 * initialize stops that were persisted from the previous instance
	 */
	private initStops = (): void => {
		for (const stopCode in this.mapStopsService.getStops()) {
			this.addStop(stopCode);
		}
	};

	/**
	 * handle the agency change event
	 */
	private handleAgenciesSelectionChange = (): void => {
		this.selectedAgency = this.agenciesDataService.getSelectedAgency();

		this.mapStopsMarkerService.setAgencyChange(this.selectedAgency);
		this.mapVehiclesMarkerService.setAgencyChange(this.selectedAgency);
		this.mapRoutesMarkerService.setAgencyChange();

		// the location should have been reset by the map service
		this.mapLocationService.setLocation(this.mapLocationService.getLocation());
	};

	/**
	 * handle the zoom in event
	 */
	private handleZoomIn = (): void => {
		if (this.mapLocationService.getLocationZoom() === this.config.getMaxZoomLevel()) {
			return;
		}

		this.mapBaseService.zoomInOrOut(1);
	};

	/**
	 * handle the zoom out event
	 */
	private handleZoomOut = (): void => {
		if (this.mapLocationService.getLocationZoom() === this.config.getMinZoomLevel()) {
			return;
		}

		this.mapBaseService.zoomInOrOut(-1);
	};

	/**
	 * handle the map update event. This event is the main event handler for the map that reacts to changes in the map
	 * state and updated the map accordingly
	 *
	 * include try catch - shouldn't be needed but if an exception otherwise occurs it breaks the subscription and nothing works until
	 * the user refreshes
	 *
	 * @param updateEvent - the type that describes the type of update it is i.e addVehicle
	 */
	private handleMapUpdate = (updateEvent: MapUpdateEvent): void => {
		try {
			switch (updateEvent.mapUpdateType) {
				case MapUpdateType.modeRefresh:
					this.handleModeRefresh();
					break;
				case MapUpdateType.locationUpdate:
					this.handleLocationUpdate();
					break;
				case MapUpdateType.routesToLocateUpdate:
					this.handleLocationRouteUpdate();
					break;
				case MapUpdateType.activeEntityUpdate:
					this.handleActiveEntityUpdate();
					break;
				case MapUpdateType.trailedVehicleUpdate:
					this.handleVehicleTrailUpdate();
					break;
				case MapUpdateType.initViewRoutes:
					this.handleInitViewRoutes();
					break;
				case MapUpdateType.addRoute:
					this.handleAddRoute(updateEvent.params);
					break;
				case MapUpdateType.removeRoute:
					this.handleRemoveRoute(updateEvent.params);
					break;
				case MapUpdateType.clearRoutes:
					this.handleClearRoutes();
					break;
				case MapUpdateType.initViewStops:
					this.handleInitViewStops();
					break;
				case MapUpdateType.refreshDisplayPriority:
					this.handleRefreshDisplayPriority();
					break;
				case MapUpdateType.addStop:
					this.handleAddStop(updateEvent.params);
					break;
				case MapUpdateType.addStops:
					this.handleAddStops(updateEvent.params);
					break;
				case MapUpdateType.removeStop:
					this.handleRemoveStop(updateEvent.params);
					break;
				case MapUpdateType.removeStops:
					this.handleRemoveStops(updateEvent.params);
					break;
				case MapUpdateType.clearStops:
					this.handleClearStops();
					break;
				case MapUpdateType.reloadStops:
					this.handleReloadStops();
					break;
				case MapUpdateType.addVehicle:
					this.handleAddVehicle(updateEvent.params);
					break;
				case MapUpdateType.addVehicles:
					this.handleAddVehicles(updateEvent.params);
					break;
				case MapUpdateType.removeVehicle:
					this.handleRemoveVehicle(updateEvent.params);
					break;
				case MapUpdateType.updateVehicles:
					this.handleUpdateVehicles(updateEvent.params);
					break;
				case MapUpdateType.removeVehicles:
					this.handleRemoveVehicles(updateEvent.params);
					break;
				case MapUpdateType.clearVehicles:
					this.handleClearVehicles();
					break;
				case MapUpdateType.reloadVehicles:
					this.handleReloadVehicles();
					break;
				case MapUpdateType.refreshVehicleLabels:
					this.handleRefreshLabels();
					break;
				case MapUpdateType.vehicleLabelClusterRadiusUpdate:
					this.handleVehicleLabelClusterRadiusUpdate(updateEvent.params);
					break;
				case MapUpdateType.fullScreenChange:
					this.handleFullScreenRefresh();
					break;
			}
		} catch (exception) {
			this.loggerService.logError('Failed to process map update', exception);
		}
	};

	/**
	 * handle mode refresh (map ladder)
	 */
	private handleModeRefresh = (): void => {
		// covers edge case where we initialise in ladder mode - we need to make sure map is resized when we return to the map
		setTimeout(() => {
			this.determineMapSize();
			this.map.invalidateSize();
		});
	};

	/**
	 * handle the map location update and set the map to the correct location
	 */
	private handleLocationUpdate = (): void => {
		let location: MapLocation = this.mapLocationService.getLocation();

		if (location.offsetAdjust) {
			location = this.getAdjustedLocation(location);
		}

		this.setMapCenter(location);
	};

	/**
	 * adjust the location based on offsets of the map navigation view is open and allow for the size of the main navigation
	 * menu bar. The aim of the method is to set the location to appear as centered, even though some of the map is underneath
	 * the main map navigation view. This should be simplified so the map just takes the remaining space after so the center is
	 * just the center
	 *
	 * @param location - the location to set
	 * @returns - the adjusted location with the offset for other views being open
	 */
	private getAdjustedLocation = (location: MapLocation): MapLocation => {
		const detailsWidth: number = this.mapOptionsService.getNavViewOpen() ? this.detailsWidth : 0;
		const navBarWidth: number = this.currentUserUtilService.hasMiniNavBar() ? this.miniNavBarWidth : this.fullNavBarWidth;

		const modWidth: number = parseFloat(((detailsWidth + navBarWidth) / 2).toFixed(7));

		const latLng: [number, number] = [location.lat, location.lon];

		const targetPoint: L.Point = this.map.project(latLng, location.zoom).subtract([modWidth, 0]);
		const modlatLng: L.LatLng = this.map.unproject(targetPoint, location.zoom);

		return {
			lat: parseFloat(modlatLng.lat.toFixed(7)),
			lon: parseFloat(modlatLng.lng.toFixed(7)),
			zoom: location.zoom,
			offsetAdjust: false,
			clearTrackedVehicle: location.clearTrackedVehicle,
		};
	};

	/**
	 * set the map center
	 *
	 * disable animation to avoid a quirk where we pan to a new location on the map and all the existing vehicles
	 * which are moving end up being displayed in the new location and transitioning to the old location.
	 * It's simpler to turn off animation. It does mean that if a vehicle in view (after our pan) is moving
	 * then it might snap to the new location but this would be rare and is the lesser of 2 evils
	 *
	 * @param location - the location to set
	 */
	private setMapCenter = (location: MapLocation): void => {
		this.mapVehiclesMarkerService.disableAnimation();

		this.map.setView(new L.LatLng(location.lat, location.lon), location.zoom);
		this.map.invalidateSize();
	};

	/**
	 * handle location route update. Essentially causes the map to pan out so all selected routes are in view
	 *
	 * when in ladder mode - there are irratic results when trying to fit the map to the bounds
	 * of the route(s) and leaves the map zoomed out/in the wrong location. Just leave the user
	 * where they are when in ladder mode, they will return to the map where they left it which
	 * is subjectively better
	 */
	private handleLocationRouteUpdate = (): void => {
		if (this.mapOptionsService.getMode() === ModeType.map) {
			const routeIds: Array<string> = this.mapLocationService.getRoutesToLocate();

			this.adjustMapViewBasedOnPaths(routeIds);
		}
	};

	/**
	 * adjust the map to pan out so all selected routes are in view
	 * @param routeIds - the routes to view
	 */
	private adjustMapViewBasedOnPaths = (routeIds: Array<string>): void => {
		let paths: MapRoutesPathItems = [];

		routeIds.forEach((routeId: string) => {
			const route: MapRoute = this.mapRoutesService.getRoute(routeId);

			if (route) {
				paths = paths.concat(this.concatAllRoutePaths(route));
			}
		});

		const latlonvals: L.LatLng[] = [];

		paths.forEach((path: MapRoutesPathItem, index: number) => {
			latlonvals[index] = new L.LatLng(path.lat, path.lng);
		});

		if (latlonvals.length > 0) {
			this.map.fitBounds(L.latLngBounds(latlonvals), { paddingTopLeft: [this.detailsWidth, 0], animate: true });
			this.map.invalidateSize();

			// store latest map location
			// timeout needed so map has time to animate before new position stored
			setTimeout(() => {
				this.updateMapLocation();
			}, 100);
		}
	};

	/**
	 * combine all route paths in the route
	 * @param route - the route
	 * @returns the concatinated route paths
	 */
	private concatAllRoutePaths = (route: MapRoute): MapRoutesPathItems => {
		let paths: MapRoutesPathItems = [];

		for (const path in route.paths) {
			if (route.paths[path]) {
				paths = paths.concat(route.paths[path]);
			}
		}

		return paths;
	};

	/**
	 * update the map location so our map state stays in sync with where we have set the map
	 */
	private updateMapLocation = (): void => {
		const lat: number = this.map.getCenter().lat;
		const lon: number = this.map.getCenter().lng;

		if (lat !== 0 || lon !== 0) {
			const location: MapLocation = {
				lat,
				lon,
				zoom: this.map.getZoom(),
				offsetAdjust: false,
				clearTrackedVehicle: true,
			};

			this.mapLocationService.setLocation(location, false);
		}
	};

	/**
	 * handle an update to the active entity (i.e which vehicle/stop is highlighted)
	 *
	 * refresh all icons. Entitys (vehicles/stops) may no longer be the active entity or now could be. We also
	 * have to consider stops clusters may have the active entity as one of their included stops. It's easier
	 * to refresh everything, especially considering setting/unsetting the active entity is fairly infrequent
	 */
	private handleActiveEntityUpdate = (): void => {
		this.refreshAllIcons();
	};

	/**
	 * handle the vehicle trail update event
	 */
	private handleVehicleTrailUpdate = (): void => {
		this.updateVehicleTrail();
	};

	/**
	 * handle the initialize routes event
	 */
	private handleInitViewRoutes = (): void => {
		this.initRoutes();
	};

	/**
	 * handle the initialize add route event
	 * @param routeId - the route to add
	 */
	private handleAddRoute = (routeId: string): void => {
		this.addRoute(routeId);
	};

	/**
	 * handle the initialize remove route event
	 * @param routeId - the route to remove
	 */
	private handleRemoveRoute = (routeId: string): void => {
		this.removeRoute(routeId);
	};

	/**
	 * handle the clear routes event
	 */
	private handleClearRoutes = (): void => {
		this.clearRoutes();
	};

	/**
	 * handle the initialize view stops event
	 */
	private handleInitViewStops = (): void => {
		this.initStops();
	};

	/**
	 * handle the refresh display priority event (vehicles/stops on top)
	 *
	 * refresh all icons so they pick up the appropriate z-index (based on getDisplayPriority)
	 */
	private handleRefreshDisplayPriority = (): void => {
		this.refreshAllIcons();
	};

	/**
	 * handle the initialize add stop event
	 * @param stopCode - the stop to add
	 */
	private handleAddStop = (stopCode: string): void => {
		this.addStop(stopCode);
	};

	/**
	 * handle the add stops event
	 * @param stopCodes - the stops to add
	 */
	private handleAddStops = (stopCodes: string[]): void => {
		stopCodes.forEach((stopCode: string) => {
			this.addStop(stopCode);
		});
	};

	/**
	 * handle the remove stop event
	 * @param stopId - the stop to remove
	 */
	private handleRemoveStop = (stopId: string): void => {
		this.removeStop(stopId);
	};

	/**
	 * handle the remove stops event
	 * @param stopCodes - the stops to remove
	 */
	private handleRemoveStops = (stopCodes: string[]): void => {
		stopCodes.forEach((stopCode: string) => {
			this.removeStop(stopCode);
		});
	};

	/**
	 * handle the clear stops event
	 */
	private handleClearStops = (): void => {
		this.clearStops();
	};

	/**
	 * handle the reload stops event
	 */
	private handleReloadStops = (): void => {
		this.reloadStops();
	};

	/**
	 * handle the add vehicle event
	 * @param vehicleId - the vehicle to add
	 */
	private handleAddVehicle = (vehicleId: string): void => {
		this.addVehicle(vehicleId);
	};

	/**
	 * handle the add vehicles event
	 * @param vehicleIds - the vehicles to add
	 */
	private handleAddVehicles = (vehicleIds: string[]): void => {
		this.addVehicles(vehicleIds);
	};

	/**
	 * handle the remove vehicle event
	 * @param vehicleId - the vehicle to remove
	 */
	private handleRemoveVehicle = (vehicleId: string): void => {
		this.removeVehicle(vehicleId);
	};

	/**
	 * handle the update vehicles event
	 * @param vehicleIds - the vehicles to update
	 */
	private handleUpdateVehicles = (vehicleIds: string[]): void => {
		this.updateVehicles(vehicleIds);
	};

	/**
	 * handle the remove vehicles event
	 * @param vehicleIds - the vehicles to remove
	 */
	private handleRemoveVehicles = (vehicleIds: string[]): void => {
		this.removeVehicles(vehicleIds);
	};

	/**
	 * handle clear vehicles event
	 */
	private handleClearVehicles = (): void => {
		this.clearVehicles();
	};

	/**
	 * handle reload vehicles event
	 */
	private handleReloadVehicles = (): void => {
		this.reloadVehicles();
	};

	/**
	 * handle refresh vehicle labels event
	 */
	private handleRefreshLabels = (): void => {
		this.refreshVehicleLabels();
	};

	/**
	 * handle vehicle label cluster radius update event
	 *
	 * @param reloadVehicleLabels - flag to indicate if vehicle labels need to be reloaded
	 */
	private handleVehicleLabelClusterRadiusUpdate = (reloadVehicleLabels: boolean): void => {
		this.refreshVehiclesLabelLayer(reloadVehicleLabels);
	};

	/**
	 * handle full screen refresh event
	 */
	private handleFullScreenRefresh = (): void => {
		this.determineMapSize();
	};

	/**
	 * add the route to the map using our marker/icon service
	 * @param routeId - the route to add
	 */
	private addRoute = (routeId: string): void => {
		this.mapRoutesMarkerService.addRoute(routeId);
	};

	/**
	 * remove the route from the map using our marker/icon service
	 * @param routeId - the route to remove
	 */
	private removeRoute = (routeId: string): void => {
		this.mapRoutesMarkerService.removeRoute(routeId);
	};

	/**
	 * clear all routes from the map using our marker/icon service
	 */
	private clearRoutes = (): void => {
		this.mapRoutesMarkerService.clearRoutes();
	};

	/**
	 * add the stop to the map using our marker/icon service
	 * @param stopCode - the stop to add
	 */
	private addStop = (stopCode: string): void => {
		const stop: MapStop = this.mapStopsService.getStop(stopCode);

		// _ar stops are virtual stops for the ladder only
		if (!stop.hidden && !stop.stopCode.includes('_ar')) {
			this.mapStopsMarkerService.addStop(stop.stopCode);
		}
	};

	/**
	 * remove the stop from the map using our marker/icon service
	 * @param stopCode - the stop to remove
	 */
	private removeStop = (stopCode: string): void => {
		this.mapStopsMarkerService.removeStop(stopCode);
	};

	/**
	 * clear all stops from the map using our marker/icon service
	 */
	private clearStops = (): void => {
		this.mapStopsMarkerService.clearStops();
	};

	/**
	 * reload all stops from the map using our marker/icon service
	 */
	private reloadStops = (): void => {
		this.mapStopsMarkerService.reloadStops();
	};

	/**
	 * add the vehicle to the map using our marker/icon service
	 * @param vehicleId - the vehicle to add
	 */
	private addVehicle = (vehicleId: string): void => {
		this.mapVehiclesMarkerService.addVehicle(vehicleId, ModeType.map);
	};

	/**
	 * add vehicles to the map using our marker/icon service
	 * @param vehicleIds - the vehicles to add
	 */
	private addVehicles = (vehicleIds: string[]): void => {
		this.mapVehiclesMarkerService.addVehicles(vehicleIds);
	};

	/**
	 * remove vehicle from the map using our marker/icon service
	 * @param vehicleId - the vehicle to remove
	 */
	private removeVehicle = (vehicleId: string): void => {
		this.mapVehiclesMarkerService.removeVehicle(vehicleId);
	};

	/**
	 * remove vehicles from the map using our marker/icon service
	 * @param vehicleIds - the vehicles to remove
	 */
	private removeVehicles = (vehicleIds: string[]): void => {
		this.mapVehiclesMarkerService.removeVehicles(vehicleIds);
	};

	/**
	 * update vehicles from the map using our marker/icon service
	 * @param vehicleIds - the vehicles to update
	 */
	private updateVehicles = (vehicleIds: string[]): void => {
		this.mapVehiclesMarkerService.updateVehicles(vehicleIds);
	};

	/**
	 * clear all vehicles from the map using our marker/icon service
	 */
	private clearVehicles = (): void => {
		this.mapVehiclesMarkerService.clearVehicles();
	};

	/**
	 * reload all vehicles from the map using our marker/icon service
	 */
	private reloadVehicles = (): void => {
		this.mapVehiclesMarkerService.reloadVehicles();
	};

	/**
	 * refresh vehicles labels within the map using our marker/icon service
	 */
	private refreshVehicleLabels = (): void => {
		this.mapVehiclesMarkerService.refreshVehicleLabels();
	};

	/**
	 * refresh vehicles labels layer within the map using our marker/icon service
	 * @param reloadVehicleLabels - set true when we need to reload the vehicle labels
	 */
	private refreshVehiclesLabelLayer = (reloadVehicleLabels: boolean): void => {
		this.mapVehiclesMarkerService.refreshVehiclesLabelLayer(reloadVehicleLabels);
	};

	/**
	 * update the vehicle trail within the map using our marker/icon service
	 */
	private updateVehicleTrail = (): void => {
		this.mapVehiclesMarkerService.updateVehicleTrail();
	};

	/**
	 * refresh all icons within the map using our marker/icon service
	 */
	private refreshAllIcons = (): void => {
		this.mapStopsMarkerService.refreshStopIcons();
		this.mapVehiclesMarkerService.refreshVehicleIcons();
	};

	/**
	 * unsubscribe from the subscriptions
	 */
	private unsubscribe = (): void => {
		this.agenciesSelectionChange$Subscription?.unsubscribe();
		this.mapZoomIn$Subscription?.unsubscribe();
		this.mapZoomOut$Subscription?.unsubscribe();
		this.mapUpdate$Subscription?.unsubscribe();
	};
}
