/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';

import { MapLocation } from '../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapLocationService {
	constructor(private mapStateService: MapStateService) {}

	/**
	 * set the current zoom level in the map state
	 *
	 * @param zoom - the zoom level to set
	 */
	public setLocationZoom = (zoom: number): void => {
		this.mapStateService.setLocationZoom(zoom);
	};

	/**
	 * get the current zoom level from the map state
	 *
	 * @returns the current zoom level
	 */
	public getLocationZoom = (): number => {
		return this.mapStateService.getLocationZoom();
	};

	/**
	 * set the current location in the map state
	 *
	 * @param location - the location to set
	 * @param refresh - when true we actually update the map to the location, when false it is assumed we are already
	 * at the location and just updating the map state
	 */
	public setLocation = (location: MapLocation, refresh: boolean = true): void => {
		this.mapStateService.setLocation(location, refresh);
	};

	/**
	 * get the current location from the map state
	 *
	 * @returns the current location
	 */
	public getLocation = (): MapLocation => {
		return this.mapStateService.getLocation();
	};

	/**
	 * set a list of routes to locate
	 *
	 * @param routesIds - the ids of the routes to locate on the map
	 */
	public setRoutesToLocate = (routesIds: Array<string>): void => {
		this.mapStateService.setRoutesToLocate(routesIds);
	};

	/**
	 * get the current set of routes that are set within the map state
	 *
	 * @returns the list of routes ids
	 */
	public getRoutesToLocate = (): Array<string> => {
		return this.mapStateService.getRoutesToLocate();
	};
}
