/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { MapLocationService } from '../../../services/map-location.service';
import { MapNavigationService } from '../../../services/map-navigation.service';
import { RoutesDataService } from '../../../../../support-features/routes/services/routes-data.service';
import { VehiclesDataService } from '../../../../../support-features/vehicles/services/vehicles-data.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapRoutesService } from '../../../services/map-routes.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { MapVehiclesService } from '../../../services/map-vehicles.service';
import { StateService } from '@cubicNx/libs/utils';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { TranslationService } from '@cubicNx/libs/utils';
import { BlocksDataService } from '../../../../../support-features/blocks/services/block-data.service';

import { MapModeType, VehicleDetailsActiveTab } from '../../../types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';

import { VehicleAdherenceDisplay, VehicleAdherenceType, VehicleStatusDisplay } from '../../../../../support-features/vehicles/types/types';
import { RouteActiveBlockVehicles, BlockVehicle, BlockVehicles } from '../../../../../support-features/vehicles/types/api-types';
import { RouteBlock, RouteBlockDetails, RouteBlocks, RouteExtended } from '../../../../../support-features/routes/types/api-types';

import {
	ActiveBlock,
	ActiveBlocks,
	RouteDetailVehicleList,
	RouteDetailVehicleListItem,
} from '../../../../../support-features/routes/types/types';

import {
	ColumnType,
	Columns,
	CssClassType,
	CssSubClassPosition,
	PageRequestInfo,
	SelectedRowData,
	SortDirection,
	SortRequestInfo,
} from '@cubicNx/libs/utils';

@Component({
	selector: 'route-details',
	templateUrl: './route-details.component.html',
	styleUrls: ['./route-details.component.scss'],
})
export class RouteDetailsComponent extends TranslateBaseComponent implements OnInit, OnChanges, OnDestroy {
	@Input() routeId: string = null;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	public readonly emptyListItemPlaceHolder: string = '--';

	public mapModeType: typeof MapModeType = MapModeType;

	public routePillDisplay: RoutePillData = null;
	public routeActiveVehicles: BlockVehicles = [];
	public toggleRouteDisabled: boolean = false;
	public blocksActiveListDisplay: string = null;
	public blockAssignmentPercentage: number = 0;
	public blocksWithIssuesPercentage: number = 0;
	public blocksAssignedList: Array<string> = [];
	public blocksActiveList: Array<string> = [];
	public blocksWithIssues: Array<string> = [];
	public route: RouteExtended = null;

	public routeLoaded: boolean = false;
	public listName: string = 'route-vehicles-list';
	public columns: Columns = [];
	public routeDetailVehicleList: RouteDetailVehicleList = [];
	public listLoadingIndicator: boolean = true;
	public sortInfo: SortRequestInfo = { sort: 'blockId', sortDir: SortDirection.asc };

	private readonly offRoute: string = 'OFF_ROUTE';
	private readonly atDepot: string = 'at_depot';
	private readonly driverOnBreak: string = 'driver on break';
	private readonly deadheading: string = 'deadheading';

	private readonly blockColumnName: string = 'blockId';
	private readonly vehicleColumnName: string = 'vehicleId';
	private readonly tripColumnName: string = 'tripId';
	private readonly statusColumnName: string = 'vehicleStatus';
	private readonly adherenceColumnName: string = 'vehicleAdherence';

	private authorityId: string = null;
	private agencyId: string = null;
	private agencyTimezone: string = null;

	private navigateRefresh$Subscription: Subscription = null;
	private refreshPoll$Subscription: Subscription = null;

	private initialized: boolean = false;
	private lastRoutePollSuccess: boolean = false;

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['sortInfo'];

	private readonly emptyVehicleAdherence: VehicleAdherenceDisplay = {
		time: this.emptyListItemPlaceHolder,
		class: '',
	};

	private readonly emptyVehicleStatus: VehicleStatusDisplay = {
		text: this.emptyListItemPlaceHolder,
		icon: '',
	};

	constructor(
		private mapRoutesService: MapRoutesService,
		private mapLocationService: MapLocationService,
		private mapEventsService: MapEventsService,
		private routesDataService: RoutesDataService,
		private blocksDataService: BlocksDataService,
		private stateService: StateService,
		private agenciesDataService: AgenciesDataService,
		private vehiclesDataService: VehiclesDataService,
		private mapHistoryService: MapHistoryService,
		private mapNavigationService: MapNavigationService,
		private mapVehiclesService: MapVehiclesService,
		private colorUtilityService: ColorUtilityService,
		private mapOptionsService: MapOptionsService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the route detaiils page
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.loadCache();

		this.buildListColumns();

		this.init();
	}

	/**
	 * handle any changes to the component input values. If a new route id is set - we need
	 * to reinitialize for that route
	 *
	 * @param changes - the details of the change of our inputs
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.routeId && !changes.routeId.firstChange) {
			if (changes.routeId.previousValue !== changes.routeId.currentValue) {
				this.init();
			}
		}
	}

	/**
	 * return to the users previous history location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * determine if the route is displayed on the map
	 * @returns true if the route is displayed on the map
	 */
	public routeDisplayed = (): boolean => {
		return this.mapRoutesService.routeDisplayed(this.route.route_id);
	};

	/**
	 * zoom to the route on the map. If the route is displayed already, zoom to it, otherwise
	 * the route will be toggled on (and the route zoomed to as part of that process)
	 */
	public zoomTo = (): void => {
		if (!this.routeDisplayed()) {
			// toggle on the stop - it will zoom to location once it's added
			this.toggleRenderRoute();
		} else {
			this.zoomToRoute();
		}
	};

	/**
	 * handle the user toggle of the route
	 */
	public toggleRenderRoute = async (): Promise<void> => {
		if (this.routeDisplayed()) {
			this.mapRoutesService.removeRoute(this.route.route_id);
		} else {
			await this.mapRoutesService.addRoute(this.route);
		}
	};

	/**
	 * get the block assignments metric bar width
	 * @returns the block assignments metric bar width
	 */
	public getBlockAssignmentPercentageWidth = (): any => {
		return { width: this.blockAssignmentPercentage + '%' };
	};

	/**
	 * get the block issues percentage width
	 * @returns the block issues percentage width
	 */
	public getBlockWithIssuesPercentageWidth = (): any => {
		return { width: this.blocksWithIssuesPercentage + '%' };
	};

	/**
	 * handle the selection of a row in the vehicles list table and navigate to that vehicle
	 * @param selectedRow - the row selected by the user
	 */
	public onSelect = async (selectedRow: SelectedRowData): Promise<void> => {
		const selectedRoute: RouteDetailVehicleListItem = selectedRow.row;

		if (selectedRoute.vehicleId !== this.emptyListItemPlaceHolder) {
			await this.mapNavigationService.navigateToVehicleDetails(
				this.authorityId,
				selectedRoute.vehicleId,
				VehicleDetailsActiveTab.summary
			);
		}
	};

	/**
	 * handle the sort request triggered from our datatable
	 * @param sortInfo - the details about the sort (sort field/direction)
	 */
	public handleSortRequest = async (sortInfo: PageRequestInfo): Promise<void> => {
		this.sortInfo.sort = sortInfo.sort;
		this.sortInfo.sortDir = sortInfo.sortDir;

		this.cacheSortInfo();

		this.routeDetailVehicleList = this.sortList(this.routeDetailVehicleList);
	};

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param routeId - the route for the target block
	 * @returns the route pill data object used to populate our route pill badges
	 */
	private determineRoutePillData = (): RoutePillData => {
		return {
			routeShortName: this.route.route_short_name,
			routeLongName: this.route.route_long_name,
			routeId: this.route.route_id,
			routeColor: this.colorUtilityService.getColor(this.route.route_color),
			routeTextColor: this.colorUtilityService.getColor(this.route.route_text_color),
		};
	};

	/**
	 * main entry point for the vehicles list sort
	 *
	 * @param routeDetailVehicleList - vehicles list to sort
	 * @returns the sorted vehicles list
	 */
	private sortList = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		switch (this.sortInfo.sort) {
			case this.blockColumnName: {
				routeDetailVehicleList = this.sortByBlock(routeDetailVehicleList);
				break;
			}
			case this.vehicleColumnName: {
				routeDetailVehicleList = this.sortByVehicle(routeDetailVehicleList);
				break;
			}
			case this.tripColumnName: {
				routeDetailVehicleList = this.sortByTrip(routeDetailVehicleList);
				break;
			}
			case this.statusColumnName: {
				routeDetailVehicleList = this.sortByStatus(routeDetailVehicleList);
				break;
			}
			case this.adherenceColumnName: {
				routeDetailVehicleList = this.sortByAdherence(routeDetailVehicleList);
				break;
			}
		}

		if (this.sortInfo.sortDir === SortDirection.desc) {
			routeDetailVehicleList = routeDetailVehicleList.reverse();
		}

		return routeDetailVehicleList;
	};

	/**
	 * handle any initialization actions.
	 *
	 * load the route and associated vehicle data
	 */
	private init = async (): Promise<void> => {
		this.enableRefresh.emit(false);

		this.resetRouteDetails();

		// perform in init so we pick up potential new agency from ngOnChanges i.e when a route for a different agency is selected
		this.loadAgencyDetails();

		await this.loadRoute();

		if (this.route) {
			// show loading during init only - not during poll of data
			this.listLoadingIndicator = true;

			await this.loadRouteBlockAndVehicleData();

			this.listLoadingIndicator = false;

			if (this.routeDisplayed()) {
				this.zoomToRoute();
			}

			this.initialized = true;
		} else {
			this.mapNavigationService.navigateToRouteList(this.authorityId);
		}

		this.enableRefresh.emit(true);
	};

	/**
	 * load translations for the page
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_MAP.MAP_ADHERENCE',
			'T_MAP.MAP_BLOCK',
			'T_MAP.MAP_VEHICLE',
			'T_MAP.MAP_TRIP',
			'T_MAP.MAP_STATUS',
			'T_MAP.MAP_ADHERENCE',
			'T_MAP.MAP_DEPOT',
			'T_MAP.MAP_UNPREDICTABLE',
			'T_MAP.MAP_VERY_LATE',
			'T_MAP.MAP_LATE',
			'T_MAP.MAP_EARLY',
			'T_MAP.MAP_VERY_EARLY',
			'T_MAP.MAP_ON_ROUTE',
			'T_MAP.MAP_DEADHEADING',
			'T_MAP.MAP_DRIVER_ON_BREAK',
		]);
	};

	/**
	 * load the cache for the page (sort info for the trips list)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.sortInfo) {
			this.sortInfo = cacheContainer['sortInfo'];
		}
	};

	/**
	 * cache for sort details selected by the user
	 */
	private cacheSortInfo = (): void => {
		this.listCacheContainer['sortInfo'] = this.sortInfo;

		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * sort the list by adherence (client side sorting)
	 *
	 * @param routeDetailVehicleList - the vehicle list to sort
	 * @returns the vehicle list sorted by adherence
	 */
	private sortByAdherence = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		return routeDetailVehicleList.sort((aItem: RouteDetailVehicleListItem, bItem: RouteDetailVehicleListItem) => {
			// Convert the time format into a number for comparison purposes which is needed
			// to be able to sort this column - so +3:55 => +3.55
			//
			// Note if there is no adherence value, assume a large number so the sort will group them together

			let a: number = 99.99;
			let b: number = 99.99;

			if (aItem.vehicleAdherence.value !== '--') {
				a = +aItem.vehicleAdherence.value.replace(/[:]/g, '.');
			}

			if (bItem.vehicleAdherence.value !== '--') {
				b = +bItem.vehicleAdherence.value.replace(/[:]/g, '.');
			}

			return a < b ? -1 : a > b ? 1 : 0;
		});
	};

	/**
	 * sort the list by status (client side sorting)
	 *
	 * @param routeDetailVehicleList - the vehicle list to sort
	 * @returns the vehicle list sorted by status
	 */
	private sortByStatus = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		return routeDetailVehicleList.sort((a: RouteDetailVehicleListItem, b: RouteDetailVehicleListItem) => {
			return a.vehicleStatus.value.localeCompare(b.vehicleStatus.value);
		});
	};

	/**
	 * sort the list by status (client side sorting)
	 *
	 * @param routeDetailVehicleList - the vehicle list to sort
	 * @returns the vehicle list sorted by status
	 */
	private sortByTrip = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		return routeDetailVehicleList.sort((aItem: RouteDetailVehicleListItem, bItem: RouteDetailVehicleListItem) => {
			// Replace all non - numeric characters with empty character so that the numeric sort can give more sensible results
			const a: number = this.columnValueToNumber(aItem.tripId);
			const b: number = this.columnValueToNumber(bItem.tripId);

			return a < b ? -1 : a > b ? 1 : 0;
		});
	};

	/**
	 * sort the list by vehicle id (client side sorting)
	 *
	 * @param routeDetailVehicleList - the vehicle list to sort
	 * @returns the vehicle list sorted by vehicle id
	 */
	private sortByVehicle = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		return routeDetailVehicleList.sort((aItem: RouteDetailVehicleListItem, bItem: RouteDetailVehicleListItem) => {
			// Replace all non - numeric characters with empty character so that the numeric sort can give more sensible results
			const a: number = this.columnValueToNumber(aItem.vehicleId);
			const b: number = this.columnValueToNumber(bItem.vehicleId);

			return a < b ? -1 : a > b ? 1 : 0;
		});
	};

	/**
	 * sort the list by block id (client side sorting)
	 *
	 * @param routeDetailVehicleList - the vehicle list to sort
	 * @returns the vehicle list sorted by block id
	 */
	private sortByBlock = (routeDetailVehicleList: RouteDetailVehicleList): RouteDetailVehicleList => {
		return routeDetailVehicleList.sort((aItem: RouteDetailVehicleListItem, bItem: RouteDetailVehicleListItem) => {
			// Replace all non - numeric characters with empty character so that the numeric sort can give more sensible results
			const a: number = this.columnValueToNumber(aItem.blockId);
			const b: number = this.columnValueToNumber(bItem.blockId);

			return a < b ? -1 : a > b ? 1 : 0;
		});
	};

	/**
	 * convert the string value to a number type
	 * @param value - the string value
	 * @returns the converted number
	 */
	private columnValueToNumber = (value: string): number => {
		// Remove any non-numeric characters from the value
		return +value.replace(/[^0-9]/g, '');
	};

	/**
	 * build the vehicles list columns for our data table
	 */
	private buildListColumns = (): void => {
		// Build the column list for the underlying datatable
		this.columns = [
			{
				name: this.blockColumnName,
				displayName: this.translations['T_MAP.MAP_BLOCK'],
				columnType: ColumnType.text,
				width: 68,
			},
			{
				name: this.vehicleColumnName,
				displayName: this.translations['T_MAP.MAP_VEHICLE'],
				columnType: ColumnType.text,
				width: 63,
			},
			{
				name: this.tripColumnName,
				displayName: this.translations['T_MAP.MAP_TRIP'],
				columnType: ColumnType.text,
				width: 122,
			},
			{
				name: this.statusColumnName,
				displayName: this.translations['T_MAP.MAP_STATUS'],
				columnType: ColumnType.cssClass,
				width: 72,
			},
			{
				name: this.adherenceColumnName,
				displayName: this.translations['T_MAP.MAP_ADHERENCE'],
				columnType: ColumnType.cssClass,
				width: 77,
			},
		];
	};

	/**
	 * load the route from our data service layer
	 */
	private loadRoute = async (): Promise<void> => {
		const result: ResultContent = await this.routesDataService.getRoute(this.authorityId, this.agencyId, this.routeId);

		if (result.success) {
			this.route = result.resultData;
			this.routePillDisplay = this.determineRoutePillData();
			this.routeLoaded = true;
		}

		this.lastRoutePollSuccess = result.success;
	};

	/**
	 * load route block and vehicle info
	 */
	private loadRouteBlockAndVehicleData = async (): Promise<void> => {
		this.routeActiveVehicles = [];

		if (this.route.agency) {
			let activeBlocks: ActiveBlocks = null;

			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				activeBlocks = await this.getReplayActiveBlockData();
			} else {
				activeBlocks = await this.getActiveBlockData();
			}

			const formattedBlockIds: string = this.determineFormattedBlockIds(activeBlocks);

			const response: ResultContent = await this.vehiclesDataService.getRouteVehiclesActiveBlocks(this.route, formattedBlockIds);

			if (response.success) {
				const routeVehicles: BlockVehicles = response.resultData;

				routeVehicles.forEach((vehicleCurrentState: BlockVehicle) => {
					if (vehicleCurrentState.veh_state && vehicleCurrentState.veh_state !== 'deassigned') {
						if (vehicleCurrentState.route.route_id === this.route.route_id || vehicleCurrentState.route.route_id === null) {
							this.routeActiveVehicles.push(vehicleCurrentState);
						}
					}
				});

				this.determineBlockTotals(activeBlocks);

				this.buildVehicleList();
			}
		}
	};

	/**
	 * get active block data for the route
	 * @returns active block data
	 */
	private getActiveBlockData = async (): Promise<ActiveBlocks> => {
		const tasks: any = [];

		let blockList: RouteBlockDetails = null;
		let blockListInProgress: RouteBlocks = null;
		let vehicleActiveBlocks: RouteActiveBlockVehicles = null;

		tasks.push(
			this.blocksDataService
				.getCurrentBlocksSingleRouteDetails(
					this.authorityId,
					this.route.agency.agency_id,
					this.agencyTimezone,
					'both',
					this.route.route_id,
					24
				)
				.then((response: ResultContent) => {
					if (response.success) {
						blockList = response.resultData;
					}
				})
		);

		tasks.push(
			this.blocksDataService
				.getCurrentBlocksSingleRoute(
					this.authorityId,
					this.route.agency.agency_id,
					this.agencyTimezone,
					'both',
					this.route.route_id,
					0,
					undefined,
					'in-progress-only'
				)
				.then((response: ResultContent) => {
					if (response.success) {
						blockListInProgress = response.resultData;
					}
				})
		);

		tasks.push(
			this.vehiclesDataService
				.getRouteActiveBlockVehicles(this.route.route_id, this.route.agency.authority_id, this.route.agency.agency_id)
				.then((responseData: ResultContent) => {
					if (responseData.success) {
						vehicleActiveBlocks = responseData.resultData;
					}
				})
		);

		await Promise.all(tasks);

		const activeBlocks: ActiveBlocks = this.determineBlockList(blockList, blockListInProgress, vehicleActiveBlocks);

		return activeBlocks;
	};

	/**
	 * get active block data for the route when in replay mode
	 *
	 * in ReplayMode, we currently do not keep track of any schedule information / do not have access to historical data.
	 * For now, we will assume all vehicles / blocks returned from the API should be active and in progress. Once the historical
	 * data is available we will remove this method and above check for replay mode.
	 *
	 * @returns the replay active block data
	 */
	private getReplayActiveBlockData = async (): Promise<ActiveBlocks> => {
		let vehicleActiveBlocks: RouteActiveBlockVehicles = null;

		const response: ResultContent = await this.vehiclesDataService.getRouteActiveBlockVehicles(
			this.route.route_id,
			this.route.agency.authority_id,
			this.route.agency.agency_id
		);

		if (response.success) {
			vehicleActiveBlocks = response.resultData;
		}

		const activeBlocks: ActiveBlocks = [];

		for (const blockId in vehicleActiveBlocks?.activeBlocks) {
			activeBlocks.push({
				blockId,
				inProgress: true,
			});
		}

		return activeBlocks;
	};

	/**
	 * Combines the various block requests to build a list of blocks to filter when we request our list of vehicles.
	 * This means we ultimately request vehicles on blocks that are from blocks not in progress that are not included in our
	 * blocks assigned/issues counts.  It is unclear if this in intended functionality
	 *
	 * @param blockList - active blocks for the route
	 * @param blockListInProgress - blocks currently in progress for the route
	 * @param vehicleActiveBlocks - vehicles (on blocks) for the route
	 * @returns the block list later used to request vehicles
	 */
	private determineBlockList = (
		blockList: RouteBlockDetails,
		blockListInProgress: RouteBlocks,
		vehicleActiveBlocks: RouteActiveBlockVehicles
	): ActiveBlocks => {
		const activeBlocks: ActiveBlocks = [];

		if (blockList && blockListInProgress && vehicleActiveBlocks) {
			// set any blocks that in progress accordingly
			blockListInProgress.forEach((blockInProgress: RouteBlock) => {
				activeBlocks.push({
					blockId: blockInProgress.block_id,
					inProgress: true,
				});
			});

			// use for/in for object iteration
			for (const blockId in blockList.today?.activeBlocks) {
				// add any blocks not already in our list
				if (!activeBlocks.some((activeBlock: ActiveBlock) => activeBlock.blockId === blockId)) {
					activeBlocks.push({
						blockId,
						inProgress: false,
					});
				}
			}

			for (const blockId in vehicleActiveBlocks?.activeBlocks) {
				// add any blocks not already in our list
				if (!activeBlocks.some((activeBlock: ActiveBlock) => activeBlock.blockId === blockId)) {
					activeBlocks.push({
						blockId,
						inProgress: false,
					});
				}
			}
		}

		return activeBlocks;
	};

	/**
	 * build a (sorted) formatted blocks list separated by commas for the data request
	 *
	 * @param activeBlocks - the active blocks list
	 * @returns a text string containing sorted block ids, comma separated
	 */
	private determineFormattedBlockIds = (activeBlocks: ActiveBlocks): string => {
		let formattedBlockIds: string = '';

		const sortedActiveBlocks: ActiveBlocks = activeBlocks.sort((a, b) => (a.blockId > b.blockId ? 1 : b.blockId > a.blockId ? -1 : 0));

		sortedActiveBlocks.forEach((activeBlock: ActiveBlock) => {
			formattedBlockIds += activeBlock.blockId + ',';
		});

		if (formattedBlockIds.length > 0) {
			formattedBlockIds = formattedBlockIds.slice(0, -1);
		}

		return formattedBlockIds;
	};

	/**
	 * zoom to the route
	 */
	private zoomToRoute = (): void => {
		this.mapLocationService.setRoutesToLocate([this.route.nb_id]);
	};

	/**
	 * get the vehicle status display value for the vehicles list
	 * @param routeVehicle - the vehicle
	 * @returns the vehicle status
	 */
	private getVehicleStatusDisplay = (routeVehicle: BlockVehicle): VehicleStatusDisplay => {
		const adherenceType: VehicleAdherenceType = this.mapVehiclesService.getVehicleAdherenceType(
			routeVehicle.block_id,
			routeVehicle.adherence.scheduled_adherence,
			this.authorityId
		);

		const vehicleStatusDisplay: VehicleStatusDisplay = {
			text: '',
			icon: '',
		};

		// default
		vehicleStatusDisplay.text = this.translations['T_MAP.MAP_ON_ROUTE'];

		switch (adherenceType) {
			case VehicleAdherenceType.veryEarly:
				vehicleStatusDisplay.text = this.translations['T_MAP.MAP_VERY_EARLY'];
				vehicleStatusDisplay.icon = 'nb-icons nb-danger-solid';
				break;
			case VehicleAdherenceType.early:
				vehicleStatusDisplay.text = this.translations['T_MAP.MAP_EARLY'];
				vehicleStatusDisplay.icon = 'nb-icons nb-warning-solid';
				break;
			case VehicleAdherenceType.late:
				vehicleStatusDisplay.text = this.translations['T_MAP.MAP_LATE'];
				vehicleStatusDisplay.icon = 'nb-icons nb-warning-solid';
				break;
			case VehicleAdherenceType.veryLate:
				vehicleStatusDisplay.text = this.translations['T_MAP.MAP_VERY_LATE'];
				vehicleStatusDisplay.icon = 'nb-icons nb-danger-solid';
				break;
		}

		if (routeVehicle.predictability === this.offRoute) {
			vehicleStatusDisplay.text = this.translations['T_MAP.MAP_UNPREDICTABLE'];
			vehicleStatusDisplay.icon = 'nb-icons nb-danger-solid';
		}

		if (routeVehicle.veh_state && routeVehicle.veh_state === this.atDepot) {
			vehicleStatusDisplay.text = this.translations['T_MAP.MAP_DEPOT'];
		}

		if (routeVehicle.veh_state && routeVehicle.veh_state === this.deadheading) {
			vehicleStatusDisplay.text = this.translations['T_MAP.MAP_DEADHEADING'];
		}

		if (routeVehicle.veh_state && routeVehicle.veh_state === this.driverOnBreak) {
			vehicleStatusDisplay.text = this.translations['T_MAP.MAP_DRIVER_ON_BREAK'];
		}

		return vehicleStatusDisplay;
	};

	/**
	 * get the adherence status display value for the vehicles list
	 * @param routeVehicle - the vehicle
	 * @returns the vehicle adherence display class
	 */
	private getAdherenceDisplay = (routeVehicle: BlockVehicle): VehicleAdherenceDisplay => {
		let adherenceDisplay: VehicleAdherenceDisplay = {
			time: this.emptyListItemPlaceHolder,
			class: '',
		};

		if (
			routeVehicle.predictability !== this.offRoute &&
			routeVehicle.veh_state !== this.driverOnBreak &&
			routeVehicle.adherence !== null
		) {
			const adherenceType: VehicleAdherenceType = this.mapVehiclesService.getVehicleAdherenceType(
				routeVehicle.block_id,
				routeVehicle.adherence.scheduled_adherence,
				this.route.authority_id
			);

			adherenceDisplay = this.mapVehiclesService.getAdherenceDisplay(routeVehicle.adherence.scheduled_adherence, adherenceType);
		}

		return adherenceDisplay;
	};

	/**
	 * determine the total blocks count
	 *
	 * @param activeBlocks - the list of active blocks
	 */
	private determineBlockTotals = (activeBlocks: ActiveBlocks): void => {
		this.resetBlockDetails();

		if (activeBlocks.length > 0) {
			activeBlocks.forEach((activeBlock: ActiveBlock) => {
				if (activeBlock.inProgress) {
					this.blocksActiveList.push(activeBlock.blockId);

					if (this.routeActiveVehicles.some((vehicleState: BlockVehicle) => vehicleState.block_id === activeBlock.blockId)) {
						this.blocksAssignedList.push(activeBlock.blockId);
					} else {
						this.blocksWithIssues.push(activeBlock.blockId);
					}
				}
			});
		}

		if (this.blocksActiveList.length > 0) {
			const perc: number = Math.round((this.blocksAssignedList.length / this.blocksActiveList.length) * 100);

			this.blockAssignmentPercentage = perc;
			this.blocksWithIssuesPercentage = 100 - perc;
		}

		this.blocksActiveListDisplay = this.determineBlocksActiveListDisplay();
	};

	/**
	 * build a (sorted) formatted blocks list separated by commas for the display
	 *
	 * @param activeBlocks - the active blocks list
	 * @returns a text string containing sorted block ids, comma separated
	 */
	private determineBlocksActiveListDisplay = (): string => {
		let blocksActiveListDisplay: string = '';

		this.blocksActiveList.forEach((block) => {
			blocksActiveListDisplay += block + ', ';
		});

		blocksActiveListDisplay = blocksActiveListDisplay.substring(0, blocksActiveListDisplay.length - 2);

		return blocksActiveListDisplay;
	};

	/**
	 * map the vehicle adherence value for display on the page
	 *
	 * @param adherenceDisplay - the adherence data
	 * @returns - the formatted css type with various adherence display info
	 */
	private mapVehicleAdherence = (adherenceDisplay: VehicleAdherenceDisplay): CssClassType => {
		const classType: CssClassType = {
			className: adherenceDisplay.class,
			value: adherenceDisplay.time,
			tooltip: null,
			subClassName: null,
			subClassTooltip: null,
		};

		return classType;
	};

	/**
	 * map the vehicle status to the format rerquired for the vehicles list
	 *
	 * @param vehicleStatusDisplay - the vehicle status display details
	 * @returns the formatted vehicle style class for the vehicles list
	 */
	private mapVehicleStatus = (vehicleStatusDisplay: VehicleStatusDisplay): CssClassType => {
		const classType: CssClassType = {
			className: null,
			value: vehicleStatusDisplay.text,
			tooltip: null,
			subClassName: vehicleStatusDisplay.icon,
			subClassTooltip: null,
			subClassPosition: CssSubClassPosition.before,
		};

		return classType;
	};

	/**
	 * build the vehicle list mapping the nextbus API property names to the expected list camel case format
	 * with any additional formatting required
	 */
	private buildVehicleList = (): void => {
		const routeDetailVehicleList: RouteDetailVehicleList = [];

		this.routeActiveVehicles.forEach((routeVehicle: BlockVehicle) => {
			const routeDetailVehicleListItem: RouteDetailVehicleListItem = {
				vehicleId: routeVehicle.vehicle_id,
				blockId: routeVehicle.block_id,
				tripId: routeVehicle.predictability !== 'OFF_ROUTE' ? routeVehicle.trip_id : this.emptyListItemPlaceHolder,
				vehicleStatus: this.mapVehicleStatus(this.getVehicleStatusDisplay(routeVehicle)),
				vehicleAdherence: this.mapVehicleAdherence(this.getAdherenceDisplay(routeVehicle)),
			};

			routeDetailVehicleList.push(routeDetailVehicleListItem);
		});

		this.blocksWithIssues.forEach((blockId: string) => {
			const routeDetailVehicleListItem: RouteDetailVehicleListItem = {
				vehicleId: this.emptyListItemPlaceHolder,
				blockId,
				tripId: this.emptyListItemPlaceHolder,
				vehicleStatus: this.mapVehicleStatus(this.emptyVehicleStatus),
				vehicleAdherence: this.mapVehicleAdherence(this.emptyVehicleAdherence),
			};

			routeDetailVehicleList.push(routeDetailVehicleListItem);
		});

		this.routeDetailVehicleList = this.sortList(routeDetailVehicleList);
	};

	/**
	 * load the current agency details
	 */
	private loadAgencyDetails = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;
		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
	};

	/**
	 * reset the route details
	 */
	private resetRouteDetails = (): void => {
		this.initialized = false;
		this.routeLoaded = false;
		this.routePillDisplay = null;
		this.route = null;
		this.routeDetailVehicleList = [];
		this.resetBlockDetails();
	};

	/**
	 * reset the route details
	 */
	private resetBlockDetails = (): void => {
		this.blockAssignmentPercentage = 0;
		this.blocksWithIssuesPercentage = 0;
		this.blocksActiveList = [];
		this.blocksAssignedList = [];
		this.blocksWithIssues = [];
	};

	/**
	 * handle any subsbriptions.
	 *
	 * refresh - the click of the refresh icon contained within the parent
	 *
	 * poll - the regular poll update triggered by the polling service
	 */
	private setSubscriptions = (): void => {
		this.navigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.handleNavigateRefresh();
		});

		this.refreshPoll$Subscription = this.mapEventsService.pollRefresh.subscribe(async () => {
			await this.handleRefreshRouteDetails();
		});
	};

	/**
	 * handle the refresh button and re-initaize the page.
	 *
	 * as we dont support block details in replay mode, revert to the menu when in that mode
	 */
	private handleNavigateRefresh = async (): Promise<void> => {
		this.init();
	};

	/**
	 * handle the refresh of the page - triggered from our poll subscription
	 */
	private handleRefreshRouteDetails = async (): Promise<void> => {
		// make sure we are initialized so we don't attempt a refresh at the same time
		if (this.initialized) {
			// essentially stop updates if the vehicle isn't returned the last time.
			// this handles an edge case where the vehicle isn't returned and we keep polling with the error
			if (this.lastRoutePollSuccess) {
				// It is essentially telling the parent navigation component to disable the refresh while the list is loading.
				this.enableRefresh.emit(false);

				await this.loadRouteBlockAndVehicleData();

				this.enableRefresh.emit(true);
			}
		}
	};

	/**
	 * unsubscribe from our subscriptions for clean up
	 */
	private unsubscribe = (): void => {
		this.navigateRefresh$Subscription?.unsubscribe();
		this.refreshPoll$Subscription?.unsubscribe();
	};
}
