/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN, ObjectHelpers } from '@cubicNx/libs/utils';
import { TimeHelpers } from '@cubicNx/libs/utils';

import { MapOptionsService } from './map-options.service';
import { MapLocationService } from './map-location.service';
import { MapStateService } from './map-state.service';
import { MapUtilsService } from './map-utils.service';
import { MapVehicleTrailService } from './map-vehicle-trail.service';
import { MapVehicleTrackService } from './map-vehicle-track.service';
import { MapDepotService } from './map-depot.service';
import { VehiclesDataService } from '../../../support-features/vehicles/services/vehicles-data.service';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapReplayService } from './map-replay.service';
import { TranslationService } from '@cubicNx/libs/utils';

import {
	ColorVehicleByType,
	MapDepotSelection,
	MapLocation,
	MapVehicle,
	MapVehicles,
	MapModeType,
	VehicleHeadwayType,
} from '../types/types';

import { AdherenceSettings, HeadwaySettings } from '../../../support-features/agencies/types/types';
import { AgencyConfig } from '../../../config/types/types';
import { ResultContent } from '@cubicNx/libs/utils';

import {
	VehicleAdherenceDisplay,
	VehicleAdherencePrefix,
	VehicleAdherenceType,
	VehicleColor,
	VehicleStatusDisplayType,
} from '../../../support-features/vehicles/types/types';

import {
	RouteVehicle,
	RouteVehicles,
	Vehicle,
	VehicleCurrentState,
	VehicleCurrentStates,
	Headway,
	Adherence,
	ActiveBlocks,
	VehicleRoute,
} from '../../../support-features/vehicles/types/api-types';

@Injectable({
	providedIn: 'root',
})
export class MapVehiclesService {
	private readonly maxVehiclesInQuery: number = 20;

	constructor(
		private translationService: TranslationService,
		private mapStateService: MapStateService,
		private mapUtilsService: MapUtilsService,
		private mapVehicleTrailService: MapVehicleTrailService,
		private mapVehicleTrackService: MapVehicleTrackService,
		private mapLocationService: MapLocationService,
		private mapOptionsService: MapOptionsService,
		private mapReplayService: MapReplayService,
		private mapDepotService: MapDepotService,
		private vehiclesDataService: VehiclesDataService,
		private colorUtilityService: ColorUtilityService,
		private agenciesDataService: AgenciesDataService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig
	) {}

	/**
	 * add a vehicle to the map state
	 *
	 * check if the vehicle exists. This scenario should only be possible when trying to add
	 * a vehicle that has been added as part of a route where the active filters have determined that
	 * it should be hidden
	 *
	 * @param vehicle - the vehicle to add
	 */
	public addVehicle = async (vehicle: Vehicle): Promise<void> => {
		if (this.vehicleExists(vehicle.vehicle_id)) {
			const existingVehicle: MapVehicle = this.getVehicle(vehicle.vehicle_id);

			// set the hidden state to false so the vehicle shows again - we always want to show a vehicle
			// that the user is manually adding
			existingVehicle.hidden = false;

			// in addition to the above - we need to set an additional flag to store that we have
			// overriden the filter and turned this vehicle manually back on. We need this so the above
			// hidden flag isn't reset to true on the next poll of data or when filters are re-assessed
			existingVehicle.applyFilter = false;

			this.mapStateService.reloadVehicles();
		} else {
			const mapVehicle: MapVehicle = this.getMapVehicleFromCurrentState(vehicle.current_state, true, true);

			this.mapStateService.addVehicle(mapVehicle);
		}
	};

	/**
	 * add vehicles to the map state
	 *
	 * @param vehicles - the vehicles to add
	 */
	public addVehicles = (vehicles: MapVehicles): void => {
		this.mapStateService.addVehicles(vehicles);
	};

	/**
	 * update vehicles within the map state
	 *
	 * @param vehicles - the vehicles to update
	 */
	public updateVehicles = (vehicles: MapVehicles): void => {
		this.mapStateService.updateVehicles(vehicles);
	};

	/**
	 * remove a vehicle from the map state
	 *
	 * also remove the vehicle trails and tracking of this vehicle is trailed/tracked
	 *
	 * @param vehicleId - the vehicle id to remove
	 */
	public removeVehicle = (vehicleId: string): void => {
		if (vehicleId === this.mapVehicleTrailService.getTrailedVehicleId()) {
			this.mapVehicleTrailService.clearTrailedVehicle();
		}

		if (vehicleId === this.mapVehicleTrackService.getTrackedVehicleId()) {
			this.mapVehicleTrackService.clearTrackedVehicle();
		}

		this.mapStateService.removeVehicle(vehicleId);
	};

	/**
	 * remove route vehicles
	 *
	 * @param vehicleIds - the list of vehicles to remove
	 */
	public removeRouteVehicles = (vehicleIds: Array<string>): void => {
		vehicleIds.forEach((vehicleId: string) => {
			// remove the associated trails/tracking if there is a match
			if (vehicleId === this.mapVehicleTrailService.getTrailedVehicleId()) {
				this.mapVehicleTrailService.clearTrailedVehicle();
			}

			if (vehicleId === this.mapVehicleTrackService.getTrackedVehicleId()) {
				this.mapVehicleTrackService.clearTrackedVehicle();
			}
		});

		this.mapStateService.removeVehicles(vehicleIds);
	};

	/**
	 * get a vehicle from the map state
	 *
	 * @param vehicleId - the vehicle id;
	 * @returns the vehicle
	 */
	public getVehicle = (vehicleId: string): MapVehicle => {
		return this.mapStateService.getVehicle(vehicleId);
	};

	/**
	 * get vehicles from the map state
	 *
	 * @param vehicles - the vehicle id;
	 * @returns the vehicle
	 */
	public getVehicles = (): MapVehicles => {
		return this.mapStateService.getVehicles();
	};

	/**
	 * determine if the vehicle is displayed on the map (differs from vehicleExists) as this version
	 * will also rule out hidden vehicles
	 *
	 * @param vehicleId - the vehicle id to check
	 * @returns true if the vehicle exists on the map and is not hidden
	 */
	public vehicleDisplayed = (vehicleId: string): boolean => {
		const vehicle: MapVehicle = this.getVehicle(vehicleId);

		return vehicle !== null && vehicle.hidden === false;
	};

	/**
	 * determine if a vehicle exists on the map
	 *
	 * @param vehicleId - the vehicle id to check
	 * @returns true if the vehicle exists on the map
	 */
	public vehicleExists = (vehicleId: string): boolean => {
		return this.getVehicle(vehicleId) !== null;
	};

	/**
	 * get vehicles that are part of a route in map vehicle format
	 *
	 * @param routeVehicles - the route vehicles to retrieve
	 * @param activeBlocks - active blocks on the route
	 * @returns the route vehicles
	 */
	public getRouteVehicles = (routeVehicles: RouteVehicles, activeBlocks: ActiveBlocks): MapVehicles => {
		const vehicles: MapVehicles = {};

		routeVehicles.forEach((routeVehicle: RouteVehicle) => {
			if (this.vehicleExists(routeVehicle.vehicle_id)) {
				const vehicleActive: boolean = this.vehicleActive(routeVehicle, activeBlocks);

				const currentVehicle: MapVehicle = this.getVehicle(routeVehicle.vehicle_id);

				// maintain applyFilter flag (i.e we may have added a vehicle that was filtered out and re-added
				// manually which ignores any filters set - ensuring we show the vehicle regardless)
				const updatedVehicle: MapVehicle = this.getMapVehicleFromCurrentState(
					routeVehicle.current_state,
					currentVehicle.applyFilter,
					vehicleActive
				);

				// only bother updating during poll if vehicle has actually changed
				if (this.vehicleChanged(currentVehicle, updatedVehicle)) {
					this.checkTrackedVehicle(routeVehicle.current_state);

					vehicles[routeVehicle.vehicle_id] = updatedVehicle;
				}
			} else {
				// add the new vehicle
				const vehicleActive: boolean = this.vehicleActive(routeVehicle, activeBlocks);

				const mapVehicle: MapVehicle = this.getMapVehicleFromCurrentState(routeVehicle.current_state, true, vehicleActive);

				vehicles[mapVehicle.vehicleId] = mapVehicle;
			}
		});

		if (Object.keys(vehicles).length > 0) {
			this.determineRouteVehiclesHiddenStatus(
				vehicles,
				this.getShowVehiclesWithAdherenceIssues(),
				this.getShowVehiclesWithHeadwayIssues(),
				this.getSelectedDepotFilter()
			);
		}

		return vehicles;
	};

	/**
	 * get vehicles that are not part of a route in map vehicle format (i.e those that have ben added manually)
	 *
	 * @param authorityId - the authority id for the request
	 * @param vehicleIds - the vehicle ids to request
	 * @param initialzing - true if the request is part of the map initialization
	 * @returns the non route vehicles
	 */
	public getNonRouteVehicles = async (authorityId: string, vehicleIds: string[], initialzing: boolean): Promise<MapVehicles> => {
		const vehicles: MapVehicles = {};

		const vehicleIdsSets: string[][] = this.getVehicleIdSets(vehicleIds);

		// cant use forEach within async function - it will return before awaits have completed
		for (const vehicleIds of vehicleIdsSets) {
			const response: ResultContent = await this.vehiclesDataService.getVehicleCurrentStates(authorityId, vehicleIds);

			if (response.success) {
				const vehicleUpdatedStates: VehicleCurrentStates = response.resultData;

				for (const vehicleUpdatedState of vehicleUpdatedStates) {
					if (this.vehicleExists(vehicleUpdatedState.vehicle_id)) {
						// create a new vehicle object - be careful not to edit (by reference the one from our
						// state) as this can lead to updates being process out of sync
						// instead provide a new object and inform the map when we are ready
						const updatedVehicle: MapVehicle = this.getMapVehicleFromCurrentState(vehicleUpdatedState, false, true);

						const currentVehicle: MapVehicle = this.getVehicle(vehicleUpdatedState.vehicle_id);

						// only bother updating during poll if vehicle has actually changed
						if (this.vehicleChanged(currentVehicle, updatedVehicle) || initialzing) {
							this.checkTrackedVehicle(vehicleUpdatedState);

							vehicles[updatedVehicle.vehicleId] = updatedVehicle;
						}
					}
				}
			}
		}

		return vehicles;
	};

	/**
	 * get sets of vehicle Ids to form a 2 dimensional string array. Each set contains a list of vehicles id's up to the maximun size set.
	 * This is essentially a helper method so we don't request too many vehicles at once (see usage)
	 *
	 * @param vehicleIds - the full list of vehicles ids to request
	 * @returns - sets of vehicle id lists
	 */
	public getVehicleIdSets = (vehicleIds: string[]): string[][] => {
		const vehicleIdSets: string[][] = [];
		let vehicleIdSet: string[] = [];

		vehicleIds.forEach((vehicleId) => {
			vehicleIdSet.push(vehicleId);

			if (vehicleIdSet.length === this.maxVehiclesInQuery) {
				// start a new set
				vehicleIdSets.push(vehicleIdSet);
				vehicleIdSet = [];
			}
		});

		// anything left over can be part of a new set
		if (vehicleIdSet.length > 0) {
			vehicleIdSets.push(vehicleIdSet);
		}

		return vehicleIdSets;
	};

	/**
	 * convert a vehicle current state returned from the nextbus API in to the format and properties needed by our map state
	 *
	 * @param updatedState - the updatd vehicle state to convert
	 * @param applyFilter - determine if any filters should be applied (in case an override has been set i.e when false)
	 * @param vehicleActive - if the vehicle is currently active
	 * @returns the map vehicle object with everything the map needs to render this vehicle
	 */
	public getMapVehicleFromCurrentState = (
		updatedState: VehicleCurrentState,
		applyFilter: boolean,
		vehicleActive: boolean
	): MapVehicle => {
		const routeColor: VehicleColor = this.getVehicleRouteColor(updatedState.route);

		const aderenceType: VehicleAdherenceType = this.getVehicleAdherenceType(
			updatedState.block_id,
			updatedState.adherence.scheduled_adherence,
			updatedState.authority_id
		);

		const adherenceColor: VehicleColor = this.getVehicleAdherenceColor(aderenceType);

		const vehicleHeadway: VehicleHeadwayType = this.getVehicleHeadwayType(updatedState.headway, updatedState.authority_id);

		const headwayColor: VehicleColor = this.getVehicleHeadwayColor(vehicleHeadway);

		const vehicleColor: VehicleColor = this.getVehicleColor(
			this.getColorVehicleBy(),
			updatedState.predictability,
			updatedState.route?.route_id,
			routeColor,
			adherenceColor,
			headwayColor
		);

		const angle: number = this.mapUtilsService.getIconAngleFromHeading(updatedState.heading);

		const vehicleStatusDisplayType: VehicleStatusDisplayType = this.getVehicleCurrentStatusType(
			updatedState.veh_state,
			updatedState.predictability,
			updatedState.route?.route_id
		);

		const state: string = this.getVehicleStatusDisplayText(vehicleStatusDisplayType).replace(' ', '&nbsp;');

		const adherenceDisplay: VehicleAdherenceDisplay = this.getVehicleAdherenceDisplay(
			updatedState.authority_id,
			updatedState.block_id,
			updatedState.adherence
		);

		const headwayDisplay: string = this.getHeadwayDisplay(updatedState.headway);

		const blockId: string = this.getBlockId(updatedState.block_id, updatedState.route?.route_id);

		const tripId: string = this.getTripId(updatedState.trip_id);

		const vehicleIds: string[] = this.getVehicleIds(
			updatedState.vehicle_id,
			updatedState.vehicles_in_consist,
			updatedState.linked_vehicle_ids
		);

		const routeName: string = this.getRouteName(updatedState.route?.route_name);

		const vehicleMoving: boolean = updatedState.speed !== 0;

		const updatedVehicle: MapVehicle = {
			vehicleId: updatedState.vehicle_id,
			authorityId: updatedState.authority_id,
			agencyId: updatedState.agency_id,
			// ensures we set null rather than undefined - other parst of the system expect null or value
			routeId: updatedState.route?.route_id || null,
			predictability: updatedState.predictability,
			adherence: updatedState.adherence,
			headway: updatedState.headway,
			lat: updatedState.lat,
			lon: updatedState.lon,
			date: updatedState.date,
			runid: updatedState.runid,
			direction: updatedState.direction,
			lastDepot: updatedState.last_depot,
			passengers: updatedState.passengers,
			vehState: updatedState.veh_state,
			vehiclePosition: updatedState.vehicle_position,
			vehicleMoving,
			routeName,
			vehicleIds,
			blockId,
			tripId,
			routeColor,
			vehicleColor,
			headwayColor,
			adherenceColor,
			angle,
			state,
			adherenceDisplay,
			headwayDisplay,
			applyFilter,
			vehicleActive,
			hidden: false,
		};

		return updatedVehicle;
	};

	/**
	 * clear all vehicles and also clear the tracked/trailed vehicle
	 */
	public clearVehicles = (): void => {
		// clear the tracked vehicle so we no longer track
		this.mapVehicleTrackService.clearTrackedVehicle();

		// clear any vehicle trails
		this.mapVehicleTrailService.clearTrailedVehicle();

		this.mapStateService.clearVehicles();
	};

	/**
	 * zoom to a vehicle
	 *
	 * @param vehicle - the vehicle to zoom to
	 */
	public zoomToVehicle = (vehicle: Vehicle): void => {
		const location: MapLocation = {
			lat: vehicle.current_state.lat,
			lon: vehicle.current_state.lon,
			zoom: this.config.getMaxZoomLevel(),
			offsetAdjust: true,
			clearTrackedVehicle: true,
		};

		this.mapLocationService.setLocation(location);
	};

	/**
	 * get the first refresh flag
	 *
	 * @returns true when this is the first refresh (i.e after map initialization)
	 */
	public getFirstRefresh = (): boolean => {
		return this.mapStateService.getFirstRefresh();
	};

	/**
	 * set the first refresh flag
	 *
	 * @param firstRefresh - true when this is the first refresh (i.e after map initialization)
	 */
	public setFirstRefresh = (firstRefresh: boolean): void => {
		this.mapStateService.setFirstRefresh(firstRefresh);
	};

	/**
	 * set the color by options for vehicles (routes/schedule/headway) and refresh colors
	 *
	 * @param colorVehicleBy - the color by type
	 */
	public setColorVehicleBy = (colorVehicleBy: ColorVehicleByType): void => {
		// refresh colors
		for (const vehicleId in this.getVehicles()) {
			const vehicle: MapVehicle = this.getVehicle(vehicleId);

			vehicle.vehicleColor = this.getVehicleColor(
				colorVehicleBy,
				vehicle.predictability,
				vehicle.routeId,
				vehicle.routeColor,
				vehicle.adherenceColor,
				vehicle.headwayColor
			);
		}

		this.mapStateService.setColorVehicleBy(colorVehicleBy);
	};

	/**
	 * get the current color by option for vehicles (routes/schedule/headway)
	 *
	 * @returns the color by type
	 */
	public getColorVehicleBy = (): ColorVehicleByType => {
		return this.mapStateService.getColorVehicleBy();
	};

	/**
	 * set the vehicle label cluser radius
	 *
	 * @param vehicleLabelClusterRadius -  the vehicle label cluser radius
	 * @param reloadVehicleLabels - when true the vehicle labels should be reloaded
	 */
	public setVehicleLabelClusterRadius = (vehicleLabelClusterRadius: number, reloadVehicleLabels: boolean): void => {
		this.mapStateService.setVehicleLabelClusterRadius(vehicleLabelClusterRadius, reloadVehicleLabels);
	};

	/**
	 * get the vehicle label cluser radius
	 *
	 * @returns the vehicle label cluser radius
	 */
	public getVehicleLabelClusterRadius = (): number => {
		return this.mapStateService.getVehicleLabelClusterRadius();
	};

	/**
	 * get the map state show vehicles with adherence issues filter on/off
	 *
	 * @returns - true when the show vehicles with adherence issues filter is on
	 */
	public getShowVehiclesWithAdherenceIssues = (): boolean => {
		return this.mapStateService.getShowVehiclesWithAdherenceIssues();
	};

	/**
	 * set the map state show vehicles with adherence issues filter on/off
	 *
	 * determine if any vehicles are now hidden due to this filter
	 *
	 * @param showVehiclesWithAdherenceIssues - true when the show vehicles with adherence issues filter is on
	 */
	public setShowVehiclesWithAdherenceIssues = (showVehiclesWithAdherenceIssues: boolean): void => {
		this.determineRouteVehiclesHiddenStatus(
			this.getVehiclesOnRoute(),
			showVehiclesWithAdherenceIssues,
			this.getShowVehiclesWithHeadwayIssues(),
			this.getSelectedDepotFilter()
		);

		this.mapStateService.setShowVehiclesWithAdherenceIssues(showVehiclesWithAdherenceIssues);
	};

	/**
	 * get the map state show vehicle with headway issues filter on/off
	 *
	 * @returns true when the show vehicles with headway issues filter is on
	 */
	public getShowVehiclesWithHeadwayIssues = (): boolean => {
		return this.mapStateService.getShowVehiclesWithHeadwayIssues();
	};

	/**
	 * set the map state show vehicle with headway issues filter on/off
	 *
	 * determine if any vehicles are now hidden due to this filter
	 *
	 * @param showVehiclesWithHeadwayIssues - true when the show vehicles with headway issues filter is on
	 */
	public setShowVehiclesWithHeadwayIssues = (showVehiclesWithHeadwayIssues: boolean): void => {
		this.determineRouteVehiclesHiddenStatus(
			this.getVehiclesOnRoute(),
			this.getShowVehiclesWithAdherenceIssues(),
			showVehiclesWithHeadwayIssues,
			this.getSelectedDepotFilter()
		);

		this.mapStateService.setShowVehiclesWithHeadwayIssues(showVehiclesWithHeadwayIssues);
	};

	/**
	 * get the map state depot filter on/off
	 *
	 * @returns - true when the map state depot filter is on
	 */
	public getSelectedDepotFilter = (): MapDepotSelection => {
		return this.mapStateService.getSelectedDepotFilter();
	};

	/**
	 * set the map state depot filter on/off
	 *
	 * determine if any vehicles are now hidden due to this filter
	 *
	 * @param selectedDepotFilter - true when the map state depot filter is on
	 */
	public setSelectedDepotFilter = (selectedDepotFilter: MapDepotSelection): void => {
		this.determineRouteVehiclesHiddenStatus(
			this.getVehiclesOnRoute(),
			this.getShowVehiclesWithAdherenceIssues(),
			this.getShowVehiclesWithHeadwayIssues(),
			selectedDepotFilter
		);

		this.mapStateService.setSelectedDepotFilter(selectedDepotFilter);
	};

	/**
	 * get the vehicle adherence type
	 *
	 * @param blockId - the vehicle block id
	 * @param adherence - the vehicle adherence
	 * @param authorityId - the authority id for the reqest
	 * @returns the vehicle adherence type i.e early
	 */
	public getVehicleAdherenceType = (blockId: string, adherence: number, authorityId: string): VehicleAdherenceType => {
		const adherenceSettings: AdherenceSettings = this.agenciesDataService.getAdherenceSettings(authorityId);

		let adherenceType: VehicleAdherenceType = VehicleAdherenceType.unknown;

		if (blockId) {
			if (adherence !== null) {
				if (adherence < 0) {
					//early
					if (adherence <= adherenceSettings.veryEarlySec * -1) {
						adherenceType = VehicleAdherenceType.veryEarly;
					} else if (adherence <= adherenceSettings.earlyMinSec * -1) {
						adherenceType = VehicleAdherenceType.early;
					} else {
						adherenceType = VehicleAdherenceType.onTime;
					}
				} else {
					//late
					if (adherence >= adherenceSettings.veryLateSec) {
						adherenceType = VehicleAdherenceType.veryLate;
					} else if (adherence >= adherenceSettings.lateMinSec) {
						adherenceType = VehicleAdherenceType.late;
					} else {
						adherenceType = VehicleAdherenceType.onTime;
					}
				}
			}
		}

		return adherenceType;
	};

	/**
	 * get the adherence display object
	 *
	 * @param seconds - the adherence seconds
	 * @param adherenceType - the determined adherence type
	 * @returns the adherence display
	 */
	public getAdherenceDisplay = (seconds: number, adherenceType: VehicleAdherenceType): VehicleAdherenceDisplay => {
		const adherencePrefix: VehicleAdherencePrefix = this.getAdherencePrefix(seconds);
		const prefix: string = adherencePrefix.prefix;
		const fSeconds: number = adherencePrefix.seconds;
		const rClass: string = this.getAdherenceTextClass(adherenceType);

		let time: string = TimeHelpers.secondsToHms(fSeconds);

		if (time.indexOf('NaN') > -1) {
			time = '--';
		} else {
			if (time.indexOf('00:') === 0) {
				time = time.slice(-5);
				if (time.indexOf('0') === 0) {
					time = time.slice(1);
				}
			}

			if (time.indexOf('0') === 0 && time.length === 8) {
				time = time.slice(1);
			}

			time = prefix + time;
		}

		return {
			time,
			class: rClass,
		};
	};

	/**
	 * get the vehicle current status type
	 * @param vehState - the vehicle state
	 * @param predictability - the vehicle predictability
	 * @param routeId - the route id of the route the vehicle belongs
	 * @returns the vehicle status enum value
	 */
	public getVehicleCurrentStatusType = (vehState: string, predictability: string, routeId: string): VehicleStatusDisplayType => {
		if (vehState) {
			switch (vehState) {
				case 'driver on break':
					return VehicleStatusDisplayType.driverOnBreak;
				case 'arrived':
					return VehicleStatusDisplayType.arrived;
				case 'deadheading':
					return VehicleStatusDisplayType.deadheading;
				case 'departed':
					return VehicleStatusDisplayType.departed;
				case 'departing':
					return VehicleStatusDisplayType.departing;
				case 'dwelling':
					return VehicleStatusDisplayType.dwelling;
				case 'intransit':
					return VehicleStatusDisplayType.intransit;
				case 'at_depot':
					return VehicleStatusDisplayType.atDepot;
				case 'unassigned':
					return VehicleStatusDisplayType.unassigned;
			}
		}

		if (predictability.toLowerCase() === 'off_route' || predictability.toLowerCase() === 'no_route') {
			return VehicleStatusDisplayType.unpredictable;
		}

		if (!routeId) {
			return VehicleStatusDisplayType.unassigned;
		}

		return VehicleStatusDisplayType.onRoute;
	};

	/**
	 * get the vehicle status display text from the type
	 *
	 * @param vehicleStatusDisplayType - the vehicle status display type
	 * @param displayUnassignedAtDepotAsUnnassigned - whether to display unassigned at depot as unnassigned
	 * @returns the vehicle status display text
	 */
	public getVehicleStatusDisplayText = (
		vehicleStatusDisplayType: VehicleStatusDisplayType,
		displayUnassignedAtDepotAsUnnassigned: boolean = false
	): string => {
		let vehicleStatusDisplayText: string = null;

		switch (vehicleStatusDisplayType) {
			case VehicleStatusDisplayType.unassigned:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_UNASSIGNED');
				break;
			case VehicleStatusDisplayType.unassignedInDepot:
				if (displayUnassignedAtDepotAsUnnassigned) {
					vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_UNASSIGNED');
				} else {
					vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEPOT');
				}
				break;
			case VehicleStatusDisplayType.stale:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_STALE');
				break;
			case VehicleStatusDisplayType.onRoute:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_ON_ROUTE');
				break;
			case VehicleStatusDisplayType.unpredictable:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_UNPREDICTABLE');
				break;
			case VehicleStatusDisplayType.deadheading:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEADHEADING');
				break;
			case VehicleStatusDisplayType.deassignedNoRoute:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEASSIGNED');
				break;
			case VehicleStatusDisplayType.intransit:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_IN_TRANSIT');
				break;
			case VehicleStatusDisplayType.arrived:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_ARRIVED');
				break;
			case VehicleStatusDisplayType.dwelling:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DWELLING');
				break;
			case VehicleStatusDisplayType.departing:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEPARTING');
				break;
			case VehicleStatusDisplayType.departed:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEPARTED');
				break;
			case VehicleStatusDisplayType.driverOnBreak:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DRIVER_ON_BREAK');
				break;
			case VehicleStatusDisplayType.atDepot:
				vehicleStatusDisplayText = this.translationService.getTranslation('T_MAP.MAP_DEPOT');
				break;
		}

		return vehicleStatusDisplayText;
	};

	/**
	 * get the vehicle adherence display
	 *
	 * @param authorityId - thge authority id
	 * @param blockId - the vehicle block id
	 * @param adherence - the vehicle adherence
	 * @returns the vehicle adherence display object
	 */
	public getVehicleAdherenceDisplay = (authorityId: string, blockId: string, adherence: Adherence): VehicleAdherenceDisplay => {
		let adherenceDisplay: VehicleAdherenceDisplay = {
			time: '—',
			class: '',
		};

		if (adherence.scheduled_adherence !== null) {
			const adherenceType: VehicleAdherenceType = this.getVehicleAdherenceType(blockId, adherence.scheduled_adherence, authorityId);

			adherenceDisplay = this.getAdherenceDisplay(adherence.scheduled_adherence, adherenceType);
		}

		return adherenceDisplay;
	};

	/**
	 * get the vehicle moving transition tyme (typically the default config time but could be faster in certain replay speeds)
	 * @returns the vehicle moving transition (animation) replay time
	 */
	public getVehicleMovingTransitionTime = (): number => {
		let vehicleMovingTransitionTime: number = this.config.getVehicleMovingTransitionTime() * 1000;

		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			if (this.mapReplayService.getReplayPollInterval() <= 3000) {
				vehicleMovingTransitionTime = this.config.getVehicleMovingFastTransitionTime() * 1000;
			}
		}

		return vehicleMovingTransitionTime;
	};

	/**
	 * calculate the headway difference
	 * @param headway - the headway
	 * @returns the headway difference
	 */
	public calcHeadwayDiff = (headway: Headway): number => {
		return (headway.scheduled_headway_adherence - headway.headway_adherence) / headway.scheduled_headway_adherence;
	};

	/**
	 * get vehicles from the map state that are part of a route
	 *
	 * @returns the vehicles on a route
	 */
	private getVehiclesOnRoute = (): MapVehicles => {
		return this.mapStateService.getVehiclesOnRoute();
	};

	/**
	 * compare two vehicle objects to see if they are different
	 * @param currentVehicle - the current vehicle to compare
	 * @param updatedVehicle - the updated vehicle to compare
	 * @returns true if the vehicle objects are different i.e the something about the vehicle has changed
	 */
	private vehicleChanged = (currentVehicle: MapVehicle, updatedVehicle: MapVehicle): boolean => {
		return !ObjectHelpers.deepEquals(currentVehicle, updatedVehicle);
	};

	/**
	 * determine if the vehicle is active i.e not stale and on a mapped route block
	 *
	 * @param routeVehicle - the route vehicle
	 * @param activeBlocks - the active blocks
	 * @returns true if the vehicle is active
	 */
	private vehicleActive = (routeVehicle: RouteVehicle, activeBlocks: ActiveBlocks): boolean => {
		return (
			!routeVehicle.is_stale &&
			(this.vehicleIsOnMappedRouteBlock(routeVehicle.current_state.block_id, activeBlocks) ||
				routeVehicle.current_state.headway.scheduled_headway_adherence !== null)
		);
	};

	/**
	 * check the tracked and trailed vehicle against this vehicle and update if necessary
	 *
	 * @param vehicleCurrentState - the current vehicle state
	 */
	private checkTrackedVehicle = (vehicleCurrentState: VehicleCurrentState): void => {
		const vehicleMovingTransitionTime: number = this.getVehicleMovingTransitionTime();

		// stay in sync with vehicle movement transition timing
		setTimeout(() => {
			if (vehicleCurrentState.vehicle_id === this.mapVehicleTrailService.getTrailedVehicleId()) {
				this.mapVehicleTrailService.updateTrailedVehicle(
					vehicleCurrentState.lat,
					vehicleCurrentState.lon,
					vehicleCurrentState.veh_state,
					vehicleCurrentState.heading,
					vehicleCurrentState.route?.route_color
				);
			}
		}, vehicleMovingTransitionTime);

		// move the map to the final position towards the end of vehicle transition. (divide by 1.5) seems to be the best compromise
		setTimeout(() => {
			if (vehicleCurrentState.vehicle_id === this.mapVehicleTrackService.getTrackedVehicleId()) {
				this.mapVehicleTrackService.updateTrackedVehiclePosition(
					vehicleCurrentState.lat,
					vehicleCurrentState.lon,
					this.mapLocationService.getLocationZoom()
				);
			}
		}, vehicleMovingTransitionTime / 1.5);
	};

	/**
	 * determine route vehicles hidden status - i.e if the vehicle pass the filter criteria to be shown on the map
	 *
	 * @param vehicles - the vehicles to check
	 * @param showVehiclesWithAdherenceIssues - the adherence filter value
	 * @param showVehiclesWithHeadwayIssues  - the headway filter value
	 * @param selectedDepotFilter - the depot filter value
	 */
	private determineRouteVehiclesHiddenStatus = (
		vehicles: MapVehicles,
		showVehiclesWithAdherenceIssues: boolean,
		showVehiclesWithHeadwayIssues: boolean,
		selectedDepotFilter: MapDepotSelection
	): void => {
		if (Object.keys(vehicles).length > 0) {
			const authorityId: string = this.agenciesDataService.getSelectedAgency().authority_id;

			const adherenceSettings: AdherenceSettings = this.agenciesDataService.getAdherenceSettings(authorityId);
			const headwaysettings: HeadwaySettings = this.agenciesDataService.getHeadwayAdherenceSettings(authorityId);

			for (const vehicleId in vehicles) {
				const vehicle: MapVehicle = vehicles[vehicleId];

				const vehicleHidden: boolean = this.determineRouteVehicleHiddenStatus(
					vehicle,
					showVehiclesWithAdherenceIssues,
					showVehiclesWithHeadwayIssues,
					selectedDepotFilter,
					adherenceSettings,
					headwaysettings
				);

				vehicle.hidden = vehicleHidden;

				if (vehicleHidden) {
					if (vehicleId === this.mapVehicleTrailService.getTrailedVehicleId()) {
						this.mapVehicleTrailService.clearTrailedVehicle();
					}

					if (vehicleId === this.mapVehicleTrackService.getTrackedVehicleId()) {
						this.mapVehicleTrackService.clearTrackedVehicle();
					}
				}
			}
		}
	};

	/**
	 * determine single route vehicle hidden status - i.e if the vehicle pass the filter criteria to be shown on the map
	 *
	 * @param vehicle - the vehicle to check
	 * @param showVehiclesWithAdherenceIssues - the adherence filter value
	 * @param showVehiclesWithHeadwayIssues  - the headway filter value
	 * @param selectedDepotFilter - the depot filter value
	 * @param adherenceSettings - the agency adherence settings
	 * @param headwaySettings - the agency headway settings
	 * @returns true if the vehicle should be hidden
	 */
	private determineRouteVehicleHiddenStatus = (
		vehicle: MapVehicle,
		showVehiclesWithAdherenceIssues: boolean,
		showVehiclesWithHeadwayIssues: boolean,
		selectedDepotFilter: MapDepotSelection,
		adherenceSettings: AdherenceSettings,
		headwaySettings: HeadwaySettings
	): boolean => {
		let hidden: boolean = true;

		const late: number = adherenceSettings.lateMinSec;
		const early: number = adherenceSettings.earlyMinSec;

		let adherenceIssue: boolean = false;

		if (vehicle.adherence.scheduled_adherence !== null) {
			const scheduledAdherence: number = vehicle.adherence.scheduled_adherence;

			adherenceIssue = scheduledAdherence <= early * -1 || scheduledAdherence >= late;
		}

		const close: number = headwaySettings.headwayAdherenceClose;
		const distant: number = headwaySettings.headwayAdherenceDistant * -1;

		let headwayIssue: boolean = false;

		const headwayAdherencePercentage: number = this.calcHeadwayDiff(vehicle.headway);

		if (!isNaN(headwayAdherencePercentage)) {
			headwayIssue = headwayAdherencePercentage <= distant || headwayAdherencePercentage >= close;
		}

		if (showVehiclesWithAdherenceIssues && showVehiclesWithHeadwayIssues) {
			if (adherenceIssue || headwayIssue) {
				hidden = false;
			}
		} else if (showVehiclesWithAdherenceIssues) {
			if (adherenceIssue) {
				hidden = false;
			}
		} else if (showVehiclesWithHeadwayIssues) {
			if (headwayIssue) {
				hidden = false;
			}
		} else {
			hidden = false;
		}

		if (!hidden) {
			if (!this.mapDepotService.vehicleBelongsToSelectedDepot(vehicle.lastDepot, selectedDepotFilter.tag)) {
				hidden = true;
			}

			if (!vehicle.vehicleActive) {
				hidden = true;
			}
		}

		if (hidden) {
			// show vehicle regardless if we have set override
			if (!vehicle.applyFilter) {
				hidden = false;
			}
		} else {
			// if a filter has been set or changed so that a vehicle which was manually overriden to be displayed
			// despite the active filter, now no longer needs the override (as it passes the filter), reset the override
			// flag to return it to normal state. It will then be filtered out if filters are re-applied
			vehicle.applyFilter = true;
		}

		return hidden;
	};

	/**
	 * determine if a vehicle is on a mapped route block
	 *
	 * @param blockId - the block id
	 * @param activeBlocks - the active blicks
	 * @returns true if a vehicle is on a mapped route block
	 */
	private vehicleIsOnMappedRouteBlock = (blockId: string, activeBlocks: ActiveBlocks): boolean => {
		let isOnMappedRouteBlock: boolean = false;

		if (activeBlocks) {
			if (activeBlocks[blockId]) {
				isOnMappedRouteBlock = true;
			}
		}

		return isOnMappedRouteBlock;
	};

	/**
	 * get the headway display value
	 *
	 * @param headway - the headway
	 * @returns the headway display value
	 */
	private getHeadwayDisplay = (headway: Headway): string => {
		let formatted: string = '—';

		if (typeof headway.headway_adherence === 'number' && typeof headway.scheduled_headway_adherence === 'number') {
			if (headway.headway_adherence !== 0 && headway.scheduled_headway_adherence !== 0) {
				formatted = this.formatHeadwayDisplay(headway.headway_adherence);
				formatted += this.formatHeadwayAdherenceDisplay(headway.scheduled_headway_adherence);
			}
		}

		return formatted;
	};

	/**
	 * format the headway display value (with seconds padded with zero)
	 *
	 * @param valueInSeconds - the headway seconds
	 * @returns the formatted headway value
	 */
	private formatHeadwayDisplay = (valueInSeconds: number): string => {
		const value: number = Math.floor(valueInSeconds);
		const minutes: number = Math.floor(value / 60);
		const seconds: number = value % 60;

		return minutes.toString().padStart(2, '0') + ':' + seconds.toString().padStart(2, '0');
	};

	/**
	 * format the adherence display
	 *
	 * @param adherence - the adherence
	 * @returns the formatted adherence display value
	 */
	private formatHeadwayAdherenceDisplay = (adherence: number): string => {
		if (!(typeof adherence === 'number')) {
			return '';
		}

		adherence = Math.abs(adherence); // adherence values are positive in the API, but displayed negative on the map

		return ' (' + this.formatHeadwayDisplay(adherence) + ')';
	};

	/**
	 * get the block Id
	 *
	 * if the block id is empty or their is no route - return a dash instead
	 *
	 * @param blockId - the block id
	 * @param routeId - the route id
	 * @returns - the adjusted block id
	 */
	private getBlockId = (blockId: string, routeId: string): string => {
		if (blockId === null || !routeId) {
			blockId = '—';
		}

		return blockId;
	};

	/**
	 * get the trip id
	 *
	 * if the trip id is empty return a dash instead
	 * @param tripId - the trip id
	 * @returns the adjusted trip id
	 */
	private getTripId = (tripId: string): string => {
		return tripId ? tripId : '—';
	};

	/**
	 * return a list of vehicle ids after breaking down linked vehicle ids
	 *
	 * @param vehicleId - the vehicle id
	 * @param vehiclesInConsist - the vehicles in consist
	 * @param linkedVehicleIds - the linked vehicle ids
	 * @returns an adjusted list of vehicle ids
	 */
	private getVehicleIds = (vehicleId: string, vehiclesInConsist: number, linkedVehicleIds: string): string[] => {
		if (vehiclesInConsist > 1) {
			// build an array from our comma separated list of linked vehicles
			const splitLinkedVehicleIds: string[] = linkedVehicleIds.split(',');

			const vehicleIds: string[] = [];

			// make sure the actual source vehicle is first
			vehicleIds.push(vehicleId);

			splitLinkedVehicleIds.forEach((linkedVehicleId: string) => {
				if (linkedVehicleId !== vehicleId) {
					vehicleIds.push(linkedVehicleId);
				}
			});

			return vehicleIds;
		} else {
			return [vehicleId];
		}
	};

	/**
	 * get the route name
	 *
	 * if the route name is empty return a dash instead
	 * @param routeName - the route name
	 * @returns the adjusted route name
	 */
	private getRouteName = (routeName: string): string => {
		if (routeName) {
			return routeName;
		} else {
			return '—';
		}
	};

	/**
	 * get the vehicle headway type
	 *
	 * @param headway - the headway value
	 * @param authorityId - the current authority id
	 * @returns the vehicle headway type
	 */
	private getVehicleHeadwayType = (headway: Headway, authorityId: string): VehicleHeadwayType => {
		const target: number = headway.scheduled_headway_adherence;
		const adherence: number = headway.headway_adherence;
		const settings: HeadwaySettings = this.agenciesDataService.getHeadwayAdherenceSettings(authorityId);

		let vehicleHeadway: VehicleHeadwayType = VehicleHeadwayType.unknown;

		if (typeof target === 'number' && typeof adherence === 'number') {
			if (target !== 0 && adherence !== 0) {
				const headwayAdherencePercentage: number = this.calcHeadwayDiff(headway);

				if (headwayAdherencePercentage > 0) {
					if (headwayAdherencePercentage > settings.headwayAdherenceVeryClose) {
						vehicleHeadway = VehicleHeadwayType.veryClose;
					} else if (headwayAdherencePercentage > settings.headwayAdherenceClose) {
						vehicleHeadway = VehicleHeadwayType.close;
					} else {
						vehicleHeadway = VehicleHeadwayType.acceptable;
					}
				} else {
					if (headwayAdherencePercentage < settings.headwayAdherenceVeryDistant * -1) {
						vehicleHeadway = VehicleHeadwayType.veryDistant;
					} else if (headwayAdherencePercentage < settings.headwayAdherenceDistant * -1) {
						vehicleHeadway = VehicleHeadwayType.distant;
					} else {
						vehicleHeadway = VehicleHeadwayType.acceptable;
					}
				}
			}
		}

		return vehicleHeadway;
	};

	/**
	 * get the vehicle route color
	 *
	 * @param route - the vehicle route
	 * @returns the vehicle route color
	 */
	private getVehicleRouteColor = (route: VehicleRoute): VehicleColor => {
		const vehicleColor: VehicleColor = {
			backgroundColor: this.config.getDefaultVehicleColor(),
			foreColor: this.config.getDefaultVehicleTextColor(),
		};

		if (route?.route_id) {
			// Test for null as 0 is considered falsy
			if (route?.route_color !== null && route?.route_text_color !== null) {
				vehicleColor.backgroundColor = this.colorUtilityService.getColor(route.route_color);
				vehicleColor.foreColor = this.colorUtilityService.getColor(route.route_text_color);
			}
		}

		return vehicleColor;
	};

	/**
	 * get the vehicle adherence color
	 *
	 * @param aderenceType - the adherence type
	 * @returns  the vehicle adherence color
	 */
	private getVehicleAdherenceColor = (aderenceType: VehicleAdherenceType): VehicleColor => {
		const adherenceColor: VehicleColor = {
			backgroundColor: this.config.getDefaultVehicleColor(),
			foreColor: this.config.getDefaultVehicleTextColor(),
		};

		switch (aderenceType) {
			case VehicleAdherenceType.veryEarly:
				adherenceColor.backgroundColor = this.config.getAdherenceVeryEarlyColor();
				break;
			case VehicleAdherenceType.early:
				adherenceColor.backgroundColor = this.config.getAdherenceEarlyColor();
				break;
			case VehicleAdherenceType.onTime:
				adherenceColor.backgroundColor = this.config.getAdherenceOnTimeColor();
				break;
			case VehicleAdherenceType.late:
				adherenceColor.backgroundColor = this.config.getAdherenceLateColor();
				break;
			case VehicleAdherenceType.veryLate:
				adherenceColor.backgroundColor = this.config.getAdherenceVeryLateColor();
				break;
			case VehicleAdherenceType.unknown:
				break;
		}

		return adherenceColor;
	};

	/**
	 * get the vehicle headway color
	 *
	 * @param vehicleHeadway - the headway type
	 * @returns  the vehicle headway color
	 */
	private getVehicleHeadwayColor = (vehicleHeadway: VehicleHeadwayType): VehicleColor => {
		const headwayColor: VehicleColor = {
			backgroundColor: this.config.getDefaultVehicleColor(),
			foreColor: this.config.getDefaultVehicleTextColor(),
		};

		switch (vehicleHeadway) {
			case VehicleHeadwayType.veryClose:
				headwayColor.backgroundColor = this.config.getHeadwayVeryCloseColor();
				break;
			case VehicleHeadwayType.close:
				headwayColor.backgroundColor = this.config.getHeadwayCloseColor();
				break;
			case VehicleHeadwayType.acceptable:
				headwayColor.backgroundColor = this.config.getHeadwayAcceptableColor();
				break;
			case VehicleHeadwayType.veryDistant:
				headwayColor.backgroundColor = this.config.getHeadwayVeryDistantColor();
				break;
			case VehicleHeadwayType.distant:
				headwayColor.backgroundColor = this.config.getHeadwayDistantColor();
				break;
			case VehicleHeadwayType.unknown:
			default:
				break;
		}

		return headwayColor;
	};

	/**
	 * get the vehicle color
	 *
	 * @param colorVehicleBy - the type to color vehicle by (route/schedule/headway)
	 * @param predictability - the vehicle predictability
	 * @param routeId - the route id
	 * @param routeColor  - the route color
	 * @param vehicleAdherenceColor - the vehicle adherence color
	 * @param vehicleHeadwayColor  - the vehicle headway color
	 * @returns the vehicle color
	 */
	private getVehicleColor = (
		colorVehicleBy: ColorVehicleByType,
		predictability: string,
		routeId: string,
		routeColor: VehicleColor,
		vehicleAdherenceColor: VehicleColor,
		vehicleHeadwayColor: VehicleColor
	): VehicleColor => {
		const vehicleColor: VehicleColor = {
			backgroundColor: this.config.getDefaultVehicleColor(),
			foreColor: this.config.getDefaultVehicleTextColor(),
		};

		if (routeId && predictability === 'OFF_ROUTE') {
			vehicleColor.backgroundColor = this.config.getDefaultUnpredictableVehicleColor();
			vehicleColor.foreColor = this.config.getDefaultUnpredictableVehicleTextColor();
		} else {
			switch (colorVehicleBy) {
				case ColorVehicleByType.route:
					if (routeColor) {
						vehicleColor.backgroundColor = routeColor.backgroundColor;
						vehicleColor.foreColor = routeColor.foreColor;
					}
					break;
				case ColorVehicleByType.adherence:
					if (vehicleAdherenceColor) {
						vehicleColor.backgroundColor = vehicleAdherenceColor.backgroundColor;
						vehicleColor.foreColor = vehicleAdherenceColor.foreColor;
					}
					break;
				case ColorVehicleByType.headway:
					if (vehicleHeadwayColor) {
						vehicleColor.backgroundColor = vehicleHeadwayColor.backgroundColor;
						vehicleColor.foreColor = vehicleHeadwayColor.foreColor;
					}
			}
		}

		return vehicleColor;
	};

	/**
	 * get the adherence display class (i.e the style containing the icon value)
	 *
	 * @param adherenceType - the adherence type
	 * @returns the adherence icon style
	 */
	private getAdherenceTextClass = (adherenceType: VehicleAdherenceType): string => {
		let adherenceClass: string = null;

		switch (adherenceType) {
			case VehicleAdherenceType.veryEarly:
				adherenceClass = 'nb-map-vehicle-adherence-very-early-text';
				break;
			case VehicleAdherenceType.early:
				adherenceClass = 'nb-map-vehicle-adherence-early-text';
				break;
			case VehicleAdherenceType.onTime:
				adherenceClass = 'nb-map-vehicle-adherence-on-time-text';
				break;
			case VehicleAdherenceType.late:
				adherenceClass = 'nb-map-vehicle-adherence-late-text';
				break;
			case VehicleAdherenceType.veryLate:
				adherenceClass = 'nb-map-vehicle-adherence-very-late-text';
				break;
		}

		return adherenceClass;
	};

	/**
	 * get the adherence prefix (i.e +/-)
	 *
	 * @param adherenceSec - the adherence in seconds
	 * @returns the adherence prefix
	 */
	private getAdherencePrefix = (adherenceSec: number): VehicleAdherencePrefix => {
		let prefix: string = '';
		let seconds: number = adherenceSec;

		if (seconds > 0) {
			prefix = '-';
		} else if (seconds < 0) {
			prefix = '+';
			seconds = seconds * -1;
		}

		return { prefix, seconds };
	};
}
