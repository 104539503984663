/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import {
	Component,
	EventEmitter,
	Inject,
	Input,
	OnChanges,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	TemplateRef,
	ViewChild,
} from '@angular/core';

import { Subscription } from 'rxjs';

import { CONFIG_TOKEN, TimeFormatType, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TimeHelpers } from '@cubicNx/libs/utils';

import { MapNavigationService } from '../../../services/map-navigation.service';
import { MapOptionsService } from '../../../services/map-options.service';
import { MapEventsService } from '../../../services/map-events.service';
import { MapHistoryService } from '../../../services/map-history.service';
import { StateService } from '@cubicNx/libs/utils';
import { StopsDataService } from '../../../../../support-features/stops/services/stops-data.service';
import { SelectedAgency } from '../../../../../support-features/agencies/types/api-types';
import { AgenciesDataService } from '../../../../../support-features/agencies/services/agencies-data.service';
import { ColorUtilityService } from '@cubicNx/libs/utils';
import { MapStopsService } from '../../../services/map-stops.service';
import { MapActiveEntityService } from '../../../services/map-active-entity.service';
import { MapLocationService } from '../../../services/map-location.service';
import { MapReplayService } from '../../../services/map-replay.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { MapLocation, MapModeType, ModeType, VehicleDetailsActiveTab } from '../../../types/types';
import { StopDetailPredictionsList, StopDetailPredictionsListItem } from '../../../../../support-features/stops/types/types';
import { ResultContent } from '@cubicNx/libs/utils';
import { RoutePillData } from '@cubicNx/libs/utils';
import { EntityType } from '../../../../../utils/components/breadcrumbs/types/types';
import { AgencyConfig } from '../../../../../config/types/types';

import {
	Prediction,
	StopPrediction,
	StopPredictionsMap,
	StopRoute,
	StopWithRoutes,
} from '../../../../../support-features/stops/types/api-types';

import { ColumnType, Columns, CssClassType, PageRequestInfo, SelectedRowData, SortDirection, SortRequestInfo } from '@cubicNx/libs/utils';

import moment, { Moment } from 'moment';

@Component({
	selector: 'stop-details',
	templateUrl: './stop-details.component.html',
	styleUrls: ['./stop-details.component.scss'],
})
export class StopDetailsComponent extends TranslateBaseComponent implements OnInit, OnDestroy, OnChanges {
	@Input() stopCode: string = null;

	@Output() enableRefresh: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild('routePillColumnTemplate', { static: true, read: TemplateRef }) routePillColumnTemplate: TemplateRef<any>;

	public stopLoaded: boolean = false;

	public modeType: typeof ModeType = ModeType;
	public mapModeType: typeof MapModeType = MapModeType;

	public listName: string = 'stop-predictions-list';
	public sortInfo: SortRequestInfo = { sort: 'predictedArrivalTime', sortDir: SortDirection.asc };
	public columns: Columns = [];
	public stopDetailPredictionsList: StopDetailPredictionsList = [];
	public listLoadingIndicator: boolean = true;
	public stopRoutes: Array<RoutePillData> = [];
	public stop: StopWithRoutes = null;

	private readonly routeColumnName: string = 'route';
	private readonly directionColumnName: string = 'direction';
	private readonly vehicleColumnName: string = 'vehicle';
	private readonly predictionColumnName: string = 'predictedArrivalTime';

	private predictions: StopPredictionsMap = null;
	private authorityId: string = null;
	private agencyId: string = null;
	private agencyTimezone: string = null;

	private navigateRefresh$Subscription: Subscription = null;
	private refreshPoll$Subscription: Subscription = null;

	private initialized: boolean = false;
	private lastStopPollSuccess: boolean = false;

	private predictedArrivalTimeInterval: any = null;

	private listCacheContainer: any = {};
	private cacheFields: string[] = ['sortInfo'];

	constructor(
		public mapOptionsService: MapOptionsService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private mapEventsService: MapEventsService,
		private stopsDataService: StopsDataService,
		private stateService: StateService,
		private mapHistoryService: MapHistoryService,
		private mapStopsService: MapStopsService,
		private mapActiveEntityService: MapActiveEntityService,
		private mapLocationService: MapLocationService,
		private agenciesDataService: AgenciesDataService,
		private colorUtilityService: ColorUtilityService,
		private mapNavigationService: MapNavigationService,
		private mapReplayService: MapReplayService,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialize the stop detaiils page
	 */
	public async ngOnInit(): Promise<void> {
		this.setSubscriptions();

		await this.loadTranslations();

		this.loadCache();

		this.buildListColumns();

		this.init();
	}

	/**
	 * handle any changes to the component input values. If a new stop id is set - we need
	 * to reinitialize for that stop
	 *
	 * @param changes - the details of the change of our inputs
	 */
	public async ngOnChanges(changes: SimpleChanges): Promise<void> {
		if (changes.stopCode && !changes.stopCode.firstChange) {
			if (changes.stopCode.previousValue !== changes.stopCode.currentValue) {
				this.init();
			}
		}
	}

	/**
	 * return to the users previous history location
	 */
	public goBack = (): void => {
		this.mapHistoryService.goBack();
	};

	/**
	 * handle the user toggle of the stop
	 */
	public toggleRenderStop = (): void => {
		if (this.stopDisplayed()) {
			this.mapStopsService.removeStop(this.stop.stop_code);
		} else {
			this.mapStopsService.addStop(this.stop);
			this.mapStopsService.zoomToStop(this.stop);
			this.mapActiveEntityService.setActiveEntity(this.stopCode, EntityType.stop);
		}
	};

	/**
	 * handle the zoom click trigger the zoom to the stop
	 *
	 * if the stop is already rendered - simply zoom to it. If not then render the stop (the zoom will occur automatically when rendered)
	 */
	public zoomTo = (): void => {
		if (this.stopDisplayed()) {
			this.zoomToStop();
		} else {
			// toggle on the stop - it will zoom to location once it's added
			this.toggleRenderStop();
		}
	};

	/**
	 * determine if zoom should be enabled (enabled if stop displayed but disabled when in ladder mode)
	 *
	 * @returns whether the zoom should be enabled
	 */
	public zoomEnabled = (): boolean => {
		return this.mapOptionsService.getMode() !== ModeType.ladder && this.stopDisplayed();
	};

	/**
	 * determine if the stop is displayed on the map
	 * @returns true if the stop is displayed on the map
	 */
	public stopDisplayed = (): boolean => {
		return this.mapStopsService.stopDisplayed(this.stop.stop_code);
	};

	/**
	 * handle the selection of a row in the prediction list table and navigate to vehicle/route details dependant on the column clicked
	 * @param selectedRow - the row selected by the user
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const selectedPrediction: StopDetailPredictionsListItem = selectedRow.row;

		if (selectedRow.columnNameSelected === this.routeColumnName) {
			this.navigateToRouteDetails(selectedPrediction.routeId);
		} else if (selectedRow.columnNameSelected === this.vehicleColumnName) {
			this.navigateToVehicleDetails(selectedPrediction.vehicle.value);
		}
	};

	/**
	 * handle the sort request triggered from our datatable
	 * @param sortInfo - the details about the sort (sort field/direction)
	 */
	public handleSortRequest = (sortInfo: PageRequestInfo): void => {
		this.sortInfo.sort = sortInfo.sort;
		this.sortInfo.sortDir = sortInfo.sortDir;

		this.cacheSortInfo();

		this.stopDetailPredictionsList = this.sortList(this.stopDetailPredictionsList);
	};

	/**
	 * navigate to the route details using the supplied route id
	 * @param routeId - the route to navigate to
	 */
	public navigateToRouteDetails = async (routeId: string): Promise<void> => {
		await this.mapNavigationService.navigateToRouteDetails(this.authorityId, routeId);
	};

	/**
	 * construct the route pill object using information retrieved about the route in the predictions list
	 *
	 * @param stopPredictionId - the prediction id
	 * @returns the route pill data object used to populate our route pill badges
	 */
	public determinePredictedRoutePillData = (stopPredictionId: string): RoutePillData => {
		// Look up data from stored presiction details
		const stopPrediction: StopPrediction = this.predictions[stopPredictionId];

		return this.createRoutePillData(
			stopPrediction.route_id,
			stopPrediction.route_short_name,
			stopPrediction.route_long_name,
			stopPrediction.route_color,
			stopPrediction.route_text_color
		);
	};

	/**
	 * handle any clean up - unsubscribe from our subscriptions
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();

		this.mapActiveEntityService.setActiveEntity(null, EntityType.stop);

		if (this.predictedArrivalTimeInterval) {
			clearInterval(this.predictedArrivalTimeInterval);
		}
	}

	/**
	 * navigate to the vehicle details using the supplied vehicle id
	 * @param vehicleId - the vehicle to navigate to
	 */
	private navigateToVehicleDetails = async (vehicleId: string): Promise<void> => {
		if (vehicleId !== null) {
			await this.mapNavigationService.navigateToVehicleDetails(this.authorityId, vehicleId, VehicleDetailsActiveTab.summary);
		}
	};

	/**
	 * load the current agency details
	 */
	private loadAgencyDetails = (): void => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.authorityId = selectedAgency.authority_id;
		this.agencyId = selectedAgency.agency_id;

		this.agencyTimezone = this.agenciesDataService.getAgencyTimezone(this.authorityId, this.agencyId);
	};

	/**
	 * sort the list by route name (client side sorting)
	 *
	 * @param stopDetailPredictionsList - the prediction list to sort
	 * @returns the prediction list sorted by route name
	 */
	private sortByRoute = (stopDetailPredictionsList: StopDetailPredictionsList): StopDetailPredictionsList => {
		return stopDetailPredictionsList.sort((aItem: StopDetailPredictionsListItem, bItem: StopDetailPredictionsListItem) =>
			aItem.routeName.localeCompare(bItem.routeName)
		);
	};

	/**
	 * sort the list by direction (client side sorting)
	 *
	 * @param stopDetailPredictionsList - the prediction list to sort
	 * @returns the prediction list sorted by direction
	 */
	private sortByDirection = (stopDetailPredictionsList: StopDetailPredictionsList): StopDetailPredictionsList => {
		return stopDetailPredictionsList.sort((aItem: StopDetailPredictionsListItem, bItem: StopDetailPredictionsListItem) =>
			aItem.direction.localeCompare(bItem.direction)
		);
	};

	/**
	 * sort the list by vehicle (client side sorting)
	 *
	 * @param stopDetailPredictionsList - the prediction list to sort
	 * @returns the prediction list sorted by rvehicle
	 */
	private sortByVehicle = (stopDetailPredictionsList: StopDetailPredictionsList): StopDetailPredictionsList => {
		return stopDetailPredictionsList.sort((aItem: StopDetailPredictionsListItem, bItem: StopDetailPredictionsListItem) =>
			aItem.vehicle.value.localeCompare(bItem.vehicle.value)
		);
	};

	/**
	 * sort the list by arrival time (client side sorting)
	 *
	 * @param stopDetailPredictionsList - the prediction list to sort
	 * @returns the prediction list sorted by arrival time
	 */
	private sortByPredictedArrival = (stopDetailPredictionsList: StopDetailPredictionsList): StopDetailPredictionsList => {
		return stopDetailPredictionsList.sort((aItem: StopDetailPredictionsListItem, bItem: StopDetailPredictionsListItem) =>
			aItem.predictedArrivalTimeRaw.isAfter(bItem.predictedArrivalTimeRaw)
				? 1
				: bItem.predictedArrivalTimeRaw.isAfter(aItem.predictedArrivalTimeRaw)
					? -1
					: 0
		);
	};

	/**
	 * main entry point for the list sort
	 *
	 * @param stopDetailPredictionsList - the prediction list to sort
	 * @returns the sorted prediction list
	 */
	private sortList = (stopDetailPredictionsList: StopDetailPredictionsList): StopDetailPredictionsList => {
		switch (this.sortInfo.sort) {
			case this.routeColumnName: {
				stopDetailPredictionsList = this.sortByRoute(stopDetailPredictionsList);
				break;
			}
			case this.directionColumnName: {
				stopDetailPredictionsList = this.sortByDirection(stopDetailPredictionsList);
				break;
			}
			case this.vehicleColumnName: {
				stopDetailPredictionsList = this.sortByVehicle(stopDetailPredictionsList);
				break;
			}
			case this.predictionColumnName: {
				stopDetailPredictionsList = this.sortByPredictedArrival(stopDetailPredictionsList);
				break;
			}
		}

		if (this.sortInfo.sortDir === SortDirection.desc) {
			stopDetailPredictionsList = stopDetailPredictionsList.reverse();
		}

		return stopDetailPredictionsList;
	};

	/**
	 * handle any initialization actions.
	 *
	 * load the stop and associated prediction data
	 */
	private init = async (): Promise<void> => {
		this.enableRefresh.emit(false);

		this.resetStopDetails();

		// perform in init so we pick up potential new agency from ngOnChanges i.e when a stop for a different agency is selected
		// note:
		this.loadAgencyDetails();

		await this.loadStop();

		if (this.stop) {
			// show loading during init only - not during poll of data
			this.listLoadingIndicator = true;

			await this.loadStopPredictions();

			this.listLoadingIndicator = false;

			if (this.stopDisplayed()) {
				this.mapActiveEntityService.setActiveEntity(this.stopCode, EntityType.stop);

				this.zoomToStop();
			}

			this.initialized = true;
		}

		this.enableRefresh.emit(true);
	};

	/**
	 * load translations for the page
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.UNAVAILABLE',
			'T_MAP.MAP_VIEW_ZOOM_TO',
			'T_MAP.MAP_VIEW_TOGGLE_VISIBILITY_TOOLTIP',
			'T_MAP.MAP_ROUTE',
			'T_MAP.MAP_DIRECTION',
			'T_MAP.MAP_VEHICLE',
			'T_MAP.MAP_PREDICTION',
			'T_MAP.MAP_NO_PREDICTIONS',
			'T_MAP.MAP_AGO',
		]);
	};

	/**
	 * load the cache for the page (sort info for the prediction list)
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.sortInfo) {
			this.sortInfo = cacheContainer['sortInfo'];
		}
	};

	/**
	 * cache for sort details selected by the user
	 */
	private cacheSortInfo = (): void => {
		this.listCacheContainer['sortInfo'] = this.sortInfo;

		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * load the stop from our data service layer
	 */
	private loadStop = async (): Promise<void> => {
		const result: ResultContent = await this.stopsDataService.getStopByCode(this.authorityId, this.agencyId, this.stopCode);

		if (result.success) {
			this.stop = result.resultData;
			this.stop.authority_id = this.authorityId;

			this.determineStopRoutes(this.stop);

			this.stopLoaded = true;

			this.lastStopPollSuccess = result.success;
		}
	};

	/**
	 * construct a route pill object using supplied parameters
	 *
	 * @param routeId - the route id
	 * @param routeShortName - the route short name
	 * @param routeLongName - the route long name
	 * @param routeColor - the route color
	 * @param routeTextColor - the route text color
	 * @returns the route pill object
	 */
	private createRoutePillData = (
		routeId: string,
		routeShortName: string,
		routeLongName: string,
		routeColor: number,
		routeTextColor: number
	): RoutePillData => {
		return {
			routeId,
			routeShortName,
			routeLongName,
			routeColor: this.colorUtilityService.getColor(routeColor),
			routeTextColor: this.colorUtilityService.getColor(routeTextColor),
		};
	};

	/**
	 * construct the route pill object using information retrieved about the route
	 *
	 * @param stopRoute - the route for the stop
	 * @returns the route pill data object used to populate our route pill badges
	 */
	private determineStopRoutePill = (stopRoute: StopRoute): RoutePillData => {
		return this.createRoutePillData(
			stopRoute.route_id,
			stopRoute.route_short_name,
			stopRoute.route_long_name,
			stopRoute.route_color,
			stopRoute.route_text_color
		);
	};

	/**
	 * determine the routes for the stop
	 * @param stop - the stop details
	 */
	private determineStopRoutes = (stop: StopWithRoutes): void => {
		this.stopRoutes = [];

		for (const key in stop.routes) {
			this.stopRoutes.push(this.determineStopRoutePill(stop.routes[key]));
		}
	};

	/**
	 * load stop predictions from the stop data layer
	 */
	private loadStopPredictions = async (): Promise<void> => {
		const predictionsResult: ResultContent = await this.stopsDataService.getStopPredictions(
			this.authorityId,
			this.agencyId,
			this.stop.stop_id,
			this.stop.stop_code
		);

		if (predictionsResult.success) {
			this.predictions = predictionsResult.resultData as StopPredictionsMap;

			this.buildPredictionList();
		}
	};

	/**
	 * build the prediction list from the prediction data mapping nextbus API 'underscore' property names to the expected
	 * UI camel case format for the list
	 *
	 * set a timer to update the list every second when we have chosen relative time in the map options
	 */
	private buildPredictionList = (): void => {
		const stopDetailPredictionsList: StopDetailPredictionsList = [];

		let curMoment: Moment = moment().tz(this.agencyTimezone).subtract(5, 'minutes');

		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			const replayTime: number = this.mapReplayService.getCurrentReplayTime();

			curMoment = moment(replayTime).tz(this.agencyTimezone).subtract(5, 'minutes');
		}

		for (const stopPredictionKey in this.predictions) {
			const stopPrediction: StopPrediction = this.predictions[stopPredictionKey];

			if (stopPrediction.predictions) {
				stopPrediction.predictions.forEach((prediction: Prediction) => {
					if (prediction.predicted_arrival) {
						const predictionTimeRaw: Moment = moment(prediction.predicted_arrival).tz(this.agencyTimezone);

						if (!curMoment.isAfter(predictionTimeRaw)) {
							const listItem: StopDetailPredictionsListItem = {
								id: stopPredictionKey,
								routeId: stopPrediction.route_id,
								routeName: stopPrediction.route_short_name,
								direction: this.getDirection(prediction.trip_headsign, prediction.trip_short_name),
								vehicle: this.mapVehicle(stopPrediction.vehicle_id),
								predictedArrivalTime: this.getArrivalTime(predictionTimeRaw),

								/* We'll keep this to sort as the arrival time value can be different formats (i.e clock/relative time)
                                   and can also display negative values (i.e 26 secs ago etc) */
								predictedArrivalTimeRaw: predictionTimeRaw,
							};

							stopDetailPredictionsList.push(listItem);
						}
					}
				});
			}
		}

		this.stopDetailPredictionsList = this.sortList(stopDetailPredictionsList);

		this.predictedArrivalTimeInterval = setInterval(() => {
			if (this.mapOptionsService.getTimeFormat() === TimeFormatType.relativeTime) {
				this.stopDetailPredictionsList.forEach((prediction) => {
					prediction.predictedArrivalTime = this.getArrivalTime(prediction.predictedArrivalTimeRaw);
				});

				this.stopDetailPredictionsList = [...this.stopDetailPredictionsList];
			}
		}, 1000);
	};

	/**
	 * map the vehicle id to the expected style format for the list
	 * @param vehicleId - the vehicle id
	 * @returns the css styling for the list
	 */
	private mapVehicle = (vehicleId: string): CssClassType => {
		return {
			className: 'nb-text',
			tooltip: vehicleId,
			value: vehicleId,
		};
	};

	/**
	 * map the direction to the expected style format for the list
	 * @param tripHeadsign - the trip headsign
	 * @param tripShortName - the trip short name
	 * @returns the css styling for the list
	 */
	private getDirection = (tripHeadsign: string, tripShortName: string): string => {
		let direction: string = '';

		if (tripHeadsign && tripHeadsign.length > 0) {
			direction = tripHeadsign;
		} else if (tripShortName && tripShortName.length > 0) {
			direction = tripShortName;
		}

		return direction.replace(/\//g, ' ');
	};

	/**
	 * get the arrival time in the appropriate format for the list
	 * @param predictionMoment - the predicted arrival time in moment format
	 * @returns the arrival time in the correct format
	 */
	private getArrivalTime = (predictionMoment: Moment): string => {
		const format: TimeFormatType = this.mapOptionsService.getTimeFormat();

		const arivalTime: Moment = moment(predictionMoment).tz(this.agencyTimezone);

		let replayTime: number = null;

		if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
			replayTime = this.mapReplayService.getCurrentReplayTime();
		}

		return TimeHelpers.getTimeByUserSelectedFormat(
			arivalTime,
			format,
			this.agencyTimezone,
			this.translations['T_MAP.MAP_AGO'],
			replayTime
		);
	};

	/**
	 * build the vehicles list columns for our data table
	 */
	private buildListColumns = (): void => {
		this.columns = [
			{
				name: this.routeColumnName,
				displayName: this.translations['T_MAP.MAP_ROUTE'],
				componentTemplate: this.routePillColumnTemplate,
				columnType: ColumnType.component,
				width: 150,
			},
			{
				name: this.directionColumnName,
				displayName: this.translations['T_MAP.MAP_DIRECTION'],
				columnType: ColumnType.text,
				width: 170,
			},
			{
				name: this.vehicleColumnName,
				displayName: this.translations['T_MAP.MAP_VEHICLE'],
				columnType: ColumnType.cssClass,
				width: 55,
			},
			{
				name: this.predictionColumnName,
				displayName: this.translations['T_MAP.MAP_PREDICTION'],
				columnType: ColumnType.text,
				width: 105,
			},
		];
	};

	/**
	 * handle any subsbriptions.
	 *
	 * refresh - the click of the refresh icon contained within the parent
	 *
	 * poll - the regular poll update triggered by the polling service
	 */
	private setSubscriptions = (): void => {
		this.navigateRefresh$Subscription = this.mapEventsService.navigateRefresh.subscribe(() => {
			this.handleNavigateRefresh();
		});

		this.refreshPoll$Subscription = this.mapEventsService.pollRefresh.subscribe(async () => {
			await this.handleRefreshStopDetails();
		});
	};

	/**
	 * handle the refresh button and re-initaize the page.
	 *
	 * as we dont support block details in replay mode, revert to the menu when in that mode
	 */
	private handleNavigateRefresh = async (): Promise<void> => {
		this.init();
	};

	/**
	 * handle the refresh of the page - triggered from our poll subscription
	 */
	private handleRefreshStopDetails = async (): Promise<void> => {
		// make sure we are initialized so we don't attempt a refresh at the same time
		if (this.initialized) {
			// essentially stop updates if the stop isn't returned the last time.
			// this handles an edge case where the stop isn't returned and we keep polling with the error
			if (this.lastStopPollSuccess) {
				// it is essentially telling the parent navigation component to disable the refresh while the list is loading.
				this.enableRefresh.emit(false);

				await this.loadStopPredictions();

				this.enableRefresh.emit(true);
			}
		}
	};

	/**
	 * reset the stop details
	 */
	private resetStopDetails = (): void => {
		this.stopLoaded = false;
		this.stop = null;
		this.predictions = null;
		this.stopDetailPredictionsList = [];
	};

	/**
	 * zoom to the stop location on the map
	 */
	private zoomToStop = (): void => {
		const mapLocation: MapLocation = {
			lat: this.stop.stop_lat,
			lon: this.stop.stop_lon,
			zoom: this.config.getMaxZoomLevel(),
			offsetAdjust: true,
			clearTrackedVehicle: true,
		};

		this.mapLocationService.setLocation(mapLocation);
	};

	/**
	 * unsubscribe from our subscriptions for clean up
	 */
	private unsubscribe = (): void => {
		this.navigateRefresh$Subscription?.unsubscribe();
		this.refreshPoll$Subscription?.unsubscribe();
	};
}
