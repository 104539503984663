import { Inject, Injectable } from '@angular/core';

import { CurrentUserUtilService } from './current-user-utils.service';

import { PermissionRequest, PermissionRequestActionType } from '../../types/types';
import { Role, Roles } from '../../../../features/admin-tools/users/types/api-types';
import { UserLogin } from '../../types/api-types';
import { CONFIG_TOKEN } from '@cubicNx/libs/utils';
import { AgencyConfig } from '../../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class CurrentUserPermissionService {
	private readonly putAuthorityVehiclePermission: string = 'put_authority_vehicles_vehicle';
	private readonly postAuthorityVehicleMessagesPermission: string = 'post_vehicle_messages';
	private readonly postAuthorityDisablePredictionsPermission: string = 'post_disable_predictions';

	private adminRoleName: string = this.config.getAdminAgencyRoleName();

	constructor(
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private currentUserUtilService: CurrentUserUtilService
	) {}

	/**
	 * Method to check if the supplied agency has any of the supplied permissions
	 *
	 * @param permissions - the list of permissions to check
	 * @param authorityId - the current authority id
	 * @param agencyId - the current agency id
	 * @returns a flag indicating if the user has permission
	 */
	public agencyHasPermissionsTo = (permissions: Array<string>, authorityId: string, agencyId: string): boolean => {
		let hasAnyPermission: boolean = false;

		permissions.forEach((permission: string) => {
			if (this.verifyUserAgencyPermissions(permission, authorityId, agencyId)) {
				hasAnyPermission = true;
			}
		});

		return hasAnyPermission;
	};

	/**
	 * checks user perssions for a particular action type
	 *
	 * @param action - the permission action type to check
	 * @param permissionRequest - the permission object with details about the context of the check
	 * @returns true if the user has permission for the actiob
	 */
	public hasPermissionTo = (action: PermissionRequestActionType, permissionRequest: PermissionRequest): boolean => {
		const curUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (!curUser) {
			return false;
		}

		switch (action) {
			case PermissionRequestActionType.editAgency:
				return this.isNextbusAdmin() || this.isAdmin(permissionRequest);
			case PermissionRequestActionType.viewUserList:
				return this.isNextbusAdmin() || this.isAdmin(permissionRequest) || this.isPWAdmin();
			case PermissionRequestActionType.viewSchedulesList:
				return this.isNextbusAdmin() || this.isAdmin(permissionRequest) || this.isPWAdmin();
			case PermissionRequestActionType.updateUserStatus:
				return (this.isNextbusAdmin() || this.isAdmin(permissionRequest)) && !this.isActiveUser(permissionRequest);
			case PermissionRequestActionType.editUser:
				return this.isNextbusAdmin() || this.isAdmin(permissionRequest) || this.isActiveUser(permissionRequest) || this.isPWAdmin();
			case PermissionRequestActionType.vehicleReassign:
				return this.canPerformRequest(permissionRequest, this.putAuthorityVehiclePermission);
			case PermissionRequestActionType.sendVehicleMessages:
				return this.canPerformRequest(permissionRequest, this.postAuthorityVehicleMessagesPermission);
			case PermissionRequestActionType.postDisablePredictions:
				return this.canPerformRequest(permissionRequest, this.postAuthorityDisablePredictionsPermission);
			default:
				return false;
		}
	};

	/**
	 * determines if the user is a nextbus admin
	 * @returns true if the user is a nextbus admin
	 */
	public isNextbusAdmin = (): boolean => {
		const curUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (!curUser) {
			return false;
		}

		return !!(curUser.global?.roles && curUser.global?.roles.indexOf(this.config.getAdminMasterRoleName()) >= 0);
	};

	/**
	 * determines if the user is an admin for the passed in credentials (i.e agency)
	 *
	 * @param permissionRequest - permission requestd etails including the user/agency
	 * @returns true if the user is an admin
	 */
	public isAdmin = (permissionRequest: PermissionRequest): boolean => {
		let isAdmin: boolean = false;

		if (this.isNextbusAdmin()) {
			isAdmin = true;
		} else {
			const curUser: UserLogin = this.currentUserUtilService.getCurrentUser();

			if (curUser.authorities) {
				let authority: any;

				const hasAuthority: boolean =
					typeof permissionRequest?.authorityId !== 'undefined' && permissionRequest.authorityId !== null;

				const hasAgency: boolean = typeof permissionRequest?.agencyId !== 'undefined' && permissionRequest.agencyId !== null;

				if (permissionRequest?.user) {
					if (permissionRequest.user.user_roles && permissionRequest.user.nb_id) {
						// allow if the current user is an admin in at least 1 agency where the user has a role
						const userRoles: Roles = permissionRequest.user.user_roles;

						if (userRoles.length === 0) {
							if (this.isAdminInUserAgency(curUser, userRoles)) {
								isAdmin = true;
							}
						} else {
							if (
								userRoles.length === 1 &&
								userRoles[0].associated_role &&
								userRoles[0].associated_role.name.indexOf(this.config.getBasicUserRoleName()) >= 0
							) {
								isAdmin = true;
							} else {
								userRoles.forEach((userRole: Role) => {
									if (userRole.agency_info) {
										authority = curUser.authorities[userRole.agency_info.authority_id];
										const agencyId: string = userRole.agency_info.agency_id;

										if (authority) {
											if (authority.agencies[agencyId]) {
												authority.agencies[agencyId].roles.forEach((role: string) => {
													if (role.includes(this.adminRoleName)) {
														isAdmin = true;
													}
												});
											}
										}
									}
								});
							}
						}
					} else {
						if (hasAuthority) {
							authority = curUser.authorities[permissionRequest.authorityId];

							if (authority && authority.agencies[permissionRequest.agencyId]) {
								authority.agencies[permissionRequest.agencyId].roles.forEach((role: string) => {
									if (role.includes(this.adminRoleName)) {
										isAdmin = true;
									}
								});

								if (authority.agencies[permissionRequest.agencyId].roles.indexOf(this.adminRoleName) >= 0) {
									isAdmin = true;
								}
							}
						} else {
							if (this.isAdminInAnyAgency(curUser)) {
								isAdmin = true;
							}
						}
					}
				}

				if (!isAdmin) {
					if (hasAuthority) {
						authority = curUser.authorities[permissionRequest.authorityId];

						if (authority) {
							if (!hasAgency) {
								// Case (like schedule) where a permission is at the authority level.
								// Until Authority-level role assignment is done in the UI, we use the
								// rule that an admin in any agency in the authority can do authority-level admin actions.
								for (const agencyIdKey in authority.agencies) {
									authority.agencies[agencyIdKey].roles.forEach((role: string) => {
										if (role.includes(this.adminRoleName)) {
											isAdmin = true;
										}
									});
								}
							} else {
								if (authority.agencies[permissionRequest.agencyId]) {
									authority.agencies[permissionRequest.agencyId].roles.forEach((role: string) => {
										if (role.includes(this.adminRoleName)) {
											isAdmin = true;
										}
									});
								}
							}
						}
					} else {
						if (!hasAgency) {
							for (const auKey in curUser.authorities) {
								if (curUser.authorities[auKey]) {
									for (const agKey in curUser.authorities[auKey].agencies) {
										curUser.authorities[auKey].agencies[agKey].roles.forEach((role: string) => {
											if (role.includes(this.adminRoleName)) {
												isAdmin = true;
											}
										});
									}
								}
							}
						}
					}
				}
			}
		}

		return isAdmin;
	};

	/**
	 * determines if the user is an authority admin for the passed in credentials (i.e agency)
	 *
	 * @param permissionRequest - permission requestd etails including the user/agency
	 * @returns true if the user is an authority admin
	 */
	public isAuthorityAdmin = (permissionRequest: PermissionRequest): boolean => {
		if (this.isNextbusAdmin()) {
			return true;
		}

		const curUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (!curUser) {
			return false;
		}

		if (!curUser.authorities) {
			return false;
		}

		if (typeof permissionRequest.authorityId !== 'undefined' && permissionRequest.authorityId !== null) {
			if (
				curUser.authorities[permissionRequest.authorityId] &&
				curUser.authorities[permissionRequest.authorityId].roles &&
				curUser.authorities[permissionRequest.authorityId].roles.length > 0 &&
				curUser.authorities[permissionRequest.authorityId].roles[0].includes(this.adminRoleName)
			) {
				return true;
			}
		}

		if (
			(typeof permissionRequest.authorityId === 'undefined' || permissionRequest.authorityId === null) &&
			(typeof permissionRequest.agencyId === 'undefined' || permissionRequest.agencyId === null)
		) {
			for (const auKey in curUser.authorities) {
				if (
					curUser.authorities[auKey].roles &&
					curUser.authorities[auKey].roles.length > 0 &&
					curUser.authorities[auKey].roles[0].includes(this.adminRoleName)
				) {
					return true;
				}
			}
		}

		return false;
	};

	/**
	 * determines if the user is an password admin for the passed in credentials (i.e agency)
	 *
	 * @param permissionRequest - permission requestd etails including the user/agency
	 * @returns true if the user is an password admin
	 */
	public isPWAdmin = (): boolean => {
		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (
			currentUser.authorities &&
			currentUser.authorities[currentUser.primary_agency_authority_id].agencies['1'].roles.includes('T_USER.PASSWORD_ADMINISTRATOR')
		) {
			return true;
		} else {
			return false;
		}
	};

	/**
	 * verifies user permissions. A UmoAdmin 'super user' always returns true
	 *
	 * @param action - the action to check
	 * @param authorityId - the current authority
	 * @param agencyId - the current agency
	 * @returns true if permission passes the check
	 */
	public verifyUserAgencyPermissions = (action: string, authorityId: string, agencyId: string): boolean => {
		if (this.isNextbusAdmin()) {
			return true;
		}

		if (!authorityId) {
			return false;
		}

		const authority: any = this.userObjectAuthorityAuthorization(authorityId);

		if (!authority?.agencies) {
			return false;
		}

		const agencyAuthorization: any = this.userObjectAgencyAuthorization(authorityId, agencyId);

		if (agencyAuthorization) {
			return agencyAuthorization.permissions.indexOf(action) > -1;
		} else {
			return false;
		}
	};

	/**
	 * determines if the user is an admin within their agency
	 *
	 * note: not sure this method is needed as it is currently only called when there are no user roles so will always return false.
	 * also.. not sure the system allows a state of no user roles (at least via the creating/editing users via this app)
	 *
	 * @param curUser - the current user
	 * @param targetUserRoles - the roles assiggned to the user
	 * @returns true if the user is an admin within the agency
	 */
	private isAdminInUserAgency = (curUser: UserLogin, targetUserRoles: Roles): boolean => {
		let found: boolean = false;

		for (const authorityKey in curUser.authorities) {
			if (curUser.authorities[authorityKey]) {
				for (const agencyIdKey in curUser.authorities[authorityKey].agencies) {
					targetUserRoles.forEach((role: Role) => {
						if (role.agency_info?.authority_id === authorityKey && role.agency_info?.agency_id === agencyIdKey) {
							curUser.authorities[authorityKey].agencies[agencyIdKey].roles.forEach((role: string) => {
								if (role.includes(this.adminRoleName)) {
									found = true;
								}
							});
							found = true;
						}
					});
				}
			}
		}

		return found;
	};

	/**
	 * determines if the user is an admin within any agency
	 *
	 * @param curUser - the current user details
	 * @returns true if the user is an admin within any agency
	 */
	private isAdminInAnyAgency = (curUser: UserLogin): boolean => {
		let found: boolean = false;

		for (const authorityKey in curUser.authorities) {
			for (const agencyIdKey in curUser.authorities[authorityKey].agencies) {
				curUser.authorities[authorityKey].agencies[agencyIdKey].roles.forEach((role: string) => {
					if (role.includes(this.adminRoleName)) {
						found = true;
					}
				});
			}
		}

		return found;
	};

	/**
	 * determines if the user passed in the logged in user (we may be selecting an account for a different user)
	 *
	 * @param permissionRequest - the permission details including the user selected
	 * @returns true if the user is the same as the logged in user
	 */
	private isActiveUser = (permissionRequest: PermissionRequest): boolean => {
		return permissionRequest.user.nb_id === this.currentUserUtilService.getCurrentUser().nb_id;
	};

	/**
	 * determines if a user can perform a request
	 * @param request - the permission requestd etails including the user details
	 * @param requiredPermission - the permission to check
	 * @returns true if the user can perform the request
	 */
	private canPerformRequest = (request: PermissionRequest, requiredPermission: string): boolean => {
		if (this.isNextbusAdmin()) {
			return true;
		}

		let canPerform: boolean = false;

		if (request && request.authorityId && request.agencyId) {
			const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

			if (currentUser.authorities) {
				const authorityForUser: any = currentUser.authorities[request.authorityId];

				if (authorityForUser) {
					const agencyPermissions: any = authorityForUser.agencies[request.agencyId].permissions;

					canPerform = agencyPermissions.some((permission: any) => permission === requiredPermission);
				}
			}
		}

		return canPerform;
	};

	/**
	 * checks if the user details contains a particular agency
	 *
	 * @param authorityId - the authority id to check
	 * @param agencyId  - the agency id to check
	 * @returns true when the agency exists
	 */
	private userObjectAgencyAuthorization = (authorityId: string, agencyId: string): void => {
		const authorities: any = this.userObjectAuthorityAuthorization(authorityId);

		if (authorities) {
			return authorities.agencies[agencyId];
		} else {
			return null;
		}
	};

	/**
	 * checks if the user details contains a particular authority
	 *
	 * @param authorityId - the authority id to check
	 * @param agencyId  - the agency id to check
	 * @returns true when the authority exists
	 */
	private userObjectAuthorityAuthorization = (authorityId: string): any => {
		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (currentUser.authorities) {
			return currentUser.authorities[authorityId];
		} else {
			return null;
		}
	};
}
