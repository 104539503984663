import { Inject, Injectable, OnDestroy } from '@angular/core';

import { AsyncTimeout, CONFIG_TOKEN } from '@cubicNx/libs/utils';
import { MapEventsService } from './map-events.service';
import { MapOptionsService } from './map-options.service';
import { MapRoutesService } from './map-routes.service';
import { LoggerService } from '@cubicNx/libs/utils';

import { AgencyConfig } from '../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapPollingService implements OnDestroy {
	private defaultPollingActive: boolean = true;

	private pollInterval: number = 10000;
	private pollTimer: AsyncTimeout = null;

	constructor(
		private mapOptionsService: MapOptionsService,
		private mapRoutesService: MapRoutesService,
		private mapEventsService: MapEventsService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private logger: LoggerService
	) {}

	/**
	 * handle any cleanup for the service (clear the map polling)
	 */
	public ngOnDestroy(): void {
		this.clearPolling();
	}

	/**
	 * initialize the map polling with the polling interval value. iIf no polling interval is set then
	 * use the default
	 *
	 * @param pollInterval - the polling interval value
	 */
	public initPolling = (pollInterval?: number): void => {
		if (pollInterval) {
			this.defaultPollingActive = false;
		} else {
			this.defaultPollingActive = true;

			pollInterval = this.config.getMapRefreshRate() * 1000;
		}

		this.pollInterval = pollInterval;

		this.clearPolling();

		if (!this.mapOptionsService.getLiveUpdatesPaused()) {
			this.initPollTimer();
		} else {
			this.logger.logInfo('live updates paused - no polling');
		}
	};

	/**
	 * stop map polling
	 */
	public stopPolling = (): void => {
		this.clearPolling();
	};

	/**
	 * pause live polling
	 */
	public pauseLivePolling = (): void => {
		this.logger.logInfo('pausing live ops polling');

		this.mapOptionsService.setLiveUpdatesPaused(true);
		this.clearPolling();
	};

	/**
	 * resume live polling
	 */
	public resumeLivePolling = (): void => {
		this.logger.logInfo('resuming live ops polling');

		this.clearPolling();

		this.mapOptionsService.setLiveUpdatesPaused(false);

		this.initPollTimer();
	};

	/**
	 * clear the polling timer
	 */
	public clearPolling = (): void => {
		if (this.pollTimer) {
			this.pollTimer.cancel();
			this.pollTimer = null;
		}
	};

	/**
	 * initialize the poll timer
	 */
	private initPollTimer = (): void => {
		this.logger.logInfo('Polling initialising - refreshing map state every ' + this.pollInterval + ' milliseconds.');

		// fire an initial refresh before we start the polling so we don't have to wait 10 seconds
		if (!this.mapOptionsService.getLiveUpdatesPaused()) {
			this.publishPollRefresh();
		}

		this.startPollTimer();
	};

	/**
	 * start the async poll timer
	 */
	private startPollTimer = async (): Promise<void> => {
		this.pollTimer = new AsyncTimeout();

		if (this.defaultPollingActive) {
			// if we are showing more routes - slow down so we aren't working to hard and asking too much of the system.
			// typically vehicles only update every minute or so. We typically poll every 10 seconds to get this change
			// as early as possible. If we have more routes on and a lot of vehicles, then it is assumed the user is
			// less interested in specific data and updating sooner probably isn't necessary
			if (this.mapRoutesService.getRouteCount() > 10) {
				this.pollInterval = this.config.getMapRefreshRate() * 3 * 1000;
			} else if (this.mapRoutesService.getRouteCount() > 5) {
				this.pollInterval = this.config.getMapRefreshRate() * 2 * 1000;
			}
		}

		this.pollTimer.start(this.pollInterval).then(async () => {
			await this.pollData();
		});
	};

	/**
	 * the poll data event - publish an event for subscribers to update and start the next timer
	 */
	private pollData = async (): Promise<void> => {
		this.publishPollRefresh();

		this.startPollTimer();
	};

	/**
	 * publish an event for subscribers to update
	 */
	private publishPollRefresh = (): void => {
		this.mapEventsService.publishPollRefresh();
	};
}
