/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, OnInit, ChangeDetectorRef, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';

import { StringHelpers } from '@cubicNx/libs/utils';
import { ObjectHelpers } from '@cubicNx/libs/utils';

import { UsersAdminDataService } from '../services/users-admin-data.service';
import { UsersAdminModalService } from '../services/users-admin-modal.service';
import { CurrentUserUtilService } from '../../../../support-features/login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../../../support-features/login/services/current-user/current-user-permission.service';
import { StateService } from '@cubicNx/libs/utils';
import { ExporterService } from '@cubicNx/libs/utils';
import { NotificationService } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';
import { BreadcrumbItems } from '../../../../utils/components/breadcrumbs/types/types';
import { UserLogin } from '../../../../support-features/login/types/api-types';
import { AgencyConfig } from '../../../../config/types/types';

import { ExportData, ExportObject, ExportProperty, ExportColumnType } from '@cubicNx/libs/utils';
import { UserListItem, SelectedUserFilters, UsersList, UserFiltersUpdate } from '../types/types';
import { Role, Roles, UserDetail, UserDetails, UserFiltersRequest, UsersPaginatedResponse, UsersPaginatedParams } from '../types/api-types';

import { Columns, ColumnType, ListSortings, PageRequestInfo, SelectedRowData, SortDirection } from '@cubicNx/libs/utils';

@Component({
	selector: 'user-admin-list',
	templateUrl: './user-admin-list.component.html',
	styleUrls: ['./user-admin-list.component.scss'],
})
export class UserAdminListComponent extends TranslateBaseComponent implements OnInit {
	// pass through the default sorting for the underlying ngx-datatable
	public readonly defaultSortings: ListSortings = [{ prop: 'username', dir: SortDirection.asc }];

	public hasFilters: boolean = false;
	public listName: string = 'users-admin-list';
	public columns: Columns = [];
	public usersList: UsersList = [];
	public listLoadingIndicator: boolean = true;
	public selectedUserDeactivated: boolean = false;
	public pageInfo: PageRequestInfo;
	public totalRows: number = 0;
	public initailized: boolean = false;
	public breadcrumbItems: BreadcrumbItems = [];
	public isPasswordAdminUser: boolean = false;
	public selectedUser: UserDetail = null;
	public searchText: string = '';

	private readonly exportFileName: string = 'UsersList';
	private readonly exportDateFormat: string = 'M/DD/YY h:mm A';

	private selectedUserFilters: SelectedUserFilters = { agencies: [], statuses: [], roles: [] };
	private usersDetails: UserDetails;
	private listCacheContainer: any = {};
	private cacheFields: string[] = ['search', 'pageInfo', 'filter'];

	constructor(
		private usersAdminDataService: UsersAdminDataService,
		private usersAdminModalService: UsersAdminModalService,
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private exporterService: ExporterService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private notificationService: NotificationService,
		private stateService: StateService,
		private router: Router,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the users list
	 *
	 * loading translations, initialisng breadcrumbs and building the list columns
	 */
	public async ngOnInit(): Promise<void> {
		await this.loadTranslations();

		this.initBreadcrumbs();

		this.loadCache();

		this.buildListColumns();

		this.initailized = true;

		if (this.currentUserPermissionService.isPWAdmin()) {
			this.isPasswordAdminUser = true;
		}
	}

	/**
	 * updates the contents of the users list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleDataRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;

		this.cachePageInfo(this.pageInfo);

		this.getUsers();
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = (searchText: string): void => {
		this.searchText = searchText;

		// we can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a search
		this.pageInfo.pageNum = 1;

		this.cacheSearch(this.searchText);

		this.getUsers();
	};

	/**
	 * presents the user search filter dialog
	 * if it is updated and saved the filter criteria is applied to the list
	 */
	public openFilter = (): void => {
		this.usersAdminModalService.openFilter(this.selectedUserFilters).subscribe((userFiltersUpdate: UserFiltersUpdate) => {
			if (userFiltersUpdate.saved) {
				// has the updated filter changed?
				if (!ObjectHelpers.deepEquals(userFiltersUpdate.selectedUserFilters, this.selectedUserFilters)) {
					this.applyUpdatedFilter(userFiltersUpdate.selectedUserFilters);
				}
			}
		});
	};

	/**
	 * clears down the user search filter and retrieves the users list again
	 */
	public clearFilter = (): void => {
		const filter: SelectedUserFilters = {
			agencies: [],
			statuses: [],
			roles: [],
		};

		this.applyUpdatedFilter(filter);
	};

	/**
	 * action taken when a user is selecred from the list - navigates to the selected user details
	 *
	 * @param selectedRow - the selected row information
	 */
	public onSelect = (selectedRow: SelectedRowData): void => {
		const user: UserListItem = selectedRow.row;

		this.router.navigate(['/admintools/users/profile', user.id]);
	};

	/**
	 * gets the selected user from the list (use index 0 as the users list is configured to only allow one row to be checked)
	 *
	 * @param users - users list
	 */
	public onCheckSelect = (users: UsersList): void => {
		this.selectedUserDeactivated = false;
		this.selectedUser = null;

		if (users.length > 0) {
			this.selectedUser = this.getSelectedUser(users[0].id);
			this.selectedUserDeactivated = this.selectedUser.status === 'Deactivated';
		}
	};

	/**
	 * exports the current users list to file in CSV format
	 */
	public onExportCsv = async (): Promise<void> => {
		const users: UserDetails = await this.getUnpagedUsers();

		// Convert into the export format
		const exportData: ExportData = this.createExportData(this.exportFileName, users);

		// Request the exporter service to perform the export to CSV format
		this.exporterService.exportToCSV(exportData);
	};

	/**
	 * presents the create new user dialog. if the user saved the details, then the users list is refreshed
	 * to reflect the new user
	 */
	public create = (): void => {
		this.usersAdminModalService.openUserEditor(null, true).subscribe((saved: boolean) => {
			if (saved) {
				this.getUsers();
			}
		});
	};

	/**
	 * presents the edit existing user dialog. if the user saved the details, then the users list is refreshed
	 * to reflect the edit
	 */
	public edit = (): void => {
		this.usersAdminModalService.openUserEditor(this.selectedUser, false).subscribe((saved: boolean) => {
			if (saved) {
				this.getUsers();
			}
		});
	};

	/**
	 * presents the deactivate user confirmation dialog. if the user confirms the deactiviation then the users list is refreshed
	 * to reflect the deactiviation
	 */
	public deactivate = (): void => {
		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (this.selectedUser.nb_id !== currentUser.nb_id) {
			const message: string = this.translations['T_USER.USER_DEACTIVATE_CONFIRM'];
			const header: string = this.translations['T_USER.USER_DEACTIVATE_CONFIRM_HEADING'];
			const successMsg: string = this.translations['T_USER.USER_DEACTIVATE_SUCCESS'];
			const failureMsg: string = this.translations['T_USER.USER_STATUS_UPDATE_ERROR'];

			this.usersAdminModalService
				.confirmStatusChange(this.selectedUser, 'Deactivated', 'ACCOUNT_DEACTIVATED', message, header, successMsg, failureMsg)
				.subscribe((updated: boolean) => {
					if (updated) {
						this.getUsers();
					}
				});
		} else {
			this.notificationService.notifyError(this.translations['T_USER.USER_DEACTIVATE_OWN_ACCOUNT']);
		}
	};

	/**
	 * presents the enable user confirmation dialog. if the user confirms the action then the users list is refreshed
	 * to reflect the user being enabled
	 */
	public enable = (): void => {
		const message: string = this.translations['T_USER.USER_ENABLE_CONFIRM'];
		const header: string = this.translations['T_USER.USER_ENABLE_CONFIRM_HEADING'];
		const successMsg: string = this.translations['T_USER.USER_ENABLE_SUCCESS'];
		const failureMsg: string = this.translations['T_USER.USER_STATUS_UPDATE_ERROR'];

		this.usersAdminModalService
			.confirmStatusChange(this.selectedUser, 'Active', 'ACCOUNT_ENABLED', message, header, successMsg, failureMsg)
			.subscribe((updated: boolean) => {
				if (updated) {
					this.getUsers();
				}
			});
	};

	/**
	 * loads the translations presented within the users list
	 */
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations([
			'T_CORE.USERNAME',
			'T_CORE.NAME',
			'T_CORE.EMAIL',
			'T_CORE.LAST_LOGIN',
			'T_CORE.STATUS',
			'T_CORE.ADMIN_TOOLS',
			'T_CORE.USERS',
			'T_USER.USER_DEACTIVATE_OWN_ACCOUNT',
			'T_USER.USER_DEACTIVATE_CONFIRM',
			'T_USER.USER_DEACTIVATE_CONFIRM_HEADING',
			'T_USER.USER_DEACTIVATE_SUCCESS',
			'T_USER.USER_ENABLE_CONFIRM',
			'T_USER.USER_ENABLE_CONFIRM_HEADING',
			'T_USER.USER_ENABLE_SUCCESS',
			'T_USER.USER_STATUS_UPDATE_ERROR',
			'T_CORE.EMAIL',
			'T_USER.SMS_NUM',
			'T_USER.LAST_LOG',
			'T_CORE.CREATED',
			'T_USER.DEACTIVATED_DATE',
			'T_USER.LOCKED_DATE',
			'T_USER.PASSWORD_CHANGED_DATE',
			'T_USER.USER_ROLES',
			'T_CORE.ADMINISTRATOR',
			'T_USER.PASSWORD_ADMINISTRATOR',
			'T_USER.USER_STATUS.NEW',
			'T_USER.USER_STATUS.ACTIVE',
			'T_USER.USER_STATUS.INACTIVE',
			'T_USER.USER_STATUS.PASSWORDEXPIRED',
			'T_USER.USER_STATUS.DEACTIVATED',
			'T_USER.USER_STATUS.LOCKED',
			'T_USER.ACCOUNT_ENABLED',
			'T_USER.ACCOUNT_DEACTIVATED',
		]);
	};

	/**
	 * initialises the user list breadcrumbs
	 */
	private initBreadcrumbs = (): void => {
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.ADMIN_TOOLS'], targetPath: '/admintools', activePage: false });
		this.breadcrumbItems.push({ displayText: this.translations['T_CORE.USERS'], activePage: true });
	};

	/**
	 * builds the users list columns
	 */
	private buildListColumns = (): void => {
		// build the column list for the underlying datatable - camel case equivalents of properties from back end
		this.columns = [
			{
				name: 'username',
				displayName: this.translations['T_CORE.USERNAME'],
				columnType: ColumnType.text,
			},
			{
				name: 'realName',
				displayName: this.translations['T_CORE.NAME'],
				columnType: ColumnType.text,
			},
			{
				name: 'email',
				displayName: this.translations['T_CORE.EMAIL'],
				columnType: ColumnType.text,
			},
			{
				name: 'lastLogin',
				displayName: this.translations['T_CORE.LAST_LOGIN'],
				columnType: ColumnType.dateFromNow,
				format: 'MMM d, yyyy',
			},
			{
				name: 'status',
				displayName: this.translations['T_CORE.STATUS'],
				columnType: ColumnType.text,
			},
		];
	};

	/**
	 * retrieves last used search and page information for the list
	 */
	private loadCache = (): void => {
		const cacheContainer: any = this.stateService.mapLoadAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);

		if (cacheContainer.search) {
			this.searchText = cacheContainer['search'];
		}

		if (cacheContainer.pageInfo) {
			this.pageInfo = cacheContainer['pageInfo'];
		}

		if (cacheContainer.filter) {
			this.selectedUserFilters = cacheContainer['filter'];
			this.hasFilters = this.hasActiveFilters(this.selectedUserFilters);
		}
	};

	/**
	 * saves the current list page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	private cachePageInfo = (pageInfo: PageRequestInfo): void => {
		this.listCacheContainer['pageInfo'] = pageInfo;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current searhed for text
	 *
	 * @param search - the current searched for text
	 */
	private cacheSearch = (search: string): void => {
		this.listCacheContainer['search'] = search;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * saves the current user filter criteria
	 *
	 * @param filter - the current user search filter criteria
	 */
	private cacheFilter = (filter: SelectedUserFilters): void => {
		this.listCacheContainer['filter'] = filter;
		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.cacheFields);
	};

	/**
	 * maps the user filter data to an equivalent filter request instance
	 *
	 * @param selectedUserFilters - users filter criteria
	 * @returns filter request
	 */
	private mapUserFilters = (selectedUserFilters: SelectedUserFilters): UserFiltersRequest => {
		// map our selected user filter to the simplified format for the outgoing data request
		const filters: UserFiltersRequest = {
			agencies: [],
			roles: [],
			status: [],
		};

		if (this.selectedUserFilters !== null) {
			if (this.selectedUserFilters.agencies) {
				filters.agencies = selectedUserFilters.agencies.map((item) => ({ id: item.nb_id }));
			}

			if (this.selectedUserFilters.roles) {
				filters.roles = selectedUserFilters.roles.map((item) => ({ id: item.nb_id }));
			}

			if (this.selectedUserFilters.statuses) {
				filters.status = selectedUserFilters.statuses.map((item) => ({ code: item.code }));
			}
		}

		return filters;
	};

	/**
	 * retrieves list of users that match the current page information and search text, filter and populates the list accordingly
	 */
	private getUsers = async (): Promise<void> => {
		this.listLoadingIndicator = true;

		// revert page settings
		this.selectedUserDeactivated = false;
		this.selectedUser = null;

		this.totalRows = 0;

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: UsersPaginatedParams = {
			pageNum: this.pageInfo.pageNum,
			pageSize: this.pageInfo.pageSize,
			sort: StringHelpers.getUnderscoreValueFromCamelCase(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
			filters: this.mapUserFilters(this.selectedUserFilters),
		};

		const result: ResultContent = await this.usersAdminDataService.getUsers(paginatedParams);

		if (result.success) {
			const usersResponse: UsersPaginatedResponse = result.resultData;

			if (usersResponse?.results) {
				// store the full list for use later
				this.usersDetails = usersResponse.results;

				this.usersList = usersResponse.results.map((user) => ({
					id: user.nb_id,
					username: user.username,
					realName: user.real_name,
					email: user.email,
					lastLogin: user.last_login,
					status: user.status,
				}));

				this.totalRows = usersResponse.total;
			}
		}

		this.listLoadingIndicator = false;

		this.changeDetectorRef.detectChanges();
	};

	/**
	 * retrieves list of users that match the current search text, filter and populates the list accordingly.
	 * note there is no paging within the request
	 * @returns unpaged list of users matching search criteria
	 */
	private getUnpagedUsers = async (): Promise<UserDetails> => {
		let users: UserDetails = [];

		// convert client side camel side notation for field name back to underscore version for back end request
		const paginatedParams: UsersPaginatedParams = {
			pageNum: null,
			pageSize: null,
			sort: StringHelpers.getUnderscoreValueFromCamelCase(this.pageInfo.sort),
			sortDir: this.pageInfo.sortDir,
			search: this.searchText,
			filters: this.mapUserFilters(this.selectedUserFilters),
		};

		const result: ResultContent = await this.usersAdminDataService.getUsers(paginatedParams);

		if (result.success) {
			const usersResponse: UsersPaginatedResponse = result.resultData;

			if (usersResponse?.results) {
				users = usersResponse.results;
			}
		}

		return users;
	};

	/**
	 * retrieves selected user details
	 *
	 * @param userId - user id
	 * @returns user details matching the supplied id
	 */
	private getSelectedUser = (userId: number): UserDetail => {
		return this.usersDetails.filter((user) => user.nb_id === userId)[0];
	};

	/**
	 * when the user filter is changed and confirmed, this method applies the filter criteria.
	 * meaning users request is made and list reflected
	 *
	 * @param updatedFilter - the user filter criteria
	 */
	private applyUpdatedFilter = (updatedFilter: SelectedUserFilters): void => {
		// we can get 0 results if we ask for a page larger than the result set - makes sense just to set back to
		// one when intigating a filter
		this.pageInfo.pageNum = 1;

		// store updated selected user filters
		this.selectedUserFilters = updatedFilter;

		this.cacheFilter(this.selectedUserFilters);

		this.hasFilters = this.hasActiveFilters(this.selectedUserFilters);

		this.getUsers();
	};

	/**
	 * utility to determine if there is an active filter being applied
	 *
	 * @param selectedUserFilters - the selected user filter data
	 * @returns true if there are active filters
	 */
	private hasActiveFilters = (selectedUserFilters: SelectedUserFilters): boolean => {
		let hasFilters: boolean = false;

		if (selectedUserFilters) {
			if (selectedUserFilters.agencies.length > 0) {
				hasFilters = true;
			}

			if (selectedUserFilters.statuses.length > 0) {
				hasFilters = true;
			}

			if (selectedUserFilters.roles.length > 0) {
				hasFilters = true;
			}
		}

		return hasFilters;
	};

	/**
	 * creates the users list in a format that can be exported
	 *
	 * @param exportFileName - export file name
	 * @param users - users
	 * @returns export data of the supplied users
	 */
	private createExportData = (exportFileName: string, users: UserDetails): ExportData => {
		const exportData: ExportData = {
			fileName: exportFileName,
			headers: this.createExportHeaders(),
			data: [],
		};

		users.forEach((user) => {
			const entry: ExportObject = {
				properties: [],
			};

			const userStatusTranslation: string = this.translations['T_USER.USER_STATUS.' + user.status.toUpperCase()];

			entry.properties.push(this.createExportProperty(user.username, ExportColumnType.text));
			entry.properties.push(this.createExportProperty(user.real_name, ExportColumnType.text));
			entry.properties.push(this.createExportProperty(user.email, ExportColumnType.text));
			entry.properties.push(this.createExportProperty(user.sms_number, ExportColumnType.text));
			entry.properties.push(this.createExportProperty(user.last_login, ExportColumnType.date, this.exportDateFormat));
			entry.properties.push(this.createExportProperty(userStatusTranslation, ExportColumnType.text));
			entry.properties.push(this.createExportProperty(user.created, ExportColumnType.date, this.exportDateFormat));
			entry.properties.push(this.createExportProperty(user.deactivated_date, ExportColumnType.date, this.exportDateFormat));
			entry.properties.push(this.createExportProperty(user.lock_date, ExportColumnType.date, this.exportDateFormat));
			entry.properties.push(this.createExportProperty(user.password_last_changed_date, ExportColumnType.date, this.exportDateFormat));
			entry.properties.push(this.createExportProperty(this.createExportRole(user.user_roles), ExportColumnType.text));

			exportData.data.push(entry);
		});

		return exportData;
	};

	/**
	 * creates the export data headers
	 *
	 * @returns the export data headers
	 */
	private createExportHeaders(): string[] {
		return [
			this.translations['T_CORE.USERNAME'],
			this.translations['T_CORE.NAME'],
			this.translations['T_CORE.EMAIL'],
			this.translations['T_USER.SMS_NUM'],
			this.translations['T_USER.LAST_LOG'],
			this.translations['T_CORE.STATUS'],
			this.translations['T_CORE.CREATED'],
			this.translations['T_USER.DEACTIVATED_DATE'],
			this.translations['T_USER.LOCKED_DATE'],
			this.translations['T_USER.PASSWORD_CHANGED_DATE'],
			this.translations['T_USER.USER_ROLES'],
		];
	}

	/**
	 * creates an export property instance from the supplied data
	 *
	 * @param propertyValue - property value
	 * @param propertyType - property type
	 * @param propertyFormmater - property formatter
	 * @returns export property instance
	 */
	private createExportProperty(propertyValue: any, propertyType: ExportColumnType, propertyFormmater: string = null): ExportProperty {
		const property: ExportProperty = {
			value: propertyValue,
			type: propertyType,
			dateFormatter: propertyFormmater,
		};

		return property;
	}

	/**
	 * creates an exported role string
	 *
	 * @param userRoles - user roles
	 * @returns formatted user roles
	 */
	private createExportRole(userRoles: Roles): string {
		const adminMasterRoleName: string = this.config.getAdminMasterRoleName();

		// Concatenate this users roles together for each agency
		let userRoleColumn: string = '';

		userRoles.forEach((role: Role) => {
			if (role.agency_info || role.associated_role.name === adminMasterRoleName) {
				if (role.agency_info) {
					userRoleColumn +=
						'<' + role.agency_info.agency_name + ' : ' + this.translations[role.associated_role.international_name] + '> ; ';
				} else {
					userRoleColumn += '<' + role.associated_role.name + '> ; ';
				}
			}
		});

		// Remove additional '; ' at the end
		return userRoleColumn.slice(0, -2);
	}
}
