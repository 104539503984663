import { InjectionToken } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { LogLevel } from '../services/logging/types/types';

export const CONFIG_TOKEN: InjectionToken<Config> = new InjectionToken<Config>('config');

export interface Config {
	appId: string;
	minLogLevel: string;
	apiPath: string;
	defaultListSize: number;
	paginationMaxSize: number;
	paginationMaxNumPages: number;
	defaultHttpRequestTimeout: number;

	init(http: HttpClient): Promise<void>;

	getAppId(): string;
	getMinLogLevel(): LogLevel;
	getApiUrl(): string;
	getDefaultPageSize(): number;
	getPaginationMaxSize(): number;
	getPaginationMaxNumPages(): number;
	getDefaultHttpRequestTimeout(): number;
}
