/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import L from 'leaflet';

import { MapEventsService } from '../map-events.service';
import { MapNavigationService } from '../map-navigation.service';
import { MapOptionsService } from '../map-options.service';
import { MapVehiclesService } from '../map-vehicles.service';
import { MapVehiclesTooltipMarkerService } from './map-vehicles-tooltip-marker.service';

import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';

import { EventExtended, HTMLElementExtended, MapVehicle, MarkerExtended, Markers, VehicleDetailsActiveTab } from '../../types/types';

@Injectable({
	providedIn: 'root',
})
export class MapVehiclesLabelMarkerService {
	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private markerClusterGroup: L.MarkerClusterGroup = null;
	private selectedAgency: SelectedAgency = null;
	private clusterRadiusUpdateInProgress: boolean = false;

	constructor(
		private mapVehiclesService: MapVehiclesService,
		private mapOptionsService: MapOptionsService,
		private mapNavigationService: MapNavigationService,
		private mapVehiclesTooltipMarkerService: MapVehiclesTooltipMarkerService,
		private mapEventsService: MapEventsService
	) {}

	/**
	 * initialize the vehicle labels marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapInstance = mapInstance;

		this.initVehicleLabelClusterGroup();

		this.markers = {};
	};

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.clearVehicleLabels();

		this.markers = {};
	};

	/**
	 * add the vehicle label
	 *
	 * @param vehicleId - vehicle id of the vehicle to add
	 */
	public addVehicleLabel = async (vehicleId: string): Promise<void> => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			this.markers[vehicleId] = new L.Marker(new L.LatLng(vehicle.lat, vehicle.lon), {
				icon: this.mapVehiclesTooltipMarkerService.getIcon(vehicle),
				zIndexOffset: 5000,
			});

			// hack to add our custom content - there is probably a better way that requires extending the leaflet classes
			// note: needed for getting the vehicleId during mouse over events in the cluster
			this.markers[vehicleId].vehicleId = vehicleId;

			this.markers[vehicleId].on('mouseover', () => {
				this.handleMouseOver(vehicleId);
			});

			this.markers[vehicleId].on('mouseout', () => {
				this.handleMouseOut(vehicleId);
			});

			this.markerClusterGroup.addLayer(this.markers[vehicleId]);
		}
	};

	/**
	 * update the vehicle label
	 *
	 * @param vehicleId - the vehicle id
	 */
	public updateVehicleLabel = (vehicleId: string): void => {
		if (this.markers[vehicleId]) {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

			this.markers[vehicleId].setLatLng([vehicle.lat, vehicle.lon]);
			this.markers[vehicleId].setIcon(this.mapVehiclesTooltipMarkerService.getIcon(vehicle));
		}
	};

	/**
	 * remove the vehicle label
	 *
	 * @param vehicleId - the vehicle id
	 */
	public removeVehicleLabel = (vehicleId: string): void => {
		if (this.markers[vehicleId]) {
			this.markers[vehicleId].remove();
			this.markerClusterGroup.removeLayer(this.markers[vehicleId]);
			delete this.markers[vehicleId];
		}
	};

	/**
	 * clear all vehicle labels
	 */
	public clearVehicleLabels = (): void => {
		for (const vehicleId in this.markers) {
			this.removeVehicleLabel(vehicleId);
		}
	};

	/**
	 * refresh all vehicle labels
	 * */
	public refreshVehicleLabels = (): void => {
		this.clearVehicleLabels();

		for (const vehicleKey in this.mapVehiclesService.getVehicles()) {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleKey);

			if (!vehicle.hidden) {
				if (this.mapOptionsService.vehicleLabelsAreShowing()) {
					this.addVehicleLabel(vehicle.vehicleId);
				}
			}
		}
	};

	/**
	 * refresh vehicles layer
	 *
	 * @param reloadVehicleLabels - when true - reload vehicle labels
	 */
	public refreshVehiclesLabelLayer = (reloadVehicleLabels: boolean): void => {
		if (!this.clusterRadiusUpdateInProgress) {
			this.clusterRadiusUpdateInProgress = true;

			this.clearVehicleLabels();
			this.mapInstance.removeLayer(this.markerClusterGroup);
			delete this.markerClusterGroup;

			this.initVehicleLabelClusterGroup();

			if (reloadVehicleLabels) {
				this.refreshVehicleLabels();
			}

			this.clusterRadiusUpdateInProgress = false;
		}
	};

	/**
	 * initialize the vehicle label cluster group and add mouse over listeners
	 */
	private initVehicleLabelClusterGroup = (): void => {
		this.markerClusterGroup = new L.MarkerClusterGroup({
			iconCreateFunction: this.iconCreateFunction,
			spiderfyOnMaxZoom: false,
			zoomToBoundsOnClick: false, // stops zooming to markers on click
			chunkedLoading: true,
			showCoverageOnHover: false,
			removeOutsideVisibleBounds: true,
			maxClusterRadius: (): number => this.mapVehiclesService.getVehicleLabelClusterRadius(),
		});

		this.markerClusterGroup.on('clustermouseover', (e: any) => {
			// There isn't an obvious way of establishing which individual label
			// we are hovering over, as the cluster is one big marker.  It's the css
			// of the individual labels that handle the on hover to expand. Simpler to
			// add the click handlers for each label - it's not expensive to do so
			e.layer.getAllChildMarkers().forEach((marker: MarkerExtended) => {
				this.addClickListeners(marker.vehicleId);
			});
		});

		this.markerClusterGroup.on('clustermouseout', (e: any) => {
			e.layer.getAllChildMarkers().forEach((marker: MarkerExtended) => {
				this.removeClickListeners(marker.vehicleId);
			});
		});

		this.mapInstance.addLayer(this.markerClusterGroup);
	};

	/**
	 * helper function for the initVehicleLabelClusterGroup method
	 *
	 * @param cluster - the vehicle label cluster
	 * @returns a leaflet div icon to display on the map
	 */
	private iconCreateFunction = (cluster: any): L.DivIcon => {
		const labels: any = cluster.getAllChildMarkers();

		let html: string = '';
		let iconAnchorX: number = null;
		let iconAnchorY: number = null;

		labels.forEach((label: any) => {
			html += label.options.icon.options.html;

			if (iconAnchorX === null || label.options.icon.options.iconAnchor[0] > iconAnchorX) {
				iconAnchorX = label.options.icon.options.iconAnchor[0];
			}

			if (iconAnchorY === null || label.options.icon.options.iconAnchor[1] > iconAnchorY) {
				iconAnchorY = label.options.icon.options.iconAnchor[1];
			}

			iconAnchorX += iconAnchorX;
			iconAnchorY += iconAnchorY;
		});

		return L.divIcon({
			className: 'label-cluster',
			html,
			iconAnchor: [iconAnchorX, iconAnchorY],
		});
	};

	/**
	 * handle mouse over for the vehicle labels (and add click listeners)
	 *
	 * @param vehicleId - the vehicle id
	 */
	private handleMouseOver = (vehicleId: string): void => {
		// make sure any other tooltips are removed (not label expand to 'tooltip' format using css)
		this.mapEventsService.publishRemoveTooltips();

		this.addClickListeners(vehicleId);
	};

	/**
	 * handle mouse out for the vehicle labels (and remove click listeners)
	 *
	 * @param vehicleId - the vehicle id
	 */
	private handleMouseOut = (vehicleId: string): void => {
		this.removeClickListeners(vehicleId);
	};

	/**
	 * add click listeners for the vehicle labels
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addClickListeners = (vehicleId: string): void => {
		this.addVehicleIdListeners(vehicleId);
		this.addRouteIdClickListener(vehicleId);
		this.addBlockIdClickListener(vehicleId);
	};

	/**
	 * remove click listeners for the vehicle labels
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeClickListeners = (vehicleId: string): void => {
		this.removeVehicleIdClickListeners(vehicleId);
		this.removeRouteClickListener(vehicleId);
		this.removeBlockClickListener(vehicleId);
	};

	/**
	 * add vehicle id click listeners
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			// setup click listeners for vehicle id clicks (we will typically have the 1 vehicle id but could
			// be more when we have linked vehicles)
			vehicle.vehicleIds.forEach((vehicleId: string) => {
				this.addVehicleIdClickListener(vehicleId);
			});
		}
	};

	/**
	 * add vehicle id click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElementExtended = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.vehicleId = vehicleId;
			vehicleIdElement.addEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * remove vehicle id click listeners
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListeners = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			vehicle.vehicleIds.forEach((vehicleId: string) => {
				this.removeVehicleIdClickListener(vehicleId);
			});
		}
	};

	/**
	 * remove vehicle id click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeVehicleIdClickListener = (vehicleId: string): void => {
		const vehicleIdElement: HTMLElement = document.getElementById('vehicleId' + vehicleId + 'Click');

		if (vehicleIdElement) {
			vehicleIdElement.removeEventListener('click', this.onVehicleIdClick);
		}
	};

	/**
	 * handle vehicle id click - navigate to the vehicle details page
	 *
	 * @param evt - the vehicle id click event including the vehicle id
	 */
	private onVehicleIdClick = async (evt: EventExtended): Promise<void> => {
		const vehicleId: string = evt.currentTarget.vehicleId;

		await this.mapNavigationService.navigateToVehicleDetails(
			this.selectedAgency.authority_id,
			vehicleId,
			VehicleDetailsActiveTab.summary
		);
	};

	/**
	 * add route id click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addRouteIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const routeIdElement: HTMLElementExtended = document.getElementById('vehicleRouteId' + vehicleId + '_' + vehicle.routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.routeId = vehicle.routeId;
			routeIdElement.addEventListener('click', this.onRouteIdClick);
		}
	};

	/**
	 * remove route click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeRouteClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			this.removeRouteIdClickListener(vehicleId, vehicle.routeId);
		}
	};

	/**
	 * remove route id click listener
	 *
	 * @param vehicleId - the vehicle id
	 * @param routeId - the route id
	 */
	private removeRouteIdClickListener = (vehicleId: string, routeId: string): void => {
		const routeIdElement: HTMLElement = document.getElementById('vehicleRouteId' + vehicleId + '_' + routeId + 'Click');

		if (routeIdElement) {
			routeIdElement.removeEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * handle route id click - navigate to the route details page
	 *
	 * @param evt - the route id click event including the route id
	 */
	private onRouteIdClick = async (evt: EventExtended): Promise<void> => {
		const routeId: string = evt.currentTarget.routeId;

		if (routeId) {
			await this.mapNavigationService.navigateToRouteDetails(this.selectedAgency.authority_id, routeId);
		}
	};

	/**
	 * add block id click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private addBlockIdClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		const blockIdElement: HTMLElementExtended = document.getElementById('blockId' + vehicle.blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.blockId = vehicle.blockId;
			blockIdElement.addEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * remove block click listener
	 *
	 * @param vehicleId - the vehicle id
	 */
	private removeBlockClickListener = (vehicleId: string): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			this.removeBlockIdClickListener(vehicle.blockId);
		}
	};

	/**
	 * remove block id click listener
	 *
	 * @param blockId - the block id
	 */
	private removeBlockIdClickListener = (blockId: string): void => {
		const blockIdElement: HTMLElement = document.getElementById('blockId' + blockId + 'Click');

		if (blockIdElement) {
			blockIdElement.removeEventListener('click', this.onBlockIdClick);
		}
	};

	/**
	 * handle block id click - navigate to the block details page
	 *
	 * @param evt - the block id click event including the block id
	 */
	private onBlockIdClick = async (evt: EventExtended): Promise<void> => {
		const blockId: string = evt.currentTarget.blockId;

		if (blockId && blockId !== '-' && blockId !== '—') {
			await this.mapNavigationService.navigateToBlockDetails(this.selectedAgency.authority_id, blockId);
		}
	};
}
