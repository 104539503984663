/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, EventEmitter, Inject, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';
import { SavedReportTemplateSelectorComponent } from '../../../saved-report-template-selector/saved-report-template-selector.component';

import { ReportsModalService } from '../../../services/reports-modal.service';
import { ReportsDataService } from '../../../services/reports-data.service';
import { ReportsUtilService } from '../../../services/reports-util.service';

import { PageRequestInfo } from '@cubicNx/libs/utils';
import { TranslationService } from '@cubicNx/libs/utils';

import { ResultContent } from '@cubicNx/libs/utils';

import {
	ReportTemplateDisplayDetail,
	ReportTemplateDisplayDetailAction,
	ReportTemplateDisplayDetails,
	ReportTemplateDisplayDetailsByCategory,
} from '../../../types/types';

import { ReportTemplateDetail, ReportTemplateDetails } from '../../../types/api-types';
import { AgencyConfig } from '../../../../../config/types/types';

@Component({
	selector: 'saved-report-templates-list',
	templateUrl: './saved-report-templates-list.component.html',
	styleUrls: ['./saved-report-templates-list.component.scss'],
})
export class SavedReportTemplatesListComponent extends TranslateBaseComponent implements OnInit {
	@ViewChild(SavedReportTemplateSelectorComponent) savedReportTemplateSelector: SavedReportTemplateSelectorComponent;

	@Output() setLoading: EventEmitter<boolean> = new EventEmitter<boolean>();

	public templateDisplayDatailsByCategory: ReportTemplateDisplayDetailsByCategory = null;
	public loading: boolean = false;
	public totalTemplates: number = 0;
	public pageInfo: PageRequestInfo = null;

	private readonly reportTemplateDetails: string = '/reports/template';
	private readonly sortField: string = 'name';
	private readonly sortDir: string = 'asc';

	private templateDetails: ReportTemplateDetails = null;
	private searchText: string = null;

	constructor(
		private reportsDataService: ReportsDataService,
		private reportsUtilService: ReportsUtilService,
		private reportsModalService: ReportsModalService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the saved reports list component
	 *
	 * setsup default page details
	 */
	public async ngOnInit(): Promise<void> {
		// get the page details for the initial request
		this.pageInfo = {
			pageNum: 1,
			pageSize: this.config.getDefaultPageSize(),
		};
	}

	/**
	 * refreshes the saved reports list
	 */
	public refresh = async (): Promise<void> => {
		await this.loadTemplateDisplayData();
	};

	/**
	 * updates the contents of the sav reports list dependent upon the supplied page information
	 *
	 * @param pageInfo - page information - page size, number and sort criteria
	 */
	public handleTemplateRequest = async (pageInfo: PageRequestInfo): Promise<void> => {
		this.pageInfo = pageInfo;
		await this.loadTemplateDisplayData();
	};

	/**
	 * searches the list for entries that match the supplied search text
	 *
	 * @param searchText - the items being searched for
	 */
	public search = async (searchText: string): Promise<void> => {
		this.searchText = searchText;
		await this.loadTemplateDisplayData();
	};

	/**
	 * navigates to the relevant report template details
	 *
	 * @param reportTemplateDisplayDetail - the report template display details
	 */
	public handleTemplateSelected = (reportTemplateDisplayDetail: ReportTemplateDisplayDetail): void => {
		const templateDetail: ReportTemplateDetail = this.getTemplateDetailsFromId(reportTemplateDisplayDetail.nbId);

		if (templateDetail) {
			this.router.navigate([
				this.reportTemplateDetails,
				templateDetail.report_options.authority.authority_id,
				templateDetail.report_options.agencies.agency_id,
				templateDetail.default_template_id,
				templateDetail.created_by_id,
				templateDetail.nb_id,
			]);
		}
	};

	/**
	 * orchestrates the action of the user interaction from the saved reports view
	 *
	 * @param reportTemplateDisplayDetailAction - the report template display detail action
	 */
	public handleActionClicked = async (reportTemplateDisplayDetailAction: ReportTemplateDisplayDetailAction): Promise<void> => {
		switch (reportTemplateDisplayDetailAction.action) {
			case 'VIEW':
				await this.handleViewClicked(reportTemplateDisplayDetailAction.reportTemplateDisplayDetails);
				break;
			case 'EDIT':
				await this.handleEditClicked(reportTemplateDisplayDetailAction.reportTemplateDisplayDetails);
				break;
			case 'RUN':
				await this.handleRunClicked(reportTemplateDisplayDetailAction.reportTemplateDisplayDetails);
				break;
			case 'DELETE':
				await this.handleDeleteClicked(reportTemplateDisplayDetailAction.reportTemplateDisplayDetails);
				break;
		}
	};

	/**
	 * builds the list of reports by category for display purposes allowing for any overrides set by the agency
	 */
	private loadTemplateDisplayData = async (): Promise<void> => {
		this.setLoading.emit(true);

		const templateDetailsReponse: ResultContent = await this.reportsDataService.getReportTemplates(
			this.pageInfo.pageNum,
			this.pageInfo.pageSize,
			this.sortField,
			this.sortDir,
			this.searchText
		);

		if (templateDetailsReponse.success) {
			this.templateDetails = templateDetailsReponse.resultData.results;

			this.templateDisplayDatailsByCategory = await this.reportsUtilService.getSavedReportTemplatesByCategory(this.templateDetails);

			this.totalTemplates = templateDetailsReponse.resultData.total;
		}

		this.setLoading.emit(false);
	};

	/**
	 * deals with view button being pressed
	 *
	 * navigates to the report details view
	 *
	 * @param reportTemplateDisplayDetails - the report template display details
	 */
	private handleViewClicked = (reportTemplateDisplayDetails: ReportTemplateDisplayDetails): void => {
		const templateDetail: ReportTemplateDetail = this.getTemplateDetailsFromId(reportTemplateDisplayDetails[0].nbId);

		if (templateDetail) {
			this.router.navigate([
				this.reportTemplateDetails,
				templateDetail.report_options.authority.authority_id,
				templateDetail.report_options.agencies.agency_id,
				templateDetail.default_template_id,
				templateDetail.created_by_id,
				templateDetail.nb_id,
			]);
		}
	};

	/**
	 * deals with the edit button being pressed
	 *
	 * retrieves report details and renders edit dialog
	 * if edited and saved, then the view is refreshed
	 *
	 * @param reportTemplateDisplayDetails - the report template display details
	 */
	private handleEditClicked = (reportTemplateDisplayDetails: ReportTemplateDisplayDetails): void => {
		const templateDetail: ReportTemplateDetail = this.getTemplateDetailsFromId(reportTemplateDisplayDetails[0].nbId);

		if (templateDetail) {
			this.reportsModalService.editReportTemplate(templateDetail).subscribe(async (reportEdited: boolean) => {
				if (reportEdited) {
					await this.loadTemplateDisplayData();
				}
			});
		}
	};

	/**
	 * deals with the run button being pressed
	 *
	 * retrieves report details and runs the report(s)
	 *
	 * @param reportTemplateDisplayDetails - the report template display details
	 */
	private handleRunClicked = async (reportTemplateDisplayDetails: ReportTemplateDisplayDetails): Promise<void> => {
		if (reportTemplateDisplayDetails) {
			const reportTemplateDetails: ReportTemplateDetails = this.getTemplateDetailsFromDisplayDetails(reportTemplateDisplayDetails);

			await this.reportsDataService.runReports(reportTemplateDetails);
		}
	};

	/**
	 * deals with the delete button being pressed
	 *
	 * retrieves report details and delete the report(s) refreshes the saved reports view
	 *
	 * @param reportTemplateDisplayDetails - the report template display details
	 */
	private handleDeleteClicked = async (reportTemplateDisplayDetails: ReportTemplateDisplayDetails): Promise<void> => {
		const reportTemplateDetails: ReportTemplateDetails = this.getTemplateDetailsFromDisplayDetails(reportTemplateDisplayDetails);

		if (reportTemplateDetails) {
			this.reportsModalService.deleteReportTemplates(reportTemplateDetails).subscribe(async (deleted: boolean) => {
				if (deleted) {
					await this.loadTemplateDisplayData();
				}
			});
		}
	};

	/**
	 * retrieves the report template details matching the supplied id
	 *
	 * @param nbId - report template id
	 * @returns the report template details
	 */
	private getTemplateDetailsFromId = (nbId: number): ReportTemplateDetail => {
		return this.templateDetails.filter((templateDetail: ReportTemplateDetail) => templateDetail.nb_id === nbId)[0];
	};

	/**
	 * retrievs the report template details from equivalent display details
	 *
	 * @param reportTemplateDisplayDetails - the report template display details
	 * @returns the report template details
	 */
	private getTemplateDetailsFromDisplayDetails = (reportTemplateDisplayDetails: ReportTemplateDisplayDetails): ReportTemplateDetails => {
		const reportTemplateDetails: ReportTemplateDetails = [];

		reportTemplateDisplayDetails.forEach((templateDisplayDetail: ReportTemplateDisplayDetail) => {
			reportTemplateDetails.push(
				this.templateDetails.find((templateDetail: ReportTemplateDetail) => templateDetail.nb_id === templateDisplayDetail.nbId)
			);
		});

		return reportTemplateDetails;
	};
}
