/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */
import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { v4 as uuidv4 } from 'uuid';

import { ModalMode } from '../dashboard-format-modal/dashboard-format-modal.component';
import { ConfirmModalComponent } from '@cubicNx/libs/utils';

import { CanDeactivateGuard } from '../../../routing/guard/deactivate-guard';

import { TranslationService } from '@cubicNx/libs/utils';
import { DashboardConfigService } from '../services/dashboard-config.service';
import { WidgetEventsService } from '../services/widget-events.service';
import { ComponentInjectionService } from '../services/component-injection.service';
import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';

import { ConfirmButtonType, ConfirmResult } from '@cubicNx/libs/utils';

interface IOption {
	id: string;
	order: number;
	title: string;
	selected: boolean;
}

interface IDashboard {
	id: string;
	order: number;
	config: IDashboardConfig;
}

interface IDashboardConfig {
	order: number;
	rows: IDashboardRow[];
	structure: string;
	title: string;
}

interface IDashboardRow {
	columns: IDashboardColumn[];
}

interface IDashboardColumn {
	cid: string;
	styleClass: string;
	widgets: IDashboardWidget[];
}

interface IDashboardWidget {
	wid: string;
	config: any;
	title: string;
	type: string;
}

@Component({
	selector: 'dashboard-main',
	templateUrl: './dashboard-main.component.html',
	styleUrls: ['./dashboard-main.component.scss'],
})
export class DashboardMainComponent implements OnInit, OnDestroy {
	public editMode: boolean = false;
	public chooseFormatModalIsOpen: boolean = false;
	public modalMode: ModalMode;

	public options: IOption[];
	public dashboards: any[];
	public selectedDashboardId: string;
	public currentDashboard: IDashboard;

	public editWidgetModalOpen: boolean = false;
	public editWidget: any = null;
	public widgetModalMode: ModalMode;

	public addWidgetLocation: any = null;

	private openWidgetEditModal$Subscription: Subscription = null;
	private widgetConfigChangedSource$Subscription: Subscription = null;

	constructor(
		private translationService: TranslationService,
		private popoverService: ComponentInjectionService,
		private widgetEventsService: WidgetEventsService,
		private currentUserUtilService: CurrentUserUtilService,
		private dashboardConfigService: DashboardConfigService,
		private canDeactivateGuard: CanDeactivateGuard,
		public deleteModal: MatDialog
	) {}

	/**
	 * performs initialization tasks for the dashboard main component - sets up susbscriptions and loads the data
	 */
	public ngOnInit(): void {
		this.setSubscriptions();
		this.loadData();
	}

	/**
	 * removes subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * sets the editMode used throughout the component and any additional settings required
	 *
	 * @param mode - is in edit mode
	 */
	public setEditMode = (mode: boolean): void => {
		this.editMode = mode;
	};

	/**
	 * Opens the choose format modal in the specified mode,
	 * also appending 'modal-open' to the class attribute of the body to disable scrolling.
	 *
	 * @param mode - - the mode to open the modal in, create/edit.
	 */
	public openChooseFormatModal = (mode: ModalMode): void => {
		this.chooseFormatModalIsOpen = true;
		this.modalMode = mode;
		this.popoverService.appendClassToBody('modal-open');
	};

	/**
	 * Closes the choose format modal, removing the 'modal-open' class from the body to enable scrolling.
	 * Sets the modalMode to null effectively ngChanges is fired in format modal next time it is set to
	 * reset page
	 */
	public closeChooseFormatModal = (): void => {
		this.modalMode = null;
		this.closeChooseFormat();
	};

	/**
	 * Selects the specified dashboard by id.
	 * Updates the selected dashboard in the options
	 * and copies the selected dashboard to the current dashboard object.
	 *
	 * @param id - - the id of the dashboard to select.
	 */
	public selectDashboard = (id: string): void => {
		this.selectedDashboardId = id;
		this.options = this.getSortedDashboardOptions();
		this.currentDashboard = this.copyCurrentDashboard();
		this.save();
	};

	/**
	 * Saves the dashboard when creating or editing. sets the modalMode to null effectively
	 * ngChanges is fired in format modal next time it is set to reset page
	 *
	 * @param value - - the structure and title of the dashboard to save.
	 */
	public saveDashboardFormat = (value: any): void => {
		this.closeChooseFormat();
		switch (this.modalMode) {
			case ModalMode.create:
				this.handleCreateSave(value);
				break;
			case ModalMode.edit:
				this.handleEditSave(value);
				break;
		}

		this.modalMode = null;

		this.save();
	};

	/**
	 * Handles the edit mode change, reloading the dashboard data if it editMode is not true.
	 *
	 * @param editMode - - selected edit mode.
	 */
	public handleEditModeChange = (editMode: any): void => {
		this.editMode = editMode;

		if (!editMode) {
			this.loadData();
		}
	};

	/**
	 * Cancels editing the current dashboard and reloads the data from the currentUserService.
	 */
	public cancelWithoutSave = (): void => {
		this.setEditMode(false);
		this.loadData();
	};

	/**
	 * Deletes the current dashboard.
	 */
	public deleteDashboard = async (): Promise<void> => {
		const message: string = this.translationService.getTranslation('T_DASH.DB_DELETE_MESSAGE');
		const header: string = this.translationService.getTranslation('T_DASH.DB_DELETE_HEADING');

		const modalRef: MatDialogRef<ConfirmModalComponent> = this.deleteModal.open(ConfirmModalComponent, {
			width: '600px',
			position: {
				top: '60px',
			},
			data: {
				message,
				header,
				confirmButtonType: ConfirmButtonType.yesNoType,
			},
		});

		modalRef.afterClosed().subscribe(async (result: ConfirmResult) => {
			if (result?.confirmed) {
				this.dashboards = this.dashboards.filter((i) => i.id !== this.selectedDashboardId).sort((a, b) => a.order - b.order);
				this.selectDashboard(this.dashboards[0].id);
				this.setEditMode(false);
				this.loadData();
			}
		});
	};

	/**
	 * Closes the edit modal.
	 */
	public closeEditModal = (): void => {
		if (this.widgetModalMode === ModalMode.create) {
			this.deleteWidget(this.editWidget);
		}

		this.editWidgetModalOpen = false;
		this.editWidget = undefined;
		this.widgetModalMode = undefined;
	};

	/**
	 * Saves the widget data.
	 *
	 * @param widget - - the widget data to save.
	 */
	public saveWidgetData = (widget: any): void => {
		let notFound: boolean = true;

		this.currentDashboard.config.rows.every((row) => {
			row.columns.every((column) => {
				column.widgets.every((i) => {
					if (i.wid === widget.wid) {
						i.title = widget.title;
						i.config = widget.config;
						notFound = false;
					}

					return notFound;
				});

				return notFound;
			});

			return notFound;
		});

		this.editWidgetModalOpen = false;
		this.editWidget = undefined;
		this.widgetModalMode = undefined;
		this.widgetEventsService.publishReloadWidget(widget.wid);

		if (!this.editMode) {
			this.save();
		}
	};

	/**
	 * Opens the add widget modal.
	 *
	 * @param location - - the row and column location the add widget button was clicked.
	 */
	public openAddWidget = (location: any): void => {
		this.addWidgetLocation = location;
	};

	/**
	 * Closes the add widget modal.
	 */
	public closeAddWidget = (): void => {
		this.addWidgetLocation = undefined;
	};

	/**
	 * Add the selected widget.
	 *
	 * @param widget - - the widget to add.
	 */
	public addSelectedWidget = (widget: any): void => {
		// make sure we are in edit mode when adding -typically we already would be unless
		// it's the first widget to be added
		this.setEditMode(true);

		const { rowIndex, columnIndex } = this.addWidgetLocation;

		const newWidget: IDashboardWidget = {
			title: this.translationService.getTranslation(widget.title),
			config: widget.config,
			type: widget.name,
			wid: uuidv4(),
		};

		this.currentDashboard.config.rows[rowIndex].columns[columnIndex].widgets.push(newWidget);
		this.addWidgetLocation = undefined;

		this.widgetModalMode = ModalMode.create;
		this.editWidgetModalOpen = true;
		this.editWidget = newWidget;
	};

	/**
	 * Delete the selected widget.
	 *
	 * @param widget - - the widget to delete.
	 */
	public deleteWidget = (widget: any): void => {
		this.currentDashboard.config.rows.forEach((row) => {
			row.columns.forEach((column) => {
				column.widgets = column.widgets.filter((i) => i.wid !== widget.wid);
			});
		});
	};

	/**
	 * The drag and drop drop event containing the widget that is to be dropped.
	 *
	 * @param event - - the drag and drop event data.
	 */
	public dropWidget = (event: any): void => {
		const { widget, row, column, widgetIndex } = event.item.data;

		this.currentDashboard.config.rows[row].columns[column].widgets.splice(widgetIndex, 1);

		const newColumnId: string = event.container.id;

		let notFound: boolean = true;

		this.currentDashboard.config.rows.every((row) => {
			row.columns.every((column) => {
				if (column.cid === newColumnId) {
					column.widgets.splice(event.currentIndex, 0, widget);
					notFound = false;
				}

				return notFound;
			});

			return notFound;
		});
	};

	/**
	 * Saves the current dashboard.
	 */
	public saveCurrentDashboard = (): void => {
		this.setEditMode(false);
		// grab the potentially updated title and set before we save
		this.currentDashboard.config.title = this.options.filter((i) => i.id === this.selectedDashboardId)[0].title;
		this.save();
	};

	/**
	 * save updated widget config
	 *
	 * Handles edge case where new config/controls/options have been added to the widget but a
	 * previous saved version of the widget exists that was created before the new property was added
	 * If there is a new property, update the old widget saved config so it has a value
	 *
	 * @param widget - the widget
	 */
	public handleWidgetConfigUpdated = (widget: any): void => {
		this.saveWidgetData(widget);
	};

	/**
	 * wait for user to confirm leaving of page (if in edit mode)
	 *
	 * When in edit mode, the user receives a prompt whether to stay/discard. When discarded
	 * the user will continue to their chosen route, if the user chooses 'stay' the routing
	 * will be cancelled via the deactivation guard
	 *
	 * @returns true if the user can deactivate
	 */
	public canDeactivate = async (): Promise<boolean> => {
		if (this.editMode) {
			return await new Promise((resolve) => {
				this.canDeactivateGuard.showDeactivatePrompt().subscribe({
					next: (confirmed) => resolve(confirmed),
				});
			});
		}

		return true;
	};

	/**
	 * Load dashboard data from currentUserService.
	 */
	private loadData = (): void => {
		this.dashboards = this.currentUserUtilService.getDashboardSettings();
		this.selectedDashboardId = this.currentUserUtilService.getSelectedDashboardId();
		this.options = this.getSortedDashboardOptions();
		this.currentDashboard = this.copyCurrentDashboard();

		// TODO - this can be removed after release 4.2

		// Code put here as a temporary solution to users who may have the twitter widget loaded within their
		// user settings and a way of deleting the twitter widget which is now no longer available to select
		// can be removed after this release?
		this.unconfigureTwitterWidget();
	};

	/**
	 * Utility to remove any twitter widgets from the current dashboard configured widgets
	 */
	private unconfigureTwitterWidget = (): void => {
		const twitter: string = 'twitter';

		this.dashboards?.forEach((dashboard: any) => {
			dashboard?.config?.rows.forEach((row: any) => {
				row?.columns.forEach((column: any) => {
					if (column.widgets) {
						column.widgets = column.widgets.reduce((acc: Array<any>, widget: any) => {
							if (widget.type !== twitter) {
								acc.push(widget);
							}

							return acc;
						}, []);
					}
				});
			});
		});

		this.currentDashboard?.config?.rows.forEach((row: any) => {
			row?.columns.forEach((column: any) => {
				if (column.widgets) {
					column.widgets = column.widgets.reduce((acc: Array<any>, widget: any) => {
						if (widget.type !== twitter) {
							acc.push(widget);
						}

						return acc;
					}, []);
				}
			});
		});

		this.save();
	};

	/**
	 * Gets the dashboard options from the dashboards.
	 *
	 * @returns the sorted dashboard options
	 */
	private getSortedDashboardOptions = (): IOption[] => {
		return this.dashboards
			.map((i) => ({
				id: i.id,
				title: i.config.title,
				selected: this.selectedDashboardId === i.id,
				order: i.config.order,
			}))
			.sort((a, b) => a.order - b.order);
	};

	/**
	 * Creates a deep copy of the current dashboard object.
	 *
	 * @returns a dashboard object.
	 */
	private copyCurrentDashboard = (): any => {
		return JSON.parse(JSON.stringify(this.dashboards.find((i) => i.id === this.selectedDashboardId)));
	};

	/**
	 * Creates a new dashboard, selects it and then saves it via the congfigService.
	 *
	 * @param value - - data object containing the structure and title.
	 */
	private handleCreateSave = (value: any): void => {
		const structure: any = this.dashboardConfigService.structures.find((i) => i.name === value.structure);

		const emptyWidgets: any[] = [];

		const rows: any = structure.rows.map((i: any) => ({
			styleClass: i.styleClass,
			columns: i.columns.map((x: any) => ({
				cid: uuidv4(),
				styleClass: x.styleClass,
				class: x.class,
				widgets: emptyWidgets,
			})),
		}));

		const dashboard: IDashboard = {
			id: uuidv4(),
			config: {
				rows,
				order: this.dashboards.length + 1,
				structure: value.structure,
				title: value.title,
			},
			order: 0,
		};

		this.dashboards.push(dashboard);
		this.options.push({
			id: dashboard.id,
			title: dashboard.config.title,
			selected: true,
			order: dashboard.config.order,
		});
		this.selectedDashboardId = dashboard.id;
		this.selectDashboard(dashboard.id);
	};

	/**
	 * Reconfigures the current dashboard structure
	 * and adds any widgets already in the config to the first column of the first row.
	 * This reconfigure does not save the dashboard at this point.
	 *
	 * @param value - - data object containing the structure and title.
	 */
	private handleEditSave = (value: any): void => {
		const structure: any = this.dashboardConfigService.structures.find((i) => i.name === value.structure);

		const emptyWidgets: any[] = [];

		const rows: any = structure.rows.map((i: any) => ({
			styleClass: i.styleClass,
			columns: i.columns.map((x: any) => ({
				cid: uuidv4(),
				styleClass: x.styleClass,
				class: x.class,
				widgets: emptyWidgets,
			})),
		}));
		const widgets: any = this.currentDashboard.config.rows.flatMap((i) => i.columns).flatMap((i) => i.widgets);

		rows[0].columns[0].widgets = widgets;
		this.currentDashboard.config.rows = rows;
		this.currentDashboard.config.structure = structure.name;
	};

	/**
	 * Saves the dashboardSettings and current selected dashboard id via the current user service.
	 */
	private save = (): void => {
		this.dashboards = this.dashboards.filter((i) => i.id !== this.currentDashboard.id);
		this.dashboards.push(this.currentDashboard);
		this.currentUserUtilService.saveDashboardSettings(this.dashboards, this.currentDashboard.id);
	};

	/**
	 * Setup subscriptions.
	 */
	private setSubscriptions = (): void => {
		this.openWidgetEditModal$Subscription = this.widgetEventsService.openWidgetEditModal.subscribe((event) => {
			this.handleOpenWidgetEditModal(event.widget);
		});

		this.widgetConfigChangedSource$Subscription = this.widgetEventsService.widgetConfigChanged.subscribe((event) => {
			this.saveWidgetData(event);
		});
	};

	/**
	 * Handles the open widget edit event.
	 *
	 * @param widget - - the widget to edit.
	 */
	private handleOpenWidgetEditModal = (widget: any): void => {
		this.editWidgetModalOpen = true;
		this.editWidget = widget;
		this.widgetModalMode = ModalMode.edit;
	};

	/**
	 * Closes the choose format modal, removing the 'modal-open' class from the body to enable scrolling.
	 */
	private closeChooseFormat = (): void => {
		this.chooseFormatModalIsOpen = false;
		this.popoverService.removeClassFromBody('modal-open');
	};

	/**
	 * Unsubscribes from and observables.
	 */
	private unsubscribe = (): void => {
		this.openWidgetEditModal$Subscription?.unsubscribe();
		this.widgetConfigChangedSource$Subscription?.unsubscribe();
	};
}
