import {
	Component,
	OnInit,
	Input,
	Output,
	EventEmitter,
	ViewChild,
	SimpleChanges,
	OnChanges,
	TemplateRef,
	AfterViewChecked,
	AfterViewInit,
	ChangeDetectorRef,
	Inject,
} from '@angular/core';

import { MatMenuTrigger } from '@angular/material/menu';

import { ColumnMode, DatatableComponent, SelectionType } from '@swimlane/ngx-datatable';

import { TranslateBaseComponent } from '../translate-base/translate-base.component';

import { TranslationService } from '../../services/translation/translation.service';
import { StateService } from '../../services/state/state-service';

import {
	Columns,
	ColumnType,
	ListSortings,
	PageRequestInfo,
	NgxPageInfo,
	SelectionByType,
	SelectedRowData,
	SortRequestInfo,
	Column,
	SelectedCssClassData,
	CssSubClassPosition,
	SelectableIconType,
} from './types/types';

import { CONFIG_TOKEN, Config } from '../../config/types';

import moment from 'moment';

@Component({
	selector: 'data-table',
	templateUrl: './data-table.component.html',
	styleUrls: ['./data-table.component.scss'],
})
export class DataTableComponent extends TranslateBaseComponent implements OnInit, OnChanges, AfterViewInit, AfterViewChecked {
	// refrence to the main ngx data table object
	@ViewChild(DatatableComponent) ngxDatatable: DatatableComponent;

	// reference to the MatMenuTrigger (context menu)
	@ViewChild(MatMenuTrigger, { static: true }) matMenuTrigger: MatMenuTrigger;

	@Input() listName: string;
	@Input() columns: Columns;
	@Input() rowData: any[] = [];
	// set when paging enabled
	@Input() totalRows: number = 0;
	// default row height
	@Input() rowHeight: number = 30;
	@Input() defaultSortings: ListSortings = [];
	@Input() selectionByType: SelectionByType = SelectionByType.checkbox;
	@Input() pageInfo: PageRequestInfo = null;
	@Input() sortInfo: SortRequestInfo = null;
	@Input() enableFooter: boolean = true;
	@Input() enableTotal: boolean = true;
	@Input() enableCount: boolean = false;
	//allow check boxes
	@Input() enableCheckRowSelection: boolean = false;
	//when enabled allows multiple check box selections rather than just one
	@Input() enabledMultiCheckRowSelection: boolean = false;
	// when enabled shows the select all option in the header (requires enabledMultiCheckRowSelection to be true)
	@Input() enabledCheckSelectAllRowSelection: boolean = false;
	// the list of rows to default check boxes to on (true)
	@Input() selectedRows: any[] = [];
	// when enabled adds the menu selection on right hand size (for export csv typically)
	@Input() enableMenuSelection: boolean = false;
	@Input() loadingIndicator: boolean = true;
	// updates the list without showing loading message and hiding footer (still shows loading indicator bar across the header)
	@Input() silentReload: boolean = false;
	@Input() loadingText: string = null;
	@Input() pagingEnabled: boolean = false;
	@Input() externalSorting: boolean = false;
	// an override for the height of the list - used when we don't want to fill the whole page
	@Input() fixedHeightSize: number = null;
	@Input() noDataMessage: string = 'T_CORE.NO_RESULTS_AVAILABLE';
	// template passed in with expanded detail content
	@Input() expandedDetailTemplate: TemplateRef<any> = null;

	@Output() requestData: EventEmitter<PageRequestInfo> = new EventEmitter<PageRequestInfo>();
	@Output() sortData: EventEmitter<PageRequestInfo> = new EventEmitter<PageRequestInfo>();
	@Output() rowClick: EventEmitter<SelectedRowData> = new EventEmitter<SelectedRowData>();
	@Output() checkSelect: EventEmitter<any[]> = new EventEmitter<any[]>();
	@Output() exportCsv: EventEmitter<void> = new EventEmitter<void>();
	@Output() curentPageAllRowsExpanded: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() cssItemClick: EventEmitter<SelectedCssClassData> = new EventEmitter<SelectedCssClassData>();
	// pass out column sizes for expanded row detail components to align with
	@Output() columnSizes: EventEmitter<Map<string, number>> = new EventEmitter<Map<string, number>>();

	// make enum accessible in template html
	public ColumnType: typeof ColumnType = ColumnType;
	public CssSubClassPosition: typeof CssSubClassPosition = CssSubClassPosition;

	// Default selection type Checkbox
	public selectionType: SelectionType = SelectionType.checkbox;
	public columnMode: ColumnMode = ColumnMode.force;
	public initialSortings: ListSortings = [];
	public contextMenuTopLeftPosition: any = { x: 0, y: 0 };
	public paginationMaxSize: number = 200;
	public count: number = 0;
	public expandedRowHeight: any = 'auto';

	private cachePagingInitializing: boolean = false;
	private columnSizeMap: Map<string, number> = new Map<string, number>();
	private listCacheContainer: any = { columnSizeCache: null };
	private columnSizeCacheField: string[] = ['columnSizeCache'];
	private expandedRowsCache: string[] = [];

	constructor(
		private stateService: StateService,
		@Inject(CONFIG_TOKEN) private config: Config,
		private changeDetectorRef: ChangeDetectorRef,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * initialises our data table
	 */
	public ngOnInit(): void {
		this.initSelectionType();

		this.getCachedColumnSizes();

		// if paging isn't enabled we'd expect the full result set to be passed in, otherwise we'll initailise our request data here
		if (this.pagingEnabled) {
			this.paginationMaxSize = this.config.getPaginationMaxSize();

			// we may have page info passed in from the caching
			if (this.pageInfo) {
				this.initCachedPaging();
			} else {
				this.initDefaultPaging();
			}
		} else {
			this.initNonPaging();
		}
	}

	/**
	 * handles further initialises after the template has been checked
	 */
	public ngAfterViewChecked(): void {
		const columnSizeMap: Map<string, number> = new Map<string, number>();

		this.ngxDatatable._internalColumns.forEach((column: any) => {
			columnSizeMap.set(column.prop.toString(), column.width);
		});

		this.columnSizes.emit(columnSizeMap);
	}

	/**
	 * handles further initialises after the template has rendered
	 *
	 * work around for ngx data table automatically sorting (the first time) i.e alphabetically despite having external
	 * sorting set to true (with custom sort being set in the parent).
	 *
	 * Setting this up after initialization ensures the default sorting is not applied (and doesn't re-order our custom sort)
	 * The issue was seen in vehicle details where the time until stop column (applied when map settings = relevant time) had
	 * a custom client side sort where the results were different
	 * from an alphabetical sort.
	 *
	 * Note: This may be fixed in a later vesion of ngx datatable (according to Github) but we are currently stuck on v19 due
	 * to Angular+ compatabilty. We can possible remove when we can upgrade.
	 */
	public ngAfterViewInit(): void {
		if (this.externalSorting && !this.pagingEnabled) {
			this.initialSortings = [{ prop: this.sortInfo.sort, dir: this.sortInfo.sortDir }];

			// stops the 'has changed after it was checked error'
			this.changeDetectorRef.detectChanges();
		}
	}

	/**
	 * handles any change to the input parameters, specifically the row data and clears appropriate checkboxes
	 * as the row data changes when a new page, sort or page size is set (or reloaded i.e following edit/delete)
	 * @param changes - object detailing the input changes
	 */
	public ngOnChanges(changes: SimpleChanges): void {
		if (changes.rowData && !changes.rowData.firstChange) {
			let index: number = this.selectedRows.length - 1;

			// only keep selected what was previously selected and exists in the updated data set
			// work backwords as if we remove an item in the array using splice, the indices will change
			this.selectedRows
				.slice()
				.reverse()
				.forEach((selectedRow) => {
					const currentRow: any = changes.rowData.currentValue.find((row: any) => row.id === selectedRow.id);

					if (currentRow) {
						// Update the existing row with up to date data
						this.selectedRows[index] = currentRow;
					} else {
						this.selectedRows.splice(index, 1);
					}

					index--;
				});

			this.checkSelect.emit(this.selectedRows);

			// expand any rows that were previously expanded
			this.expandedRowsCache.forEach((rowId) => {
				const rowToExpand: boolean = changes.rowData.currentValue.find((row: any) => row.id === rowId);

				if (rowToExpand) {
					this.ngxDatatable.rowDetail.toggleExpandRow(rowToExpand);
				}
			});

			this.curentPageAllRowsExpanded.emit(this.isCurrentPageAllRowsExpanded());
		}
	}

	/**
	 * Gets the current page set by the user
	 * @returns the current page value
	 */
	public getOffset = (): number => {
		// return our page number minus 1. The ngx controls indexes from 0
		// but we store our pageNum indexed from 1 as that is what our back end expects
		return this.getCurrentPage() - 1;
	};

	/**
	 * Gets the current page set by the user
	 * @returns the current page value
	 */
	public getCurrentPage = (): number => {
		//return our page number minus 1. The ngx controls indexes from 0
		// but we store our pageNum indexed from 1 as that is what our back end expects
		if (this.pagingEnabled && this.pageInfo) {
			return this.pageInfo.pageNum;
		} else {
			return null;
		}
	};

	/**
	 * Gets the current size
	 * @returns the current page size value
	 */
	public getPageSize = (): number => {
		// leave as undefined (what the ngx control expects if paging not turned on)
		let pageSize: number;

		if (this.pagingEnabled && this.pageInfo) {
			pageSize = this.pageInfo.pageSize;
		}

		return pageSize;
	};

	/**
	 * initialises settings when in non page mode
	 */
	public initNonPaging = (): void => {
		if (!this.externalSorting) {
			// default settings - grab the default sortings passed in
			this.initialSortings = this.defaultSortings;
		}
	};

	/**
	 * initialises the paging request object with existing cached values
	 */
	public initCachedPaging = (): void => {
		this.cachePagingInitializing = true;

		// set the cached sort settings
		this.initialSortings = [{ prop: this.pageInfo.sort, dir: this.pageInfo.sortDir }];

		if (this.pageInfo.pageSize > this.paginationMaxSize) {
			// config must have been changed to be lower than the current list size - reduce to the max size
			this.pageInfo.pageSize = this.paginationMaxSize;
		}
	};

	/**
	 * initialises the paging request object with default values
	 */
	public initDefaultPaging = (): void => {
		// default settings - grab the default sortings passed in
		this.initialSortings = this.defaultSortings;

		let pageSize: number = this.config.getDefaultPageSize();

		if (pageSize > this.paginationMaxSize) {
			// config mismatch - can't have a page size less than max size - set to max available
			pageSize = this.paginationMaxSize;
		}

		// set default paging info
		this.pageInfo = {
			pageNum: 1,
			sort: this.initialSortings[0].prop,
			sortDir: this.initialSortings[0].dir,
			pageSize,
		};
	};

	/**
	 * Fired by ngx control when paging is triggered. Request new data based on updated page info
	 * @param ngxPageInfo - The page to select
	 */
	public setPage = (ngxPageInfo: NgxPageInfo): void => {
		if (this.pagingEnabled) {
			if (!this.cachePagingInitializing) {
				// if not initializing update the page num to the page selected (our backend indexes from 1 not 0 so need to add 1)
				this.pageInfo.pageNum = ngxPageInfo.offset + 1;
			} else {
				// when initializing, the underlying control fires this event with an offset as 0 (page 1) despite attempts to
				// set our cached page number (bug in ngx datatable). Workaround is to just ignore the requested offset/page num
				// the first time this event fires and instead request the data with the cached page number in our page info object.
				// The correct data will be returned and the appropriate page set afterwards
				this.cachePagingInitializing = false;
			}

			// request data from parent
			this.requestData.emit(this.pageInfo);
		}
	};

	/**
	 * Fired by ngx control when sorting is triggered
	 * @param event - the event containing sort details
	 */
	public onSort = (event: any): void => {
		// only need to worry about sorting when paging is enabled. Client side will be handled by the ngx control itself
		if (this.pagingEnabled) {
			// event was triggered, start external sort request

			// the ngx table control assumes the page number (known as offset internally) should be reset on sorting yet our
			// backend honours the current page so gets out of sync. Simpe working around to set back to the page we were on
			this.ngxDatatable.offset = this.getCurrentPage();

			this.pageInfo.sort = event.sorts[0].prop;
			this.pageInfo.sortDir = event.sorts[0].dir;

			// request data from parent
			this.requestData.emit(this.pageInfo);
		} else {
			if (this.externalSorting) {
				this.sortInfo.sort = event.sorts[0].prop;
				this.sortInfo.sortDir = event.sorts[0].dir;

				// request data from parent
				this.sortData.emit(this.sortInfo);
			}
		}
	};

	/**
	 * Fired when user changes the paging size
	 */
	public setPageSizeChanged = (): void => {
		// paging size already stored due to 2 way binding of select control

		// reset the page number to the first page when the page size is changed
		this.pageInfo.pageNum = 1;

		// request data from parent
		this.requestData.emit(this.pageInfo);
	};

	/**
	 * gets the style class for the individual row in the required format for the ngx datatable
	 * If a particular row class exists within the table row (pased in via parent) then use it
	 * otehrwise color appropriate selection color if selected
	 *
	 * @param row - the table row to be processed
	 * @returns style class name containing the correct color for the row
	 */
	public getRowClass = (row: any): any => {
		const rowClassName: string = row?.rowClass;

		// check if we have row coloring passed in (i.e predictor list colors it's rows)
		if (rowClassName) {
			return { [rowClassName]: true };
		} else {
			//determine if row is selected
			let isSelected: boolean = false;

			this.selectedRows.some((selectedRow) => {
				// each table to needs to be configured to have an id field to color row selected on check
				if (row.id) {
					if (row.id === selectedRow.id) {
						isSelected = true;
					}
				}
			});

			if (isSelected) {
				return { ['selected-color']: true };
			} else {
				return {};
			}
		}
	};

	/**
	 * Determines the column name. Typically this will be stored in a standard
	 * display name string but we can also have a dynamic version which reacts to
	 * changes if required
	 *
	 * Note: displayNameDynamic is a function pointer set up in the parent to ensure changes
	 * are automatically evaluated.
	 *
	 * @param column - The column being evaluated.
	 * @returns the evaluated column name.
	 */
	public getDisplayName = (column: Column): string => {
		if (column.displayNameDynamic) {
			return column.displayNameDynamic();
		} else {
			return column.displayName;
		}
	};

	/**
	 * Determines whether to show the column based upon the hidden property.
	 * Note: Hidden is a function pointer set up in the parent to ensure changes
	 * are automatically evaluated.
	 *
	 * @param column - The column being evaluated.
	 * @returns Whether we should show the column.
	 */
	public showColumn = (column: Column): boolean => {
		if (column.hidden) {
			return !column.hidden();
		}

		return true;
	};

	/**
	 * Returns the basic boolean value supplied when it is a boolean or returns dynamic state based upon the function pointer property
	 * setup in the parent to ensure changes are automatically evaluated.
	 *
	 * @param value - The value being evaluated.
	 * @returns The boolean based result.
	 */
	public isSelected = (value: any): boolean => {
		if (typeof value === 'function') {
			return value();
		}

		return value;
	};

	/**
	 * Returns the basic boolean value supplied when it is a boolean or returns dynamic state based upon the function pointer property
	 * setup in the parent to ensure changes are automatically evaluated.
	 *
	 * @param value - The value being evaluated.
	 * @returns The boolean based result.
	 */
	public isDisabled = (value: any): boolean => {
		if (typeof value === 'function') {
			return value();
		}

		return value;
	};

	/**
	 * Returns the basic string value supplied when it is a string or returns dynamic state based upon the function pointer property
	 * setup in the parent to ensure changes are automatically evaluated.
	 *
	 * @param value - The value being evaluated.
	 * @returns The string based result.
	 */
	public getIconName = (value: any): string => {
		if (typeof value === 'function') {
			return value();
		}

		return value;
	};

	/**
	 * Determines whether to show the value of the selectable icon type based upon the hidden property.
	 * Note: Hidden is a function pointer set up in the parent to ensure changes
	 * are automatically evaluated.
	 *
	 * @param value - The value being evaluated.
	 * @returns Whether we should show the value.
	 */
	public showValue = (value: SelectableIconType): boolean => {
		if (value.hidden) {
			return !value.hidden();
		}

		return true;
	};

	/**
	 * handle the user click of a custom css class (typically displayed as a badge)
	 *
	 * sends click handler back up to parent
	 *
	 * @param column - The column being evaluated.
	 * @param data - the contents of the item clicked
	 */
	public onCssClassItemSelected = (column: string, data: any): void => {
		const selectedItemData: SelectedCssClassData = {
			item: data,
			columnNameSelected: column,
		};

		this.cssItemClick.emit(selectedItemData);
	};

	/**
	 * called when a row is clicked (or mouse hovered)
	 *
	 * @param event - the event containing the type (i.e click/mouseover) and the selected row
	 */
	public onSelect = (event: any): void => {
		if (event.type === 'click') {
			const selectedRowData: SelectedRowData = {
				row: event.row,
				columnNameSelected: event.column.prop,
			};

			// ignore the column click if it's the expand column
			if (event.column.prop !== 'expand') {
				// ignore check box click
				if (this.enableCheckRowSelection) {
					if (event.cellIndex !== 0) {
						this.rowClick.emit(selectedRowData);
					}
				} else {
					this.rowClick.emit(selectedRowData);
				}
			}
		}
	};

	/**
	 * determines if a particular row should have a checkbox
	 *
	 * @returns always true
	 */
	public displayCheck = (): boolean => {
		// just return true so all rows have a checkbox
		return true;
	};

	/**
	 * gets the checkbox checkbox state of the row.
	 *
	 * @param row - the current row
	 * @returns true when the row is selected
	 */
	public rowSelected = (row: any): boolean => {
		return this.selectedRows.some((selectedRow) => selectedRow.id === row.id);
	};

	/**
	 * sets/unsets all checkboxes (not including where disabled check flag is set in the row).
	 *
	 * @param selectedRows - the selected rows
	 */
	public onSelectAllCheck = (selectedRows: any): void => {
		if (this.enabledMultiCheckRowSelection) {
			this.selectedRows = [...selectedRows.selected];

			this.checkSelect.emit(this.selectedRows);
		}
	};

	/**
	 * sets the checkbox of the selected row. Turns off previous selections
	 * when not in multi mode
	 *
	 * @param selected - the selected rows
	 */
	public onSelectCheck = (selected: any): void => {
		const index: number = this.selectedRows.findIndex((selectedRow) => selectedRow.id === selected.id);

		if (index > -1) {
			// value is already in list - toggle off
			this.selectedRows.splice(index, 1);
		} else {
			if (this.enabledMultiCheckRowSelection) {
				// value not in list - toggle on
				this.selectedRows.push(selected);
			} else {
				// replace the selected array with the single selected row
				this.selectedRows = [...[selected]];
			}
		}

		this.checkSelect.emit(this.selectedRows);
	};

	/**
	 * enables or disables the column sorting. Esnures we enable sorting
	 * unless sorting is explicitly disabled
	 * @param sortable - the sortable config associated with the column
	 * @returns whether or not sorting is enabled for the column
	 */
	public isSortable = (sortable: boolean): boolean => {
		if (sortable === false) {
			return sortable;
		}

		return true;
	};

	/**
	 * gets the column width, fixed size if applicable, then the cached size (user may have resized the column)
	 * then the initial/default width set, finally null would be returned which means the auto sizing of the list will be used
	 * @param name - the column name
	 * @param width - the column width
	 * @param fixedWidth - the width for the column
	 * @returns the width of the column
	 */
	public getColumnWidth = (name: string, width: number, fixedWidth: number): number => {
		if (fixedWidth) {
			return fixedWidth;
		}

		if (width && this.columnSizeMap.size === 0) {
			return width;
		}

		return this.columnSizeMap.get(name);
	};

	/**
	 * gets the auto resize value (true allows columns to justify themselves, unless it's a fixed size icon type - false)
	 * @param type - the column type
	 * @returns whether the column can resize automatically
	 */
	public canColumnAutoResize = (type: ColumnType): boolean => {
		if (type === ColumnType.icon || type === ColumnType.selectableIcon) {
			return false;
		}

		return true;
	};

	/**
	 * returns formatted 'time ago' value
	 * @param value - the date value
	 * @returns a time value in the form of x mins/hours/days/years ago
	 */
	public getTimeAgoValue = (value: string): string => {
		return value ? '(' + moment(value).fromNow() + ')' : null;
	};

	/**
	 * fires when a column is resized - stores the update in the cache
	 * @param event - contains the column details
	 */
	public onColumnResize = (): void => {
		// ignore the event. this only tells us the column that was changed. As the auto sizing
		// will resize other columns when one changes we must store all column sizes when one changes
		this.ngxDatatable._internalColumns.forEach((column: any) => {
			this.columnSizeMap.set(column.prop.toString(), column.width);
		});

		this.cacheColumnSizes();
	};

	/**
	 * fires when a row is expanded. Maintains a cache of what is selected
	 * @param row - contains the row details
	 */
	public toggleExpandRow = (row: any): void => {
		const foundIndex: number = this.expandedRowsCache.indexOf(row.id);

		if (foundIndex > -1) {
			this.expandedRowsCache.splice(foundIndex, 1);
		} else {
			this.expandedRowsCache.push(row.id);
		}

		this.ngxDatatable.rowDetail.toggleExpandRow(row);

		this.curentPageAllRowsExpanded.emit(this.isCurrentPageAllRowsExpanded());
	};

	/**
	 * sets all current rows expanded. note: rows not currently in current page may already
	 * be expanded
	 */
	public expandAllRows = (): void => {
		this.rowData.forEach((row: any) => {
			if (this.expandedRowsCache.indexOf(row.id) === -1) {
				this.expandedRowsCache.push(row.id);
			}
		});

		this.ngxDatatable.rowDetail.expandAllRows();
	};

	/**
	 * collapse all rows. note: rows not currently in current page may be
	 * be expanded - only collapse current rows
	 */
	public collapseAllRows = (): void => {
		this.rowData.forEach((row) => {
			const foundIndex: number = this.expandedRowsCache.indexOf(row.id);

			if (foundIndex > -1) {
				this.expandedRowsCache.splice(foundIndex, 1);
			}
		});

		this.ngxDatatable.rowDetail.collapseAllRows();
	};

	/**
	 * Method called when the user click with the menu option
	 * @param event - MouseEvent containing the coordinates
	 */
	public onMenuClick = (event: MouseEvent): void => {
		// preventDefault avoids to show the visualization of the right-click menu of the browser
		event.preventDefault();

		// we record the mouse position in our object
		this.contextMenuTopLeftPosition.x = event.clientX;
		this.contextMenuTopLeftPosition.y = event.clientY;

		// we open the menu
		this.matMenuTrigger.openMenu();
	};

	/**
	 * Method called when the user clicks the export to CSV option within the context menu
	 */
	public exportToCsv = (): void => {
		this.exportCsv.emit();
	};

	/**
	 * maps our selectionByType to the type required by ngx datatable
	 */
	private initSelectionType = (): void => {
		// map our selectionByType to ngx datatable selectionType
		switch (this.selectionByType) {
			case SelectionByType.checkbox:
				this.selectionType = SelectionType.checkbox;
				break;
			case SelectionByType.cell:
				this.selectionType = SelectionType.cell;
				break;
			default:
				this.selectionType = SelectionType.checkbox;
				break;
		}
	};

	/**
	 * gets the cached column size from the state sevice (browser local storage) and
	 * initialises the column name to size (width) mapping
	 */
	private getCachedColumnSizes = (): void => {
		const columnSizeCacheContainer: any = this.stateService.mapLoadAcrossSessions(
			this.listName,
			this.listCacheContainer,
			this.columnSizeCacheField
		);

		if (columnSizeCacheContainer.columnSizeCache) {
			// convert the cahced string back to our mapping of column name -> size
			this.columnSizeMap = new Map(JSON.parse(this.listCacheContainer.columnSizeCache));
		}
	};

	/**
	 * Caches the column sizes
	 * format the data in to a state that the stateService expects i.e a property within an object.
	 * note: we could perhaps add a new method to the state service that takes a simple string but
	 * the state service needs reworking anyway as in the past (anngularJS app) a whole vm object
	 * would be stored containing all field data. With Angular components, we typically don't store
	 * fields in this manner so it needs a rethink.  Defer until state service is reworked
	 */
	private cacheColumnSizes = (): void => {
		// convert the map to a string for caching
		this.listCacheContainer.columnSizeCache = JSON.stringify(Array.from(this.columnSizeMap.entries()));

		this.stateService.mapPersistAcrossSessions(this.listName, this.listCacheContainer, this.columnSizeCacheField);
	};

	/**
	 * determine if all currently displayed rows are expanded. Note: we can't just check
	 * lengths as the cache may have rows that are not current visible
	 * @returns whether or not all rows currently displayed are expanded
	 */
	private isCurrentPageAllRowsExpanded = (): boolean => {
		let allCurrentPageRowsExpanded: boolean = false;

		if (this.rowData) {
			// default to true (but only if we have any rows)
			allCurrentPageRowsExpanded = this.rowData.length > 0;

			// if any are collapsed - set to false
			this.rowData.forEach((row) => {
				if (this.expandedRowsCache.indexOf(row.id) === -1) {
					allCurrentPageRowsExpanded = false;
				}
			});
		}

		return allCurrentPageRowsExpanded;
	};
}
