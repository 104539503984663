/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { TranslateBaseComponent } from '@cubicNx/libs/utils';

import { TimeHelpers } from '@cubicNx/libs/utils';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { AgenciesDataService } from '../../../../support-features/agencies/services/agencies-data.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { SliderConfig } from '@cubicNx/libs/utils';
import { SelectedReplayTimeFrame } from '../types/types';

import moment, { Moment } from 'moment';
import { MapReplayService } from '../../services/map-replay.service';

@Component({
	selector: 'replay-time-frame-select',
	templateUrl: './replay-time-frame-select.component.html',
	styleUrls: ['./replay-time-frame-select.component.scss'],
})
export class ReplayTimeFrameSelectComponent extends TranslateBaseComponent implements OnInit {
	public sliderTimeRangeConfig: SliderConfig = null;
	public sliderTimeRangeValues: Array<number> = [];

	public timeFrameSelectForm: FormGroup = null;
	public selectedStartTimeDisplay: string = null;
	public selectedEndTimeDisplay: string = null;

	public sliderContainerLeftWidth: string = null;
	public sliderContainerRightWidth: string = null;
	public sliderContainerRightOffset: string = null;

	public dayLeftDisplay: string = null;
	public dayRightDisplay: string = null;

	public maxDate: Date = null;
	public selectedDayErrorText: string = null;
	public hasSelectedDayError: boolean = false;

	public includePredictionsEnabled: boolean = false;

	private readonly sliderContainerWidth: number = 654;
	private readonly timeFormatShort: string = 'HH:mm';
	private readonly dateFormatLong: string = 'ddd, MMMM Do, HH:mm:ss';
	private readonly dateFormatMid: string = 'ddd, MMMM Do';

	private timezone: string = null;
	private timeRangeStart: Moment = null;
	private timeRangeEnd: Moment = null;

	constructor(
		private agenciesDataService: AgenciesDataService,
		private formBuilder: FormBuilder,
		private modalRef: MatDialogRef<ReplayTimeFrameSelectComponent>,
		private mapReplayService: MapReplayService,
		@Inject(MAT_DIALOG_DATA) public data: any,
		translationService: TranslationService
	) {
		super(translationService);

		// disabled default close operation - meaning modal doesn't close on click outside.
		// this also disables escape key functionality but that can be handled with hostlistener approach above
		// This strategy also ensure our close method is always called when the modal is closed meaning we can
		// pass data back to the parent accordingly
		this.modalRef.disableClose = true;
	}

	/**
	 * handle the escape key press and close the replay dialog
	 */
	@HostListener('document:keydown.escape', ['$event']) onKeydownHandler(): void {
		this.cancel();
	}

	/**
	 * initializes the component
	 */
	public async ngOnInit(): Promise<void> {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		this.timezone = this.agenciesDataService.getAgencyTimezone(selectedAgency.authority_id, selectedAgency.agency_id);

		this.maxDate = moment().tz(this.timezone).toDate();

		const selectedDay: Moment = this.mapReplayService.getSelectedDay();

		if (!selectedDay) {
			// no delected day saved - inialize using current day
			const now: Moment = moment().tz(this.timezone);

			this.buildTimeRangeForm(now);

			this.initSlider(now, true);
		} else {
			// set selected day
			this.buildTimeRangeForm(selectedDay);

			// call the selected day update method which will sync up the slider
			this.setUpdatedSelectedDay();

			// adjust to the saved slider selection if available
			const sliderTimeRangeValues: number[] = this.mapReplayService.getSliderTimeRange();

			if (sliderTimeRangeValues.length > 0) {
				this.sliderTimeRangeValues = [sliderTimeRangeValues[0].valueOf(), sliderTimeRangeValues[1].valueOf()];
			}
		}

		await this.loadTranslations();
	}

	/**
	 * handle the user updating the selected day for replay mode and update the sliders accordingly
	 */
	public setUpdatedSelectedDay = async (): Promise<void> => {
		this.hasSelectedDayError = this.getSpecificDateErrorText();

		let endTime: Moment = null;
		let defaultSelectionToEnd: boolean = false;

		if (!this.hasSelectedDayError) {
			const parsedSelectedDay: string = new Date(this.timeFrameSelectForm.controls.selectedDay.value).toDateString();
			const selectedDay: Moment = moment(parsedSelectedDay).tz(this.timezone, true).startOf('day');

			this.mapReplayService.setSelectedDay(selectedDay);

			const now: Moment = moment();

			// if we have selected the current day - get the current time as the end time - otherwise get the end of the day
			if (now.isSame(selectedDay.clone(), 'day')) {
				endTime = moment().tz(this.timezone);
				defaultSelectionToEnd = true;
			} else {
				// note: using endOf('day') caused an issue where the eventual valueOf() didn't return the correct value
				// and the end interval was missed. Add 24 hours and removing 1 second seems to be reliable
				endTime = selectedDay.clone().add(24, 'hours').subtract(1, 'second');
				defaultSelectionToEnd = false;
			}

			this.initSlider(endTime, defaultSelectionToEnd);
		}
	};

	/**
	 * handle the updated time range selection
	 */
	public handleUpdatedTimeRangeSelection = (): void => {
		this.mapReplayService.setSliderTimeRange(this.sliderTimeRangeValues);

		this.determineSelectedDateDisplayValues();
	};

	/**
	 * determine if the save button should be anbled
	 * @returns true if the save button should be enabled (when the form is valid)
	 */
	public saveEnabled = (): boolean => {
		return this.timeFrameSelectForm.valid;
	};

	/**
	 * handle the cancel button and close the replay dialog
	 */
	public cancel = (): void => {
		const selectedReplayTimeFrame: SelectedReplayTimeFrame = {
			saved: false,
		};

		this.close(selectedReplayTimeFrame);
	};

	/**
	 * handle the save button and close the replay dialog and pass the replay data to the parent
	 */
	public save = async (): Promise<void> => {
		const selectedStartTime: Moment = moment(this.sliderTimeRangeValues[0]).tz(this.timezone);
		const selectedEndTime: Moment = moment(this.sliderTimeRangeValues[1]).tz(this.timezone);

		const selectedReplayTimeFrame: SelectedReplayTimeFrame = {
			saved: true,
			selectedStartTime,
			selectedEndTime,
			includePredictions: this.includePredictionsEnabled,
		};

		this.close(selectedReplayTimeFrame);
	};

	/**
	 * handle the predictions enabled toggle
	 */
	public togglePredictionsEnabled = (): void => {
		this.includePredictionsEnabled = !this.includePredictionsEnabled;
	};

	/**
	 * initailize the form for the selected day validation handling
	 *
	 * @param selectedDay - the selected day to set in the form
	 */
	private buildTimeRangeForm = (selectedDay: Moment): void => {
		this.timeFrameSelectForm = this.formBuilder.group(
			{
				selectedDay: [
					{
						value: TimeHelpers.createNewDateOnlyFromMoment(selectedDay),
						disabled: false,
					},
					[Validators.required],
				],
			},
			{}
		);
	};

	/**
	 * handle and get appropriate translated text for any date selection errors
	 * @returns the date error text
	 */
	private getSpecificDateErrorText = (): boolean => {
		let hasDateError: boolean = false;

		this.selectedDayErrorText = '';

		// check the date has been updated before checking errors. Then check null value which covers an actual null
		// but also anything entered that isn't a valid date
		if (this.timeFrameSelectForm.controls.selectedDay.hasError('required')) {
			hasDateError = true;
			this.selectedDayErrorText = this.translations['T_CORE.FORM.ERRORS.REQUIRED'];
		}

		if (
			this.timeFrameSelectForm.controls.selectedDay.hasError('invalid') ||
			this.timeFrameSelectForm.controls.selectedDay.hasError('matDatepickerParse')
		) {
			hasDateError = true;
			this.selectedDayErrorText = this.translations['T_CORE.FORM.ERRORS.DATE_START'];
		}

		return hasDateError;
	};

	/**
	 * initialize the date slider
	 * @param endTime - the end time
	 * @param defaultToEnd - whether to default the slider value to the end
	 */
	private initSlider = (endTime: Moment, defaultToEnd: boolean): void => {
		let initialEndTime: Moment = null;
		let initialStartTime: Moment = null;

		this.timeRangeStart = endTime.clone().subtract(1, 'day').startOf('day');
		this.timeRangeEnd = endTime;

		if (defaultToEnd) {
			initialEndTime = this.timeRangeEnd.clone();
			initialStartTime = this.timeRangeEnd.clone().subtract(2, 'hour');
		} else {
			initialStartTime = this.timeRangeStart.clone().add(24, 'hour');
			initialEndTime = this.timeRangeStart.clone().add(26, 'hour');
		}

		this.sliderTimeRangeValues = [initialStartTime.valueOf(), initialEndTime.valueOf()];

		this.sliderTimeRangeConfig = this.determineSliderConfig();

		this.determineDayDisplayValues();

		this.determineSelectedDateDisplayValues();

		this.determineSliderContainerSize();
	};

	/**
	 * determine the default slider config
	 * @returns the slider config
	 */
	private determineSliderConfig = (): SliderConfig => {
		return {
			limit: 7200000, // limit the selected time frame to 2 hours
			range: {
				min: this.timeRangeStart.valueOf(),
				max: this.timeRangeEnd.valueOf(),
			},
			padding: 0,
			pips: {
				mode: 'values',
				values: this.getSliderPipIntervalValues(),
				format: {
					to: (value: any) => this.formatSliderPipTimeInterval(value),
				},
			},
			start: [this.sliderTimeRangeValues[0].valueOf(), this.sliderTimeRangeValues[1].valueOf()],
			behaviour: 'drag',
			tooltips: false,
		};
	};

	/**
	 * get the slider pip intevals
	 * @returns  the slider pip intevals
	 */
	private getSliderPipIntervalValues = (): any => {
		const timeRangeStart: Moment = this.timeRangeStart.clone();

		const pipsVals: any[] = [];

		do {
			pipsVals.push(timeRangeStart.clone().valueOf());
			timeRangeStart.add(3, 'hours');
		} while (!timeRangeStart.isAfter(this.timeRangeEnd));

		if (timeRangeStart.subtract(1, 'second').isSame(this.timeRangeEnd)) {
			pipsVals.push(this.timeRangeEnd.valueOf());
		}

		return pipsVals;
	};

	/**
	 * for the time for the slider pip intervals
	 * @param timeValue - the time for the pip interval
	 * @returns the formatted time for the pip interval
	 */
	private formatSliderPipTimeInterval = (timeValue: string): string => {
		const time: Moment = moment(timeValue).tz(this.timezone);

		return time.format(this.timeFormatShort);
	};

	/**
	 * determine the day display values for the view
	 */
	private determineDayDisplayValues = (): void => {
		this.dayLeftDisplay = this.timeRangeStart.format(this.dateFormatMid);
		this.dayRightDisplay = this.timeRangeEnd.format(this.dateFormatMid);
	};

	/**
	 * determine the selected day display values for the view
	 */
	private determineSelectedDateDisplayValues = (): void => {
		const selectedStartTime: Moment = moment(this.sliderTimeRangeValues[0]).tz(this.timezone);
		const selectedEndTime: Moment = moment(this.sliderTimeRangeValues[1]).tz(this.timezone);

		this.selectedStartTimeDisplay = selectedStartTime.format(this.dateFormatLong);
		this.selectedEndTimeDisplay = selectedEndTime.format(this.dateFormatLong);
	};

	/**
	 * determine the slider css style for the view
	 */
	private determineSliderContainerSize = (): void => {
		const pipWidth: number = this.sliderContainerWidth / this.sliderTimeRangeConfig.pips.values.length;

		const sliderContainerLeftWidth: number = 8 * pipWidth + 18;
		const sliderContainerRightOffset: number = sliderContainerLeftWidth - 10;
		const sliderContainerRightWidth: number = this.sliderContainerWidth - sliderContainerLeftWidth;

		this.sliderContainerLeftWidth = sliderContainerLeftWidth.toString() + 'px';
		this.sliderContainerRightOffset = sliderContainerRightOffset.toString() + 'px';
		this.sliderContainerRightWidth = sliderContainerRightWidth.toString() + 'px';
	};

	/**
	 * handle the close of the dialog
	 * @param selectedReplayTimeFrame - the selected replay data to pass back to the parent
	 */
	private close = (selectedReplayTimeFrame: SelectedReplayTimeFrame): void => {
		this.modalRef.close(selectedReplayTimeFrame);
	};

	/**
	 * load translations for the page
	 **/
	private loadTranslations = async (): Promise<void> => {
		await this.initTranslations(['T_CORE.FORM.ERRORS.REQUIRED', 'T_CORE.FORM.ERRORS.DATE_START']);
	};
}
