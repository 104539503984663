/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { CONFIG_TOKEN, TranslateBaseComponent } from '@cubicNx/libs/utils';
import { CurrentUserUtilService } from '../../support-features/login/services/current-user/current-user-utils.service';
import { LogoutService } from '../../support-features/logout/services/logout.service';
import { UsersAdminEventsService } from '../../features/admin-tools/users/services/users-admin-events.service';
import { TranslationService } from '@cubicNx/libs/utils';

import { PasswordExpiry } from '../../support-features/login/types/types';
import { UserLogin } from '../../support-features/login/types/api-types';
import { AgencyConfig, ConfigServiceNow } from '../../config/types/types';

@Component({
	selector: 'the-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss'],
})
export class HeaderComponent extends TranslateBaseComponent implements OnInit, OnDestroy {
	public userFullName: string;
	public currentUserId: number;
	public warnPasswordExpiring: boolean = false;

	private passwordChange$Subscription: Subscription = null;

	constructor(
		private logoutService: LogoutService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private currentUserUtilService: CurrentUserUtilService,
		private usersAdminEventsService: UsersAdminEventsService,
		private router: Router,
		translationService: TranslationService
	) {
		super(translationService);
	}

	/**
	 * performs initialization tasks for the header view
	 *
	 * sets up subscriptions, logs the user out if they have left a previous session too long and checks for password expiry
	 */
	public ngOnInit(): void {
		this.setSubscriptions();

		const currentUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		if (currentUser) {
			this.userFullName = currentUser.username;
			this.currentUserId = currentUser.nb_id;
		} else {
			this.logOut();
		}

		const passwordExpiry: PasswordExpiry = this.currentUserUtilService.determinePasswordExpiring();

		this.warnPasswordExpiring = passwordExpiry.passwordExpiring;
	}

	/**
	 * renders the service now dialog when the service now option is selected
	 */
	public popUpServiceNow = (): void => {
		const serviceNowSettings: ConfigServiceNow = this.config.getServiceNowSettings();

		window.open(serviceNowSettings.url, 'ServiceNow', 'width=' + serviceNowSettings.width + ',height=' + serviceNowSettings.height);
	};

	/**
	 * navigates the user to the supplied target URL
	 *
	 * @param target - the target
	 * @param userId - the user id
	 */
	public navigate = (target: string, userId: number): void => {
		this.router.navigate([target, userId]);
	};

	/**
	 * logs out the user
	 */
	public logOut = (): void => {
		this.logoutService.logOut();
	};

	/**
	 * general clean up activities such as removing subscriptions when component is destroyed
	 */
	public ngOnDestroy(): void {
		this.unsubscribe();
	}

	/**
	 * sets up subscriptions
	 *
	 * is interested in password changes
	 */
	private setSubscriptions = (): void => {
		this.passwordChange$Subscription = this.usersAdminEventsService.passwordChange.subscribe(() => {
			this.warnPasswordExpiring = false;
		});
	};

	/**
	 * unsubscribes from subscriptions
	 */
	private unsubscribe = (): void => {
		this.passwordChange$Subscription?.unsubscribe();
	};
}
