import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

import { MaterialModule } from '@cubicNx/libs/utils';
import { NotificationModule } from '@cubicNx/libs/utils';
import { UtilsModule } from '@cubicNx/libs/utils';
import { BlocksModule } from '../../support-features/blocks/blocks.module';
import { VehiclesModule } from '../../support-features/vehicles/vehicles.module';
import { AgencyPortalUtilsModule } from '../../utils/agency-portal-utils.module';

import { reportsStoreProvider } from './services/reports-store-factory';

import { DefaultReportTemplatesComponent } from './create-report-template-selector/default-report-templates/default-report-templates.component';
import { ReportTemplateSelectorComponent } from './create-report-template-selector/report-template-selector.component';
import { SavedReportTemplatesComponent } from './create-report-template-selector/saved-report-templates/saved-report-templates.component';
import { AdherenceDailyStackedBarChartComponent } from './interactive-viewer/charts/adherence-daily-stacked-bar-chart/adherence-daily-stacked-bar-chart.component';
import { AdherenceDonutChartComponent } from './interactive-viewer/charts/adherence-donut-chart/adherence-donut-chart.component';
import { D3WrapperComponent } from './interactive-viewer/charts/d3-wrapper/d3-wrapper.component';
import { HeadwayByRouteChartComponent } from './interactive-viewer/charts/headway-by-route-chart/headway-by-route-chart.component';
import { HeadwayByStopChartComponent } from './interactive-viewer/charts/headway-by-stop-chart/headway-by-stop-chart.component';
import { HeadwayDetailChartComponent } from './interactive-viewer/charts/headway-detail-chart/headway-detail-chart.component';
import { ReportViewerHeaderComponent } from './interactive-viewer/report-viewer-header/report-viewer-header.component';
import { AdherenceByRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad100/adherence-by-route-list.component';
import { AdherenceByStopListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad200/adherence-by-stop-list.component';
import { AdherenceByStopSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad200/sub-list/adherence-by-stop-sub-list.component';
import { AdherenceByVehicleByRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad300/adherence-by-vehicle-by-route-list.component';
import { AdherenceByVehicleByRouteSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad300/sub-list/adherence-by-vehicle-by-route-sub-list.component';
import { AdherenceForVehiclesByStopListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad400/adherence-for-vehicles-by-stop-list.component';
import { AdherenceForVehiclesByStopSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad400/sub-list/adherence-for-vehicles-by-stop-sub-list.component';
import { AdherenceByRouteDirectionStopListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad500/adherence-by-route-direction-stop-list.component';
import { AdherenceByRouteDirectionStopSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad500/sub-list/adherence-by-route-direction-stop-sub-list.component';
import { AdherenceVehicleArrivalAndDepartureListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad600/adherence-vehicle-arrival-and-departure-list.component';
import { AdherenceVehicleArrivalAndDepartureSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/ad600/sub-list/adherence-vehicle-arrival-and-departure-sub-list.component';
import { AdherenceDisplayComponent } from './interactive-viewer/report-viewer-list/list-reports/adherence/display/adherence-display.component';
import { BlockAssignmentsListComponent } from './interactive-viewer/report-viewer-list/list-reports/block/bl100/block-assignments-list.component';
import { BlockDifferenceDisplayComponent } from './interactive-viewer/report-viewer-list/list-reports/block/display/difference/block-difference-display.component';
import { BlockStatusDisplayComponent } from './interactive-viewer/report-viewer-list/list-reports/block/display/status/block-status-display.component';
import { ConfigurationStopsAndTimepointsByBlockListComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c100/configuration-stops-and-timepoints-by-block-list.component';
import { ConfigurationStopsAndTimepointsByBlockSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c100/sub-list/configuration-stops-and-timepoints-by-block-sub-list.component';
import { ConfigurationScheduleForRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c200/configuration-schedule-for-route-list.component';
import { ConfigurationStopsByRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c300/configuration-stops-by-route-list.component';
import { ConfigurationStopsByRouteSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c300/sub-list/configuration-stops-by-route-sub-list.component';
import { ConfigurationAverageTravelTimeForRouteComponent } from './interactive-viewer/report-viewer-list/list-reports/configuration/c500/configuration-average-travel-time-for-route-list.component';
import { DeadheadingVehiclesComponent } from './interactive-viewer/report-viewer-list/list-reports/deadheading-vehicles/dv100/deadheading-vehicles-list.component';
import { HeadwayAdherenceDisplayComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/display/headway-adherence-display.component';
import { HeadwayByStopListComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/hw100/headway-by-stop-list.component';
import { HeadwayByStopSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/hw100/sub-list/headway-by-stop-sub-list.component';
import { HeadwayDetailListComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/hw200/headway-detail-list.component';
import { HeadwayDetailSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/hw200/sub-list/headway-detail-sub-list.component';
import { HeadwayByRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/headway/hw300/headway-by-route-list.component';
import { RiderCountByRouteListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc100/rider-count-by-route-list.component';
import { RiderCountByRouteByStopListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc200/rider-count-by-route-by-stop-list.component';
import { RiderCountByRouteByStopSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc200/sub-list/rider-count-by-route-by-stop-sub-list.component';
import { RiderCountByTripListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc300/rider-count-by-trip-list.component';
import { RiderCountByTripSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc300/sub-list/rider-count-by-trip-sub-list.component';
import { RiderCountByBlockListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc400/rider-count-by-block-list.component';
import { RiderCountByVehicleListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc500/rider-count-by-vehicle-list.component';
import { RiderCountByBlockAndVehicleListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc600/rider-count-by-block-and-vehicle-list.component';
import { RiderCountByBlockAndVehicleSubListComponent } from './interactive-viewer/report-viewer-list/list-reports/ridership/rc600/sub-list/rider-count-by-block-and-vehicle-sub-list.component';
import { VehicleLocationByVehicleListComponent } from './interactive-viewer/report-viewer-list/list-reports/vehicle-location/avl200/vehicle-location-by-vehicle-list.component';
import { VehicleLocationLastListComponent } from './interactive-viewer/report-viewer-list/list-reports/vehicle-location/avl300/vehicle-location-last-list.component';
import { ReportViewerListComponent } from './interactive-viewer/report-viewer-list/report-viewer-list.component';
import { ReportViewerSummaryComponent } from './interactive-viewer/report-viewer-summary/report-viewer-summary.component';
import { AdherenceByRouteSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/ad100/adherence-by-route-summary.component';
import { AdherenceByStopSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/ad200/adherence-by-stop-summary.component';
import { AdherenceByVehicleSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/ad300/adherence-by-vehicle-summary.component';
import { BlockAssignmentsSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/bl100/block-assignments-summary.component';
import { HeadwayByStopSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/hw100/headway-by-stop-summary.component';
import { HeadwayDetailSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/hw200/headway-detail-summary.component';
import { HeadwayByRouteSummaryComponent } from './interactive-viewer/report-viewer-summary/summary-reports/hw300/headway-by-route-summary.component';
import { ReportViewerComponent } from './interactive-viewer/report-viewer.component';
import { AvailableReportsListComponent } from './main/list/available/available-reports-list.component';
import { AvailableReportsListFilterComponent } from './main/list/available/filter/available-reports-list-filter.component';
import { SavedReportTemplatesListComponent } from './main/list/saved/saved-report-templates-list.component';
import { ReportsHomeComponent } from './main/reports-home.component';
import { ReportDetailsComponent } from './report-details/report-details.component';
import { ReportTemplateDetailsComponent } from './report-template-details/report-template-details.component';
import { ReportTemplateEditComponent } from './report-template-edit/report-template-edit.component';
import { SavedReportTemplateSelectorComponent } from './saved-report-template-selector/saved-report-template-selector.component';
import { ReportSummaryContentComponent } from './summary/content/summary-content/report-summary-content.component';
import { ReportTemplateSummaryContentComponent } from './summary/content/template-summary-content/report-template-summary-content.component';

import { ReportsApiService } from './services/reports-api.service';
import { ReportsBlockSelectionDataService } from './services/reports-block-selection-data.service';
import { ReportsConfigService } from './services/reports-config.service';
import { ReportsDataService } from './services/reports-data.service';
import { ReportsEventsService } from './services/reports-events.service';
import { ReportsGenerationService } from './services/reports-generation.service';
import { ReportsModalService } from './services/reports-modal.service';
import { ReportsStateService } from './services/reports-state.service';
import { ReportsViewLookupService } from './services/reports-view-lookup.service';

// AoT requires an exported function for factories
const httpLoaderFactory: any = (http: HttpClient) => new TranslateHttpLoader(http);

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		MaterialModule,
		NotificationModule,
		NgxDatatableModule,
		TranslateModule.forRoot({
			loader: {
				provide: TranslateLoader,
				useFactory: httpLoaderFactory,
				deps: [HttpClient],
			},
		}),
		UtilsModule,
		BlocksModule,
		VehiclesModule,
		AgencyPortalUtilsModule,
	],
	declarations: [
		ReportsHomeComponent,
		AvailableReportsListComponent,
		AvailableReportsListFilterComponent,
		SavedReportTemplatesListComponent,
		SavedReportTemplateSelectorComponent,
		ReportTemplateEditComponent,
		SavedReportTemplatesComponent,
		ReportTemplateSelectorComponent,
		DefaultReportTemplatesComponent,
		ReportViewerComponent,
		ReportViewerSummaryComponent,
		HeadwayByRouteSummaryComponent,
		HeadwayDetailSummaryComponent,
		HeadwayByRouteSummaryComponent,
		HeadwayByStopSummaryComponent,
		BlockAssignmentsSummaryComponent,
		AdherenceByStopSummaryComponent,
		AdherenceByRouteSummaryComponent,
		ReportViewerListComponent,
		VehicleLocationLastListComponent,
		VehicleLocationByVehicleListComponent,
		RiderCountByBlockAndVehicleListComponent,
		RiderCountByBlockAndVehicleSubListComponent,
		RiderCountByVehicleListComponent,
		RiderCountByBlockListComponent,
		RiderCountByTripListComponent,
		RiderCountByTripSubListComponent,
		RiderCountByRouteByStopListComponent,
		RiderCountByRouteByStopSubListComponent,
		RiderCountByRouteListComponent,
		HeadwayByRouteListComponent,
		HeadwayDetailListComponent,
		HeadwayDetailSubListComponent,
		HeadwayByStopListComponent,
		HeadwayByStopSubListComponent,
		HeadwayAdherenceDisplayComponent,
		DeadheadingVehiclesComponent,
		ConfigurationStopsByRouteListComponent,
		ConfigurationStopsByRouteSubListComponent,
		ConfigurationAverageTravelTimeForRouteComponent,
		ConfigurationScheduleForRouteListComponent,
		ConfigurationStopsAndTimepointsByBlockListComponent,
		ConfigurationStopsAndTimepointsByBlockSubListComponent,
		BlockStatusDisplayComponent,
		BlockDifferenceDisplayComponent,
		BlockAssignmentsListComponent,
		AdherenceDisplayComponent,
		AdherenceVehicleArrivalAndDepartureListComponent,
		AdherenceVehicleArrivalAndDepartureSubListComponent,
		AdherenceByRouteDirectionStopListComponent,
		AdherenceByRouteDirectionStopSubListComponent,
		AdherenceForVehiclesByStopListComponent,
		AdherenceForVehiclesByStopSubListComponent,
		AdherenceByVehicleByRouteListComponent,
		AdherenceByVehicleByRouteSubListComponent,
		AdherenceByStopListComponent,
		AdherenceByStopSubListComponent,
		AdherenceByRouteListComponent,
		ReportViewerHeaderComponent,
		HeadwayDetailChartComponent,
		HeadwayByStopChartComponent,
		HeadwayByRouteChartComponent,
		D3WrapperComponent,
		AdherenceByVehicleSummaryComponent,
		AdherenceDonutChartComponent,
		AdherenceDailyStackedBarChartComponent,
		ReportTemplateSummaryContentComponent,
		ReportSummaryContentComponent,
		ReportDetailsComponent,
		ReportTemplateDetailsComponent,
	],
	providers: [
		reportsStoreProvider,
		ReportsDataService,
		ReportsApiService,
		ReportsBlockSelectionDataService,
		ReportsConfigService,
		ReportsEventsService,
		ReportsGenerationService,
		ReportsModalService,
		ReportsStateService,
		ReportsViewLookupService,
	],
	exports: [],
})
export class ReportsModule {}
