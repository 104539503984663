/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { CONFIG_TOKEN, ObjectHelpers } from '@cubicNx/libs/utils';

import { CurrentUserUtilService } from '../../login/services/current-user/current-user-utils.service';
import { CurrentUserPermissionService } from '../../login/services/current-user/current-user-permission.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { AgenciesApiService } from './agencies-api.service';
import { StorageService } from '@cubicNx/libs/utils';

import { StorageType } from '@cubicNx/libs/utils';
import { Agencies, Agency, AgencyDetail, AgencyFeature, AgencyFeatures, SelectedAgency } from '../types/api-types';
import { ResultContent } from '@cubicNx/libs/utils';
import { UserLogin } from '../../login/types/api-types';

import { CurrentUserAgencies, defaultHeadwayAdherenceSettings, HeadwaySettings, AdherenceSettings } from '../types/types';
import { AgencyConfig } from '../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class AgenciesDataService {
	private agencies: Agencies = [];
	private agencyFeatures: AgencyFeatures = [];
	private loaded: boolean = false;

	private readonly storageItemPrefix: string = 'nextbus-agency-portal.';

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private currentUserPermissionService: CurrentUserPermissionService,
		private logger: LoggerService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private storageService: StorageService,
		private agenciesApiService: AgenciesApiService
	) {}

	/**
	 * extract the full agency detail for the selected agency from the loaded agencies list
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the full agency
	 */
	public getAgencyDetail = (authorityId: string, agencyId: string): Agency => {
		let agencyDetail: Agency = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && agency.agency_id === agencyId) {
				agencyDetail = agency;
			}
		});

		return agencyDetail;
	};

	/**
	 * extract the agency name from the agencies settings
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency name
	 */
	public getAgencyName = (authorityId: string, agencyId: string): string => {
		const agency: Agency = this.getAgencyDetail(authorityId, agencyId);

		return agency.agency_name;
	};

	/**
	 * extract the agency nb id from the agencies settings for the authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency nb id
	 */
	public getAgencyNbIdFromAuthorityAgencyId = (authorityId: string, agencyId: string): number => {
		let agencyNbId: number = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && agency.agency_id === agencyId) {
				agencyNbId = agency.agency_nb_id;
			}
		});

		return agencyNbId;
	};

	/**
	 * extract the nb id from the agencies settings for the authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the nb id
	 */
	public getNbIdFromAuthorityAgencyId = (authorityId: string, agencyId: string): number => {
		let nbId: number = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && agency.agency_id === agencyId) {
				nbId = agency.nb_id;
			}
		});

		return nbId;
	};

	/**
	 * get the agency time format for a authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency time format
	 */
	public getAgencyTimeFormat = (authorityId: string, agencyId: string): string => {
		let timeFormat: string;

		let agencyTimeFormat: string = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && (agency.agency_id === agencyId || agencyId === null)) {
				timeFormat = agency.time_format;

				if (!timeFormat) {
					timeFormat = 'hh:mm a';
				}

				const timeFormatParts: string[] = timeFormat.split(':');

				agencyTimeFormat = timeFormatParts.shift();

				timeFormatParts.forEach((timeFormatPart: string) => {
					agencyTimeFormat += ':' + timeFormatPart.toLowerCase();
				});
			}
		});

		return agencyTimeFormat;
	};

	/**
	 * get the agency date format for a authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency date format
	 */
	public getAgencyDateFormat = (authorityId: string, agencyId: string): string => {
		let dateFormat: string = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && (agency.agency_id === agencyId || agencyId === null)) {
				dateFormat = agency.date_format;
			}
		});

		return dateFormat;
	};

	/**
	 * get the agency timezone for a authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency timezone
	 */
	public getAgencyTimezone = (authorityId: string, agencyId: string): string => {
		let agencyTimezone: string = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && (agency.agency_id === agencyId || !agencyId)) {
				agencyTimezone = agency.agency_timezone;
			}
		});

		return agencyTimezone;
	};

	/**
	 * get the agency measurement system (unit of measure) for a authority/agency id
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the agency unit of measure
	 */
	public getAgencyMeasurementSystem = (authorityId: string, agencyId: string): string => {
		let unitOfMeasure: string = null;

		this.agencies.forEach((agency: Agency) => {
			if (agency.authority_id === authorityId && (agency.agency_id === agencyId || agencyId === null)) {
				unitOfMeasure = agency.unit_of_measure;
			}
		});

		return unitOfMeasure;
	};

	/**
	 * get the selected agency for a user
	 *
	 * @param authorityId - the authority id for the request
	 * @param agencyId - the agency id for the request
	 * @returns the selected agency
	 */
	public getSelectedAgency = (): SelectedAgency => {
		const selectedAgency: SelectedAgency = this.currentUserUtilService.getSelectedAgency();

		return selectedAgency;
	};

	/**
	 * get the adherence settings for an authority
	 *
	 * @param authorityId - the authority id for the request
	 * @returns the adherence settings for the agency
	 */
	public getAdherenceSettings = (authorityId: string): AdherenceSettings => {
		const agencies: Agencies = this.getAgencies();

		let adherenceSettings: AdherenceSettings = {
			veryLateSec: 600,
			lateMinSec: 60,
			earlyMinSec: 60,
			veryEarlySec: 600,
		};

		agencies.forEach((item) => {
			if (item.authority_id === authorityId) {
				adherenceSettings = ObjectHelpers.defaults(
					{
						veryLateSec: item.adherence_setting_very_late_sec,
						lateMinSec: item.adherence_setting_late_min_sec,
						earlyMinSec: item.adherence_setting_early_min_sec,
						veryEarlySec: item.adherence_setting_very_early_sec,
					},
					adherenceSettings
				);
			}
		});

		return adherenceSettings;
	};

	/**
	 * get the headway settings for an authority
	 *
	 * @param authorityId - the authority id for the request
	 * @returns the headway settings for the agency
	 */
	public getHeadwayAdherenceSettings = (authorityId: string): HeadwaySettings => {
		const defaults: HeadwaySettings = defaultHeadwayAdherenceSettings;

		const agency: Agency = this.agencies.find((a) => a.authority_id === authorityId);

		return {
			headwayAdherenceClose: agency.headway_adherence_close || defaults.headwayAdherenceClose,
			headwayAdherenceVeryClose: agency.headway_adherence_very_close || defaults.headwayAdherenceVeryClose,
			headwayAdherenceDistant: agency.headway_adherence_distant || defaults.headwayAdherenceDistant,
			headwayAdherenceVeryDistant: agency.headway_adherence_very_distant || defaults.headwayAdherenceVeryDistant,
		};
	};

	/**
	 * set the selected agencies for the user (restristed to single agency)
	 *
	 * @param authorityId - the authority id for the request
	 */
	public setUserSelectedAgency = async (authorityId: string): Promise<void> => {
		if (this.agencies) {
			this.agencies.forEach(async (agency: Agency) => {
				if (agency.authority_id === authorityId) {
					const selectedAgency: SelectedAgency = this.getSelectedAgencyFromAgency(agency);

					await this.currentUserUtilService.setUserSelectedAgency(selectedAgency);
				}
			});
		}
	};

	/**
	 * check a list of agencies and check those agencies to check if the user has been assigned
	 *
	 * @param agencies - the agencies to check
	 * @returns the list of current user agencies
	 */
	public getCurrentUserAgencies = (agencies: Agencies): CurrentUserAgencies => {
		const curUser: UserLogin = this.currentUserUtilService.getCurrentUser();

		const curUserAgencies: CurrentUserAgencies = [];

		if (curUser?.authorities) {
			for (const authorityKey in curUser.authorities) {
				const authority: any = curUser.authorities[authorityKey];

				if (authority.roles && authority.roles.length > 0 && authority.roles[0].includes(this.config.getAdminAgencyRoleName())) {
					//authority Admin so add all authority agencies
					if (agencies && Array.isArray(agencies)) {
						agencies.forEach((item) => {
							if (item.authority_id === authorityKey) {
								const currentUserAgency: any = {
									authorityId: authorityKey,
									nbAgencyInfoId: item.nb_id,
									nbAgencyId: item.agency_nb_id,
								};

								curUserAgencies.push(currentUserAgency);
							}
						});
					}
				} else {
					for (const agencyKey in curUser.authorities[authorityKey].agencies) {
						if (curUser.authorities[authorityKey].agencies[agencyKey]) {
							const currentUserAgency: any = {
								authorityId: authorityKey,
								nbAgencyInfoId: curUser.authorities[authorityKey].agencies[agencyKey].nbAgencyInfoId,
								nbAgencyId: curUser.authorities[authorityKey].agencies[agencyKey].nbAgencyId,
							};

							curUserAgencies.push(currentUserAgency);
						}
					}
				}
			}
		}

		return curUserAgencies;
	};

	/**
	 * checks the agency cache is valid
	 *
	 * @param cacheTimeStamp - the cached time stamp
	 * @returns a fkag indiicating if the flag is valid
	 */
	public agencyCacheIsValid = (cacheTimeStamp: number): boolean => {
		const today: Date = new Date();
		const yesterday: Date = new Date();

		yesterday.setDate(today.getDate() - 1);

		const importTimeStamp: number = yesterday.getTime();

		return cacheTimeStamp >= importTimeStamp;
	};

	/**
	 * get the full list of agencies
	 *
	 * @returns the full list of agencies
	 */
	public getAgencies = (): Agencies => {
		return this.agencies;
	};

	/**
	 * get the agencies for an authority (now 1 to 1 mapping)
	 *
	 * @param authorityId - the authority id
	 * @returns  the agencies for an authority
	 */
	public getAgenciesByAuthority = (authorityId: string): Agencies => {
		return this.agencies.filter((agency) => agency.authority_id === authorityId);
	};

	/**
	 * get an agency based on Id
	 *
	 * this version returns agency based on nb_id (assumed to be database identifer) but typically
	 * is the same id as agency_nb_id (see getAgencyByAgencyNbId). It can be different to agency_nb_id
	 * as seen in SF Muni CIS
	 *
	 * @param nbId - the id to locate the agency
	 * @returns the agency
	 */
	public getAgencyByNbId = (nbId: number): Agency => {
		return this.agencies.find((agency) => agency.nb_id === nbId);
	};

	/**
	 * get an agency based on id
	 *
	 * this version returns agency based on agency_nb_id but typically
	 * is the same id as nb_id (see getAgencyByNbId). It can be different to nb_id
	 * as seen in SF Muni CIS
	 *
	 * @param agencyNbId - the id to locate the agency
	 * @returns the agency
	 */
	public getAgencyByAgencyNbId = (agencyNbId: number): Agency => {
		return this.agencies.find((agency) => agency.agency_nb_id === agencyNbId);
	};

	/**
	 * determine if a particular agency filter is enabled
	 *
	 * @param agencyId - the agency id to check
	 * @param featureId - the feature id to check
	 * @returns true if the feature is enabled
	 */
	public isFeatureEnabled = (agencyId: number, featureId: number): boolean => {
		let featureEnabled: boolean = false;

		this.agencyFeatures.forEach((feat) => {
			if (featureId === feat.feature_id && agencyId === feat.agency_info_id) {
				const today: Date = new Date(Date.now());

				if (feat.turned_on) {
					const turnedOnDate: Date = new Date(feat.turned_on);

					if (turnedOnDate <= today) {
						featureEnabled = true;
					}

					if (feat.expires) {
						const expiration: Date = new Date(feat.expires);

						featureEnabled = expiration >= today;
					}
				} else {
					featureEnabled = false;
				}
			}
		});

		return featureEnabled;
	};

	/**
	 * get all agency features
	 *
	 * @returns the agency features
	 */
	public getAgencyFeatures = (): AgencyFeatures => {
		return this.agencyFeatures;
	};

	/**
	 * get an agency feature by name
	 *
	 * @param name - the name of the required feature
	 * @returns the agency feature
	 */
	public getAgencyFeature = (name: string): AgencyFeature => {
		return this.agencyFeatures.find((feat: AgencyFeature) => feat.feature.name === name);
	};

	/**
	 * handle the request of loading all agencies
	 */
	public loadAgencies = async (): Promise<void> => {
		try {
			const agenciesList: Agencies = await this.agenciesApiService.loadAgencies();

			this.agencies = [];

			if (this.currentUserPermissionService.isNextbusAdmin()) {
				this.agencies = agenciesList;
			} else {
				const curUserAgencies: CurrentUserAgencies = this.getCurrentUserAgencies(agenciesList);

				agenciesList.forEach((agenciesItem) => {
					curUserAgencies.forEach((curUserItem) => {
						if (curUserItem.nbAgencyInfoId === agenciesItem.nb_id) {
							this.agencies.push(agenciesItem);
						}
					});
				});
			}

			const defaultAgency: Agency = this.getDefaultAgency();

			this.agencyFeatures = await this.agenciesApiService.getAgencyFeatures(defaultAgency.authority_id);

			// now we have agencies - check that the user has an agency set and is valid and set to default if not
			this.checkCurrentAgency();

			this.loaded = true;
		} catch (exception: any) {
			this.logger.logError('Failed to get agencies - ' + exception.message);
		}
	};

	/**
	 * check that the user is still allowed to agency they have set. If not set to their default agency
	 */
	public checkCurrentAgency = async (): Promise<void> => {
		// get the current agency
		const currentUserSelectedAgency: SelectedAgency = this.getSelectedAgency();

		// if there isn't an agency set for the user (will happen when user first created) OR
		// if the agency is no longer in the list of agencies (edge case where a user loses permision to
		// an agency or they are set to an agency removed by the system) then reset the agency to default.
		// Note a new user wouldn't have the agency object set but the primary user agency id will always be present
		// so we can set from that.
		if (!currentUserSelectedAgency || !this.selectedAgencyAllowed(currentUserSelectedAgency)) {
			await this.setUserDefaultSelectedAgency();
		}
	};

	/**
	 * handle the request to retrieve a single agency
	 *
	 * @param authorityId - the authority id required for the request
	 * @param agencyId - the agency id required for the request
	 * @returns the agency
	 */
	public getAgency = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		if (authorityId !== null) {
			try {
				const agencyDetail: AgencyDetail = await this.agenciesApiService.getAgency(authorityId, agencyId);

				result.success = true;
				result.resultData = agencyDetail as AgencyDetail;
			} catch (exception) {
				this.logger.logError('Failed to get agency - ', exception);
			}
		}

		return result;
	};

	/**
	 * get the users default agency by looking up the primary agency id
	 *
	 * @returns the users default agency
	 */
	public getDefaultAgency = (): Agency => {
		const userPrimaryAgencyId: number = this.currentUserUtilService.getCurrentUserPrimaryAgencyId();

		return this.getAgencyByNbId(userPrimaryAgencyId);
	};

	//--------------------------------------------------------------------
	//
	//--------------------------------------------------------------------

	/**
	 * get agency route bounding box
	 *
	 * @param authorityId - the authority id required for the request
	 * @param agencyId - the agency id required for the request
	 * @returns the agency boundary
	 */
	public getAgencyBoundary = async (authorityId: string, agencyId: string): Promise<ResultContent> => {
		const result: ResultContent = {
			success: false,
			resultData: null,
		};

		if (authorityId && agencyId) {
			const cacheKey: string = this.storageItemPrefix + authorityId + '-' + agencyId + '-boundary';
			const cachedBoundary: any = this.storageService.get(cacheKey, StorageType.local);

			if (cachedBoundary && cachedBoundary.val && this.agencyCacheIsValid(cachedBoundary.timestamp)) {
				result.success = true;
				result.resultData = cachedBoundary.val;
			} else {
				try {
					const boundary: any = await this.agenciesApiService.getAgencyBoundary(authorityId, agencyId);

					result.success = true;

					if (boundary) {
						const cacheVal: any = { timestamp: Date.now(), val: boundary };

						this.storageService.set(cacheKey, cacheVal, StorageType.local);
						result.resultData = boundary;
					} else {
						this.storageService.set(cacheKey, [], StorageType.local);
						result.resultData = [];
					}
				} catch (exception) {
					this.logger.logError('Failed to get agency boundary - ', exception);
				}
			}
		} else {
			this.logger.logError('Failed to get agency boundary, no agency');
		}

		return result;
	};

	/**
	 * determine if agencies are successfully loaded
	 * @returns if agencies are loaded
	 */
	public agenciesLoaded = (): boolean => {
		return this.loaded;
	};

	/**
	 * set the current agency to the users default agency
	 */
	private setUserDefaultSelectedAgency = async (): Promise<void> => {
		const primaryAgencyId: number = this.currentUserUtilService.getCurrentUserPrimaryAgencyId();

		if (this.agencies) {
			this.agencies.forEach(async (agency: Agency) => {
				if (agency.nb_id === primaryAgencyId) {
					const selectedAgency: SelectedAgency = this.getSelectedAgencyFromAgency(agency);

					await this.currentUserUtilService.setUserSelectedAgency(selectedAgency);
				}
			});
		}
	};

	/**
	 * get the selected agency summary from the full agency object
	 * @param agency - the full agency
	 * @returns - the summary agency object
	 */
	private getSelectedAgencyFromAgency = (agency: Agency): SelectedAgency => {
		return {
			id: agency.nb_id,
			agency_id: agency.agency_id,
			agency_nb_id: agency.agency_nb_id,
			authority_id: agency.authority_id,
			agency_name: agency.agency_name,
		};
	};

	/**
	 * determine if the user is allowed a particular agency
	 * @param cachedSelectedAgency - the current cached agency that needs checking
	 * @returns a flag indicating if the cached agency is still allowed
	 */
	private selectedAgencyAllowed = (cachedSelectedAgency: SelectedAgency): boolean => {
		let foundAgency: boolean = false;

		for (const agency of this.agencies) {
			if (cachedSelectedAgency.authority_id === agency.authority_id && cachedSelectedAgency.agency_id === agency.agency_id) {
				foundAgency = true;
				break;
			}
		}

		if (!foundAgency) {
			return false;
		}

		return true;
	};
}
