/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { CurrentUserUtilService } from '../../../support-features/login/services/current-user/current-user-utils.service';
import { CONFIG_TOKEN, StorageService, TimeFormatType } from '@cubicNx/libs/utils';
import { ObjectHelpers } from '@cubicNx/libs/utils';
import { AgenciesDataService } from '../../../support-features/agencies/services/agencies-data.service';
import { MapEventsService } from './map-events.service';
import { LoggerService } from '@cubicNx/libs/utils';
import { AgenciesEventsService } from '../../../support-features/agencies/services/agencies-events.service';

import { StorageType } from '@cubicNx/libs/utils';
import { Agency, SelectedAgency } from '../../../support-features/agencies/types/api-types';

import { ColorVehicleByType, DisplayPriorityType, MapRoute, MapRoutes, RouteAssociatedStop, RouteAssociatedVehicle } from '../types/types';

import {
	ModeType,
	MapModeType,
	MapState,
	NavigationPages,
	BaseMapType,
	MapUpdateType,
	TrailedVehicle,
	MapLocation,
	MapStop,
	MapStops,
	MapVehicles,
	allDepot,
	MapDepotSelection,
	MapVehicle,
} from '../types/types';

import { AgencyConfig, ConfigMapLocation } from '../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapStateService {
	private readonly stateKey: string = 'map-state';

	private readonly defaultState: MapState = {
		navViewOpen: true,
		navHistory: [],
		mode: ModeType.map,
		mapMode: MapModeType.live,
		replay: {
			showReplayControl: false,
			currentReplayTime: null,
			currentReplayId: null,
		},
		liveUpdatesPaused: false,
		fullScreen: false,
		location: {
			lat: 30.2672,
			lon: -97.7431,
			zoom: 11,
			offsetAdjust: true,
			clearTrackedVehicle: true,
		},
		routesToLocate: null,
		activeEntity: null,
		trackedVehicleId: null,
		trailedVehicle: {
			vehicleId: null,
			vehicleColor: null,
			trailPoints: [],
		},
		routes: {},
		stops: {},
		vehicles: {},
		settings: {
			baseMap: BaseMapType.streets,
			showVehicleLabels: false,
			showVehicleLabelId: true,
			showVehicleLabelRoute: true,
			showVehicleLabelDepartureAdherence: true,
			showVehicleLabelHeadway: true,
			showVehicleLabelStatus: true,
			showVehicleLabelBlock: false,
			showVehicleLabelPassengers: false,
			showVehiclesWithAdherenceIssues: false,
			showVehiclesWithHeadwayIssues: false,
			showVehiclesAtDepot: false,
			showHiddenStops: false,
			showLadderStopLabels: true,
			showLadderStopLabelCode: true,
			showLadderStopLabelName: true,
			selectedDepotFilter: allDepot,
			displayPriority: DisplayPriorityType.vehicles,
			colorVehicleBy: ColorVehicleByType.route,
			timeFormat: TimeFormatType.relativeTime,
			vehicleLabelClusterRadius: 50,
		},
		authorityId: null, // placeholder - we will set this at runtime
		firstRefresh: true,
	};

	private currentUsername: string = null;

	private state: MapState = null;

	constructor(
		private currentUserUtilService: CurrentUserUtilService,
		private mapEventsService: MapEventsService,
		private agenciesDataService: AgenciesDataService,
		private agenciesEventsService: AgenciesEventsService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private logger: LoggerService,
		private storageService: StorageService
	) {}

	/**
	 * initialize the map state
	 *
	 * retrieve the map state from the browser cache if present.  Merge anything new that has been added (may impact users
	 * who last ran under a different release version)
	 */
	public init = (): void => {
		this.currentUsername = this.currentUserUtilService.getCurrentUser().username;

		const state: MapState = this.storageService.get(this.stateKey + '-' + this.currentUsername, StorageType.local);

		if (state) {
			this.state = state;

			this.mergeDefaultData();

			if (this.hasAgencyChanged(this.state.authorityId)) {
				this.reset();
			} else {
				// always start up in live mode - too many complications to deal with starting in the middle of a
				// replay and isn't a sensible use case
				this.setMapMode(MapModeType.live);

				this.setFirstRefresh(true);
			}
		} else {
			this.reset();
		}

		this.save();
	};

	/**
	 * return the current map state
	 * @returns  the current map state
	 */
	public getCurrentState = (): MapState => {
		return this.state;
	};

	/**
	 * set the current map state
	 *
	 * if we are loading an old (or saved view) state with a different authority selected then set this agency
	 *
	 * add any defaults that have been added (i.e new development), since the state was last saved
	 *
	 * @param state - the new map state
	 */
	public setCurrentState = async (state: MapState): Promise<void> => {
		if (this.hasAgencyChanged(state.authorityId)) {
			await this.agenciesDataService.setUserSelectedAgency(state.authorityId);

			this.reset();

			this.agenciesEventsService.publishAgenciesSelectionChange();
		}

		this.state = state;

		this.mergeDefaultData();

		this.mapEventsService.publishStateChanged();

		this.save();
	};

	/**
	 * set the navigation view open
	 *
	 * @param navViewOpen - true when opening the navigation view
	 */
	public setNavViewOpen = (navViewOpen: boolean): void => {
		this.state.navViewOpen = navViewOpen;
		this.save();
	};

	/**
	 * get the navigation view open state
	 *
	 * @param navViewOpen - true when the navigation view is open
	 * @returns true when the nav view is open
	 */
	public getNavViewOpen = (): boolean => {
		return this.state.navViewOpen;
	};

	/**
	 * set the current map mode (live/replay)
	 *
	 * @param mapMode -the current map mode (live/replay)
	 */
	public setMapMode = (mapMode: MapModeType): void => {
		this.state.mapMode = mapMode;

		if (mapMode === MapModeType.live) {
			this.state.replay.showReplayControl = false;
		}

		this.save();
	};

	/**
	 * get the current map mode (live/replay)
	 *
	 * @returns the current map mode (live/replay)
	 */
	public getMapMode = (): MapModeType => {
		return this.state.mapMode;
	};

	/**
	 * set the current mode (map/ladder)
	 *
	 * @param mode - the current mode (map/ladder)
	 */
	public setMode = (mode: ModeType): void => {
		this.state.mode = mode;

		this.mapEventsService.publishMapUpdate(MapUpdateType.modeRefresh);

		this.save();
	};

	/**
	 * get the current mode (map/ladder)
	 *
	 * @returns the current mode (map/ladder)
	 */
	public getMode = (): ModeType => {
		return this.state.mode;
	};

	/**
	 * refresh the current mode
	 */
	public refreshMode = (): void => {
		this.mapEventsService.publishMapUpdate(MapUpdateType.modeRefresh);
	};

	/**
	 * get whether the replay control is showing
	 *
	 * @returns whether the replay control is showing
	 */
	public getShowReplayControl = (): boolean => {
		return this.state.replay.showReplayControl;
	};

	/**
	 * set whether the replay control is showing
	 *
	 * @param showReplayControl - whether the replay control is showing
	 */
	public setShowReplayControl = (showReplayControl: boolean): void => {
		this.state.replay.showReplayControl = showReplayControl;
		this.save();
	};

	/**
	 * get the current replay time
	 *
	 * @returns the current replay time
	 */
	public getCurrentReplayTime = (): number => {
		return this.state.replay.currentReplayTime;
	};

	/**
	 * set the current replay time
	 *
	 * @param currentReplayTime - the current replay time
	 */
	public setCurrentReplayTime = (currentReplayTime: number): void => {
		this.state.replay.currentReplayTime = currentReplayTime;
		this.save();
	};

	/**
	 * get the current replay id
	 *
	 * @returns the current replay id
	 */
	public getCurrentReplayId = (): string => {
		return this.state.replay.currentReplayId;
	};

	/**
	 * set the current replay id
	 *
	 * @param currentReplayId - the current replay id
	 */
	public setCurrentReplayId = (currentReplayId: string): void => {
		this.state.replay.currentReplayId = currentReplayId;
		this.save();
	};

	/**
	 * set the navigation history
	 *
	 * @param navHistory - the navigation history
	 */
	public setNavHistory = (navHistory: NavigationPages): void => {
		this.state.navHistory = navHistory;
		this.save();
	};

	/**
	 * get the navigation history
	 *
	 * @returns the navigation history
	 */
	public getNavHistory = (): NavigationPages => {
		return this.state.navHistory;
	};

	/**
	 * set live updates paused (or live)
	 *
	 * @param liveUpdatesPaused - true when live updates are to be paused
	 */
	public setLiveUpdatesPaused = (liveUpdatesPaused: boolean): void => {
		this.state.liveUpdatesPaused = liveUpdatesPaused;
		this.save();
	};

	/**
	 * get whether live updates are paused (or live)
	 *
	 * @param liveUpdatesPaused - true when live updates are paused
	 * @returns whether live updates are paused (or live)
	 */
	public getLiveUpdatesPaused = (): boolean => {
		return this.state.liveUpdatesPaused;
	};

	/**
	 * set full screen mode on or off
	 *
	 * @param fullScreen - true when full screen mode is to be set on
	 */
	public setFullScreen = (fullScreen: boolean): void => {
		this.state.fullScreen = fullScreen;
		this.mapEventsService.publishMapUpdate(MapUpdateType.fullScreenChange);
		this.save();
	};

	/**
	 * get whether full screen mode is on
	 *
	 * @returns true when full screen mode is on
	 */
	public getFullScreen = (): boolean => {
		return this.state.fullScreen;
	};

	/**
	 * set the location
	 *
	 * keep refresh value separate so it doesnt get stored with location and incorrectly persited for map reloads
	 *
	 * @param location - the location to be set
	 * @param refresh - whether to refresh the location (when false we are already in the right place and just maintaining
	 * the currently stored location)
	 */
	public setLocation = (location: MapLocation, refresh: boolean): void => {
		this.state.location = location;

		// inside refresh to cover edge case where we start tracking a vehicle shortly after adding a route and the tracking being
		// cleared instantly
		if (location.clearTrackedVehicle) {
			this.setTrackedVehicleId(null);
		}

		if (refresh) {
			this.mapEventsService.publishMapUpdate(MapUpdateType.locationUpdate);
		}

		this.save();
	};

	/**
	 * get the current map location
	 *
	 * @returns the current map location
	 */
	public getLocation = (): MapLocation => {
		return this.state.location;
	};

	/**
	 * set the location zoom level
	 *
	 * @param zoom - the location zoom level
	 */
	public setLocationZoom = (zoom: number): void => {
		this.state.location.zoom = zoom;
		this.save();
	};

	/**
	 * get the location zoom level
	 *
	 * @returns the location zoom level
	 */
	public getLocationZoom = (): number => {
		return this.state.location.zoom;
	};

	/**
	 * set routes to locate
	 *
	 * @param routesIds - the routes to locate
	 */
	public setRoutesToLocate = (routesIds: Array<string>): void => {
		// stores routes to ultimately send the routesToLocateUpdate which in turn will inform the map to call the
		// map leaflet fitBounds method which will center the map to ensure that all routes supplied will be displayed
		// in the map view. Note: Leaflet will trigger the moveend method and the setLocation method will be called
		// to keep our map state up to date with the lat/lon it decided on
		this.state.routesToLocate = routesIds;

		this.setTrackedVehicleId(null);

		this.mapEventsService.publishMapUpdate(MapUpdateType.routesToLocateUpdate);

		this.save();
	};

	/**
	 * get the routes to locate
	 *
	 * @returns the routes to locate
	 */
	public getRoutesToLocate = (): Array<string> => {
		return this.state.routesToLocate;
	};

	/**
	 * set the currently active (highligted) entity on the map
	 *
	 * @param activeEntityKey - the acive entity key
	 */
	public setActiveEntity = (activeEntityKey: string): void => {
		this.state.activeEntity = activeEntityKey;

		// publish an event so the map can update the highlight (set or clear) for the entity (vehicle or stop)
		this.mapEventsService.publishMapUpdate(MapUpdateType.activeEntityUpdate);

		this.save();
	};

	/**
	 * get the currently active (highligted) entity on the map
	 *
	 * @returns - the active entity key
	 */
	public getActiveEntity = (): string => {
		return this.state.activeEntity;
	};

	/**
	 * set the vehicle id to track
	 *
	 * @param vehicleId - the vehicle id to track
	 */
	public setTrackedVehicleId = (vehicleId: string): void => {
		this.state.trackedVehicleId = vehicleId;
		this.save();
	};

	/**
	 * get the currently tracked vehicle
	 *
	 * @param vehicleId - the currently tracked vehicle id
	 * @returns the currently tracked vehicle
	 */
	public getTrackedVehicleId = (): string => {
		return this.state.trackedVehicleId;
	};

	/**
	 * set the vehicle id to set trails
	 *
	 * @param trailedVehicle - the trailed vehicle details
	 */
	public setTrailedVehicle = (trailedVehicle: TrailedVehicle): void => {
		if (trailedVehicle !== null) {
			this.state.trailedVehicle = trailedVehicle;
		} else {
			this.state.trailedVehicle = this.defaultState.trailedVehicle;
		}

		// publish an event so the map can update the trail (or clear)
		this.mapEventsService.publishMapUpdate(MapUpdateType.trailedVehicleUpdate);

		this.save();
	};

	/**
	 * get the currently trailed vehicle
	 *
	 * @param vehicleId - the currently trailed vehicle
	 * @returns the trailed vehicle
	 */
	public getTrailedVehicle = (): TrailedVehicle => {
		return this.state.trailedVehicle;
	};

	/**
	 * get the currently trailed vehicle id
	 *
	 * @param vehicleId - the currently trailed vehicle id
	 * @returns the trailed vehicle id
	 */
	public getTrailedVehicleId = (): string => {
		return this.state.trailedVehicle?.vehicleId;
	};

	/**
	 * set a flag to indicate that this is the first refresh
	 *
	 * @param firstRefresh - true when this is the first refresh
	 */
	public setFirstRefresh = (firstRefresh: boolean): void => {
		this.state.firstRefresh = firstRefresh;
		this.save();
	};

	/**
	 * get whether this is the first refresh to handle actions accordingly
	 *
	 * @returns true when this is the first refresh
	 */
	public getFirstRefresh = (): boolean => {
		return this.state.firstRefresh;
	};

	/**
	 * set the base map (i.e vector street map)
	 *
	 * @param baseMap - the base map type
	 */
	public setBaseMap = (baseMap: BaseMapType): void => {
		this.state.settings.baseMap = baseMap;

		this.save();
	};

	/**
	 * get the base map (i.e vector street map)
	 *
	 * @returns the base map type
	 */
	public getBaseMap = (): BaseMapType => {
		return this.state.settings.baseMap;
	};

	/**
	 * set the map state to show/hide vehicle labels
	 *
	 * @param showVehicleLabels - flag to show/hide vehicle labels
	 */
	public setShowVehicleLabels = (showVehicleLabels: boolean): void => {
		this.state.settings.showVehicleLabels = showVehicleLabels;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle labels
	 *
	 * @returns the map state setting to show/hide vehicle labels
	 */
	public getShowVehicleLabels = (): boolean => {
		return this.state.settings.showVehicleLabels;
	};

	/**
	 * set the map state to show/hide vehicle id part of the vehicle label
	 *
	 * @param showVehicleLabelId - flag to show/hide vehicle id
	 */
	public setShowVehicleLabelId = (showVehicleLabelId: boolean): void => {
		this.state.settings.showVehicleLabelId = showVehicleLabelId;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label id
	 *
	 * @returns the map state setting to show/hide vehicle label id
	 */
	public getShowVehicleLabelId = (): boolean => {
		return this.state.settings.showVehicleLabelId;
	};

	/**
	 * set the map state to show/hide route part of the vehicle label
	 *
	 * @param showVehicleLabelRoute - flag to show/hide route
	 */
	public setShowVehicleLabelRoute = (showVehicleLabelRoute: boolean): void => {
		this.state.settings.showVehicleLabelRoute = showVehicleLabelRoute;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label route
	 *
	 * @returns the map state setting to show/hide vehicle label route
	 */
	public getShowVehicleLabelRoute = (): boolean => {
		return this.state.settings.showVehicleLabelRoute;
	};

	/**
	 * set the map state to show/hide depature adherence part of the vehicle label
	 *
	 * @param showVehicleLabelDepartureAdherence - flag to show/hide depature adherence
	 */
	public setShowVehicleLabelDepartureAdherence = (showVehicleLabelDepartureAdherence: boolean): void => {
		this.state.settings.showVehicleLabelDepartureAdherence = showVehicleLabelDepartureAdherence;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label depature adherence
	 *
	 * @returns the map state setting to show/hide vehicle label depature adherence
	 */
	public getShowVehicleLabelDepartureAdherence = (): boolean => {
		return this.state.settings.showVehicleLabelDepartureAdherence;
	};

	/**
	 * set the map state to show/hide headway part of the vehicle label
	 *
	 * @param showVehicleLabelHeadway - flag to show/hide headway
	 */
	public setShowVehicleLabelHeadway = (showVehicleLabelHeadway: boolean): void => {
		this.state.settings.showVehicleLabelHeadway = showVehicleLabelHeadway;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label headway
	 *
	 * @returns the map state setting to show/hide vehicle label headway
	 */
	public getShowVehicleLabelHeadway = (): boolean => {
		return this.state.settings.showVehicleLabelHeadway;
	};

	/**
	 * set the map state to show/hide status part of the vehicle label
	 *
	 * @param showVehicleLabelStatus - flag to show/hide status
	 */
	public setShowVehicleLabelStatus = (showVehicleLabelStatus: boolean): void => {
		this.state.settings.showVehicleLabelStatus = showVehicleLabelStatus;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label status
	 *
	 * @returns the map state setting to show/hide vehicle label status
	 */
	public getShowVehicleLabelStatus = (): boolean => {
		return this.state.settings.showVehicleLabelStatus;
	};

	/**
	 * set the map state to show/hide block part of the vehicle label
	 *
	 * @param showVehicleLabelBlock - flag to show/hide block
	 */
	public setShowVehicleLabelBlock = (showVehicleLabelBlock: boolean): void => {
		this.state.settings.showVehicleLabelBlock = showVehicleLabelBlock;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label block
	 *
	 * @returns the map state setting to show/hide vehicle label block
	 */
	public getShowVehicleLabelBlock = (): boolean => {
		return this.state.settings.showVehicleLabelBlock;
	};

	/**
	 * set the map state to show/hide passengers part of the vehicle label
	 *
	 * @param showVehicleLabelPassengers - flag to show/hide passengers
	 */
	public setShowVehicleLabelPassengers = (showVehicleLabelPassengers: boolean): void => {
		this.state.settings.showVehicleLabelPassengers = showVehicleLabelPassengers;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshVehicleLabels);

		this.save();
	};

	/**
	 * get the map state setting to show/hide vehicle label passengers
	 *
	 * @returns the map state setting to show/hide vehicle label passengers
	 */
	public getShowVehicleLabelPassengers = (): boolean => {
		return this.state.settings.showVehicleLabelPassengers;
	};

	/**
	 * set the map state display priority to the appropriate type (vehicles/stops on top)
	 *
	 * @param displayPriority - whether to set vehicles or stops on top
	 */
	public setDisplayPriority = (displayPriority: DisplayPriorityType): void => {
		this.state.settings.displayPriority = displayPriority;

		this.mapEventsService.publishMapUpdate(MapUpdateType.refreshDisplayPriority);

		this.save();
	};

	/**
	 * get the map state display priority (vehicles/stops on top)
	 *
	 * @returns whether he display priority is set to vehicles or stops on top
	 */
	public getDisplayPriority = (): DisplayPriorityType => {
		return this.state.settings.displayPriority;
	};

	/**
	 * set the color by options for vehicles (routes/schedule/headway)
	 *
	 * @param colorVehicleBy - the color by type
	 */
	public setColorVehicleBy = (colorVehicleBy: ColorVehicleByType): void => {
		this.state.settings.colorVehicleBy = colorVehicleBy;

		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadVehicles);

		this.save();
	};

	/**
	 * get the current color by option for vehicles (routes/schedule/headway)
	 *
	 * @returns the color by type
	 */
	public getColorVehicleBy = (): ColorVehicleByType => {
		return this.state.settings.colorVehicleBy;
	};

	/**
	 * set the map state to the chosen time format
	 *
	 * @param timeFormat - the time format to set
	 */
	public setTimeFormat = (timeFormat: TimeFormatType): void => {
		this.state.settings.timeFormat = timeFormat;
		this.save();
	};

	/**
	 * get the map state setting for the time format
	 *
	 * @returns the map state setting for the time format
	 */
	public getTimeFormat = (): TimeFormatType => {
		return this.state.settings.timeFormat;
	};

	/**
	 * set the map state show vehicles with adherence issues filter on/off
	 *
	 * @param showVehiclesWithAdherenceIssues - true when the show vehicles with adherence issues filter is on
	 */
	public setShowVehiclesWithAdherenceIssues = (showVehiclesWithAdherenceIssues: boolean): void => {
		this.state.settings.showVehiclesWithAdherenceIssues = showVehiclesWithAdherenceIssues;

		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadVehicles);

		this.save();
	};

	/**
	 * get the map state show vehicles with adherence issues filter on/off
	 *
	 * @returns - true when the show vehicles with adherence issues filter is on
	 */
	public getShowVehiclesWithAdherenceIssues = (): boolean => {
		return this.state.settings.showVehiclesWithAdherenceIssues;
	};

	/**
	 * set the map state show vehicle with headway issues filter on/off
	 *
	 * @param showVehiclesWithHeadwayIssues - true when the show vehicles with headway issues filter is on
	 */
	public setShowVehiclesWithHeadwayIssues = (showVehiclesWithHeadwayIssues: boolean): void => {
		this.state.settings.showVehiclesWithHeadwayIssues = showVehiclesWithHeadwayIssues;

		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadVehicles);

		this.save();
	};

	/**
	 * get the map state show vehicle with headway issues filter on/off
	 *
	 * @returns true when the show vehicles with headway issues filter is on
	 */
	public getShowVehiclesWithHeadwayIssues = (): boolean => {
		return this.state.settings.showVehiclesWithHeadwayIssues;
	};

	/**
	 * set the map state depot filter on/off
	 *
	 * @param selectedDepotFilter - true when the map state depot filter is on
	 */
	public setSelectedDepotFilter = (selectedDepotFilter: MapDepotSelection): void => {
		this.state.settings.selectedDepotFilter = selectedDepotFilter;

		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadVehicles);

		this.save();
	};

	/**
	 * get the map state depot filter on/off
	 *
	 * @returns - true when the map state depot filter is on
	 */
	public getSelectedDepotFilter = (): MapDepotSelection => {
		return this.state.settings.selectedDepotFilter;
	};

	/**
	 * set the map state show hidden stops on/off
	 *
	 * @param showHiddenStops - true when the map state is set to show hidden stops
	 */
	public setShowHiddenStops = (showHiddenStops: boolean): void => {
		this.state.settings.showHiddenStops = showHiddenStops;

		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadStops);

		this.save();
	};

	/**
	 * get the map state show hidden stops on/off
	 *
	 * @returns - true when the map state is set to show hidden stops
	 */
	public getShowHiddenStops = (): boolean => {
		return this.state.settings.showHiddenStops;
	};

	/**
	 * set the map state to show/hide ladder stop labels
	 *
	 * @param showLadderStopLabels - the map state setting to show/hide ladder stop labels
	 */
	public setShowLadderStopLabels = (showLadderStopLabels: boolean): void => {
		this.state.settings.showLadderStopLabels = showLadderStopLabels;

		this.save();
	};

	/**
	 * get the map state setting to show/hide ladder stop labels
	 *
	 * @returns the map state setting to show/hide ladder stop labels
	 */
	public getShowLadderStopLabels = (): boolean => {
		return this.state.settings.showLadderStopLabels;
	};

	/**
	 * set the map state to show/hide ladder stop label code
	 *
	 * @param showLadderStopLabelCode - the map state setting to show/hide ladder stop code
	 */
	public setShowLadderStopLabelCode = (showLadderStopLabelCode: boolean): void => {
		this.state.settings.showLadderStopLabelCode = showLadderStopLabelCode;

		this.save();
	};

	/**
	 * get the map state setting to show/hide ladder stop label code
	 *
	 * @returns the map state setting to show/hide ladder stop label code
	 */
	public getShowLadderStopLabelCode = (): boolean => {
		return this.state.settings.showLadderStopLabelCode;
	};

	/**
	 * set the map state to show/hide ladder stop label name
	 *
	 * @param showLadderStopLabelName - the map state setting to show/hide ladder stop name
	 */
	public setShowLadderStopLabelName = (showLadderStopLabelName: boolean): void => {
		this.state.settings.showLadderStopLabelName = showLadderStopLabelName;

		this.save();
	};

	/**
	 * get the map state setting to show/hide ladder stop label name
	 *
	 * @returns the map state setting to show/hide ladder stop label name
	 */
	public getShowLadderStopLabelName = (): boolean => {
		return this.state.settings.showLadderStopLabelName;
	};

	/**
	 * set the vehicle label cluser radius
	 *
	 * @param vehicleLabelClusterRadius -  the vehicle label cluser radius
	 * @param reloadVehicleLabels - when true the vehicle labels should be reloaded
	 */
	public setVehicleLabelClusterRadius = (vehicleLabelClusterRadius: number, reloadVehicleLabels: boolean): void => {
		this.state.settings.vehicleLabelClusterRadius = vehicleLabelClusterRadius;

		this.mapEventsService.publishMapUpdate(MapUpdateType.vehicleLabelClusterRadiusUpdate, reloadVehicleLabels);

		this.save();
	};

	/**
	 * get the vehicle label cluser radius
	 *
	 * @returns the vehicle label cluser radius
	 */
	public getVehicleLabelClusterRadius = (): number => {
		return this.state.settings.vehicleLabelClusterRadius;
	};

	/**
	 * init saved route views
	 */
	public initViewRoutes = (): void => {
		this.mapEventsService.publishMapUpdate(MapUpdateType.initViewRoutes);
	};

	/**
	 * add a route to the map state
	 *
	 * @param route - the route to add
	 */
	public addRoute = (route: MapRoute): void => {
		this.state.routes[route.routeId] = route;

		this.mapEventsService.publishMapUpdate(MapUpdateType.addRoute, route.routeId);

		this.save();
	};

	/**
	 * remove a route from the map state
	 *
	 * @param route - the route to remove
	 */
	public removeRoute = (route: MapRoute): void => {
		delete this.state.routes[route.routeId];

		this.mapEventsService.publishMapUpdate(MapUpdateType.removeRoute, route.routeId);

		this.save();
	};

	/**
	 * get a route from the map state
	 *
	 * @param routeId - the route id;
	 * @returns the route
	 */
	public getRoute = (routeId: string): MapRoute => {
		return this.state.routes[routeId] || null;
	};

	/**
	 * get all routes from the map state
	 *
	 * @param routeId - the route id
	 * @returns the routes
	 */
	public getRoutes = (): MapRoutes => {
		return this.state.routes;
	};

	/**
	 * clear all routes from the map state
	 */
	public clearRoutes = (): void => {
		for (const routeId in this.state.routes) {
			delete this.state.routes[routeId];
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.clearRoutes);

		this.save();
	};

	/**
	 * get all stops that were added due to being on a route
	 *
	 * @returns all stops that were added due to being on a route
	 */
	public getStopsOnRoute = (): MapStops => {
		const stopsOnRoute: MapStops = {};

		for (const routeId in this.state.routes) {
			this.state.routes[routeId].stops.forEach((stop: RouteAssociatedStop) => {
				stopsOnRoute[stop.stopCode] = this.getStop(stop.stopCode);
			});
		}

		return stopsOnRoute;
	};

	/**
	 * initialize view stops
	 */
	public initViewStops = (): void => {
		this.mapEventsService.publishMapUpdate(MapUpdateType.initViewStops);
	};

	/**
	 * add a stop to the map state
	 *
	 * @param stop - the stop to add
	 */
	public addStop = (stop: MapStop): void => {
		this.state.stops[stop.stopCode] = stop;

		this.mapEventsService.publishMapUpdate(MapUpdateType.addStop, stop.stopCode);

		this.save();
	};

	/**
	 * add a stops to the map state
	 *
	 * @param stops - the stops to add
	 */
	public addStops = (stops: MapStops): void => {
		const stopCodes: string[] = [];

		for (const stopCode in stops) {
			const stop: MapStop = stops[stopCode];

			this.state.stops[stopCode] = stop;

			stopCodes.push(stopCode);
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.addStops, stopCodes);

		this.save();
	};

	/**
	 * remove a stop from the map state
	 *
	 * @param stopCode - the stop to remove
	 */
	public removeStop = (stopCode: string): void => {
		delete this.state.stops[stopCode];

		this.mapEventsService.publishMapUpdate(MapUpdateType.removeStop, stopCode);

		this.save();
	};

	/**
	 * remove a stops from the map state
	 *
	 * @param stopCodes - the stops to remove
	 */
	public removeStops = (stopCodes: Array<string>): void => {
		stopCodes.forEach((stopCode: string) => {
			delete this.state.stops[stopCode];
		});

		this.mapEventsService.publishMapUpdate(MapUpdateType.removeStops, stopCodes);

		this.save();
	};

	/**
	 * get a stop from the map state
	 *
	 * @param stopCode - the stop code;
	 * @returns the stop
	 */
	public getStop = (stopCode: string): MapStop => {
		return this.state.stops[stopCode] || null;
	};

	/**
	 * get stops from the map state
	 *
	 * @returns the stops
	 */
	public getStops = (): MapStops => {
		return this.state.stops;
	};

	/**
	 * clear all stops from the map state
	 */
	public clearStops = (): void => {
		for (const stopCode in this.state.stops) {
			delete this.state.stops[stopCode];
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.clearStops);

		this.save();
	};

	/**
	 * reload stops
	 */
	public reloadStops = (): void => {
		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadStops);
	};

	/**
	 * add a vehicle to the map state
	 *
	 * @param vehicle - the vehicle to add
	 */
	public addVehicle = (vehicle: MapVehicle): void => {
		this.state.vehicles[vehicle.vehicleId] = vehicle;

		this.mapEventsService.publishMapUpdate(MapUpdateType.addVehicle, vehicle.vehicleId);

		this.save();
	};

	/**
	 * add vehicles to the map state
	 *
	 * @param vehicles - the vehicles to add
	 */
	public addVehicles = (vehicles: MapVehicles): void => {
		const vehicleIds: string[] = [];

		for (const vehicleId in vehicles) {
			this.state.vehicles[vehicleId] = vehicles[vehicleId];

			vehicleIds.push(vehicleId);
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.addVehicles, vehicleIds);

		this.save();
	};

	/**
	 * remove a vehicle from the map state
	 *
	 * @param vehicleId - the vehicle id to remove
	 */
	public removeVehicle = (vehicleId: string): void => {
		delete this.state.vehicles[vehicleId];

		this.mapEventsService.publishMapUpdate(MapUpdateType.removeVehicle, vehicleId);

		this.save();
	};

	/**
	 * update vehicles within the map state
	 *
	 * @param vehicles - the vehicles to update
	 */
	public updateVehicles = (vehicles: MapVehicles): void => {
		const vehicleIds: string[] = [];

		for (const vehicleId in vehicles) {
			this.state.vehicles[vehicleId] = vehicles[vehicleId];

			vehicleIds.push(vehicleId);
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.updateVehicles, vehicleIds);

		this.save();
	};

	/**
	 * remove vehicle from the map state
	 *
	 * @param vehicleIds - to remove
	 */
	public removeVehicles = (vehicleIds: Array<string>): void => {
		vehicleIds.forEach((vehicleId: string) => {
			if (this.state.vehicles[vehicleId]) {
				delete this.state.vehicles[vehicleId];
			}
		});

		this.mapEventsService.publishMapUpdate(MapUpdateType.removeVehicles, vehicleIds);

		this.save();
	};

	/**
	 * get a vehicle from the map state
	 *
	 * @param vehicleId - the vehicle id;
	 * @returns the vehicle
	 */
	public getVehicle = (vehicleId: string): MapVehicle => {
		return this.state.vehicles[vehicleId] || null;
	};

	/**
	 * get vehicles from the map state
	 *
	 * @param vehicles - the vehicle id;
	 * @returns the vehicle
	 */
	public getVehicles = (): MapVehicles => {
		return this.state.vehicles;
	};

	/**
	 * get vehicles from the map state that are part of a route
	 *
	 * @returns the vehicles on a route
	 */
	public getVehiclesOnRoute = (): MapVehicles => {
		const vehiclesOnRoute: MapVehicles = {};

		for (const routeId in this.state.routes) {
			this.state.routes[routeId].vehicles.forEach((vehicle: RouteAssociatedVehicle) => {
				vehiclesOnRoute[vehicle.vehicleId] = this.getVehicle(vehicle.vehicleId);
			});
		}

		return vehiclesOnRoute;
	};

	/**
	 * clear vehicles from the map state
	 */
	public clearVehicles = (): void => {
		for (const vehicleId in this.state.vehicles) {
			delete this.state.vehicles[vehicleId];
		}

		// always clear out vehicles stored against routes when clearing our main vehicle list.
		// The update process cross references both lists to determine between a vehicle being new to a route
		// or one we know about and has been manually removed. if we don't keep both lists in sync then it
		// can get in to a state where vehicles are ignored that should be added
		for (const routeId in this.state.routes) {
			this.state.routes[routeId].vehicles = [];
		}

		this.mapEventsService.publishMapUpdate(MapUpdateType.clearVehicles);

		this.save();
	};

	/**
	 * reload vehicles
	 */
	public reloadVehicles = (): void => {
		this.mapEventsService.publishMapUpdate(MapUpdateType.reloadVehicles);
	};

	/**
	 * deep clone the whole map state object
	 *
	 * @returns the cloned object
	 */
	public clone = (): MapState => {
		return ObjectHelpers.deepCopy(this.state);
	};

	/**
	 * reset the map state object back to defaults
	 */
	public reset = (): void => {
		if (this.state) {
			const currentState: MapState = ObjectHelpers.deepCopy(this.state);

			// typically we want to reset back to default state with the exception of certian properties we want
			// to keep such as map settings
			const resetState: MapState = {
				navViewOpen: this.defaultState.navViewOpen,
				navHistory: this.defaultState.navHistory,
				mode: currentState.mode,
				mapMode: this.defaultState.mapMode,
				replay: {
					showReplayControl: this.defaultState.replay.showReplayControl,
					currentReplayTime: this.defaultState.replay.currentReplayTime,
					currentReplayId: this.defaultState.replay.currentReplayId,
				},
				liveUpdatesPaused: this.defaultState.liveUpdatesPaused,
				fullScreen: currentState.fullScreen,
				location: {
					// keep current values for now but we'll later overwrite with agency default location
					lat: currentState.location.lat,
					lon: currentState.location.lon,
					zoom: currentState.location.zoom,
					offsetAdjust: true,
					clearTrackedVehicle: true,
				},
				routesToLocate: null,
				activeEntity: null,
				trackedVehicleId: null,
				trailedVehicle: {
					vehicleId: null,
					vehicleColor: null,
					trailPoints: [],
				},
				routes: {},
				vehicles: {},
				stops: {},
				settings: {
					baseMap: currentState.settings.baseMap,
					showVehicleLabels: currentState.settings.showVehicleLabels,
					showVehicleLabelId: currentState.settings.showVehicleLabelId,
					showVehicleLabelRoute: currentState.settings.showVehicleLabelRoute,
					showVehicleLabelDepartureAdherence: currentState.settings.showVehicleLabelDepartureAdherence,
					showVehicleLabelHeadway: currentState.settings.showVehicleLabelHeadway,
					showVehicleLabelStatus: currentState.settings.showVehicleLabelStatus,
					showVehicleLabelBlock: currentState.settings.showVehicleLabelBlock,
					showVehicleLabelPassengers: currentState.settings.showVehicleLabelPassengers,
					showVehiclesWithAdherenceIssues: currentState.settings.showVehiclesWithAdherenceIssues,
					showVehiclesWithHeadwayIssues: currentState.settings.showVehiclesWithHeadwayIssues,
					showVehiclesAtDepot: currentState.settings.showVehiclesAtDepot,
					showHiddenStops: currentState.settings.showHiddenStops,
					showLadderStopLabels: currentState.settings.showLadderStopLabels,
					showLadderStopLabelCode: currentState.settings.showLadderStopLabelCode,
					showLadderStopLabelName: currentState.settings.showLadderStopLabelName,
					selectedDepotFilter: this.defaultState.settings.selectedDepotFilter,
					displayPriority: currentState.settings.displayPriority,
					colorVehicleBy: currentState.settings.colorVehicleBy,
					timeFormat: currentState.settings.timeFormat,
					vehicleLabelClusterRadius: currentState.settings.vehicleLabelClusterRadius,
				},
				authorityId: this.defaultState.authorityId,
				firstRefresh: true,
			};

			this.state = resetState;
		} else {
			this.state = ObjectHelpers.deepCopy(this.defaultState);
		}

		this.setCurrentAuthority();

		this.save();
	};

	/**
	 * merge the current object with any missing default data. This ensure that users that have missing data in their
	 * map state (due to new development) receive the default data for those properties
	 */
	private mergeDefaultData = (): void => {
		// add any defaults that have been added, since the state was last saved
		this.state = ObjectHelpers.defaults(this.state, this.defaultState);

		// Object defaults doesn't seem to handle nested objects so handle separately - ideally rework the method to do so
		this.state.settings = ObjectHelpers.defaults(this.state.settings, this.defaultState.settings);

		this.state.settings.selectedDepotFilter = ObjectHelpers.defaults(
			this.state.settings.selectedDepotFilter,
			this.defaultState.settings.selectedDepotFilter
		);

		this.state.replay = ObjectHelpers.defaults(this.state.replay, this.defaultState.replay);
		this.state.location = ObjectHelpers.defaults(this.state.location, this.defaultState.location);
	};

	/**
	 * get the current agency
	 *
	 * @returns the current agency
	 */
	private getCurrentAgency = (): SelectedAgency => {
		const selectedAgency: SelectedAgency = this.agenciesDataService.getSelectedAgency();

		return selectedAgency;
	};

	/**
	 * set the current authority - set map to agency default
	 */
	private setCurrentAuthority = (): void => {
		const selectedAgency: SelectedAgency = this.getCurrentAgency();

		this.state.authorityId = selectedAgency.authority_id;

		// get the full Agency object
		const agency: Agency = this.agenciesDataService.getAgencyDetail(selectedAgency.authority_id, selectedAgency.agency_id);

		this.setLocationToAgencyDefault(agency);
	};

	/**
	 * set map to agency default
	 *
	 * @param selectedAgency - the agency to set
	 */
	private setLocationToAgencyDefault = (selectedAgency: Agency): void => {
		const defaultMapLocation: ConfigMapLocation = this.config.getDefaultMapLocation();

		this.state.location = {
			lat: defaultMapLocation.lat,
			lon: defaultMapLocation.lng,
			zoom: this.config.getDefaultZoomLevel(),
			offsetAdjust: true,
			clearTrackedVehicle: true,
		};

		// defensive code to avoid a scenario where the agency had a default location of NaN. This just ensures the map still loads
		if (
			selectedAgency.latitude !== null &&
			!isNaN(parseFloat(selectedAgency.latitude)) &&
			selectedAgency.latitude.length >= 0 &&
			selectedAgency.longitude !== null &&
			!isNaN(parseFloat(selectedAgency.longitude)) &&
			selectedAgency.longitude.length >= 0
		) {
			this.state.location = {
				lat: parseFloat(selectedAgency.latitude),
				lon: parseFloat(selectedAgency.longitude),
				zoom: this.config.getDefaultZoomLevel(),
				offsetAdjust: true,
				clearTrackedVehicle: true,
			};
		} else {
			this.logger.logError('invalid location - using default start localtion: ', selectedAgency);
		}
	};

	/**
	 * determine if agency has changed
	 * @param savedAuthorityId - the saved authority id
	 * @returns true if the agency has changed since the user last ran up the map
	 */
	private hasAgencyChanged = (savedAuthorityId: string): boolean => {
		const currentAuthorityId: string = this.getCurrentAgency().authority_id;

		// current agency has changed since user last visited the map - reset
		if (savedAuthorityId !== currentAuthorityId) {
			return true;
		}

		return false;
	};

	/**
	 * save the current map state to the browser cache
	 */
	private save = (): void => {
		this.storageService.set(this.stateKey + '-' + this.currentUsername, this.state, StorageType.local);
	};
}
