import { Injectable } from '@angular/core';

import { DeviceDetectorService } from 'ngx-device-detector';

/**
 * Service responsible for browser device detection.
 */
@Injectable({
	providedIn: 'root',
})
export class BrowserDetectionService {
	constructor(private deviceService: DeviceDetectorService) {}

	/**
	 * gets the users browser name
	 *
	 * @returns the name of the browser
	 */
	public getBrowserName = (): string => {
		return this.deviceService.browser;
	};

	/**
	 * gets the users browser version
	 *
	 * @returns the version of the browser
	 */
	public getBrowserVersion = (): string => {
		return this.deviceService.browser_version;
	};

	/**
	 * returns whether the users browser is running on a mobile device or not
	 *
	 * @returns true if the device is a mobile device
	 */
	public isMobile = (): boolean => {
		return this.deviceService.isMobile();
	};

	/**
	 * gets the users browsers user agent
	 *
	 * @returns the user agent
	 */
	public getUserAgent = (): string => {
		return this.deviceService.userAgent;
	};
}
