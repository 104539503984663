/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import L from 'leaflet';

import { MapStateService } from './map-state.service';
import { MapEventsService } from './map-events.service';

import { BaseMapLayerConfig, BaseMapLayerConfigLayerOptions, BaseMapLayersConfig, BaseMapLayerType, BaseMapType } from '../types/types';
import { CONFIG_TOKEN } from '@cubicNx/libs/utils';
import { AgencyConfig } from '../../../config/types/types';

@Injectable({
	providedIn: 'root',
})
export class MapBaseService {
	private mapBoxApiKey: string = this.config.getMapBoxApiKey();

	private readonly mapBoxBaseUrl: string = 'https://api.mapbox.com/styles/v1/';
	private readonly mapBoxBaseUrl2: string = '/tiles/{z}/{x}/{y}?';
	private readonly detailsWidth: number = 445;
	private readonly fullNavBarWidth: number = 70;

	private readonly baseMapLayers: BaseMapLayersConfig = {
		Streets: {
			name: 'MapBox Umo IQ',
			format: BaseMapLayerType.vector,
			layerOptions: {
				accessToken: this.mapBoxApiKey,
				style: 'mapbox://styles/mapbox/streets-v9',
			},
		},
		Dark: {
			name: 'MapBox Dark',
			format: BaseMapLayerType.vector,
			layerOptions: {
				accessToken: this.mapBoxApiKey,
				style: 'mapbox://styles/mapbox/dark-v9',
			},
		},
		Light: {
			name: 'MapBox Light',
			format: BaseMapLayerType.vector,
			layerOptions: {
				accessToken: this.mapBoxApiKey,
				style: 'mapbox://styles/mapbox/light-v9',
			},
		},
		Traffic: {
			name: 'MapBox Traffic',
			format: BaseMapLayerType.vector,
			layerOptions: {
				accessToken: this.mapBoxApiKey,
				style: 'mapbox://styles/mapbox/traffic-day-v2',
			},
		},
		Satellite: {
			name: 'MapBox Satellite',
			format: BaseMapLayerType.vector,
			layerOptions: {
				zoomOffset: -1,
				accessToken: this.mapBoxApiKey,
				style: 'mapbox://styles/mapbox/satellite-streets-v9',
			},
		},
		RasterStreets: {
			name: 'MapBox Umo IQ',
			format: BaseMapLayerType.raster,
			url: this.mapBoxBaseUrl + 'mapbox/streets-v9' + this.mapBoxBaseUrl2 + 'access_token=' + this.mapBoxApiKey,
			layerOptions: {
				zoomOffset: -1,
				tileSize: 512,
				accessToken: this.config.getMapBoxApiKey(),
			},
		},
		RasterDark: {
			name: 'MapBox Dark',
			format: BaseMapLayerType.raster,
			url: this.mapBoxBaseUrl + 'mapbox/dark-v9' + this.mapBoxBaseUrl2 + 'access_token=' + this.mapBoxApiKey,
			layerOptions: {
				zoomOffset: -1,
				tileSize: 512,
				accessToken: this.config.getMapBoxApiKey(),
			},
		},
		RasterLight: {
			name: 'MapBox Light',
			format: BaseMapLayerType.raster,
			url: this.mapBoxBaseUrl + 'mapbox/light-v9' + this.mapBoxBaseUrl2 + 'access_token=' + this.mapBoxApiKey,
			layerOptions: {
				zoomOffset: -1,
				tileSize: 512,
				accessToken: this.config.getMapBoxApiKey(),
			},
		},
		RasterTraffic: {
			name: 'MapBox Traffic',
			format: BaseMapLayerType.raster,
			url: this.mapBoxBaseUrl + 'mapbox/traffic-day-v2' + this.mapBoxBaseUrl2 + 'access_token=' + this.mapBoxApiKey,
			layerOptions: {
				zoomOffset: -1,
				tileSize: 512,
				accessToken: this.config.getMapBoxApiKey(),
			},
		},
		RasterSatellite: {
			name: 'MapBox Satellite',
			format: BaseMapLayerType.raster,
			url: this.mapBoxBaseUrl + 'mapbox/satellite-streets-v9' + this.mapBoxBaseUrl2 + 'access_token=' + this.mapBoxApiKey,
			layerOptions: {
				zoomOffset: -1,
				tileSize: 512,
				accessToken: this.config.getMapBoxApiKey(),
			},
		},
	};

	private mapInstance: any = null;
	private baseMapLayer: any = null;

	constructor(
		private mapStateService: MapStateService,
		private mapEventsService: MapEventsService,
		@Inject(CONFIG_TOKEN) private config: AgencyConfig
	) {}

	/**
	 * zoom in our out on the map
	 * @param zoomChange - the zoom change value (negative when zooming out)
	 */
	public zoomInOrOut = (zoomChange: number): void => {
		const curZoom: number = this.mapInstance.getZoom();
		const modWidth: number = parseFloat(((this.detailsWidth + this.fullNavBarWidth) / 2).toFixed(7));
		const lat: number = parseFloat(this.mapInstance.getCenter().lat.toFixed(7));
		const lng: number = parseFloat(this.mapInstance.getCenter().lng.toFixed(7));

		let latLng: L.LatLng = L.latLng(lat, lng);

		const targetPoint: any = this.mapInstance.project(latLng, curZoom).add([modWidth, 0]);
		const modlatLng: any = this.mapInstance.unproject(targetPoint, curZoom);

		modlatLng.lat = parseFloat(modlatLng.lat.toFixed(7));
		modlatLng.lng = parseFloat(modlatLng.lng.toFixed(7));

		latLng = L.latLng(modlatLng.lat, modlatLng.lng);

		this.mapInstance.setZoomAround(latLng, curZoom + zoomChange, { animate: true });
	};

	/**
	 * initialise the service with t he current map isntance and set the default/current base map
	 *
	 * @param mapInstance - the current map intsance
	 */
	public init = (mapInstance: L.Map): void => {
		this.mapInstance = mapInstance;

		this.initBaseMap();
	};

	/**
	 * get the current base map type
	 *
	 * @returns the current base map type
	 */
	public getBaseMap = (): BaseMapType => {
		return this.mapStateService.getBaseMap();
	};

	/**
	 * set the base map type
	 *
	 * @param baseMapType - the base map type to set
	 */
	public setBaseMap = (baseMapType: BaseMapType): void => {
		if (this.baseMapLayer) {
			this.mapInstance.removeLayer(this.baseMapLayer);
		}

		this.setBaseLayer(baseMapType);

		this.mapStateService.setBaseMap(baseMapType);
	};

	/**
	 * get the base map layer config
	 *
	 * @param baseMapType - the base map type
	 * @returns the base map layer config
	 */
	public getBaseMapLayer = (baseMapType: BaseMapType): BaseMapLayerConfig => {
		const mapKey: string = this.getBaseKeyfromType(baseMapType);

		return this.baseMapLayers[mapKey];
	};

	/**
	 * init the base map
	 */
	private initBaseMap = (): void => {
		const baseMapType: BaseMapType = this.getBaseMap();

		this.setBaseMap(baseMapType);
	};

	/**
	 * set the base layer from config
	 * @param baseMapType - the base map type
	 */
	private setBaseLayer = async (baseMapType: BaseMapType): Promise<void> => {
		const mapKey: string = this.getBaseKeyfromType(baseMapType);

		const baseMapLayer: BaseMapLayerConfig = this.baseMapLayers[mapKey];

		switch (baseMapLayer.format) {
			case BaseMapLayerType.vector:
				this.loadVector(baseMapLayer.layerOptions);
				break;
			case BaseMapLayerType.raster:
				this.loadRaster(baseMapLayer.url, baseMapLayer.layerOptions);
				break;
		}

		this.mapEventsService.publishSettingsBaseMapChangeComplete();
	};

	/**
	 * get the the base map key
	 *
	 * @param baseMapType  - the base map type
	 * @returns the base map key
	 */
	private getBaseKeyfromType = (baseMapType: BaseMapType): string => {
		let mapBaseLayerKeyKey: string = 'RasterStreets';

		switch (baseMapType) {
			case BaseMapType.streets:
				mapBaseLayerKeyKey = 'Streets';
				break;
			case BaseMapType.dark:
				mapBaseLayerKeyKey = 'Dark';
				break;
			case BaseMapType.light:
				mapBaseLayerKeyKey = 'Light';
				break;
			case BaseMapType.traffic:
				mapBaseLayerKeyKey = 'Traffic';
				break;
			case BaseMapType.satellite:
				mapBaseLayerKeyKey = 'Satellite';
				break;
			case BaseMapType.rasterStreets:
				mapBaseLayerKeyKey = 'RasterStreets';
				break;
			case BaseMapType.rasterDark:
				mapBaseLayerKeyKey = 'RasterDark';
				break;
			case BaseMapType.rasterLight:
				mapBaseLayerKeyKey = 'RasterLight';
				break;
			case BaseMapType.rasterTraffic:
				mapBaseLayerKeyKey = 'RasterTraffic';
				break;
			case BaseMapType.rasterSatellite:
				mapBaseLayerKeyKey = 'RasterSatellite';
				break;
			default:
				mapBaseLayerKeyKey = 'RasterStreets';
		}

		return mapBaseLayerKeyKey;
	};

	/**
	 * load the vector map type layer
	 *
	 * @param layerOptions - the layer options
	 */
	private loadVector = (layerOptions: BaseMapLayerConfigLayerOptions): void => {
		const mapboxGLOptions: L.MapboxGLOptions = {
			style: layerOptions.style,
			accessToken: layerOptions.accessToken,
		};

		this.baseMapLayer = L.mapboxGL(mapboxGLOptions).addTo(this.mapInstance);
	};

	/**
	 * load the raster map type layer
	 *
	 * @param url - the url to request
	 * @param layerOptions - the layer options
	 */
	private loadRaster = (url: string, layerOptions: BaseMapLayerConfigLayerOptions): void => {
		const tileLayerOptions: L.TileLayerOptions = {
			accessToken: layerOptions.accessToken,
			zoomOffset: layerOptions.zoomOffset,
			tileSize: layerOptions.tileSize,
		};

		this.baseMapLayer = L.tileLayer(url, tileLayerOptions).addTo(this.mapInstance);
	};
}
