import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Config, CONFIG_TOKEN } from '@cubicNx/libs/utils';
import { Observable } from 'rxjs';
import { timeout } from 'rxjs/operators';

// Intercept any errors
@Injectable({
	providedIn: 'root',
})
export class TimeoutInterceptor implements HttpInterceptor {
	constructor(@Inject(CONFIG_TOKEN) private config: Config) {}

	/**
	 * intercept http requests to handle errors
	 *
	 * @param req - the http request
	 * @param next - the http handler
	 * @returns the http event
	 */
	public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		const timeoutValue: string | number = req.headers.get('timeout') || this.config.getDefaultHttpRequestTimeout();
		const timeoutValueNumeric: number = Number(timeoutValue);

		return next.handle(req).pipe(timeout(timeoutValueNumeric));
	}
}
