import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';

import { AppRouterModule } from './app-router.module';

import { UtilsModule } from '@cubicNx/libs/utils';
import { HomeModule } from './home/home.module';
import { LoginModule } from './support-features/login/login.module';

import { AppComponent } from './app.component';
import { MainComponent } from './main/main/main.component';

import { CONFIG_TOKEN } from '../../../../libs/utils/src/lib/utils/config/types';
import { config } from './config/config';

@NgModule({
	imports: [BrowserModule, AppRouterModule, UtilsModule, HomeModule, LoginModule],
	declarations: [AppComponent, MainComponent],
	providers: [
		{ provide: LocationStrategy, useClass: HashLocationStrategy },
		{ provide: CONFIG_TOKEN, useValue: config },
	],
	bootstrap: [AppComponent],
})
export class AppModule {}
