/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Inject, Injectable } from '@angular/core';

import { MapStateService } from './map-state.service';
import { MapUtilsService } from './map-utils.service';
import { ColorUtilityService, CONFIG_TOKEN } from '@cubicNx/libs/utils';

import { TrailedVehicle, TrailedVehiclePoint, TrailedVehiclePoints } from '../types/types';
import { Vehicle } from '../../../support-features/vehicles/types/api-types';
import { VehicleColor } from '../../../support-features/vehicles/types/types';
import { AgencyConfig } from '../../../config/types/types';

import moment, { Moment } from 'moment';

@Injectable({
	providedIn: 'root',
})
export class MapVehicleTrailService {
	private readonly vehicleUnassignedState: string = 'unassigned';

	constructor(
		@Inject(CONFIG_TOKEN) private config: AgencyConfig,
		private colorUtilityService: ColorUtilityService,
		private mapUtilsService: MapUtilsService,
		private mapStateService: MapStateService
	) {}

	/**
	 * toggle the vehicle trails for a vehicle
	 *
	 * @param vehicle - the vehicle to set trails on
	 */
	public toggleTrailedVehicle = (vehicle: Vehicle): void => {
		// toggle the vehicle in our mapstate between null (tracking off) and our vehicle
		if (vehicle.vehicle_id === this.mapStateService.getTrailedVehicleId()) {
			this.clearTrailedVehicle();
		} else {
			this.initTrailedVehicle(vehicle);
		}
	};

	/**
	 * initialize the trailed vehicle and create the initial trail point
	 *
	 * @param vehicle - the vehicle to set trails on
	 */
	public initTrailedVehicle = (vehicle: Vehicle): void => {
		const vehicleColor: VehicleColor = {
			backgroundColor: vehicle.current_state.route?.route_color
				? this.colorUtilityService.getColor(vehicle.current_state.route.route_color)
				: this.config.getDefaultVehicleColor(),
			foreColor: vehicle.current_state.route?.route_text_color
				? this.colorUtilityService.getColor(vehicle.current_state.route.route_text_color)
				: this.config.getDefaultVehicleTextColor(),
		};

		const newTrailPoint: TrailedVehiclePoint = this.createVehicleTrailPoint(
			vehicle.current_state.lat,
			vehicle.current_state.lon,
			vehicle.current_state.veh_state,
			vehicle.current_state.heading,
			vehicleColor.backgroundColor
		);

		const trailedVehicle: TrailedVehicle = {
			vehicleId: vehicle.vehicle_id,
			vehicleColor,
			trailPoints: [newTrailPoint],
		};

		this.mapStateService.setTrailedVehicle(trailedVehicle);
	};

	/**
	 * update the trails of the vehicle
	 *
	 * @param lat - the latitude of the vehicle
	 * @param lon - the latitude of the vehicle
	 * @param vehState - the vehicle state
	 * @param heading - the vehicle heading
	 * @param routeColor - the vehicle route color
	 */
	public updateTrailedVehicle = (lat: number, lon: number, vehState: string, heading: number, routeColor: number): void => {
		let refreshTrailPoints: boolean = false;

		const trailedVehicle: TrailedVehicle = this.mapStateService.getTrailedVehicle();

		const trailPoints: TrailedVehiclePoints = trailedVehicle.trailPoints;

		for (let i: number = trailPoints.length - 1; i >= 0; i--) {
			const trailDuration: moment.Duration = moment.duration(moment().diff(trailPoints[i].timeStamp));

			if (trailDuration.asSeconds() > this.config.getDefaultMaxVehicleTrailMs() / 1000) {
				trailPoints.splice(i, 1);
				refreshTrailPoints = true;
			}

			if (trailPoints[i]) {
				trailPoints[i].opacity = this.getOpacity(trailPoints[i].timeStamp);
			}
		}

		const vehicleColor: string = routeColor ? this.colorUtilityService.getColor(routeColor) : this.config.getDefaultVehicleColor();

		const newTrailPoint: TrailedVehiclePoint = this.createVehicleTrailPoint(lat, lon, vehState, heading, vehicleColor);

		const locationChanged: boolean = trailPoints.length > 0 && lat !== trailPoints[0].lat && lon !== trailPoints[0].lng;

		if (locationChanged) {
			trailPoints.unshift(newTrailPoint);
			refreshTrailPoints = true;
		}

		if (trailPoints.length === 0) {
			trailPoints.push(newTrailPoint);
			refreshTrailPoints = true;
		}

		if (refreshTrailPoints) {
			this.mapStateService.setTrailedVehicle(trailedVehicle);
		}
	};

	/**
	 * clear the trailed vehicle
	 */
	public clearTrailedVehicle = (): void => {
		this.mapStateService.setTrailedVehicle(null);
	};

	/**
	 * get the currently trailed vehicle id
	 *
	 * @returns the currently trailed vehicle id
	 */
	public getTrailedVehicleId = (): string => {
		return this.mapStateService.getTrailedVehicleId();
	};

	/**
	 * get the currently trailed vehicle
	 *
	 * @returns the currently trailed vehicle
	 */
	public getTrailedVehicle = (): TrailedVehicle => {
		return this.mapStateService.getTrailedVehicle();
	};

	/**
	 * create a vehicle trail point
	 *
	 * @param lat - the latitude of the vehicle
	 * @param lon - the latitude of the vehicle
	 * @param vehState - the vehicle state
	 * @param heading - the vehicle heading
	 * @param routeColor - the vehicle route color
	 * @returns the vehicle trail object ready for display
	 */
	private createVehicleTrailPoint = (
		lat: number,
		lon: number,
		vehState: string,
		heading: number,
		routeColor: string
	): TrailedVehiclePoint => {
		return {
			lat,
			lng: lon,
			angle: this.mapUtilsService.getIconAngleFromHeading(heading),
			timeStamp: moment(),
			updatedColor: vehState === this.vehicleUnassignedState ? this.config.getDefaultVehicleColor() : routeColor,
			opacity: 1,
		};
	};

	/**
	 * get the vehicle trail opacity based on how old the trail is
	 *
	 * @param timestamp - the timestamp the trail was added to check against
	 * @returns the trail opacity
	 */
	private getOpacity = (timestamp: Moment): number => {
		const maxTrailTimeSec: number = this.config.getDefaultMaxVehicleTrailMs() / 1000;
		const ageSeconds: number = moment.duration(moment().diff(timestamp)).asSeconds();

		let opacity: number = 1;

		if (ageSeconds > maxTrailTimeSec * 0.1 && ageSeconds <= maxTrailTimeSec * 0.2) {
			opacity = 0.9;
		} else if (ageSeconds > maxTrailTimeSec * 0.2 && ageSeconds <= maxTrailTimeSec * 0.3) {
			opacity = 0.8;
		} else if (ageSeconds > maxTrailTimeSec * 0.3 && ageSeconds <= maxTrailTimeSec * 0.4) {
			opacity = 0.7;
		} else if (ageSeconds > maxTrailTimeSec * 0.4 && ageSeconds <= maxTrailTimeSec * 0.5) {
			opacity = 0.6;
		} else if (ageSeconds > maxTrailTimeSec * 0.5 && ageSeconds <= maxTrailTimeSec * 0.6) {
			opacity = 0.5;
		} else if (ageSeconds > maxTrailTimeSec * 0.6 && ageSeconds <= maxTrailTimeSec * 0.7) {
			opacity = 0.4;
		} else if (ageSeconds > maxTrailTimeSec * 0.7 && ageSeconds <= maxTrailTimeSec * 0.8) {
			opacity = 0.3;
		} else if (ageSeconds > maxTrailTimeSec * 0.8 && ageSeconds <= maxTrailTimeSec * 0.9) {
			opacity = 0.2;
		} else if (ageSeconds > maxTrailTimeSec * 0.9 && ageSeconds <= maxTrailTimeSec) {
			opacity = 0.1;
		} else if (ageSeconds > maxTrailTimeSec) {
			opacity = 0;
		}

		return opacity;
	};
}
