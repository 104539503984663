/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { HttpClient } from '@angular/common/http';

import { LogLevel, ObjectHelpers } from '@cubicNx/libs/utils';

import { AgencyConfig, ConfigMapLocation, ConfigServiceNow, ConfigValidator } from './types/types';

/**
 * attempts to read app settings from a remote server and merges in with the configured defaults
 *
 * @param http - the HTTP client
 */
const init = async (http: HttpClient): Promise<void> => {
	return new Promise((resolve) => {
		http.get('appsettings', {}).subscribe(
			async (response: any) => {
				ObjectHelpers.mergeDeep(config, response);

				console.log('Using server app settings for Agency Portal', config);
				resolve();
			},
			(error: any) => {
				console.warn(
					'App settings not available when running locally - using default app settings for Agency Portal',
					config,
					error
				);
				resolve();
			}
		);
	});
};

/**
 * gets the configured default minimum log level
 *
 * @returns the confogured minimum log level
 */
const getMinLogLevel = (): LogLevel => {
	const logLevelStr: any = config.minLogLevel;
	const minLogLevel: LogLevel = +LogLevel[logLevelStr];

	// Return configured minimum log level or default 'info' level if the log level has been mis-configured
	return minLogLevel !== undefined && !Number.isNaN(minLogLevel) ? minLogLevel : LogLevel.info;
};

/**
 * gets the configured service now settings
 *
 * @returns the configured service now settings
 */
const getServiceNowSettings = (): ConfigServiceNow => {
	return {
		url: config.service_now_url,
		width: config.service_now_width,
		height: config.service_now_height,
	};
};

const getAppId = (): string => config.appId;
const getApiUrl = (): string => config.apiPath;
const getDefaultPageSize = (): number => config.defaultListSize;
const getPaginationMaxSize = (): number => config.paginationMaxSize;
const getPaginationMaxNumPages = (): number => config.paginationMaxNumPages;
const getDefaultHttpRequestTimeout = (): number => config.defaultHttpRequestTimeout;

const getCurrentUserKey = (): string => config.appId + '-current-user';
const getMapBoxApiKey = (): string => config.mapbox_api_key;
const getIdle = (): number => config.idle;
const getIdleTimeout = (): number => config.idleTimeout;
const getDefaultMapLocation = (): ConfigMapLocation => config.default_map_location;
const getAdminMasterRoleName = (): string => config.adminMasterRoleName;
const getAdminAgencyRoleName = (): string => config.adminAgencyRoleName;
const getBasicUserRoleName = (): string => config.basicUserRoleName;
const getGuestUserRoleName = (): string => 'GUEST_USER';
const getUserNameValidators = (): ConfigValidator[] => config.usernameValidators;
const getPasswordValidators = (): ConfigValidator[] => config.passwordValidators;
const getDefaultZoomLevel = (): number => config.defaultZoomLevel;
const getMinZoomLevel = (): number => config.minZoomLevel;
const getMaxZoomLevel = (): number => config.maxZoomLevel;
const getShowRasterMapOption = (): boolean => typeof config.show_raster_map !== 'undefined';
const getMapRefreshRate = (): number => config.mapRefreshRate;
const getMapReplayPlaybackSpeed1RefreshRate = (): number => config.mapReplayPlaybackSpeed1RefreshRate;
const getMapReplayPlaybackSpeed2RefreshRate = (): number => config.mapReplayPlaybackSpeed2RefreshRate;
const getMapReplayPlaybackSpeed5RefreshRate = (): number => config.mapReplayPlaybackSpeed5RefreshRate;
const getMapReplayPlaybackSpeed10RefreshRate = (): number => config.mapReplayPlaybackSpeed10RefreshRate;
const getMapReplayPlaybackSpeed20RefreshRate = (): number => config.mapReplayPlaybackSpeed20RefreshRate;
const getMapReplayPlaybackSpeed60RefreshRate = (): number => config.mapReplayPlaybackSpeed60RefreshRate;
const getMapReplayInitPollPeriod = (): number => config.mapReplayInitPollPeriod;
const getMapReplayInitTimeoutPeriod = (): number => config.mapReplayInitTimeoutPeriod;
const getDefaultVehicleColor = (): string => config.defaultVehicleColor;
const getDefaultVehicleTextColor = (): string => config.defaultVehicleTextColor;
const getDefaultUnpredictableVehicleColor = (): string => config.unpredictableVehicleColor;
const getDefaultUnpredictableVehicleTextColor = (): string => config.unpredictableVehicleTextColor;
const getAdherenceVeryEarlyColor = (): string => config.adherenceVeryEarlyColor;
const getAdherenceEarlyColor = (): string => config.adherenceEarlyColor;
const getAdherenceOnTimeColor = (): string => config.adherenceOnTimeColor;
const getAdherenceLateColor = (): string => config.adherenceLateColor;
const getAdherenceVeryLateColor = (): string => config.adherenceVeryLateColor;
const getHeadwayVeryCloseColor = (): string => config.headwayVeryCloseColor;
const getHeadwayCloseColor = (): string => config.headwayCloseColor;
const getHeadwayAcceptableColor = (): string => config.headwayAcceptableColor;
const getHeadwayVeryDistantColor = (): string => config.headwayVeryDistantColor;
const getHeadwayDistantColor = (): string => config.headwayDistantColor;
const getDefaultMaxVehicleTrailMs = (): number => config.maxVehicleTrailMs;
const getLadderMaximumStopLabelLength = (): number => config.ladderMaximumStopLabelLength;
const getVehicleMovingTransitionTime = (): number => config.vehicleMovingTransitionTime;
const getVehicleMovingFastTransitionTime = (): number => config.vehicleMovingFastTransitionTime;

export const config: AgencyConfig = {
	appId: 'nextbus-agency-portal',
	minLogLevel: 'info',
	//apiPath: 'https://api.prd-1.iq.live.umoiq.com/v2.0',
	apiPath: 'https://api.dev-1.iq.play.umoiq.com/v2.0',
	//apiPath: 'https://api.stage-1.iq.work.umoiq.com/v2.0',
	//apiPath: 'http://localhost:8089/v2.0',
	defaultListSize: 25,
	paginationMaxSize: 200, // the maximum value allowed when selected page size (number of records to show) in dropdown
	paginationMaxNumPages: 5, // how many pages options we show in paging i.e << < 1 2 3 4 5 > >>
	defaultHttpRequestTimeout: 60000,

	idle: 14100,
	idleTimeout: 300,
	adminMasterRoleName: 'UMO_ADMIN',
	adminAgencyRoleName: 'Administrator',
	basicUserRoleName: 'BASIC_USER',

	// exist in config but are not used anywhere -- possibly take out
	configId: 'local',
	appVersion: 'v2.0.5-alpha',
	userNameMinLength: 1,
	userNameMaxLength: 30,
	navMinified: false,
	statusPageUrl: 'http://status.dev.nxbs2dev.com:8080/',

	usernameValidators: [
		{
			validator: '^.{0,30}$',
			error: 'username-too-long',
		},
		{
			validator: '^[\\w\\.@\\-]+$',
			error: 'username-bad-char',
		},
	],
	passwordValidators: [
		{
			validator: '^.{10,}$',
			error: 'password-too-short',
		},
		{
			validator: '^.{0,160}$',
			error: 'password-too-long',
		},
		{
			validator: '[A-Z]',
			error: 'password-needs-upper',
		},
		{
			validator: '[a-z]',
			error: 'password-needs-lower',
		},
		{
			validator: '[0-9]',
			error: 'password-needs-number',
		},
		{
			validator: '[!"#$%\\&\\(\\)\\*\\+,./:;<=>?@\\[\\]\\^_\\{|\\}~]',
			error: 'password-needs-special',
		},
	],

	default_map_location: { lat: 30.2672, lng: -97.7431 },
	service_now_url: 'https://ctscubic.service-now.com/',
	service_now_width: '800',
	service_now_height: '600',
	mapbox_api_key: 'pk.eyJ1IjoibmV4dGJ1cyIsImEiOiJjamRuNXJrNnowYXplMnFtcG95dWh0MHduIn0.R_G_ioW1qGMJbmde6-HhoA',
	show_raster_map: true,
	mapRefreshRate: 10,
	mapReplayPlaybackSpeed1RefreshRate: 10,
	mapReplayPlaybackSpeed2RefreshRate: 5,
	mapReplayPlaybackSpeed5RefreshRate: 3,
	mapReplayPlaybackSpeed10RefreshRate: 3,
	mapReplayPlaybackSpeed20RefreshRate: 3,
	mapReplayPlaybackSpeed60RefreshRate: 3,
	mapReplayInitPollPeriod: 3,
	mapReplayInitTimeoutPeriod: 300,
	defaultVehicleColor: '#666666',
	defaultVehicleTextColor: '#ffffff',
	unpredictableVehicleColor: '#ffffff',
	unpredictableVehicleTextColor: '#000000',
	adherenceVeryEarlyColor: '#b00808',
	adherenceEarlyColor: '#eb3b3b',
	adherenceOnTimeColor: '#14ad3d',
	adherenceLateColor: '#ffb852',
	adherenceVeryLateColor: '#e89015',
	headwayVeryCloseColor: '#b00808',
	headwayCloseColor: '#eb3b3b',
	headwayAcceptableColor: '#14ad3d',
	headwayVeryDistantColor: '#e89015',
	headwayDistantColor: '#ffb852',
	maxVehicleTrailMs: 300000,
	defaultZoomLevel: 11,
	minZoomLevel: 8,
	maxZoomLevel: 18,
	ladderMaximumStopLabelLength: 25,
	// needs to match delay in nb-map-vehicle-marker. Can we set this in code so both values point to the same place?
	vehicleMovingTransitionTime: 5,
	// needs to match delay in nb-map-vehicle-marker-fast-transition. Can we set this in code so both values point to the same place?
	vehicleMovingFastTransitionTime: 2,

	init: init,

	getAppId: getAppId,
	getMinLogLevel: getMinLogLevel,
	getApiUrl: getApiUrl,
	getDefaultPageSize: getDefaultPageSize,
	getPaginationMaxSize: getPaginationMaxSize,
	getPaginationMaxNumPages: getPaginationMaxNumPages,
	getDefaultHttpRequestTimeout: getDefaultHttpRequestTimeout,

	getCurrentUserKey: getCurrentUserKey,
	getMapBoxApiKey: getMapBoxApiKey,
	getIdle: getIdle,
	getIdleTimeout: getIdleTimeout,
	getDefaultMapLocation: getDefaultMapLocation,
	getAdminMasterRoleName: getAdminMasterRoleName,
	getAdminAgencyRoleName: getAdminAgencyRoleName,
	getBasicUserRoleName: getBasicUserRoleName,
	getGuestUserRoleName: getGuestUserRoleName,
	getUserNameValidators: getUserNameValidators,
	getPasswordValidators: getPasswordValidators,
	getServiceNowSettings: getServiceNowSettings,
	getDefaultZoomLevel: getDefaultZoomLevel,
	getMinZoomLevel: getMinZoomLevel,
	getMaxZoomLevel: getMaxZoomLevel,
	getShowRasterMapOption: getShowRasterMapOption,
	getMapRefreshRate: getMapRefreshRate,
	getMapReplayPlaybackSpeed1RefreshRate: getMapReplayPlaybackSpeed1RefreshRate,
	getMapReplayPlaybackSpeed2RefreshRate: getMapReplayPlaybackSpeed2RefreshRate,
	getMapReplayPlaybackSpeed5RefreshRate: getMapReplayPlaybackSpeed5RefreshRate,
	getMapReplayPlaybackSpeed10RefreshRate: getMapReplayPlaybackSpeed10RefreshRate,
	getMapReplayPlaybackSpeed20RefreshRate: getMapReplayPlaybackSpeed20RefreshRate,
	getMapReplayPlaybackSpeed60RefreshRate: getMapReplayPlaybackSpeed60RefreshRate,
	getMapReplayInitPollPeriod: getMapReplayInitPollPeriod,
	getMapReplayInitTimeoutPeriod: getMapReplayInitTimeoutPeriod,
	getDefaultVehicleColor: getDefaultVehicleColor,
	getDefaultVehicleTextColor: getDefaultVehicleTextColor,
	getDefaultUnpredictableVehicleColor: getDefaultUnpredictableVehicleColor,
	getDefaultUnpredictableVehicleTextColor: getDefaultUnpredictableVehicleTextColor,
	getAdherenceVeryEarlyColor: getAdherenceVeryEarlyColor,
	getAdherenceEarlyColor: getAdherenceEarlyColor,
	getAdherenceOnTimeColor: getAdherenceOnTimeColor,
	getAdherenceLateColor: getAdherenceLateColor,
	getAdherenceVeryLateColor: getAdherenceVeryLateColor,
	getHeadwayVeryCloseColor: getHeadwayVeryCloseColor,
	getHeadwayCloseColor: getHeadwayCloseColor,
	getHeadwayAcceptableColor: getHeadwayAcceptableColor,
	getHeadwayVeryDistantColor: getHeadwayVeryDistantColor,
	getHeadwayDistantColor: getHeadwayDistantColor,
	getDefaultMaxVehicleTrailMs: getDefaultMaxVehicleTrailMs,
	getLadderMaximumStopLabelLength: getLadderMaximumStopLabelLength,
	getVehicleMovingTransitionTime: getVehicleMovingTransitionTime,
	getVehicleMovingFastTransitionTime: getVehicleMovingFastTransitionTime,
};
