/*
 * COPYRIGHT - CUBIC TRANSPORTATION SYSTEMS, INC ("CUBIC"). ALL RIGHTS RESERVED.
 *
 * Information Contained Herein is Proprietary and Confidential.
 * The document is the property of "CUBIC" and may not be disclosed
 * distributed, or reproduced  without the express written permission of
 * "CUBIC".
 */

import { Injectable } from '@angular/core';

import { ObjectHelpers } from '@cubicNx/libs/utils';

import { MapVehiclesTooltipMarkerService } from './map-vehicles-tooltip-marker.service';
import { SelectedAgency } from '../../../../support-features/agencies/types/api-types';
import { MapVehiclesService } from '../map-vehicles.service';
import { VehicleColor } from '../../../../support-features/vehicles/types/types';
import { MapMarkerUtilsService } from './map-marker-utils.service';
import { MapActiveEntityService } from '../map-active-entity.service';
import { MapReplayService } from '../map-replay.service';
import { MapOptionsService } from '../map-options.service';
import { MapVehiclesLabelMarkerService } from './map-vehicles-label-marker.service';
import { MapNavigationService } from '../map-navigation.service';
import { MapVehiclesTrailMarkerService } from './map-vehicles-trail-marker.service';

import { DisplayPriorityType, MapModeType, MapVehicle, Markers, ModeType, VehicleDetailsActiveTab } from '../../types/types';
import { EntityType } from '../../../../utils/components/breadcrumbs/types/types';

import L, { PointExpression } from 'leaflet';

import 'leaflet-rotatedmarker';

@Injectable({
	providedIn: 'root',
})
export class MapVehiclesMarkerService {
	private mapInstance: L.Map = null;
	private markers: Markers = {};
	private selectedAgency: SelectedAgency = null;

	constructor(
		private mapVehiclesService: MapVehiclesService,
		private mapMarkerUtilsService: MapMarkerUtilsService,
		private mapActiveEntityService: MapActiveEntityService,
		private mapOptionsService: MapOptionsService,
		private mapReplayService: MapReplayService,
		private mapNavigationService: MapNavigationService,
		private mapVehiclesTooltipMarkerService: MapVehiclesTooltipMarkerService,
		private mapVehiclesLabelMarkerService: MapVehiclesLabelMarkerService,
		private mapVehiclesTrailMarkerService: MapVehiclesTrailMarkerService
	) {}

	/**
	 * initialize the vehicle marker service
	 *
	 * @param mapInstance - the current map instance
	 * @param selectedAgency - the current agency
	 */
	public init = (mapInstance: L.Map, selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapInstance = mapInstance;

		this.markers = {};

		this.mapVehiclesTooltipMarkerService.init(mapInstance, selectedAgency);
		this.mapVehiclesLabelMarkerService.init(mapInstance, selectedAgency);
		this.mapVehiclesTrailMarkerService.init(mapInstance);
	};

	/**
	 * handle the agency change and clear down any markers
	 *
	 * @param selectedAgency - the current agency
	 */
	public setAgencyChange = (selectedAgency: SelectedAgency): void => {
		this.selectedAgency = selectedAgency;

		this.mapVehiclesTooltipMarkerService.setAgencyChange(selectedAgency);
		this.mapVehiclesLabelMarkerService.setAgencyChange(selectedAgency);
		this.mapVehiclesTrailMarkerService.setAgencyChange();

		this.clearVehicles();

		this.markers = {};
	};

	/**
	 * add the vehicle to the map
	 *
	 * @param vehicleId - the vehicle id
	 * @param modeType - the mode type
	 */
	public addVehicle = (vehicleId: string, modeType: ModeType): void => {
		const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

		if (vehicle) {
			// if the vehicle is not already showing...
			if (!this.markers[vehicleId]) {
				this.markers[vehicleId] = new L.Marker(new L.LatLng(vehicle.lat, vehicle.lon), {
					icon: this.getIcon(vehicle, modeType, false),
					zIndexOffset: 4000,
					rotationAngle: vehicle.angle,
				}).addTo(this.mapInstance);

				if (this.mapOptionsService.vehicleLabelsAreShowing()) {
					this.mapVehiclesLabelMarkerService.addVehicleLabel(vehicleId);
				}

				this.markers[vehicleId].on('mouseover', () => {
					// don't show tooltips if we have labels (we can expand labels to see the same data)
					if (!this.mapOptionsService.vehicleLabelsAreShowing()) {
						this.mapVehiclesTooltipMarkerService.addVehicleTooltip(vehicleId);
					}
				});

				this.markers[vehicleId].on('mouseout', () => {
					// don't show tooltips if we have labels (we can expand labels to see the same data)
					if (!this.mapOptionsService.vehicleLabelsAreShowing()) {
						this.mapVehiclesTooltipMarkerService.removeVehicleTooltip(vehicleId);
					}
				});

				this.markers[vehicleId].on('click', async () => {
					await this.mapNavigationService.navigateToVehicleDetails(
						this.selectedAgency.authority_id,
						vehicleId,
						VehicleDetailsActiveTab.summary
					);
				});
			}
		}
	};

	/**
	 * add vehicles to the map
	 *
	 * @param vehicleIds - the vehicle id list to add
	 */
	public addVehicles = (vehicleIds: string[]): void => {
		vehicleIds.forEach((vehicleId: string) => {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

			if (vehicle) {
				if (!vehicle.hidden) {
					this.addVehicle(vehicleId, ModeType.map);
				}
			}
		});
	};

	/**
	 * remove a vehicle from the map
	 *
	 * @param vehicleId - the vehicle id to remove
	 */
	public removeVehicle = (vehicleId: string): void => {
		if (this.markers[vehicleId]) {
			this.markers[vehicleId].remove();
			delete this.markers[vehicleId];
		}

		this.mapVehiclesLabelMarkerService.removeVehicleLabel(vehicleId);
	};

	/**
	 * remove vehicles from the map
	 *
	 * @param vehicleIds - the vehicle ids to remove
	 */
	public removeVehicles = (vehicleIds: string[]): void => {
		vehicleIds.forEach((vehicleId: string) => {
			this.removeVehicle(vehicleId);
		});
	};

	/**
	 * clear all vehicles from the map
	 */
	public clearVehicles = (): void => {
		for (const vehicleId in this.markers) {
			this.removeVehicle(vehicleId);
		}
	};

	/**
	 * update vehicles on the map
	 *
	 * @param vehicleIds - the vehicle id list
	 */
	public updateVehicles = (vehicleIds: string[]): void => {
		vehicleIds.forEach((vehicleId: string) => {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

			if (vehicle && this.markers[vehicleId]) {
				if (!vehicle.hidden) {
					this.updateVehicle(vehicle);
				} else {
					this.removeVehicle(vehicle.vehicleId);
				}
			} else {
				if (!vehicle.hidden) {
					this.addVehicle(vehicleId, ModeType.map);
				}
			}
		});
	};

	/**
	 * refresh vehicle icons on the map
	 */
	public refreshVehicleIcons = (): void => {
		for (const vehicleId in this.markers) {
			if (this.markers[vehicleId]) {
				const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

				this.markers[vehicleId].setIcon(this.getIcon(vehicle, ModeType.map, false));
			}
		}
	};

	/**
	 * reload vehicle icons
	 *
	 * when updating vehicles we will also need to update the labels to keep the colors in sync.
	 */
	public reloadVehicles = (): void => {
		for (const vehicleId in this.markers) {
			this.removeVehicle(vehicleId);
		}

		for (const vehicleId in this.mapVehiclesService.getVehicles()) {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

			if (!vehicle.hidden) {
				this.addVehicle(vehicleId, ModeType.map);
			}
		}

		this.refreshVehicleLabels();

		this.refreshVehicleTrail();
	};

	/**
	 * refresh vehicle labels
	 */
	public refreshVehicleLabels = (): void => {
		this.mapVehiclesLabelMarkerService.refreshVehicleLabels();
	};

	/**
	 * disable vehicle animation
	 */
	public disableAnimation = (): void => {
		for (const vehicleId in this.markers) {
			const vehicle: MapVehicle = this.mapVehiclesService.getVehicle(vehicleId);

			this.markers[vehicleId].setIcon(this.getIcon(vehicle, ModeType.map, false));
		}
	};

	/**
	 * get the vehicle icon for the map
	 *
	 * @param vehicle - the vehicle object
	 * @param modeType - the map mode type (i.e map/ladder)
	 * @param vehicleTransitioning - whether the vehicle is currently transitioning/animating on the map
	 * @returns a leaflet icon to display on the map
	 */
	public getIcon = (vehicle: MapVehicle, modeType: ModeType, vehicleTransitioning: boolean = false): L.DivIcon => {
		const markerSize: number[] = this.getIconSize();

		const className: string = 'nb-map-vehicle-marker-container';

		return L.divIcon({
			className,
			html: '' + this.getIconSvg(vehicle, modeType, vehicleTransitioning) + '',
			iconAnchor: [Math.floor(markerSize[0] / 2), Math.floor(markerSize[1] / 2)],
			iconSize: markerSize as PointExpression,
		});
	};

	/**
	 * refresh vehicles label layer
	 *
	 * @param reloadVehicleLabels - when true reloading the vehicle labels
	 */
	public refreshVehiclesLabelLayer = (reloadVehicleLabels: boolean): void => {
		this.mapVehiclesLabelMarkerService.refreshVehiclesLabelLayer(reloadVehicleLabels);
	};

	/**
	 * update vehicle trail
	 */
	public updateVehicleTrail = (): void => {
		this.mapVehiclesTrailMarkerService.updateVehicleTrail();
	};

	/**
	 * refresh vehicle trail
	 */
	private refreshVehicleTrail = (): void => {
		this.mapVehiclesTrailMarkerService.refreshVehicleTrail();
	};

	/**
	 * get the vehicle icon size
	 *
	 * @returns the vehicle icon size
	 */
	private getIconSize = (): number[] => {
		return this.mapMarkerUtilsService.getVehicleMarkerSize();
	};

	/**
	 * get the vehicle icon svg
	 *
	 * @param vehicle - the vehicle
	 * @param modeType - the mode type i.e map/ladder
	 * @param vehicleTransitioning - if the vehicle is currently transitioning/animating
	 * @returns the vehicle icon svg
	 */
	private getIconSvg = (vehicle: MapVehicle, modeType: ModeType, vehicleTransitioning: boolean): string => {
		let className: string = 'nb-vehicle-icon-shadow';

		let svgContent: any = null;

		if (vehicle.vehicleMoving) {
			svgContent = this.getSvgBusMovingContent(modeType, vehicle.vehicleId, vehicle.vehicleColor);
		} else {
			svgContent = this.getSvgBusStoppedContent(modeType, vehicle.vehicleId, vehicle.vehicleColor);
		}

		if (this.mapActiveEntityService.isActiveEntity(vehicle.vehicleId, EntityType.vehicle)) {
			className = 'nb-vehicle-icon-shadow-active';
		}

		// the main className for the object doesn't get updated when the vehicle changes so put in the SVG instead and
		// use css workaround to set parent style when
		// child exists - see .nb-map-vehicle-marker-container:has(.nb-vehicle-icon-vehicles-top) {
		if (vehicleTransitioning) {
			if (this.mapOptionsService.getMapMode() === MapModeType.replay) {
				if (this.mapReplayService.getReplayPollInterval() <= 3000) {
					className += ' nb-map-vehicle-marker-fast-transition';
				} else {
					className += ' nb-map-vehicle-marker';
				}
			} else {
				className += ' nb-map-vehicle-marker';
			}
		}

		if (this.mapOptionsService.getDisplayPriority() === DisplayPriorityType.vehicles) {
			className += ' nb-vehicle-icon-vehicles-top';
		} else {
			className += ' nb-vehicle-icon-vehicles-bottom';
		}

		return (
			'<svg class="scaling-svg ' +
			className +
			'" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18.22 32.36">' +
			svgContent +
			'</svg>'
		);
	};

	/**
	 * get the generic vehicle svg icon and styling
	 *
	 * @param modeType - the map mode
	 * @param vehicleId - the vehicle id
	 * @param vehicleColor - the vehicle color
	 * @returns the generic vehicle svg icon and styling
	 */
	private getSvgDef = (modeType: ModeType, vehicleId: string, vehicleColor: VehicleColor): string => {
		return (
			'<defs>' +
			'<style data-test="map.vehicle" authorityId="' +
			'" vehicleId="' +
			this.getContext(modeType, vehicleId) +
			'">' +
			'.inner-fill' +
			this.getContext(modeType, vehicleId) +
			'{fill:' +
			vehicleColor.backgroundColor +
			';}' +
			'.outer-fill' +
			this.getContext(modeType, vehicleId) +
			'{fill:' +
			vehicleColor.foreColor +
			';}' +
			'</style>' +
			'</defs>'
		);
	};

	/**
	 * get bus stopped vehicle icon content
	 *
	 * @param modeType - the map mode
	 * @param vehicleId - the vehicle id
	 * @param vehicleColor - the vehicle color
	 * @returns the bus stopped vehicle icon content
	 */
	private getSvgBusStoppedContent = (modeType: ModeType, vehicleId: string, vehicleColor: VehicleColor): string => {
		return (
			this.getSvgDef(modeType, vehicleId, vehicleColor) +
			'<rect class="inner-fill' +
			this.getContext(modeType, vehicleId) +
			'" x="1.04" y="1.04" width="16.14" height="30.27" rx="1.74" ry="1.74"/>' +
			'<path class="outer-fill' +
			this.getContext(modeType, vehicleId) +
			'" d="M15.44,2.08a.7.7,0,0,1,.7.7V29.57a.7.7,0,0,1-.7.7H2.78a.7.7,0,0,1-.7-.7V2.78a.7.7,0,0,1,.7-.7H15.44m0-2.08H2.78A2' +
			'.79,2.79,0,0,0,0,2.78V29.57a2.79,2.79,0,0,0,2.78,2.78H15.44a2.79,2.79,0,0,0,2.78-2.78V2.78A2.79,2.79,0,0,0,15.44,0Z"/>' +
			'<rect class="outer-fill' +
			this.getContext(modeType, vehicleId) +
			'" x="5.06" y="12.13" width="8.09" height="8.09"/>'
		);
	};

	/**
	 * get bus moving vehicle icon content
	 *
	 * @param modeType - the map mode
	 * @param vehicleId - the vehicle id
	 * @param vehicleColor - the vehicle color
	 * @returns the bus moving vehicle icon content
	 */
	private getSvgBusMovingContent = (modeType: ModeType, vehicleId: string, vehicleColor: VehicleColor): string => {
		return (
			this.getSvgDef(modeType, vehicleId, vehicleColor) +
			'<rect class="inner-fill' +
			this.getContext(modeType, vehicleId) +
			'" x="1.04" y="1.04" width="16.14" height="30.27" rx="1.74" ry="1.74"/>' +
			'<path class="outer-fill' +
			this.getContext(modeType, vehicleId) +
			'" d="M15.44,2.08a.7.7,0,0,1,.7.7V29.57a.7.7,0,0,1-.7.7H2.78a.7.7,0,0,1-.7-.7V2.78a.7.7,0,0,1,.7-.7H15.44m0-2.08H2.78A2.79,' +
			'2.79,0,0,0,0,2.78V29.57a2.79,2.79,0,0,0,2.78,2.78H15.44a2.79,2.79,0,0,0,2.78-2.78V2.78A2.79,2.79,0,0,0,15.44,0Z"/>' +
			'<polygon class="outer-fill' +
			this.getContext(modeType, vehicleId) +
			'" points="4.06 21.78 2.86 20.18 9.14 15.47 15.36 20.13 14.16 21.73 9.14 17.97 4.06 21.78"/>' +
			'<polygon class="outer-fill' +
			this.getContext(modeType, vehicleId) +
			'" points="4.06 16.44 2.86 14.84 9.14 10.13 15.36 14.79 14.16 16.39 9.14 12.63 4.06 16.44"/>'
		);
	};

	/**
	 * get vehicle icon content with different context for mode type (map/ladder)
	 *
	 * create style id to support dynamic inline styling which is created for each vehicle - see vehicleStyleId usage
	 * Without this the same 2 inline <style> tags will be created and both the map and the ladder will lookup the same style rather
	 * than each refrerring to their own. Whilst not currently as issue as they are both always the same, if one was to differ in
	 * the future and the style content was different for the map and ladder then they would both still inherit the same style
	 * i.e the first one set in the final rendered we page. Note the map and ladder are both always loaded (one is just hidden)
	 *
	 * Replace white space from within vehicle id as some ids (seen in BCC Ferries) as this causes problems using the unique name as
	 * the style values
	 *
	 * @param modeType - the map mode
	 * @param vehicleId - the vehicle id
	 * @returns the vehicle icon content (with style context)
	 */
	private getContext = (modeType: ModeType, vehicleId: string): string => {
		let vehicleStyleId: string = null;

		switch (modeType) {
			case ModeType.map:
				vehicleStyleId = 'map-' + vehicleId.replace(/\s/g, '');
				break;
			case ModeType.ladder:
				vehicleStyleId = 'ladder-' + vehicleId.replace(/\s/g, '');
				break;
		}

		return vehicleStyleId;
	};

	/**
	 * update vehicle
	 *
	 * @param updatedVehicle - the updated map vehicle
	 */
	private updateVehicle = (updatedVehicle: MapVehicle): void => {
		let vehicleMovingTransitionTime: number = 0;

		if (updatedVehicle && this.markers[updatedVehicle.vehicleId]) {
			const currentPosition: L.LatLng = this.markers[updatedVehicle.vehicleId].getLatLng();
			const currentRotationAngle: number = this.markers[updatedVehicle.vehicleId].options.rotationAngle;

			if (
				this.vehicleMoved(
					currentPosition.lat,
					currentPosition.lng,
					currentRotationAngle,
					updatedVehicle.lat,
					updatedVehicle.lon,
					updatedVehicle.angle
				)
			) {
				vehicleMovingTransitionTime = this.mapVehiclesService.getVehicleMovingTransitionTime();
				this.moveVehicle(updatedVehicle);

				// move label after vehicle has moved. Animation doesn't work on clusters and it's preferred
				// to move it after rather than before. It makes sense to wait until it finishes moving
				// so the label can then move and the label cluster be re-evaluated
				setTimeout(() => {
					if (this.mapOptionsService.vehicleLabelsAreShowing()) {
						this.mapVehiclesLabelMarkerService.updateVehicleLabel(updatedVehicle.vehicleId);
					}

					// check again if marker exists - possible the vehicle is removed/hidden while we are waiting for this timeout to occur
					if (this.markers[updatedVehicle.vehicleId]) {
						this.markers[updatedVehicle.vehicleId].setIcon(this.getIcon(updatedVehicle, ModeType.map, false));
					}
				}, vehicleMovingTransitionTime);
			} else {
				// vehicle not moved but other properties may have changed - just update the icon
				this.markers[updatedVehicle.vehicleId].setIcon(this.getIcon(updatedVehicle, ModeType.map, false));

				if (this.mapOptionsService.vehicleLabelsAreShowing()) {
					this.mapVehiclesLabelMarkerService.updateVehicleLabel(updatedVehicle.vehicleId);
				}
			}
		}
	};

	/**
	 * determine if vehicle has moved position (or rotated)
	 *
	 * @param currentLat - the current latitude
	 * @param currentLon - the current longitude
	 * @param currentAngle - the current angle
	 * @param updatedLat - the updated latitude
	 * @param updatedLng - the updated longitude
	 * @param updatedAngle - the updated angle
	 * @returns true when vehicle has moved location or rotated
	 */
	private vehicleMoved = (
		currentLat: number,
		currentLon: number,
		currentAngle: number,
		updatedLat: number,
		updatedLng: number,
		updatedAngle: number
	): boolean => {
		return currentLat !== updatedLat || currentLon !== updatedLng || currentAngle !== updatedAngle;
	};

	/**
	 * handle the moving of a vehicle
	 *
	 * take a copy of the original vehicle (not this updated one passed in) and
	 * fudge it so we see the moving icon while the vehicle is transitioning
	 *
	 * @param vehicle - the updated vehicle
	 */
	private moveVehicle = (vehicle: MapVehicle): void => {
		if (this.markers[vehicle.vehicleId]) {
			this.markers[vehicle.vehicleId].setLatLng([vehicle.lat, vehicle.lon]);
			this.markers[vehicle.vehicleId].setRotationAngle(vehicle.angle);

			const vehicleClone: MapVehicle = ObjectHelpers.deepCopy(vehicle);

			vehicleClone.vehicleMoving = true;

			// set the icon with vehicleTransitioning true - this ensures the moving icon is set
			// regardless of what the vehicle is reporting so while it's actually moving we see the moving icon
			this.markers[vehicle.vehicleId].setIcon(this.getIcon(vehicleClone, ModeType.map, true));

			// turn the animation off once the animation has finished (avoids some strange behaviour when the map pans)
			setTimeout(() => {
				if (this.markers[vehicle.vehicleId]) {
					this.markers[vehicle.vehicleId].setIcon(this.getIcon(vehicleClone, ModeType.map, false));
				}
			}, this.mapVehiclesService.getVehicleMovingTransitionTime());
		}
	};
}
